import React from 'react';
import { Dialog} from "@material-ui/core";
import { DialogProps } from '@material-ui/core/Dialog';
import _ from 'lodash';

type Props = {
    onOk?: () => void | Promise<unknown>
    onCancel?: () => void | Promise<unknown>
} & DialogProps;

export const Popup = (props: Props) => <Dialog
            // KeyDown will lead to an onClick event. preventDefault disables this behavior.
            // See https://www.w3.org/TR/uievents/#event-type-keydown
            onKeyDown={e => {
                if (e.key === 'Enter' && props.onOk){
                    e.preventDefault();
                    e.stopPropagation();
                    const result = props.onOk();
                    if(!result)
                        return;
                    return result.catch((e) => {
                        //do nothing -- for now
                    });
                }

                if (e.key === 'Escape' && props.onCancel){
                    return props.onCancel();
                }
            }}
            {..._.omit(props, ['onOk', 'onCancel'])}
        >
    </Dialog>;
