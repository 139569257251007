import React, {createContext, useContext}  from 'react'
import ReactPDF, { View } from '@react-pdf/renderer';

export type TableProps = React.PropsWithChildren<{
    colWidths: readonly number[],
    rowStyle?: ReactPDF.Style,
    cellStyle?: ReactPDF.Style
}> & ReactPDF.ViewProps

const TableContext = createContext<{ colWidths: readonly number[], rowStyle?: ReactPDF.Style, cellStyle?: ReactPDF.Style }>(null)

export const TableSettings = createContext<{ 
    // A legacy mode for OBH's report. If this is turned on, then functional components 
    // will have to provide a style-prop and apply it to it's own root element. When turned
    // off functional components will be wrapped and style applied to the wrapper instead, meaning
    // less work for the end user.
    alwaysApplyCellStylesToChildren: boolean 
}>({ alwaysApplyCellStylesToChildren: false })

export const Table = ({ colWidths, rowStyle, cellStyle, ...props } : TableProps) => 
    <TableContext.Provider value={{ colWidths, rowStyle, cellStyle }}>
        <View {...props}>
            {props.children}
        </View>
    </TableContext.Provider>

export type RowProps = React.PropsWithChildren<{
    colWidths?: readonly number[],
    cellStyle?: ReactPDF.Style,
    wrap?: boolean
}> & ReactPDF.ViewProps

export const Row = (props: RowProps) => {
    let settings = useContext(TableSettings);
    let context = useContext(TableContext);

    let { colWidths, wrap, rowStyle, cellStyle, ...restProps } = { 
        wrap: false,
        ...context,
        ...props,
        rowStyle: { ...context.rowStyle, ...props.style },
        cellStyle: { ...context.cellStyle, ...props.cellStyle }
    };

    let numberOfChildren = React.Children.count(props.children);

    if (colWidths.length != numberOfChildren) {
        throw new Error(`You must provide as many columns as defined in the colWidths prop. Got ${numberOfChildren} children, expected ${colWidths.length} columns.`);
    }

    return (
        <View {...restProps} wrap={wrap} style={{ ...rowStyle, display: 'flex', flexDirection: 'row' }}>
            {React.Children.map(props.children, (child, i) => {
                const width = colWidths[i];

                const widthStyle = width ? { width } : { flex: 1 };

                 // If child is a View return a clone with new styles
                 if (React.isValidElement<{ style?: ReactPDF.Style }>(child)) {
                    if (settings.alwaysApplyCellStylesToChildren || child.type == 'VIEW' || child.type == 'TEXT') {
                        return React.cloneElement(child, { style: { ...widthStyle, ...cellStyle, ...child.props.style } });
                    }
                }

                 // If it's a complex child wrap it in a View with styles. 
                 // Resulting child nodes must use { style: { flex: 1 } } in order to obtain full height.
                 return (
                    <View style={{ ...widthStyle, ...cellStyle, display: 'flex' }}>
                        {child}
                    </View>
                )
            })}
        </View>
    );
}