import React from 'react'
import { StyleSheet, View, Image } from '@react-pdf/renderer';
import { Text, Page, Header2, TOCHeader, Markdown, Header1 } from './controls'
import { ObhTable, ObhHeaderRow, ObhDataRow, ObhHeaderCell, ObhDataCell } from './obh-table';
import Api from 'server/api'
import BuildingDto = Api.Modules.Obh.Queries.BuildingDto;

type Props = {
    caseName: string,
    caseDescription: string,
    buildings: readonly BuildingDto[],
    headers: TOCHeader[],
    image: Api.Modules.Obh.Queries.ImageDto,
    isCompleted: boolean
};

const imageStyles = StyleSheet.create({
    image: {
        alignSelf: 'center',
        maxWidth: 472,
        maxHeight: 435,
    },
    imageContainer: {
        marginBottom: 10
    },
    description: {
        marginTop: 3
    }
});

export const Estate = (props: Props) => props.buildings.length &&
    <Page size="A4" caseName={props.caseName} showWatermark={!props.isCompleted}>

        <Header1 headers={props.headers}>Ejendommen</Header1>        
        {props.image && <View style={imageStyles.imageContainer}>
            <Image style={imageStyles.image}
                source={'/api/' + props.image.url} />
            <Text style={imageStyles.description}>{props.image.description}</Text>
        </View>}

        <Header2 headers={props.headers}>Bygninger</Header2>
        <ObhTable colWidths={[61, 61, 87, 71, 61, 131]}>
            <ObhHeaderRow fixed>
                <ObhHeaderCell left>Bygning</ObhHeaderCell>
                <ObhHeaderCell>Matr. areal</ObhHeaderCell>
                <ObhHeaderCell>Bygningsareal</ObhHeaderCell>
                <ObhHeaderCell style={{padding: '7,0,5,0',}}>Opførelsesår</ObhHeaderCell>
                <ObhHeaderCell>EM-nr.</ObhHeaderCell>
                <ObhHeaderCell>EM gyldighedsperiode</ObhHeaderCell>
            </ObhHeaderRow>
            {
                props.buildings.map((x, i) =>
                    <ObhDataRow key={i}>
                        <ObhDataCell left>{x.name}</ObhDataCell>
                        <ObhDataCell>{x.cadastralArea}</ObhDataCell>
                        <ObhDataCell>{x.buildingArea}</ObhDataCell>
                        <ObhDataCell>{x.buildingYear}</ObhDataCell>
                        <ObhDataCell>{x.energyLabelSerialIdentifier}</ObhDataCell>
                        <ObhDataCell>{x.energyLabelValidityPeriod}</ObhDataCell>
                    </ObhDataRow>
                )
            }
        </ObhTable>
        
        <Header2 minPresenceAhead={30} headers={props.headers}>Ejendomsbeskrivelse</Header2>
        <Markdown>{props.caseDescription}</Markdown>
    </Page>