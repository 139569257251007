import React from "react";

import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { DvError } from "custom-hooks/use-error-display";

export const ErrorListDisplay = (props: { errors: readonly DvError[] }) => {
    return props.errors && props.errors.length > 0 && <List dense={true} style={{width: '100%'}}>
            {props.errors.map(error => {
                return (
                        <ListItem key={error.message}>
                            <ListItemIcon>
                                <ErrorOutlineOutlinedIcon color='error' />
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={{color: 'error'}} primary={<span style={{whiteSpace: 'pre-line'}}>{error.message}</span>} />
                        </ListItem>
                    )
            })}
        </List>
}