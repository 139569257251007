export type Valid<T> = T;
export class Invalid {
    public readonly reason;
    
    private constructor(reason: string = 'Unknown reason.') {
        this.reason = reason;
    }

    static readonly instance = (reason?: string) => new Invalid(reason);
}

export type MaybeValid<T> = Valid<T> | Invalid;

export const isInvalid = <T>(val: MaybeValid<T>): val is Invalid => (val instanceof Invalid)

export class Maybe<T> {

    private readonly value;
    constructor(value: MaybeValid<T>) {
        this.value = value;
    }

    static invalid = <T>(reason?: string) => Maybe.of<T>(Invalid.instance(reason));
    static valid = <T>(value: Valid<T>) => new Maybe<T>(value);
    static of = <T>(value: MaybeValid<T>|Maybe<T>): Maybe<T> => value instanceof Maybe ? value : new Maybe<T>(value);
    static unpackIfMaybe = <T>(value: Maybe<T>|T): MaybeValid<T> => value instanceof Maybe ? value.unpack() : value;

    isInvalid = () => {
        return isInvalid(this.value);
    }

    require = (predicate: (valid: Valid<T>) => boolean, errorReason?: string): Maybe<T> => {
        if (isInvalid(this.value)) return this;
        if (!predicate(this.value)) return Maybe.of<T>(Invalid.instance(errorReason));
        return this;
    }

    notNullAndDefined = () => {
        return this.notNull().defined();
    }

    defined = () => {
        return this.require(value => value !== undefined, 'Value was undefined.');
    }

    notNull = () => {
        return this.require(value => value !== null, 'Value was null.');
    }

    map = <TSelector>(selector: (valid: Valid<T>) => Valid<TSelector>): Maybe<TSelector> => {
        if (isInvalid(this.value)) return this as unknown as Maybe<TSelector>;
        return Maybe.of(selector(this.value));
    }

    unpack = (): MaybeValid<T> => {
        return this.value;
    }

    validOrThrow = (): Valid<T> => {
        if (isInvalid(this.value)) throw new Error(`Value is invalid. ${this.value.reason}`);
        return this.value;
    }
    
    caseOf = <TReturn>(cases: { valid: (valid: Valid<T>) => TReturn , invalid: (invalid: Invalid) => TReturn }): TReturn => {
        if (isInvalid(this.value)) {
            return cases.invalid(this.value);
        }
        return cases.valid(this.value);
    }

    caseOfAsync = async <TReturn>(cases: { valid: (just: Valid<T>) => Promise<TReturn>, invalid: (invalid: Invalid) => Promise<TReturn> }): Promise<TReturn> => {
        if (isInvalid(this.value)) {
            return await cases.invalid(this.value);
        }
        return await cases.valid(this.value);
    }

    ifValid = async (validAction: (just: Valid<T>) => void|Promise<unknown>) => {
        if (!isInvalid(this.value)) {
            await validAction(this.value);
        }
    }

    supressErrorAsInvalid = <TSelector>(selector: (valid: Valid<T>) => Valid<TSelector>): Maybe<TSelector> => {
        try {
            return this.map(selector);
        }
        catch (error: unknown) {
            return Maybe.invalid<TSelector>(error.toString());
        }
    }
}