import Component from "component";
import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, WithStyles, withStyles, Grid, Toolbar } from "@material-ui/core";
import styles from './styles'
import { Button } from "controls/button";
import _ = require("lodash");
import Api from "server/api";
import GetCaseTask = Api.Cases.Queries.GetCaseTask;
import { Subscription } from "rxjs";
import TaskType = Api.Cases.TaskType
import { OneTimeTask } from "./one-time-task";
import { RecurringTask } from "./recurring-task";

type Props = {
    open: boolean;
    caseId: string;
    taskId: string;
    priorities: Api.Modules.Priority.Queries.GetPriorities.PriorityDto[];
    onClose: () => void;
} & WithStyles<typeof styles>

export default withStyles(styles)(class EditTaskPopup extends Component<Props, GetCaseTask.Result>{

    state = null as GetCaseTask.Result
    
    commandsSubscription: Subscription;

    componentDidMount() {
        this.commandsSubscription = this.server.Commands.subscribe(this.getTask);
        this.getTask();
    };

    componentWillUnmount = () =>
        this.commandsSubscription.unsubscribe();

    render() {

        return (
            <Dialog
                maxWidth='lg'
                open={this.props.open}
                onClose={this.props.onClose}
                onEnter={this.getTask}>

                <DialogTitle>Rediger opgaven</DialogTitle>

                <DialogContent>                   
                    { this.showContent() }                           
                </DialogContent>

                <DialogActions>
                    <Toolbar>
                        <Button locked={false} label='Luk' onClick={this.props.onClose} />
                    </Toolbar>
                </DialogActions>
            </Dialog>);
    }

    getTask = () => {
        if(!this.props.open) return;

        this.server.query<GetCaseTask.Result>(
            new GetCaseTask({ caseId: this.props.caseId, taskId: this.props.taskId })
        ).subscribe(taskResult => { this.setState( taskResult ); })
    };

    showContent = () => {
        if(!this.state) return null;

        if (this.state.taskType === TaskType.OneTimeTask) {
            return <OneTimeTask 
                        caseId={this.props.caseId} 
                        priorities={this.props.priorities}
                        task={this.state.task as Api.Cases.Queries.GetCaseTask.OneTimeTaskDto}
                        updateTask={(task) => this.setState({ task })}
                    />
        } else if (this.state.taskType === TaskType.RecurringTask) {
            return <RecurringTask 
                        caseId={this.props.caseId} 
                        priorities={this.props.priorities}
                        task={this.state.task as Api.Cases.Queries.GetCaseTask.RecurringTaskDto}                   
                        updateTask={(task) => this.setState({ task })}
                    />
        } else return null;
    }
})