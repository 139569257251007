import React, { useState } from "react";
import { Table, TableHead, TableCell, TableRow, TableBody, Toolbar, Grid, Typography } from "@material-ui/core";
import { Button } from "controls/button";
import { TypeMappingsRow } from "./type-mappings-row";
import { useCommandSubscription, useImmutableArrayState, useServer } from "custom-hooks";

import { CreateTypeMappingsPopup } from "./create-type-mappings-popup";
import { UpdateTypeMappingsPopup } from "./update-type-mappings-popup";
import { ImportTypeMappingsPopup } from "./import-type-mappings-popup";

import Api from "server/api";
import GetTypeMappings = Api.Users.Queries.GetTypeMappings;
import TypeMappingsDto = Api.Users.Queries.GetTypeMappings.TypeMappingsDto;
import TemplateGroupDto = Api.Templates.Queries.GetCompanyTemplates.TemplateGroupDto;

type Props = {
    companyId: string;
    templates: readonly TemplateGroupDto[];
}

export const TypeMappings = (props: Props) => {

    const server = useServer();

    const [typeMappings, setTypeMappings] = useImmutableArrayState<TypeMappingsDto>();

    const [updateTypeMappings, setUpdateTypeMappings] = useState<TypeMappingsDto | null>(null);

    const [openCreatePopup, setOpenCreatePopup] = useState(false);
    const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
    const [openDuplicatePopup, setOpenDuplicatePopup] = useState(false);

    const openUpdateTypeMappings = async (id: string) => {
        server.query<readonly TypeMappingsDto[]>(new GetTypeMappings({})).subscribe(typeMappings => {
            const typeMapping = typeMappings.find(x => x.id == id);
            setTypeMappings(typeMappings);
            setUpdateTypeMappings(typeMapping);
            setOpenUpdatePopup(true);
        });
    }

    const isNameUnique = (name: string) => !typeMappings.some(mapping => mapping.name === name);

    useCommandSubscription(() => {
        server.query<readonly TypeMappingsDto[]>(new GetTypeMappings({})).subscribe(setTypeMappings);
    }, [server]);

    return (
        <div style={{ width: '100%' }}>
            <Toolbar>
                <Grid item xs={6}>
                    <Typography variant="h6">Klassifikationer</Typography>    
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={2} justify='flex-end'>
                        <Grid item>
                            <Button label='Importér' onClick={() => setOpenDuplicatePopup(true)} />
                        </Grid>
                        <Grid item>
                            <Button label='Opret' onClick={() => setOpenCreatePopup(true)} />
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell variant='head'>Navn</TableCell>
                        <TableCell variant='head'></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{typeMappings.map(row => (
                    <TypeMappingsRow key={row.id}
                        typeMappings={row}
                        templates={props.templates}
                        updateTypeMappings={openUpdateTypeMappings}
                    />))}
                </TableBody>
            </Table>
            <CreateTypeMappingsPopup companyId={props.companyId} open={openCreatePopup} setOpen={setOpenCreatePopup} canExecute={isNameUnique} />
            <UpdateTypeMappingsPopup typeMappings={updateTypeMappings} open={openUpdatePopup} setOpen={setOpenUpdatePopup} />
            <ImportTypeMappingsPopup companyId={props.companyId} open={openDuplicatePopup} setOpen={setOpenDuplicatePopup} canExecute={isNameUnique} />
        </div>
    );
};