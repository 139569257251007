import React from 'react';

import styles from '../styles'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);

export const DescriptionContent = () => {
    const styles = useStyles();

    return (
    <div className={styles.descriptionContent}>
        <div className={styles.subHeader}>
            D&V Plan fra NRGi Systems A/S er en app til tilstandsregistrering af bygninger og udarbejdelse af troværdige, overskuelige drift- og vedligeholdelsesplaner –
            med et digitalt grundlag.
        </div>
        <div className={styles.descriptionText} style={{ marginTop: 10 }}>
            NRGi Systems A/S og vores mange hundrede daglige, professionelle brugere i Energy10 har gennem mere end ti år perfektioneret bygningsregistrering –
            og nu bruger vi vores afprøvede teknologier og kendte metoder i en D&V-app, der kan anvendes af alle bygningsprofessionelle, uafhængigt af
            om man vil bruge dataet i sit FM-system eller blot have en grundig plan på papir.
        </div>

        <div className={styles.subHeader}>Kom hurtigt i gang</div>
        <div className={styles.descriptionText}>
            Hvis du vil vide mere - eller bare i gang med det samme, så kontakt Jacob Hansen og få en uforpligtende snak.
        </div >

        <div className={styles.contactOuterContainer}>
            <img src='/assets/jacob_hansen.jpg' width={132} height={132} style={{ borderRadius: '50%' }} />
            <div className={styles.contactInnerContainer}>
                <div>
                    <div className={styles.subHeader}>Jacob Nørgaard Hansen</div>
                    <div>Telefon: 70 22 93 10</div>
                    <div>
                        E-mail: <a className={styles.link} href='mailto: support@nrgisystems.dk'> support@nrgisystems.dk</a>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}