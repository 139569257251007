import * as React from "react";
import { Grid, GridList, makeStyles } from "@material-ui/core";
import { ImageUploadButton } from 'controls/images/image-upload-button';
import { WarningPopup } from "controls/warning-popup";
import { LogoTile } from './logo-tile';
import * as _ from 'lodash';
import { useState } from "react";

export type Logo = {
    id: string;
    url: string;
}

type Props = {
    logo: Logo
    onFileSelected: (files: File[]) => Promise<void>
    deleteLogo: () => Promise<void>
}

const useStyles = makeStyles(theme => ({
    contentContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: (theme.spacing(100)) - (theme.spacing(4))
    }
}));

export const LogoContainer = (props: Props) => {

    const styles = useStyles();

    const [deleteLogoConfirmation, setDeleteLogoConfirmation] = useState(false);

    const confirmDeleteLogo = () => setDeleteLogoConfirmation(true);

    const deleteLogo = async () => {
        if (!deleteLogoConfirmation) return;

        setDeleteLogoConfirmation(false);

        await props.deleteLogo();
    };

    return (
        <Grid container>
            {
                props.logo
                    ? <GridList cellHeight={180} cols={3} spacing={1} className={styles.contentContainer}>
                        <LogoTile key={props.logo.id}
                            logo={props.logo}
                            downloadTitle='firmalogo'
                            onFileSelected={props.onFileSelected}
                            confirmDeleteLogo={confirmDeleteLogo} />
                    </GridList>
                    : <ImageUploadButton label='UPLOAD LOGO' onFileSelected={props.onFileSelected} />
            }

            <WarningPopup
                onOk={() => deleteLogo()}
                onClose={() => setDeleteLogoConfirmation(false)}
                isOpen={!!deleteLogoConfirmation}
                title='Slet logo?'
                message='Du er ved at slette logoet. Er du sikker?'
                okText='SLET'
                cancelText='FORTRYD'
            />
        </Grid>
    );
}