import React, { PropsWithChildren } from "react";
import { makeStyles } from "@material-ui/core";
import _ from 'lodash';
import { ActionButton } from 'controls/button/action-button'
import { Tooltip } from "../tooltip";
import cx from 'classnames';
import { useWriteLock } from 'custom-hooks';
import { Lockable } from "utils/lockable";

type Props = PropsWithChildren<{
    tooltip: string;
    onClick?: () => void;
    download?: string;
    href?: string;
    className?: string;
}> & Lockable

const useStyles = makeStyles({
    inlineStyles: {
        display: 'inline-flex', // Apply the display behaviour of the action button to the wrapper that is used when tooltips are disabled see the Tooltip component
        '&:last-child': {
            marginRight: 0
        }
    },
    imageToolbarButton: {
        marginRight: 10
    }
});

export const ImageToolButton = (props: Props) => {
    const styles = useStyles();
    const writeLock = useWriteLock(props);
    
    return (
        <Tooltip disabledHelperClassName={cx(styles.inlineStyles, styles.imageToolbarButton)} title={props.tooltip}>
            <ActionButton
                className={cx(styles.imageToolbarButton, props.className)}
                onClick={props.onClick}
                download={props.download}
                href={props.href}
                locked={writeLock}>
                {props.children}
            </ActionButton>
        </Tooltip>)
}