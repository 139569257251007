import { createStyles, Theme } from "@material-ui/core";

export default (theme: Theme) => createStyles({
    container: {
        position: 'absolute',
        width: 500,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontFamily: 'Open Sans',
        fontSize: 14
    },
    logo: {
        width: '80%',
        marginBottom: 20
    },
    acceptContainer: {
        display: 'flex'
    },
    acceptText: {
        fontWeight: 'bold',
        marginLeft: 10,
        cursor: 'pointer',
        paddingTop: 6
    },
    licenseContent: {
        color: 'black'
    },
    approveButton: {
        background:'lightgrey'
    }
});