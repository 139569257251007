
import React, { forwardRef } from "react";
import { Tooltip as MaterialUITooltip } from "@material-ui/core";
import { PropsWithChild } from "utils";
import { useWriteLock } from "custom-hooks";
import { Lockable } from "utils/lockable";

type Props = PropsWithChild<{
    title: React.ReactNode;
    'aria-label'?: string;
    disabledHelperClassName?: string;
}> & Lockable

export const Tooltip = (props: Props) => {

    const writeLock = useWriteLock(props);

    const children = writeLock
    // Disabled elements do not fire events in material ui, but tooltips rely on certian event emitted from thier children in order to work properly. 
    // Wrapping the children in an enabled div, fixes this problem, since the div can still fire events.
    // Later note: This wrapping results in a visual/css error on the budget page, we disable it for this particular usage on that page.
    ? <div className={props.disabledHelperClassName ?? ''}>{props.children}</div> 
    : props.children;

    return (
        <MaterialUITooltip 
            title={props.title}
            aria-label={props["aria-label"] ?? (typeof props.title === 'string' ? props.title : undefined)}
            arrow={true}
            >
            {children}
        </MaterialUITooltip>);;
}