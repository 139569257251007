import React from "react";
import { ImageTile, ImageTileProps } from './image-tile';
import { SortableElement } from 'react-sortable-hoc';
import _ from 'lodash';

export const SortableImageTile = SortableElement((props: ImageTileProps) => {
    // key has to be different after each 'sort' to make sure SortableElement is re-rendered
    // therefore key should be data-dependent, and not just an index number
    return <ImageTile {...props} key={props.image.id} />;
});
