import React = require("react");
import { TableRow, TableCell, makeStyles } from "@material-ui/core";

import DeleteIcon from '@material-ui/icons/Delete'
import Api from "server/api";
import { TaskYearsColumns } from "./task-years-columns";
import styles from './styles'
import cx from 'classnames'
import { WarningPopup } from 'controls/warning-popup';
import { scrollIntoViewIfNeeded, move } from "utils";
import { useState, useRef, useLayoutEffect } from "react";
import { useCommand } from "custom-hooks/use-command";
import { Tooltip } from "controls/tooltip";
import { IconButton } from "controls/button/icon-button";
import { ext } from "utils";

type Props = {
    gotoCard: () => void,
    gotoTask: () => void,
    caseId: string,
    line: Api.Cases.Queries.GetBudget.TaskLine,
    isNewest: boolean,
    updateLine: (line: Api.Cases.Queries.GetBudget.TaskLine) => void
}

const useStyles = makeStyles(styles);

export const TaskRow = (props : Props) =>
{
    const [hover, setHover] = useState(false);
    const [warningPopupOpen, setWarningPopupOpen] = useState(false);

    const ref = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (ref.current && props.isNewest) {
            scrollIntoViewIfNeeded(ref.current);
        }
    }, []); //note, this should only occur on mount, so don't recreate effect when values change

    const writeTaskField = useCommand((toYearOffset: number) => 
        new Api.Cases.Commands.WriteTaskField({
            caseId: props.caseId,
            cardId: props.line.cardId,
            taskId: props.line.id,
            fieldName: 'OffsetYear',
            value: toYearOffset
        }));

    const onYearMoved = async (fromYearOffset: number, toYearOffset: number) => {
        props.updateLine({
            ...props.line, 
            years: move(props.line.years, fromYearOffset, toYearOffset)
        });
        await writeTaskField(toYearOffset);
    };

    const confirmDelete = () => setWarningPopupOpen(true);
    
    const deleteTask = useCommand(() => new Api.Cases.Commands.DeleteTask({
        caseId: props.caseId,
        cardId: props.line.cardId,
        taskId: props.line.id,
    }));

    const closePopup = () => setWarningPopupOpen(false);

    const onOk = async () => {
        closePopup();
        await deleteTask();
    }

    const startHover = () => setHover(true)
    const endHover = () => setHover(false)

    const styles = useStyles(props);

    return <TableRow className={cx(styles.taskRow, { [styles.taskRowHighlight]: hover })}
        onMouseEnter={startHover}
        onMouseLeave={endHover} >
        <TableCell className={cx(styles.deleteCell, styles.taskRowDeleteCell)}>
            <>
                {
                    hover &&
                    <Tooltip title='Slet opgaven'>
                        <IconButton
                            onClick={confirmDelete}>
                            <DeleteIcon className={styles.deleteIcon} />
                        </IconButton>
                    </Tooltip>
                }
                <div ref={ref} />
            </>
        </TableCell>
        <TableCell onClick={props.gotoCard}><div className={styles.taskTextCell}>{props.line.location}</div></TableCell>
        <TableCell onClick={props.gotoTask}><div className={styles.taskTextCell}>{props.line.title}</div></TableCell>
        {ext(props.line.extensions, 'TaskPriority', ex => <TableCell className={styles.taskRowPriorityColumn}>{ex.label}</TableCell>)}
        <TaskYearsColumns hover={hover} years={props.line.years} onMove={onYearMoved} />
        <WarningPopup
            onOk={onOk}
            onClose={closePopup}
            isOpen={warningPopupOpen}
            title='Slet opgaven?'
            message='Du er ved at slette opgaven. Er du sikker?'
            okText='SLET'
            cancelText='FORTRYD' />
    </TableRow>
}
