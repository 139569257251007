import { Theme, createStyles } from "@material-ui/core/styles";

export default (theme: Theme) => createStyles({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(3),
        maxWidth: theme.spacing(100),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    updateContainer: {
        alignItems: 'center'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    buildings: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    building: {
        maxWidth: theme.spacing(100)
    },
    buildingHeader: {
        margin: 0,
        maxWidth: theme.spacing(100),
        padding: theme.spacing(3),
        paddingRight: theme.spacing(7),
    },
    buildingTool: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        maxWidth: theme.spacing(100)
    },
    updateToolbar: {
        display: 'flex',
        alignItems: 'center'
    },
    spacer: {
        '@media (max-width: 1700px)': {
            minHeight: theme.spacing(21)
        }
    }
})