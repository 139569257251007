import { useState, useEffect, PropsWithChildren } from "react";
import React from "react";
import { List, ListItem, ListItemIcon, ListItemText, makeStyles, createStyles, Theme, ListItemSecondaryAction, WithStyles } from "@material-ui/core";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import RemoveIcon from '@material-ui/icons/Remove';
import { DvError } from "custom-hooks";
import { IconButton } from "./button/icon-button";

type Severity = 'error' | 'warning';
type PanelMessageProps = PropsWithChildren<Props & { severity: Severity }>;

type Props = { onRemove : () => void }
export type ErrorProps = Props & { errors: readonly DvError[] }
export type WarningProps = PropsWithChildren<Props>

const useStyles = makeStyles(createStyles({
    list: {
        paddingTop: 0,
        paddingBottom: 0
    }
}));

const useSingleErrorStyles = makeStyles((theme:Theme) => createStyles({
    item: {
        padding: theme.spacing(1),
        backgroundColor: (props: PanelMessageProps) => props.severity === 'error' ? theme.palette.error.main : theme.palette.warning.main,
        width: '100%'
    }
}));

const PanelMessage = (props : PanelMessageProps) => {
    const styles = useSingleErrorStyles(props);
    const [show, setShow] = useState(true);

    if(!show) return null;

    return (
        <ListItem className={styles.item}>
            <ListItemIcon>
                <ErrorOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ color: "textPrimary" }} primary={props.children} />
            <ListItemSecondaryAction>
                <IconButton 
                    edge="end" 
                    aria-label="remove" 
                    onClick={() => { 
                        props.onRemove(); 
                        setShow(false); 
                    }}>
                    <RemoveIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    )
};

const Panel = (props: PropsWithChildren<{}>) => {

    const styles = useStyles();
    if(!props.children) return null;

    return <List dense={true} className={styles.list} style={{ width: '100%' }}>
                {props.children}
        </List>
};

export const ErrorPanel = (props: ErrorProps) => {

    return <Panel>
        {props.errors.map((error) => <PanelMessage severity='error' key={error.id} onRemove={props.onRemove}><span style={{ whiteSpace: "pre-line" }}>{error.message}</span></PanelMessage>)}
    </Panel>
}

export const WarningPanel = (props: WarningProps) => {

    return <Panel>
        {React.Children.map(props.children, (child) => <PanelMessage severity='warning' onRemove={props.onRemove}>{child}</PanelMessage>)}
    </Panel>
}