import React, { useContext } from 'react'
import ReactPdf, { Image as PdfImage, View, Text as PdfText, Page as PdfPage } from '@react-pdf/renderer';
import { SmallText, SmallLightText, SmallBoldText, TOCHeader } from './text';
import Api from 'server/api'
import _ from 'lodash';
import { PageContext, ReportThemeContext } from './context'

import MasterDataDto = Api.Cases.Queries.Reports.Es.MasterDataDto;

export const DraftWatermark = (props: { orientation: ReactPdf.Orientation }) =>
    <PdfText fixed style={{
        fontSize: 200,
        color: '#808080',
        opacity: 0.2,
        position: 'absolute',
        transform: props.orientation !== "landscape"
            ? 'translateX(-20) translateY(450) rotate(300deg)'
            : 'translateX(40) translateY(220) rotate(330deg)'
    }}>KLADDE</PdfText>

const Header = (props: {title: string, masterData: MasterDataDto}) => <View fixed style={{ position: 'absolute', top: 35, left: 48, width: '100%' }}>
    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ maxWidth: 410 }}>
            <SmallLightText style={{ marginBottom: 3 }}>{props.title} - {props.masterData.case.name}</SmallLightText>
            <SmallText>{_.compact([props.masterData.case.nickName, props.masterData.case.address]).join(' - ')}</SmallText>
        </View>
        {props.masterData.company.logoUrl && <PdfImage style={{ height: 24, alignSelf: 'flex-start' }} source={'/api/' + props.masterData.company.logoUrl} />}
    </View>
    <Divider size='thick' margin='large' />
</View>

export const Footer = (props: { masterData: MasterDataDto }) => <View fixed style={{ position: 'absolute', bottom: 35, left: 48, width: '100%', flexDirection: 'row' }}>
    <SmallLightText render={() => props.masterData.reportDate} />
    <SmallBoldText fixed style={{ marginLeft: 'auto' }} render={({ pageNumber, totalPages }) => `Side ${pageNumber} af ${totalPages}`} />
</View>

export const Divider = ({ size, margin }: { size: 'thin' | 'thick', margin: 'small' | 'large' }) => {
    const colorTheme = useContext(ReportThemeContext).color;

    return <View style={{
        width: '100%',
        marginVertical: margin == 'small' ? 8 : 15,
        height: 1,
        backgroundColor: size == 'thin' ? colorTheme.tertiaryColor : colorTheme.primaryColor
    }} />
}

export const VerticalSpacer = (props: { height?: "small" | "medium" | "large" | "xlarge" }) => {
    const height = {
        "small": 10,
        "medium": 15,
        "large": 30,
        "xlarge": 50
    }[props.height] ?? 10

    return <View style={{ height: height }} />
}

export const Page = (props: React.PropsWithChildren<ReactPdf.PageProps> & { noHeader?: boolean, noFooter?: boolean }) => {
    const context = useContext(PageContext);

    return <PdfPage size="A4" {...props} style={{
        paddingTop: props.noHeader ? 69 : 89,
        paddingBottom: props.noFooter ? 35 : 69,
        paddingLeft: 48,
        paddingRight: 37,
        ...props.style
    }}>
        {props.noHeader || context.masterData && <Header title={context.reportTitle} masterData={context.masterData} />}
        {props.children}
        {props.noFooter || context.masterData && <Footer masterData={context.masterData} />}
        {!context.isCompleted && <DraftWatermark orientation={props.orientation} />}
    </PdfPage>;
}
