import React, { useState } from "react";
import { FormGroup, Grid, Typography, Toolbar, makeStyles } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import Api from "server/api";
import { FormField } from "controls/field/form-field";
import { Images } from "controls/images/image-tiles";
import Page from 'controls/page'
import { Paper } from 'controls/paper'
import { Button } from 'controls/button'
import { Checkbox } from "controls/checkbox";
import { upperFirst } from 'lodash'
import styles from './styles'
import { CreateCard } from 'pages/toolbar/create-card';
import PageToolbar from 'pages/toolbar/toolbar';
import { CreateBuilding } from 'pages/toolbar/create-building';
import { UploadImage } from 'pages/toolbar/upload-image';
import { Templates } from "templates";
import { sguid, readSingleFile, match } from "utils";
import { createUpdater3, createUpdater1 } from "utils/updaters";
import { replaceById } from "utils/immutable-array";
import Buildings from './buildings';
import { SelectField } from "controls/field/select-field";
import cx from 'classnames'
import VerticalDivider from "controls/vertical-divider";

import { useServer, useCommandSubscription, useCommand, useCaseLockState, useOpenIds } from "custom-hooks";
import { WriteLockProvider } from 'context';
import { between } from "controls/Field/field-value-transform";
import { FieldInfo } from "controls/field";

import Result = Api.Cases.Queries.GetCase.Result;
import ProfileDto = Api.Cases.Queries.GetCase.ProfileDto;
import BuildingDto = Api.Cases.Queries.GetCase.BuildingDto;
import TypeMappingsDto = Api.Users.Queries.GetTypeMappings.TypeMappingsDto;
import Commands = Api.Cases.Commands;


type Props = {
    typeMappings: TypeMappingsDto[];
    templates: Templates;
    updateSettings: (caseId: string) => Promise<void>;
}

type State = Result

const profileToListItem = (profile: ProfileDto) => ({ label: profile.fullName, value: profile.id });

const useStyles = makeStyles(styles);

export const Case = (props: Props) => {

    const [state, setState] = useState<State|null>(null);
    const server = useServer();
    const classes = useStyles(props);
    const { caseId } = useOpenIds();
    const { locked } = useCaseLockState(caseId);
    
    useCommandSubscription(() => server.query<Result>(
            new Api.Cases.Queries.GetCase({ caseId: caseId })
    ).subscribe(setState), [server, caseId]);

    const writeCase = useCommand((x: { fieldName: string, value: any }) => 
        new Api.Cases.Commands.WriteCaseField({
            caseId: caseId,
            ...x
        }));

    const updateCase = createUpdater1(state, setState, ['case'], writeCase);
    const updateObh = createUpdater3(state, setState, ['case', 'extensions', 'obh'], writeCase);
    const updateDeas = createUpdater3(state, setState, ['case', 'extensions', 'deas'], writeCase);
    const updateEbas = createUpdater3(state, setState, ['case', 'extensions', 'ebas'], writeCase);

    const updateBuilding = (building: BuildingDto) => setState(s => ({ ...s, buildings: replaceById(building)(s.buildings) }));

    const deleteBuilding = useCommand((buildingId: string) => 
        new Commands.DeleteBuilding({
            caseId: caseId,
            buildingId: buildingId
        }));

    const onFileSelected = async (files: File[]) => {
        const onFileLoaded = async (_: File, content: string) => await uploadImage(content);

        files.map(file => {
            readSingleFile(file, onFileLoaded);
        })
    };

    const updateImage = useCommand((imageId: string, field: string, value: any) => 
        new Commands.WriteCaseImageField({
            caseId: state.case.id,
            imageId: imageId,
            fieldName: upperFirst(field),
            value: value
        }));

    const deleteImage = useCommand((imageId: string) => 
        new Commands.DeleteCaseImage({ caseId: state.case.id, imageId: imageId }));

    const uploadImage = useCommand((data: string) =>
        new Commands.UploadCaseImage({ caseId: state.case.id, imageId: `image/${sguid()}`, data: data }));

    const markImage = useCommand((imageId: string) =>
        new Commands.MarkCaseImage({ caseId: state.case.id, imageId }));

    const orderImages = useCommand((imageIds: string[]) =>
        new Commands.OrderCaseImages({
            caseId: caseId,
            imageIds: imageIds
        }));

    if (!state) return null;

    // Mapping from TypeMappingsDto (specific type for the GetCase request) to select box specific type. The label is the shown text 
    // and value will be sent via WriteCaseField, thus this value must match the Case fields type! 
    // In this case it's Revision<TypeMappingsId> which has the form { value: TypeMappingsId, commitId: Api.Guid }.
    const typeMappings = state.typeMappingsIdValues.map(x => ({ label: x.name, value: { value: x.id, commitId: x.commitId } }));

    const selectableConsultants = state.selectableConsultants.map(profileToListItem);
    const selectableApprovers = state.selectableApprovers.map(profileToListItem);
    
    const selected = state.case.typeMappingsId.value &&
        typeMappings.find(x =>
            x.value.value === state.case.typeMappingsId.value.value &&
            x.value.commitId == state.case.typeMappingsId.value.commitId);
    const selectedTypeMapping = selected && selected.value;

    const markdownInfo: FieldInfo = {
        info: <div>Formattering:<br/><b>Fed tekst</b>: **tekst**<br/>Punktopstilling:<br/>- Pkt 1<br/>- Pkt 2</div>,
        icon: <Info></Info>
    }

    return (
        <>        
        <WriteLockProvider locked={locked}>
            <Page>
                {state.case.caseCanBeUpdated 
                ?   <Paper className={cx(classes.container, classes.updateContainer)}>
                        <div className={classes.updateToolbar}>
                            <Toolbar>
                                <Button label={'Opdater'} onClick={() => props.updateSettings(state.case.id)} />
                            </Toolbar>
                            <Typography align='left' variant='body1'>{state.case.updateMessage}</Typography>
                        </div>
                    </Paper>
                : null}
                <Paper className={classes.container} header='Stamdata'>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <FormField
                                type='text'
                                label="Sagsnavn"
                                field={state.case.name}
                                onValidBlur={updateCase('name')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormField
                                type='text'
                                label="Kaldenavn"
                                field={state.case.nickname}
                                onValidBlur={updateCase('nickname')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormField
                                label='Besigtigelsesdato'
                                type='date'
                                field={state.case.inspectionDate}
                                onValidBlur={updateCase('inspectionDate')}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormField
                                type='text'
                                label='Vejnavn'
                                field={state.case.streetName}
                                onValidBlur={updateCase('streetName')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormField
                                type='text'
                                label='Husnr.'
                                field={state.case.houseNumber}
                                onValidBlur={updateCase('houseNumber')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormField
                                type='number'
                                label='Postnummer'
                                field={state.case.postalCode}
                                onValidBlur={updateCase('postalCode')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormField
                                readonly={true}
                                disabled={true}
                                type='text'
                                label='By'
                                value={state.case.postalCity.value}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormField
                                type='text'
                                label='Kunde'
                                field={state.case.customer}
                                onValidBlur={updateCase('customer')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormField
                                type='text'
                                label='Kontaktperson'
                                field={state.case.contactPerson}
                                onValidBlur={updateCase('contactPerson')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectField
                                label={
                                    match<string>(state.case.extensions)
                                        .on(ext => ext.deas, 'Rådgiver')
                                        .on(ext => ext.bjornsholm, 'Udarbejdet af')
                                        .else('Konsulent')
                                }
                                field={state.case.consultantId}
                                items={selectableConsultants}
                                onChange={updateCase('consultantId')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectField
                                optional
                                label='Godkendt af'
                                field={state.case.approvedBy}
                                items={selectableApprovers}
                                onChange={updateCase('approvedBy')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormField
                                label='Ejendomsbeskrivelse'
                                type='text'
                                multiline
                                field={state.case.description}
                                onValidBlur={updateCase('description')}
                                fieldInfo={markdownInfo}
                            />
                        </Grid>
                        {(state.case.extensions.deas &&
                            <>
                                <Grid item xs={12}>
                                    <FormField
                                        label='Seneste aktiviteter'
                                        type='text'
                                        multiline
                                        field={state.case.extensions.deas.latestActivitiesComments}
                                        onValidBlur={updateDeas('latestActivitiesComments')}
                                        fieldInfo={markdownInfo}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormField
                                        label='Ejendommens generelle tilstand'
                                        type='text'
                                        multiline
                                        field={state.case.extensions.deas.generalConditionComments}
                                        onValidBlur={updateDeas('generalConditionComments')}
                                        fieldInfo={markdownInfo}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormField
                                        label='Beskrivelse af fællesarealer'
                                        type='text'
                                        multiline
                                        field={state.case.extensions.deas.communalAreasComments}
                                        onValidBlur={updateDeas('communalAreasComments')}
                                        fieldInfo={markdownInfo}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormField
                                        label='Materiale tilgængeligt ved gennemgang'
                                        type='text'
                                        multiline
                                        field={state.case.extensions.deas.backgroundMaterialComments}
                                        onValidBlur={updateDeas('backgroundMaterialComments')}
                                        fieldInfo={markdownInfo}
                                    />
                                </Grid>
                            </>)}
                        {(state.case.extensions.ebas &&
                            <>
                                <Grid item xs={12}>
                                    <FormField
                                        label='Supplerende kommentarer til overordnet energi'
                                        type='text'
                                        multiline
                                        field={state.case.extensions.ebas.additionalEnergyComments}
                                        onValidBlur={updateEbas('additionalEnergyComments')}
                                        fieldInfo={markdownInfo}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormField
                                        label='Kommentarer til overordnet indeklima'
                                        type='text'
                                        multiline
                                        field={state.case.extensions.ebas.indoorEnvironmentComments}
                                        onValidBlur={updateEbas('indoorEnvironmentComments')}
                                        fieldInfo={markdownInfo}
                                    />
                                </Grid>
                            </>)}
                        {(state.case.extensions.obh &&
                            <>
                                <Grid item xs={12}>
                                    <FormField
                                        label='Baggrundstekst til rapport'
                                        type='text'
                                        multiline
                                        field={state.case.extensions.obh.reportBackground}
                                        onValidBlur={updateObh('reportBackground')}
                                        fieldInfo={markdownInfo}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormField
                                        label='Resumé af resultater til rapport'
                                        type='text'
                                        multiline
                                        field={state.case.extensions.obh.reportResultResume}
                                        onValidBlur={updateObh('reportResultResume')}
                                        fieldInfo={markdownInfo}
                                    />
                                </Grid>
                            </>)}
                        <Grid item xs={6}>
                            <SelectField
                                optional
                                label='Vælg klassifikationer'
                                items={typeMappings}
                                value={selectedTypeMapping}
                                onChange={updateCase('typeMappingsId')}
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper className={classes.container} header='Budget og priser'>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <FormField
                                type='number'
                                label='Indeksår'
                                field={state.case.indexYear}
                                onValidBlur={updateCase('indexYear')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormField
                                type='number'
                                label='Budgetlængde'
                                field={state.case.durationYears}
                                onValidBlur={updateCase('durationYears')}
                                transformValue={between(1, 30)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormField
                                type='number'
                                label='Prisindeks'
                                field={state.case.priceIndex}
                                onValidBlur={updateCase('priceIndex')}
                                transformValue={between(0, 2)}
                            />
                        </Grid>
                        {state.case.includeVat.show && <Grid item xs={6}>
                            <FormGroup style={{ marginLeft: "10px", marginTop: "16px" }}>
                                <Checkbox
                                    checked={state.case.includeVat.value}
                                    onChange={(e) => updateCase("includeVat")(e.target.checked)}
                                    label="Vis priser inkl. moms"
                                />
                            </FormGroup>
                        </Grid>}
                    </Grid>
                </Paper>
                <Buildings
                    buildings={state.buildings}
                    updateBuilding={updateBuilding}
                    deleteBuilding={deleteBuilding} />
                <Images
                    onFileSelected={onFileSelected}
                    header={state.case.extensions.obh ? 'Billeder af ejendommen' : 'Billeder til forsiden'}
                    allowDescription={false}
                    images={state.images}
                    deleteImage={deleteImage}
                    updateImage={updateImage}
                    markImage={markImage} 
                    orderImages={orderImages}/>

                <div className={classes.spacer}></div>
            </Page>

            <PageToolbar>
                <UploadImage onUpload={onFileSelected} />
                <CreateBuilding />
                <VerticalDivider height={107} />
                <CreateCard caseId={caseId} templates={props.templates} />
            </PageToolbar>
        </WriteLockProvider>
        </>
    );
}