import React, { useState, useEffect } from "react";
import { DialogTitle, DialogContent, DialogActions,  Toolbar, DialogContentText } from "@material-ui/core";
import { Button } from "controls/button";
import { FormField } from "controls/field/form-field";
import { hasValue } from "utils";
import {Popup} from 'controls/popup';

type Props = {
    open: boolean;
    onOk: (result: NewConsultantResult) => Promise<void>;
    onClose: () => void;
}

export type NewConsultantResult = {
    username: string;
    fullName: string;
    initials: string;
    alternativeEmail: string;
}

export const NewConsultantPopup = (props: Props) =>  {

    const [username, setUsername] = useState<string>(null);
    const [fullName, setFullName] = useState<string>(null);
    const [initials, setInitials] = useState<string>(null);
    const [alternativeEmail, setAlternativeEmail] = useState<string>(null);

    useEffect(() => {
        setUsername(null);
        setFullName(null);
        setInitials(null);
        setAlternativeEmail(null);
    }, [props.open]);
        
    const canExecute = hasValue(username) && hasValue(fullName) && hasValue(initials);

    const onOk = () => {
        if(!canExecute) return;
        
        return props.onOk({
            username,
            fullName,
            initials,
            alternativeEmail
        });
    }

    return (
        <Popup
            maxWidth='md'
            open={props.open}
            onClose={props.onClose}
            onCancel={props.onClose}
            onOk={onOk}
            >

            <DialogTitle>Opret bruger</DialogTitle>

            <DialogContent>
                <DialogContentText>Indtast brugeroplysninger for den nye bruger.</DialogContentText>

                <FormField
                    type='text'
                    label='Brugernavn'
                    value={username}
                    onValidChange={setUsername}
                    autoFocus                     
                />                                                          

                <FormField
                    type='text'
                    label='Navn'
                    value={fullName}
                    onValidChange={setFullName}
                />  
                
                <FormField
                    type='text'
                    label='Initialer'
                    value={initials}
                    onValidChange={setInitials}
                /> 

                <FormField
                    type='text'
                    label='Alternative email'
                    value={alternativeEmail}
                    optional
                    onValidChange={setAlternativeEmail}
                />
            </DialogContent>

            <DialogActions>
                <Toolbar>
                    <Button label='Fortryd' onClick={props.onClose}/>                        
                    <Button 
                        label='Opret'
                        disabled={!canExecute}
                        onClick={onOk} />
                </Toolbar>
            </DialogActions>
        </Popup>
    )
}