import React from "react";
import Field, { Props as FieldProps, FieldType, TypeSelector } from '.';
import { useWriteLock } from "custom-hooks";

type Props<T extends FieldType> = FieldProps<T, TypeSelector<T>>;

export { AnonymousField };
const AnonymousField = <T extends FieldType>(props: Props<T>) => {
    const style: Partial<Props<T>> = {
        variant: 'outlined',
        margin: 'normal'        
    }

    const writeLock = useWriteLock(props);

    return (
        <Field
            optional
            showRequired
            {...props}
            {...style}
            disabled={writeLock}
        />
    );
}