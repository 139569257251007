import React, { useEffect, useRef, useState } from "react";
import { GridListTile, makeStyles, Theme } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import SaveAlt from '@material-ui/icons/SaveAlt';
import TurnedIn from '@material-ui/icons/TurnedIn';
import TurnedInNot from '@material-ui/icons/TurnedInNot';
import { scrollIntoViewIfNeeded } from "utils";
import _ from 'lodash';
import { Image as ImageProps } from "./image-tiles";
import { ImageToolbar } from "./image-toolbar";
import { ImageToolButton } from "./image-tool-button";
import { ImageDragHandle } from "./image-drag-handle";
import cx from 'classnames';
import { Tooltip } from "../tooltip";
import { IconButton } from "../button/icon-button";
import { useWriteLock } from "custom-hooks";
import { Lockable } from "utils/lockable";

export type ImageTileProps = {
    openImage: (index: number) => void
    confirmDeleteImage: (image: ImageProps) => void
    markImage: (imageId: string) => Promise<void>
    unmarkImage?: (imageId: string) => Promise<void>
    image: ImageProps
    imageIndex: number
    isNewest: boolean
    underSorting: boolean
    allowDescription: boolean
    style?: any // style property is set automatically by parent GridList. see https://material-ui.com/guides/composition/
} & Lockable

const useImageSectionStyles = makeStyles({
    imageFrame: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        height: 180,
        pointerEvents: 'none' // make dragging smooth on firefox
    },
    imageFrameLarge: {
        height: 220
    },
    imgFullHeight: {
        height: '100%',
        transform: 'translateX(-50%)',
        position: 'relative',
        left: '50%'
    },
    imgFullWidth: {
        width: '100%',
        transform: 'translateY(-50%)',
        position: 'relative',
        top: '50%'
    }
});

const getAspect = (imageWidth: number, imageHeight: number, frameWidth: number, frameHeight: number)
    : 'LongerThanFrame' | 'WiderThanFrame' =>
    imageWidth / imageHeight > frameWidth / frameHeight
        ? 'WiderThanFrame'
        : 'LongerThanFrame';

const ImageSection = (props: { image: ImageProps, hasDescription: boolean, openImage: () => void }) => {

    const styles = useImageSectionStyles();

    const [aspect, setAspect] = useState('LongerThanFrame' as 'LongerThanFrame' | 'WiderThanFrame');

    const divRef = useRef(null);
    const imgRef = useRef(null);

    useEffect(() => {
        const imageElement = imgRef.current;
        const divElement = divRef.current;
        imgRef.current.onload = () =>
            setAspect(getAspect(imageElement.width, imageElement.height, divElement.offsetWidth, divElement.offsetHeight));
    }, [props.image.url]);

    return (
        <div className={cx(styles.imageFrame, { [styles.imageFrameLarge]: !props.hasDescription })} ref={divRef}>
            <img
                ref={imgRef}
                className={cx(
                    { [styles.imgFullWidth]: aspect === 'LongerThanFrame' },
                    { [styles.imgFullHeight]: aspect === 'WiderThanFrame' })}
                src={'/api/' + props.image.url + '&width=316&height=219'}
                alt={props.image.description}
                onClick={props.openImage} />
        </div>);
}

const useDescriptionSectionStyles = makeStyles({
    descriptionSection: {
        position: "absolute",
        top: 180,
        left: 0,
        right: 0,
        height: 40,
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden'
    },
    description: {
        overflow: 'hidden',
        marginLeft: 16,
        flexGrow: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        lineHeight: 2,
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.87)'
    }
});

const DescriptionSection = (props: { image: ImageProps }) => {

    const styles = useDescriptionSectionStyles();

    return (
        <div className={styles.descriptionSection}>
            <div className={styles.description}>
                {props.image.description}
            </div>
            <Tooltip title='Rediger billede'>
                <IconButton><EditIcon /></IconButton>
            </Tooltip>
        </div>);
}

const ToolbarSection = (props: {
    image: ImageProps,
    confirmDeleteImage: (image: ImageProps) => void,
    markImage: (imageId: string) => Promise<void>,
    unmarkImage?: (imageId: string) => Promise<void>
}) => {
    const image = props.image;

    return (
        <ImageToolbar>
            <ImageToolButton
                tooltip={!image.isMarked ? 'Vis i rapport' : 'Skjul fra rapport'}
                onClick={() =>
                    image.isMarked
                        ? props.unmarkImage && props.unmarkImage(image.id)
                        : props.markImage(image.id)
                }>
                {image.isMarked ? <TurnedIn /> : <TurnedInNot />}
            </ImageToolButton>
            <ImageToolButton locked={false} tooltip='Hent billede' href={'/api/' + image.url} download={image.filename}>
                <SaveAlt />
            </ImageToolButton>
            <ImageToolButton tooltip='Slet billede' onClick={() =>
                props.confirmDeleteImage(image)
            }>
                <DeleteIcon />
            </ImageToolButton>
        </ImageToolbar>);
}

const useImageTileStyles = makeStyles({
    tile: {
        zIndex: 1,
    },
    tileHover: {
        '&:hover': {
            cursor: 'pointer'
        },
        '&:hover .ImageDragHandle': {
            zIndex: 1,
            position: 'absolute',
            top: 'calc((100% - 50px) / 2)',
            display: 'flex'
        },
        '&:hover .EditButton': {
            zIndex: 1,
            position: 'absolute',
            bottom: 10,
            right: 10,
            display: 'inline-flex'
        }
    },
    tileBorder: {
        border: 'rgba(0, 0, 0, 0.13) solid 1px',
        height: '100%',
        width: '100%',
        position: 'relative',
        overflow: 'hidden'
    },
    editButton: {
        display: 'none'
    }
});

export const ImageTile = (props: ImageTileProps) => {

    const ref = useRef(null);

    const hasDescription = props.allowDescription && !_.isEmpty(props.image.description);

    const styles = useImageTileStyles();

    useEffect(() => {
        if (props.isNewest) scrollIntoViewIfNeeded(ref.current);
    }, []);

    const writeLock = useWriteLock(props);
    const image = props.image;

    const openImage = () => !writeLock && props.openImage(props.imageIndex);

    return (
        <GridListTile onClick={openImage} key={image.id} style={{ ...props.style }}
            className={cx(styles.tile, { [styles.tileHover]: !writeLock && !props.underSorting })}>

            <div ref={ref} className={styles.tileBorder}>

                <ToolbarSection image={image} markImage={props.markImage} unmarkImage={props.unmarkImage} confirmDeleteImage={props.confirmDeleteImage} />

                <ImageSection image={image} hasDescription={hasDescription} openImage={openImage} />

                {hasDescription && <DescriptionSection image={image} />}

                <ImageDragHandle />

                <ImageToolButton
                    tooltip='Rediger billede' onClick={openImage}
                    className={cx(styles.editButton, { EditButton: !hasDescription })} >
                    <EditIcon />
                </ImageToolButton>
            </div>

        </GridListTile>);
}