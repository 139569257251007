import { hsbGradientHex } from "color-utils";
import _ from 'lodash'

export const getConditionRatingColors = (maxConditionRating: number): { rating: number, color: string }[] => {

    const colors = hsbGradientHex(maxConditionRating, ["#F16A64", "#F1C264", "#54DFA7"]);

    const result = _.range(1, maxConditionRating + 1).map(x => ({ rating: x, color: colors[x - 1] }));
    return result;
}
