import React from "react";
import { Authentication } from "root";
import { Templates } from "./templates";
import { TypeMappings } from "./type-mappings";
import { Grid } from "@material-ui/core";

import Api from "server/api";
import TemplateGroupDto = Api.Templates.Queries.GetCompanyTemplates.TemplateGroupDto;

export const TemplatesAndTypeMappings = (props: { authentication: Authentication, templates: readonly TemplateGroupDto[] }) => (
    <Grid container spacing={1}>
        <Grid item lg={6}><Templates key={props.authentication.companyId} templates={props.templates} /></Grid>
        <Grid item lg={6}><TypeMappings key={props.authentication.companyId} companyId={props.authentication.companyId} templates={props.templates} /></Grid>
    </Grid>)