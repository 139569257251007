import React from 'react'
import { View } from '@react-pdf/renderer';
import { Page, BlueText, VerticalSpacer, Title, SubTitle } from './controls'
import { Row } from 'pages/report/controls/table'
import Api from 'server/api'
import { ObhTable } from './obh-table';
import ReportDto = Api.Modules.Obh.Queries.ObhReportDto;

export const Front = (props: ReportDto) => 
    <Page size="A4" ruler={false} rulerSteps={10} showWatermark={!props.isCompleted}>
        <Title >Drift- og vedligeholdelsesplan</Title>
        <View >
            <SubTitle>{`${props.streetName} ${props.houseNumber}${(props.streetName || props.houseNumber) && (props.postalCode || props.postalCity) && ','} ${props.postalCode} ${props.postalCity}`}</SubTitle>
        </View>
        <ObhTable colWidths={[75, 165]} style={{ marginTop: 14 }} rowStyle={{ minHeight: 15 }}>
            <Row>
                <BlueText>Sagsnr.</BlueText>
                <BlueText>{props.caseName}</BlueText>
            </Row>
            <Row>
                <BlueText>Besigtiget</BlueText>
                <BlueText>{props.inspectionDate}</BlueText>
            </Row>
            <Row>
                <BlueText>Rapportdato</BlueText>
                <BlueText>{props.dateOfReport}</BlueText>
            </Row>
            <Row>
                <BlueText>Konsulent</BlueText>
                <BlueText>{props.consultant}</BlueText>
            </Row>
            <Row>
                <BlueText>Telefon</BlueText>
                <BlueText>{props.phoneNumber}</BlueText>
            </Row>
            <Row>
                <BlueText>Mail</BlueText>
                <BlueText>{props.email}</BlueText>
            </Row>
            <VerticalSpacer height={25} />
            <Row>
                <BlueText>Kunde</BlueText>
                <BlueText>{props.customer}</BlueText>
            </Row>
            <Row>
                <BlueText>Kontaktperson</BlueText>
                <BlueText>{props.contactPerson}</BlueText>
            </Row>
        </ObhTable>
    </Page>
