import React, { useEffect, useRef, useState } from "react"
import { Document, Page as PdfPage, pdfjs } from 'react-pdf'
import styles from './styles'
import { CircularProgress, makeStyles } from '@material-ui/core'
import Page from 'controls/page'
import Toolbar from "pages/toolbar/toolbar"
import { ToolbarButton } from "pages/toolbar/toolbar-button"
import PrintIcon from '@material-ui/icons/PrintOutlined'
import OpenIcon from '@material-ui/icons/OpenInNew'
import FetchIcon from '@material-ui/icons/SaveAlt'
import UpdateIcon from '@material-ui/icons/Update'
import { useOpenIds, useCommandSubscription, useServer, useCommandAsync, useLoadingState } from 'custom-hooks';
import { WarningPanel } from 'controls/global-error-panel';
import { Authentication } from 'root';
import { CaseType, generateReportBlob, generateReportBlobFromExisting, getCurrentReportVersion, ReportData } from './generate-report';
import { useQuery } from "custom-hooks/use-query";
import { blobToBase64 } from "utils/blobs";
import Api from "server/api";
import { Font } from "@react-pdf/renderer";
import { hyphenateSync } from "hyphen/da";

import ReportResult = Api.Cases.Queries.GetReport.ReportResult;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {
    caseId?: string;
    authentication: Authentication;
    reportResult: ReportResult;
    currentReportVersion: number;
}

const useStyles = makeStyles(styles);

export const Report = (props: { authentication: Authentication }) => {

    const { caseId } = useOpenIds();
    const [reportResult, setReportResult] = useState<ReportResult>(null);
    const [currentReportVersion, setCurrentReportVersion] = useState(null as number | null);

    useEffect(() => {
        if (reportResult != null) setCurrentReportVersion(getCurrentReportVersion(reportResult.caseType as CaseType))
    }, [reportResult?.caseType]);

    const getReport = useQuery((caseId: string) => new Api.Cases.Queries.GetReport({ caseId: caseId }));

    useCommandSubscription(() => getReport(caseId).then(setReportResult), [caseId])

    if (!reportResult || !currentReportVersion) return null;

    return (
        <ReportContent
            caseId={caseId}
            authentication={props.authentication}
            reportResult={reportResult}
            currentReportVersion={currentReportVersion} />)
}

const ReportContent = (props: Props) : JSX.Element => {
    const server = useServer();
    const loadingState = useLoadingState();

    const [hideCompanyConfigurationWarning, setHideCompanyConfigurationWarning] = useState(false);
    const [numberOfPages, setNumberOfPages] = useState(null as number | null);
    const [documentLoaded, setDocumentLoaded] = useState(false);

    const [reportData, setReportData] = useState<ReportData>({
        documentUrl: null,
        documentBlob: null,
        filename: 'rapport.pdf',
        companyDetailsAreIncomplete: false
    });

    const documentOpener = useRef(null as HTMLAnchorElement);
    const documentPrinter = useRef(null as HTMLIFrameElement);

    useEffect(() => {
        let url:string|null = null;

        async function getReport() {
            const report = await (props.reportResult.reportExists && props.reportResult.caseIsCompleted
                ? generateReportBlobFromExisting(props.reportResult.reportData, props.reportResult.filename)
                : generateReportBlob(server, props.caseId)
            )
            console.time('Load');
            url = report.documentUrl;
            setReportData(report);
        }

        loadingState.load(getReport())

        return () => {
            if (url) URL.revokeObjectURL(url); // Release blob in browser.
            // Currently caseId should not change unless the component is unmounted, but in case that changes we should reset the state
            setReportData(null);
            setNumberOfPages(null);
            setDocumentLoaded(false);
            setHideCompanyConfigurationWarning(false);
        };
    }, [props.caseId]);

    const WarningMessageForUser = () =>
        <WarningPanel onRemove={() => setHideCompanyConfigurationWarning(true)}>
            <div>
                Rapporten mangler kontaktoplysninger og/eller logo for din virksomhed.
                Kontakt din firmaadministrator for få udfyldt de sidste detaljer.
                Kontakt os på supporten på 70 22 93 10 eller support@nrgisystems.dk, hvis du har brug for hjælp.
            </div>
        </WarningPanel>;

    const WarningMessageForCompanyAdmin = () =>
        <WarningPanel onRemove={() => setHideCompanyConfigurationWarning(true)}>
            <div>
                Rapporten mangler kontaktoplysninger og/eller logo for din virksomhed.
                Gå til <a href="/settings">indstillinger</a> for at udfylde de sidste detaljer.
                Kontakt os på supporten på 70 22 93 10 eller support@nrgisystems.dk, hvis du har brug for hjælp.
            </div>
        </WarningPanel>;

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        console.timeEnd('Load');
        console.time('Render')

        setNumberOfPages(numPages);
    }

    const onPageRenderSuccess = (page: any) => {
        if (page.pageNumber == numberOfPages) {
            setDocumentLoaded(true);
            console.timeEnd('Render');
        };
    }

    const update = useCommandAsync(async () => {
        const updatedReportData = await generateReportBlob(server, props.caseId)
            .then(report => {
                setReportData(report);
                return report;
            });
            
        return new Api.Cases.Commands.UpdateReport({
            reportVersion: props.currentReportVersion,
            reportData: await blobToBase64(updatedReportData.documentBlob),
            caseId: props.caseId
        })
    });

    const open = () => {
        if (self.navigator && self.navigator.msSaveOrOpenBlob) {
            self.navigator.msSaveOrOpenBlob(reportData.documentBlob, reportData.filename);
        }
        else {
            self.open(reportData.documentUrl, '_blank');
        }
    }

    const download = () =>  documentOpener.current.click();

    const print = () => {

        documentPrinter.current.onload = () => {
            documentPrinter.current.contentWindow.focus();
            documentPrinter.current.contentWindow.print();
        };

        documentPrinter.current.src = reportData.documentUrl;
    }

    const styles = useStyles();

    if (!reportData.documentUrl) 
        return (<>
            <Page style={{ backgroundColor: 'grey', paddingTop: 20 }}>
                {/** The disableShrink property makes the spinner work better under heavy load. (disableShrink uses transform css-properties, which runs on a different thread in the browser) */}
                {(loadingState.loaderVisible) && <CircularProgress style={{ margin: '20vh auto'}} size={200} disableShrink />}
            </Page>
        </>);

    const showWarning = reportData.companyDetailsAreIncomplete && !hideCompanyConfigurationWarning;
    const canUpdateReport = props.reportResult.caseIsCompleted &&
                            props.authentication.isSystemAdmin &&
        (props.currentReportVersion > props.reportResult.reportVersion || !props.reportResult.reportExists)

    return (
        <>
            {
                showWarning
                    ? props.authentication.isCompanyAdmin
                        ? <WarningMessageForCompanyAdmin />
                        : <WarningMessageForUser />
                    : null
            }
            <Page style={{ backgroundColor: 'grey', paddingTop: 20 }}>

                <Document className={styles.document} file={reportData.documentUrl} onLoadSuccess={onDocumentLoadSuccess}>
                    {
                        [...Array(numberOfPages).keys()].map(i =>
                            <div id={`page${i + 1}`} style={{ marginBottom: 10 }}>
                                <PdfPage
                                    onRenderSuccess={onPageRenderSuccess}
                                    renderMode="svg"
                                    pageIndex={i}
                                    onDoubleClick={open}
                                    scale={1.5} />
                            </div>)
                    }
                </Document>
            </Page>

            <Toolbar>
                {
                    canUpdateReport
                        ? <ToolbarButton
                            label='Opdatér'
                            icon={<UpdateIcon className={styles.toolBarIcons} />}
                            onClick={update}
                            tooltip="Rapportens design har ændret sig. Klik hér for at opdatere til det nye design." />
                        : null
                }
                <ToolbarButton label='Åbn' icon={<OpenIcon className={styles.toolBarIcons} />} onClick={open} />
                <ToolbarButton label='Hent' icon={<FetchIcon className={styles.toolBarIcons} />} onClick={download} />
                <ToolbarButton label='Udskriv' icon={<PrintIcon className={styles.toolBarIcons} />} onClick={print} />
            </Toolbar>

            <a ref={documentOpener} style={{ display: 'none' }} download={reportData.filename} href={reportData.documentUrl} />
            <iframe ref={documentPrinter} style={{ display: 'none' }} />
            {documentLoaded && <div id="report-document-loaded" style={{ display: 'none' }} />} {/* A marker for automated tests */}
        </>
    )
}

Font.register({
    family: 'Open Sans',
    src: '/assets/OpenSans/OpenSans-Regular.ttf'
});

Font.register({
    family: 'Open Sans Bold',
    src: '/assets/OpenSans/OpenSans-Bold.ttf'
});

Font.register({
    family: 'Open Sans Semi Bold',
    src: '/assets/OpenSans/OpenSans-SemiBold.ttf'
});

Font.register({
    family: 'Open Sans Light',
    src: '/assets/OpenSans/OpenSans-Light.ttf'
});

Font.register({
    family: 'Open Sans Italic',
    src: '/assets/OpenSans/OpenSans-Italic.ttf'
});

Font.register({
    family: 'Open Sans Bold Italic',
    src: '/assets/OpenSans/OpenSans-BoldItalic.ttf'
});

Font.register({
    family: 'arial',
    src: '/assets/Arial/arial.ttf'
});

Font.register({
    family: 'arial-bold',
    src: '/assets/Arial/arialbd.ttf'
});

Font.register({
    family: 'arial-italic',
    src: '/assets/Arial/ariali.ttf'
});

Font.register({
    family: 'DINPro Bold',
    src: '/assets/DINPro/DINPro-Bold.ttf'
});

Font.register({
    family: 'DINPro Bold Italic',
    src: '/assets/DINPro/DINPro-BoldItalic.ttf'
});

Font.register({
    family: 'DINPro Italic',
    src: '/assets/DINPro/DINPro-Italic.ttf'
});

Font.register({
    family: 'DINPro Light',
    src: '/assets/DINPro/DINPro-Light.ttf'
});

Font.register({
    family: 'DINPro Medium',
    src: '/assets/DINPro/DINPro-Medium.ttf'
});

Font.register({
    family: 'DINPro Regular',
    src: '/assets/DINPro/DINPro.ttf'
});

Font.register({
    family: 'DINOT Black',
    src: '/assets/DINOT/DINOT-Black.ttf'
});

// This fixes a problem with the default hyphenation engine that would prevent a line from breaking
// if an overflowing word contained danish characters (e.g. "Overdækninger").
Font.registerHyphenationCallback((word) =>
    hyphenateSync(word, { hyphenChar: '[•]', minWordLength: 8 }).split('[•]')
);