import { jsonDateConverter } from './json-date-converter';
import { stringify } from 'qs';
import { Observable, Subject } from 'rxjs';
import $ from 'jquery'

export class Server {
    private commandsObserver: Subject<Command> = new Subject<Command>();
    private commandQueue = $({});

    get Commands() {
        return this.commandsObserver;
    }

    constructor(private rewrite: (url: string) => string) {
        this.rewrite = rewrite || ((url) => url);
    }

    command(command: Command, baseUrl: string = '/api/command/') {
        return new Promise<CommandResult>((resolve, reject) => {
            this.commandQueue.queue((next) => $.ajax({
                url: this.rewrite( baseUrl + command.$commandName.replace(/\./g, '-')),
                type: 'POST',
                data: JSON.stringify(command),
                dataType: 'json',
                contentType: 'application/json',
                error: (error: JQueryXHR) => {
                    next();
                    reject(error);
                },
                success: ((response: CommandResult) => {
                    next();
                    resolve(response);
                    this.commandsObserver.next(command);
                })
            }));
        });
    }

    query<T>(query: Query): Observable<T> {
        const url = this.queryUrl(query);

        return new Observable(subscriber => {
            let aborting = false;
            const xhr = $.ajax({
                type: 'GET',
                url: url.queryUrl,
                data: url.queryData,
                dataType: 'json',
                contentType: 'application/json',
                error: error => {
                    if (aborting) return;
                    
                    subscriber.error(error);
                    throw error;
                },
                success: response => {
                    subscriber.next(response);
                    subscriber.complete();
                },
                converters: {
                    'text json': (data: any) => JSON.parse(data, jsonDateConverter)
                }
            });
            return () => {
                aborting = true;
                xhr.abort();
            };
        });
    }
    
    queryUrl(query: Query) {
        const url = {
            queryUrl: this.rewrite(`/api/query/${query.$queryName.replace(/\./g, '-')}${(query.commitId ? (`?commitId=${query.commitId}`) : '')}`),
            queryData: stringify(query, { allowDots: true, filter: (prefix, value) => prefix.startsWith('$') ? undefined : value }),
        };

        const delimiter = url.queryUrl.includes('?') ? '&' : '?';
        return {
            ...url,
            url: url.queryUrl + (url.queryData ? `${delimiter}${url.queryData}` : '')
        }
    }

    login = async (args: { username: string; password: string; rememberMe: boolean }) => {
        return await $.ajax({
            url: '/api/login',
            type: 'POST',
            data: JSON.stringify(args),
            contentType: 'application/json',
            dataType: 'text',
            converters: {
                'text json': (data: any) => JSON.parse(data, jsonDateConverter)
            },
        });
    }

    logout(callback: () => void) {
        $.ajax({
            url: '/api/logout',
            type: 'POST',
            error: this.throw,
            success: callback
        });
    }

    approveGdpr(callback: () => void) {
        $.ajax({
            url: 'api/gdpr/approve',
            type: 'POST',
            error: this.throw,
            success: callback
        });
    }

    private throw = (error: JQueryXHR) => { throw error; }
}