import React from 'react'
import { View } from '@react-pdf/renderer';
import { Header2, Header3 } from '../text'
import { Divider } from '../controls'
import { Table, Row } from 'pages/report/controls/table'
import { ConditionRating } from '../condition-rating';
import { CardHeader, ImageSection, OneTimeTasks, RecurringTaskTable, StatusTable } from '../card-page'

import Api from 'server/api';
import Es = Api.Cases.Queries.Reports.Es;
import CardPageDto = Es.CardPageDto;

const RecurringTasks = (props: CardPageDto) =>
    props.recurringTasks.length > 0
        ? <>
            <Divider size="thick" margin="large" />
            <Header2 style={{ marginTop: 10 }}>{props.header.number}.2 Løbende vedligehold</Header2>
            <RecurringTaskTable {...props} />
        </>
        : null;

export const EbasCardPage = (props: CardPageDto) => <View>
    <Table colWidths={[430, 0]}>
        <Row>
            <CardHeader {...props} />
            <View style={{ alignItems: 'flex-end', marginTop: 4 }}>
                <ConditionRating rating={props.header.conditionRating} maxRating={props.header.maxConditionRating} width={16} spacing={4} />
            </View>
        </Row>
    </Table>
    <Header3>Nuværende tilstand</Header3>
    <StatusTable {...props} />
    <OneTimeTasks {...props} />
    <RecurringTasks {...props} />
    <ImageSection images={props.images} />
</View>