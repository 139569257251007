import React from "react";
import { ListItem, withStyles, WithStyles } from "@material-ui/core";
import _ from 'lodash';
import Api from "server/api";
import TaskType = Api.Cases.TaskType;
import Component from "component";
import styles from "./styles";
import { WarningPopup } from "controls/warning-popup";
import { scrollIntoViewIfNeeded } from "utils";
import { DeleteIcon, DamageIcon, MaintenanceIconSmall } from "controls/images/custom-icons";
import { IconButton } from "controls/button/icon-button";

type State = {
    showDeleteButton: boolean;
    isWarningPopupOpen: boolean;
}

type Props = {
    caseId: string;
    cardId: string;
    isSelected: boolean;
    task: Api.Cases.Queries.GetCards.TaskDto;
    taskType: TaskType;
} & WithStyles<typeof styles>

export default withStyles(styles)(class TaskItem extends Component<Props, State> {
    state = { showDeleteButton: false, isWarningPopupOpen: false }

    ref: HTMLElement;

    componentDidMount = () => {
        if (this.props.isSelected) {
            scrollIntoViewIfNeeded(this.ref, { behavior: 'smooth', block: 'center' });
        }
    }

    render() {
        const { taskItem, taskItemHeader } = this.props.classes;
        const { task, taskType, isSelected } = this.props;
        
        return <div ref={ref => this.ref = ref}>
            <StyledListItem button
                className={taskItem}
                onMouseOver={() => this.setState({ showDeleteButton: true })}
                onMouseLeave={() => this.setState({ showDeleteButton: false })}
                selected={isSelected}
                onClick={() => this.goto(`/cards/${this.props.caseId}/${this.props.cardId}/${task.id}`)}>
                <div />
                <div>
                    <div className={taskItemHeader}>
                        {taskType == TaskType.OneTimeTask ? <DamageIcon size={12} /> : <MaintenanceIconSmall />}
                        <span>{task.payment}</span>
                    </div>
                    <div className={this.props.classes.taskListName}>
                        {task.name}
                    </div>
                </div>
                <div>
                    {(isSelected || this.state.showDeleteButton) &&
                        <IconButton
                            onClick={event => {
                                event.stopPropagation();
                                this.setState({ isWarningPopupOpen: true });
                            }}>
                            <DeleteIcon size={12} classSelector={c => c.rootFontSize} />
                        </IconButton>}
                </div>
            </StyledListItem>

            <WarningPopup
                onOk={() => this.deleteTask(task.id)}
                onClose={() => this.setState({ isWarningPopupOpen: false })}
                isOpen={this.state.isWarningPopupOpen}
                title={'Slet opgaven?'}
                message={'Du er ved at slette opgaven. Er du sikker?'}
                additionalMessages={[task.name]}
                okText='SLET'
                cancelText='FORTRYD'
            />
        </div>;
    }

    deleteTask = async (taskId: string) => {
        this.setState({ isWarningPopupOpen: false })

        if (this.props.isSelected) {
            this.goto(`/cards/${this.props.caseId}/${this.props.cardId}`);
        }

        await this.server.command(new Api.Cases.Commands.DeleteTask({ caseId: this.props.caseId, cardId: this.props.cardId, taskId: taskId }));
    }
})

const StyledListItem = withStyles({
    root: {
        backgroundColor: "white"
    }
})(ListItem);