import * as React from 'react'
import { View } from '@react-pdf/renderer';
import { Text as Text, BoldText, Header1, Header3 } from '../text'
import _ from 'lodash';
import { VerticalSpacer } from '../controls';

const IndentedSection= (props: React.PropsWithChildren<{ }>) =>
    <View style={{ marginLeft: 15 }}>{props.children}</View>

const NumberedParagraph = (props: { num: number, text: string }) =>
        <View style={{ flexDirection: "row" }}>
            <Text style={{ width: 20 }}>{props.num}.</Text><Text>{props.text}</Text>
        </View>

export const FutureWorkPage = () =>
    <View>
        <Header1>Det videre arbejde</Header1>
        <Text>
            Når I har modtaget rapporten med tilhørende vedligeholdelsesplan, 
            skal der tages beslutninger om det videre forløb omkring ejendommens 
            vedligeholdelse, herunder økonomi.
        </Text>
        <VerticalSpacer height="medium"/>
        <Text>
            <BoldText>DEAS A/S</BoldText> har stor erfaring i renovering og vedligeholdelse af ejendomme 
            og bistår gerne foreningen som rådgiver i forbindelse med gennemførelsen 
            af påtænkte renoveringsarbejder eller som daglig sparringspartner som 
            driftsleder. Ydelser kan bestå af:
        </Text>
        <VerticalSpacer height="small"/>
        <View>
            <Header3>Byggeteknisk rådgivning:</Header3>
            <IndentedSection>
                <NumberedParagraph num={1} text="Deltagelse ved budgetmøder"/>
                <NumberedParagraph num={2} text="Fastlæggelse af foreningens ønsker og istandsættelsesarbejder"/>
                <NumberedParagraph num={3} text="Deltagelse ved generalforsamling med teknisk information og beslutningsforslag"/>
                <NumberedParagraph num={4} text="Ansøgninger og forhandlinger med myndigheder."/>
                <NumberedParagraph num={5} text="Udarbejdelse af udbudsmateriale."/>
                <NumberedParagraph num={6} text="Indhentning af tilbud fra håndværkere samt vurdering af indkomne tilbud."/>
                <NumberedParagraph num={7} text="Forhandling med håndværkere og budgetindstilling til bestyrelsen."/>
                <NumberedParagraph num={8} text="Kontrahering med håndværker og igangsætning af entreprise."/>
                <NumberedParagraph num={9} text="Tilsyn, afholdelse og referering af byggemøder under arbejdets udførelse."/>
                <NumberedParagraph num={10} text="Attestering af fakturaer vedr. entreprisen"/>
                <NumberedParagraph num={11} text="Afholdelse af afleveringsforretning inkl. udarbejdelse af afleveringsprotokol."/>
                <NumberedParagraph num={12} text="1 års garantigennemgang af entreprisearbejdet."/>
                <NumberedParagraph num={13} text="5 års garantigennemgang af entreprisearbejdet."/>
            </IndentedSection>
        </View>
        <VerticalSpacer height="small"/>
        <View>
            <Header3>Driftsledelse:</Header3>
            <IndentedSection>
                <NumberedParagraph num={1} text="Løbende tilsyn med ejendommen"/>
                <NumberedParagraph num={2} text="Løbende teknisk dialog og korrespondance med bestyrelse og beboere"/>
                <NumberedParagraph num={3} text="Indhentning af tilbud og rekvirering af håndværkere samt kontrol af udført arbejde."/>
                <NumberedParagraph num={4} text="Kontrol og styring af serviceaftaler."/>
                <NumberedParagraph num={5} text="Kontrol og styring af viceværtaftale."/>
                <NumberedParagraph num={6} text="Teknisk assistance ved forsikringsskader."/>
                <NumberedParagraph num={7} text="Årlig bygningsgennemgang."/>
                <NumberedParagraph num={8} text="Udarbejdelse af årligt driftsbudget."/>
                <NumberedParagraph num={9} text="Tilpasning af driftsbudget ud fra vedligeholdelsesplan"/>
                <NumberedParagraph num={10} text="Orientering til bestyrelse og beboere ved deltagelse i bestyrelsesmøder og generalforsamling."/>
            </IndentedSection>
        </View>
        <VerticalSpacer height="medium"/>
        <Text>
            Byggeteknisk rådgivning kan tilbydes som fastprishonorar, efter medgået tid eller som et honorar der svarer til et aftalt % af den samlede håndværker udgifter. 
        </Text>
        <VerticalSpacer height="small"/>
        <Text>
            Udlægsudgifter til kørsel, tryk af tegninger mv. vil normalt være indeholdt i aftalen.
        </Text>
    </View>