import { createStyles, Theme } from "@material-ui/core";

export default (theme: Theme)=> createStyles({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2),
        maxWidth: theme.spacing(100),
        textAlign: 'center'
    },
    completeContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    completeToolbar: {
        paddingLeft: 0
    }
});
