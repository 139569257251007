import { createStyles, Theme } from "@material-ui/core";

export default (theme: Theme) => createStyles({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2),
        maxWidth: theme.spacing(100),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    spacer: {
        '@media (max-width: 1700px)': {
            height: theme.spacing(21)
        }
    }
});