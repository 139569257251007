
import React from 'react'
import { createStyles, Theme, WithStyles, withStyles, Typography } from "@material-ui/core";
import cx from 'classnames'
import { stringEllipsis } from 'utils';

const styles = (theme: Theme) => createStyles({
    content: {
        margin: theme.spacing(2),
        fontSize: 12
    }
});

type Props = {
    crumbs: string[]
    className?: string,
} & WithStyles<typeof styles>

export default withStyles(styles)((props: Props) => (
    <Typography variant="body1" className={cx(props.classes.content, props.className)}>
        {props.crumbs.filter(x => x != null || x != "").map(stringEllipsis(75)).join(" > ")}
    </Typography>
));
