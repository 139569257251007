import React from "react";
import { withStyles, WithStyles, Toolbar} from "@material-ui/core";
import Component from "component";
import styles from './styles'
import Page from 'controls/page'
import { Button } from "controls/button";
import { Server } from "../../server";
import { Checkbox } from "controls/checkbox";

type Props = {
    server: Readonly<Server>,
    logout: () => void
} & WithStyles<typeof styles>

type State = {
    accepted: boolean;
    isExecuting: boolean;
};

export default withStyles(styles)(class ApproveGdpr extends Component<Props, State> {  
    
    state = {accepted: false, isExecuting: false}
    
    render() {       
        return (
            <Page>
                <div className={this.props.classes.container}>
                    <img className={this.props.classes.logo} src='/assets/logo_dv.png' />
                    <h2>NRGi Systems A/S passer på jeres persondata</h2>
                    <p>NRGi Systems A/S prioriterer datasikkerhed meget højt, hvorfor jeres persondata til enhver tid behandles og opbevares helt sikkert.</p>
                    <p>EU-lovgivningen om øget datasikkerhed gør, at NRGi Systems A/S skal dokumentere denne sikkerhed i en skriftlig aftale med jer – en databehandleraftale mellem NRGi Systems A/S som databehandler og jer som dataansvarlige.</p>
                    
                    <h2>Godkendelse</h2>

                    <p>Databehandleraftalen skal godkendes af dig eller en anden af jeres firmaadministratorer, hvorefter I kan arbejde videre i D&V Plan.</p>
                    <p>Databehandleraftalen kan læses <a className={this.props.classes.licenseContent} href='https://www.nrgisystems.dk/hjaelp-til-energy10/brugsbetingelser-og-persondataforordning/'>hér.</a></p>
                    <p>Du kan som firmaadministrator godkende databehandleraftalen ved at klikke nedenfor:</p>
                    <p>Såfremt I ikke godkender databehandleraftalen, vil det desværre ikke være muligt for jer at logge ind i D&V Plan.</p>
                    <p>Såfremt I måtte have yderligere spørgsmål til databehandleraftalen, er I velkomne til at kontakte os.</p>
                    <p className={this.props.classes.acceptContainer} >
                        <Checkbox checked={this.state.accepted} onChange={this.toggleAccepted}></Checkbox>
                        <span className={this.props.classes.acceptText} onClick={this.toggleAccepted}>Som firmaadministrator er jeg indforstået med indholdet af nærværende databehandleraftale.</span>
                    </p>
                    <Toolbar>
                        <Button label='Afgiv samtykke' onClick={this.accept} disabled={this.state.isExecuting || !this.state.accepted}/>
                        <Button label='Afvis' onClick={this.props.logout} disabled={this.state.isExecuting} className={this.props.classes.approveButton}></Button>
                    </Toolbar>
                </div>              
            </Page>            
        )
    }

    accept = async () => {
        this.setState({
            isExecuting: true
        })

        await this.props.server.approveGdpr(() => {
            window.location.reload();
        });
    }

    toggleAccepted = () => this.setState({ accepted: !this.state.accepted })
})