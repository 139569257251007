import React from "react";
import { FileUpload} from "controls/file-upload";
import { ToolbarButton } from './toolbar-button';
import { AddImageIcon } from "controls/images/custom-icons";

type Props = {
    onUpload: (files: File[]) => Promise<void>;
    visible?: boolean;
}

export const UploadImage = (props : Props) =>  {
    const visible = props.visible === undefined || props.visible;

    return <FileUpload disabled={!visible} accept='.png, .jpg, .jpeg' multiple={true} onUpload={props.onUpload}>
        <ToolbarButton
            label='UPLOAD BILLEDER'
            icon={<AddImageIcon />}
            visible={visible}
            onClick={() => {}}
        />
    </FileUpload>
}