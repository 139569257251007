import React, { useState } from 'react';

import styles from '../styles'
import type { ErrorDisplay } from 'custom-hooks/use-error-display';
import { useServer, useLoadingState } from 'custom-hooks';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';

import { Button } from "controls/button";
import { Checkbox } from 'controls/checkbox';
import { IconButton } from "controls/button/icon-button";

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { awaitableTimeout } from 'utils';
import { createStateUpdater } from "utils/updaters";

const useStyles = makeStyles(styles);

type State = Readonly<{
    username: string
    password: string
    rememberMe: boolean
    showPassword: boolean
}>

export const LoginForm = (props: {errorDisplay: ErrorDisplay}) => {
    const styles = useStyles();
    const server = useServer();

    const [state, setState] = useState<State>({
        username: "",
        password: "",
        rememberMe: false,
        showPassword: false
    });

    const loadingState = useLoadingState();
    const [loggingIn, setLoggingIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null as string|null);

    const updaterFactory = createStateUpdater(setState);
    const updateUsername = updaterFactory('username');
    const updatePassword = updaterFactory('password');

    const loginAsync = async () => {
        try {
            const error = await server.login({
                username: state.username,
                password: state.password,
                rememberMe: state.rememberMe
            });

            if (!error) {
                setLoggingIn(true); // keep button loading forever (until the page reloads)
                window.location.reload();
                return;
            }
            throw error;
        }
        catch (error) {
            // When you type your login wrongly twice in row, and get the same error message, this delay makes it more clear that the system actually did something the second time
            // Note: the delay only shows up on errros (otherwise the page is redirected instantly, and the spinner will show untill the new page loads);
            await awaitableTimeout(!errorMessage ? 0 : 800);
            
            if (typeof error === 'string') { 
                setErrorMessage(error);
            }
            else {
                props.errorDisplay.add(error);
            }
        }
    }

    const login = () => {
        if (loadingState.loading) return;
        
        setErrorMessage(null);
        loadingState.load(loginAsync());
    }

    const onKeyUp = (event: React.KeyboardEvent<any>) => {
        if (event.key === 'Enter') {
            login();
        }
    }

    return <>
        <div className={styles.loginContent}>
            <img style={{width: 132, height: 49.8906}} src='/assets/logo_dv.png' />

            <FormControl>
                <InputLabel className={styles.inputLabel}>Brugernavn</InputLabel>
                <Input
                    classes={{
                        root: styles.input
                    }}
                    id="user-name"
                    type='text'
                    value={state.username}
                    onChange={e => updateUsername(e.target.value)}
                    onKeyUp={onKeyUp}
                    tabIndex={1}
                />
            </FormControl>

            <FormControl>
                <InputLabel className={styles.inputLabel} htmlFor="adornment-password">
                    Password
                </InputLabel>
                <Input
                    classes={{
                        root: styles.input
                    }}
                    id="adornment-password"
                    type={state.showPassword ? 'text' : 'password'}
                    value={state.password}
                    onChange={e => updatePassword(e.target.value)}
                    onKeyUp={onKeyUp}
                    tabIndex={2}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                classes={{
                                    root: styles.inputAdornment
                                }}
                                aria-label="Toggle password visibility"
                                onClick={() => setState(s => ({ ...s, showPassword: !s.showPassword }))}
                            >
                                {state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>

            <div style={{display:'flex', flexDirection:'row', justifyContent: 'space-between', marginTop: 3}}>
                <Checkbox tabIndex={3} className={styles.checkboxLabel} label='Husk mig' checked={state.rememberMe} onChange={() => setState(s => ({ ...s, rememberMe: !s.rememberMe }))}/>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <a tabIndex={5} href='/requestresetpassword' style={{float: 'right', fontSize: 10, color:'#56DFA6', textDecoration: 'none'}}>Glemt adgangskode?</a>
                </div>
            </div>

            <div className={styles.loginButtonOuterContainer}>
                <div className={styles.loginButtonInnerContainer}>
                    <a href='/registercompany' style={{marginTop: 3, fontSize: 13, color:'#56DFA6', textDecoration: 'none'}}>Opret ny bruger</a>
                    <Button loading={loadingState.loaderVisible || loggingIn} className={styles.loginButton} onClick={login} label='Login' />
                </div>
                <div className={styles.errorMessage}>
                    <label>{errorMessage}</label>
                </div>                                            
            </div>
        </div>
    </>
}

