import React from "react";
import Api from "server/api";
import { TableCell, makeStyles} from "@material-ui/core";
import cx from 'classnames'
import styles from "./styles";
import { numeral } from 'utils'
import { MoveSidesIcon } from "controls/images/custom-icons";
import { Tooltip } from "controls/tooltip";
import { useWriteLock } from "custom-hooks";
import { Lockable } from "utils/lockable";

type DragProps = {
    isDraggable: boolean;
    isDropTarget: boolean;
    isDraggingOver: boolean;
    onDrag: () => void;
    onDragOver: (event: React.DragEvent<HTMLTableCellElement>) => void;
    onDragEnd: () => void;
    onDrop: () => Promise<void>;
}

type Props = {
    price: Api.Cases.Price;
    disableTooltip?: boolean;
    hover?: boolean;
    dragProps?: DragProps;
} & Lockable

const useStyles = makeStyles(styles);

export const TaskYearCell = (props: Props) => {

    const styles = useStyles();
    const writeLock = useWriteLock(props);

    const price = props.price;
    const formattedValueIndexed = price && price.indexed !== 0 ? numeral(price.indexed).format('0,0') : "";
    const formattedValueOriginal = price && price.original !== 0 ? numeral(price.original).format('0,0') : "";

    const cell = props.dragProps && !writeLock
        ? 
        <TableCell 
            draggable={props.dragProps.isDraggable}
            onDrag={props.dragProps.onDrag}
            onDragEnd={props.dragProps.onDragEnd}
            onDragOver={props.dragProps.onDragOver}
            onDrop={async () => await props.dragProps.onDrop()}
            className={cx(
                { [styles.dropTarget]: props.dragProps.isDropTarget },
                { [styles.draggable]: props.dragProps.isDraggable },
                { [styles.draggingOver]: props.dragProps.isDraggingOver }
            )}>
            <div className={styles.taskRowContent}>
                {props.hover && props.dragProps.isDraggable && <MoveSidesIcon className={styles.taskRowContentImg} />}
                <div className={styles.taskRowContentValue}>{formattedValueIndexed}</div>
            </div>
        </TableCell>
        : 
        <TableCell>
            <div className={styles.taskRowContent}>
                <div className={styles.taskRowContentValue}>{formattedValueIndexed}</div>
            </div>
        </TableCell>;

    return !props.disableTooltip && props.price.original != 0
        // We unlock the tooltip to avoid the inner wrapper 'cause it ruins the styling
        ? <Tooltip locked={false} title={`Før indeksregulering: ${formattedValueOriginal}`}>{cell}</Tooltip>
        : cell
}