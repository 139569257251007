import React, { useState, useEffect } from 'react';
import { DialogTitle, DialogContent, DialogActions, Toolbar, FormGroup } from "@material-ui/core";
import {Popup} from 'controls/popup';
import { FormField } from "controls/field/form-field";
import { Button } from "controls/button";
import _ from 'lodash';
import { isNullOrWhitespace } from "utils";
import { useLoadingState } from 'custom-hooks';

type Props = {
    onOk: (companyName: string) => Promise<void>;
    onClose: () => void;
    open: boolean;
    companyName: string
}

export const EditCompanyNamePopup = (props: Props) => {

    const [companyName, setCompanyName] = useState(props.companyName);

    useEffect(() => {
        setCompanyName(props.companyName);
    }, [props.companyName, props.open]);

    const {
        load,
        loading,
        loaderVisible,
    } = useLoadingState(100);

    const canEdit = !loading;
    const canExecute = !isNullOrWhitespace(companyName) && !loading;

    const changeCompanyName = async () => {
        if (!canExecute) return;

        await load(props.onOk(companyName));
    }

    return (
        <Popup
            maxWidth='lg'
            open={props.open}
            onClose={props.onClose}
            onOk={changeCompanyName}
            onCancel={props.onClose}>

            <DialogTitle>Rediger firmanavn</DialogTitle>

            <DialogContent>
                <FormGroup>
                    <FormField
                        type='text'
                        label='Navn'
                        disabled={!canEdit}
                        value={companyName}
                        onValidChange={setCompanyName} />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Toolbar>
                    <Button label='Fortryd' disabled={loading} onClick={props.onClose} />
                    <Button
                        label='Gem'
                        loading={loading && loaderVisible}
                        disabled={!canExecute}
                        onClick={changeCompanyName} />
                </Toolbar>
            </DialogActions>
        </Popup>);
}