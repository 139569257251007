import React, { useState } from "react";
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Toolbar } from "@material-ui/core";
import { Button } from "controls/button";
import { FormField } from "controls/field/form-field";
import { Popup } from 'controls/popup';
import { after, disableWhileRunningDecorator, pipe, useCommand, useLoadingState } from "custom-hooks";
import { sguid } from "utils";

import Api from "server/api";
import CreateTypeMappings = Api.Users.Commands.CreateTypeMappings;

type Props = {
    companyId: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    canExecute: (name: string) => boolean;
}

const disableWhileRunning = disableWhileRunningDecorator();

export const CreateTypeMappingsPopup = (props: Props) => {

    const [name, setName] = useState(null as string|null);
    
    const close = () => props.setOpen(false);

    const loadingState = useLoadingState();
    const createTypeMapping = useCommand(() => new CreateTypeMappings({ newTypeMappingsId: `mappings/${props.companyId}/${sguid()}`, name: name }), 
        pipe(disableWhileRunning, after(async () => close()), loadingState))

    const onOk = () => {
        if (!props.canExecute(name)) return;
        createTypeMapping();
    }

    return (
        <Popup
            maxWidth='md'
            fullWidth
            open={props.open}
            onClose={close}
            onOk={onOk}
            onCancel={close}>

            <DialogTitle>
                Opret konfiguration af klassifikationer
            </DialogTitle>

            <DialogContent>
                <DialogContentText>Indtast navn for ny konfiguration</DialogContentText>
                        
                <FormField
                    autoFocus
                    type='text'
                    label='Navn'
                    value={name}
                    onValidChange={setName}
                />

            </DialogContent>

            <DialogActions>
                <Toolbar>
                    <Button label='Fortryd' onClick={close}/>                        
                    <Button 
                        label='Gem'
                        loading={loadingState.loaderVisible}
                        onClick={onOk} 
                        disabled={!props.canExecute(name)}/>
                </Toolbar>
            </DialogActions>
        </Popup>);
}