import React from "react";
import Api from "server/api";
import { TableRow, TableCell } from "@material-ui/core";
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { TaskYearCell } from './task-year-cell';
import { get } from "utils";

type Props = {
    budget: Api.Cases.Queries.GetBudget.Budget,
    group: Api.Cases.Queries.GetBudget.GroupLine;
    className?: string
    onClick?: React.MouseEventHandler<any>
    collapsed: boolean,
}

export const GroupRow = (props: Props) => {

    const groupTitle = props.group.classification != null
        ? !isNaN(Number(props.group.classification))
            ? `${props.group.classification}. ${props.group.classificationDescription}`
            : `${props.group.classificationDescription} (${props.group.classification})`
        : props.group.classificationDescription;

    return <>
        <TableRow className={props.className} onClick={props.onClick}>
            <TableCell colSpan={get(props.budget.extensions, 'PriorityHeader') ? 4 : 3}>
                <div>
                    {props.collapsed ? <ExpandLess /> : <ExpandMore />}
                    <div>{groupTitle}</div>
                </div>
            </TableCell>
            {props.group.total.years.map((value, index) => <TaskYearCell key={index} price={props.collapsed ? value : null} disableTooltip={!props.collapsed} />)}
        </TableRow>
    </>;
}
