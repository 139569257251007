import React from "react";
import Component from "component";

type Props = React.PropsWithChildren<{}>;

export default ((props: Props) => {
    const TopContainer = () =>
        <div style={{height:65, display:'flex', flexDirection:'row', width:'100%', alignContent: 'stretch', paddingTop: 30}}>
            <div style={{flex: 1, display:'flex', flexDirection:'column', alignItems: 'center'}}>                    
                <a href='/'><img src='/assets/logo_dv.png' width={132}/></a>
            </div>
            
            <div style={{flex: 1}} />

            <div style={{flex: 1, display:'flex', flexDirection:'column', alignItems: 'center'}}>                    
                <a href='http://www.nrgisystems.dk'><img src='/assets/nrgi-logo.svg' width={123} height={40} /></a>
            </div>
        </div>
             
    const BottomContainer = () =>
        <div style={{height:100, background: 'linear-gradient(180deg, #9EE994 0%, #94E9C9 100%'}}>
            <div style={{height: 196, marginTop: 4, background: 'white'}}/>
        </div>

    return ( 
        <div style={{ display:'flex', flexDirection:'column', width:'100%', height:'100%', alignContent: 'stretch', fontFamily: 'Open Sans'}}>
            <TopContainer/>
                <div style={{flex: 1, display:'flex', flexDirection:'row', width:'100%', height:'100%', overflowY: 'auto', alignContent: 'stretch'}}>     
                    <div style={{flex: 1}} />

                    <div style={{flex: 1, flexDirection:'column', margin: 'auto', padding: '25px 0'}}>
                        {
                            props.children
                        }
                    </div>

                    <div style={{flex: 1}} />                   
                </div>  
            <BottomContainer/>
        </div>
    )       
})