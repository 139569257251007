import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import styles from './styles';

type Props = {
    header: string;
};

const useStyles = makeStyles(styles);

export const ToolbarHeader = (props: Props) => {
    const styles = useStyles();
    return <Typography className={styles.toolbarHeader} variant='h6'>{props.header}</Typography>
};