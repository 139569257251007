import { Tabs} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';

export default withStyles({
    indicator: {
        background: 'linear-gradient(135deg, #83E576 0%, #54DFA7 100%)',
        boxShadow: '0 0 4px 0 rgba(0,0,0,0.5)',
        transform: 'scaleY(-1)',
        height: '4px',
    },
})(Tabs);