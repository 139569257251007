import React, { useContext } from 'react'
import ReactPDF, { Image as PdfImage, View, Text as PdfText } from '@react-pdf/renderer';
import { SmallBoldText, BoldText } from './text'
import Api from 'server/api'
import _ from 'lodash';
import { PageContext } from './context'

import Es = Api.Cases.Queries.Reports.Es;
import MasterDataDto = Es.MasterDataDto;

const Title = (props: ReactPDF.TextProps) => 
    <PdfText {...props} style={{
        fontSize: 20, 
        height: 50, 
        fontFamily: 'Open Sans Bold',
        paddingTop: 7,
        ...props.style}} 
    />

const CompanyInfo = (props: {masterData: MasterDataDto}) =>
    <View style={{ 
        position: 'absolute', 
        right: 0, 
        bottom: 0, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'flex-end' 
    }}>
        {
            props.masterData.company.logoUrl && <View style={{ marginBottom: 6 }}>
                <PdfImage style={{ height: 24, alignSelf: 'center' }} source={'/api/' + props.masterData.company.logoUrl } />
            </View>
        }

        <SmallBoldText>{props.masterData.company.name}</SmallBoldText>
        <SmallBoldText>{props.masterData.company.address}</SmallBoldText>
        <SmallBoldText>{props.masterData.company.phoneNumber}</SmallBoldText>
        <SmallBoldText>{props.masterData.company.email}</SmallBoldText>
    </View>

export default (props: {masterData: MasterDataDto}) =>{
    
    const context = useContext(PageContext);

    return <View style={{height: '100%'}}>
        <View style={{ position: 'absolute', right: 0, top: 149 }}>
            <BoldText>{props.masterData.case.name}</BoldText>
            <BoldText>{props.masterData.case.nickName}</BoldText>
            <BoldText>{props.masterData.case.address}</BoldText>
            <Title>{context.reportTitle.toUpperCase()}</Title>
            <View style={{ width: 382, height: 382 }}>
            {props.masterData.case.imageUrl
                ? <PdfImage style={{ 
                    objectFit: 'contain',
                    objectPosition: '0 0'
                } as any} source={'/api/' + props.masterData.case.imageUrl + '&width=1146'} />
                : <View style={{ width: '100%', height: '100%', backgroundColor: '#CCCCCC' }} />}
            </View>
        </View>
        <CompanyInfo {...props} />
    </View>
}