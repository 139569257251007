import React from 'react'
import ReactPdf, { StyleSheet, View } from '@react-pdf/renderer';
import { BoldText, colors, Header1, Page, Text, TOCHeader } from './controls'
import { Table, Row } from 'pages/report/controls/table'
import Api from 'server/api'
import { numeral } from 'utils'
import Obh = Api.Modules.Obh.Queries
import TenYearBudget = Obh.TenYearBudget;

type Props = {
    caseName: string,
    tenYearBudgets: readonly TenYearBudget[],
    headers: TOCHeader[],
    isCompleted: boolean
};

type TenYearBudgetProps = {
    caseName: string,
    budget: TenYearBudget,    
    headers: TOCHeader[],
    isCompleted: boolean
};

type BudgetPageProps = React.PropsWithChildren<{ 
    headerLine: Obh.HeaderLine, 
    columnSizes: readonly number[], 
    caseName: string, 
    headers: TOCHeader[], 
    isCompleted: boolean }>;

const styles = StyleSheet.create({
    headerCell: {
        borderTop: 1,
        borderTopColor: 'black',
        padding: '7,5,5,5',
        marginRight: 1,
        backgroundColor: 'white'
    },
    right: {
        textAlign: 'right'
    },
    groupHeader: {
        backgroundColor: colors.darkBlue,
        color: 'white',
        textAlign: 'center'
    },
    cell: {
        padding: '5,5,2,5',
        marginRight: 1,
        backgroundColor: colors.lightBlue
    }
});

const BudgetBoldText = (props: ReactPdf.TextProps) => <BoldText {...props} style={{ fontSize: 7, ...props.style }} />
const BudgetText = (props: ReactPdf.TextProps) => <Text {...props} style={{ fontSize: 7, ...props.style }} />

const BudgetPage = (props: BudgetPageProps) =>
    <Page size="A4" orientation="landscape" caseName={props.caseName} showWatermark={!props.isCompleted}>    

        <Header1 headers={props.headers}>Budget</Header1>

        {/* Ensures fixed table header on each page */}
        <Table fixed colWidths={props.columnSizes}>
            <Row cellStyle={styles.headerCell}>
                <BudgetBoldText>{props.headerLine.cardHeader}</BudgetBoldText>
                <BudgetBoldText>{props.headerLine.taskHeader}</BudgetBoldText>
                <BudgetBoldText>{props.headerLine.priorityHeader}</BudgetBoldText>
                {props.headerLine.years.map((value, index) => <BudgetBoldText style={styles.right} key={index}>{value}</BudgetBoldText>)}
                <BudgetBoldText style={styles.right}>{props.headerLine.subtotalHeader}</BudgetBoldText>
            </Row>
        </Table>
        {props.children}
    </Page>

const GroupRow = (groupLine: Obh.GroupLine) =>
    <Row colWidths={[0]} style={{ paddingBottom: 1 }} minPresenceAhead={30}>
        <BudgetBoldText style={styles.groupHeader}>{groupLine.groupTitle}</BudgetBoldText>
    </Row>

const TaskRow = (taskLine: Obh.TaskLine, index: number) =>
    <Row
        key={taskLine.id}
        cellStyle={index % 2 !== 0 ? { backgroundColor: 'white' } : { }}>
        <BudgetText>{taskLine.location}</BudgetText>      
        <BudgetText>{taskLine.title}</BudgetText>
        <BudgetText>{taskLine.priority}</BudgetText>
        {taskLine.years.map((value, i) => <BudgetText style={styles.right} key={i}>{value !== 0 ? numeral(value).format('0,0') : ''}</BudgetText>)}
        <BudgetText style={styles.right}>{numeral(taskLine.subtotal).format('0,0')}</BudgetText>
    </Row>

const SumRow = (sumLine: Obh.SumLine, index: number) =>
    <Row style={{ marginTop: 2 }} key={index}>
        <BudgetBoldText>{sumLine.title}</BudgetBoldText>
        <BudgetBoldText />
        <BudgetBoldText />
        {sumLine.years.map((value, index) => <BudgetBoldText style={styles.right} key={index}>{numeral(value).format('0,0')}</BudgetBoldText>)}
        <BudgetBoldText style={styles.right}>{numeral(sumLine.subtotal).format('0,0')}</BudgetBoldText>
    </Row>

const TenYearBudget = (props: TenYearBudgetProps) => {    
    const budget = props.budget;

    const cardTitleColumnWidth = 135;
    const priorityColumnWidth = 45;
    const yearColumnWidth = 35;

    const titleColumnSize = 135 + (11 - (budget.headerLine.years.length + 1)) * (yearColumnWidth + 1);
    const columnSizes = [cardTitleColumnWidth, titleColumnSize, priorityColumnWidth].concat(Array(budget.headerLine.years.length + 1).fill(yearColumnWidth));

    return (
        <BudgetPage headerLine={budget.headerLine} columnSizes={columnSizes} {...props}>

            <Table style={{ width: 714 }} colWidths={columnSizes} cellStyle={styles.cell}>
                {
                    budget.lines.filter(x => x.discriminator === 'GroupLine')
                        .map((line: Obh.GroupLine) =>
                            [
                                GroupRow(line),
                                line.lines.map((taskLine, index) => TaskRow(taskLine, index))
                            ])
                }
                {
                    budget.lines.filter(x => x.discriminator === 'SumLine')
                        .map((line: Obh.SumLine, index) => SumRow(line, index))
                }
            </Table>
            <BudgetText style={{ margin: "5em" }}>{props.budget.description}</BudgetText>
        </BudgetPage>
    );
}

export const Budget = (props: Props) => {
    return (
        <>
            {
                props.tenYearBudgets.map((x, index) =>
                    <TenYearBudget key={index} budget={x} caseName={props.caseName} headers={props.headers} isCompleted={props.isCompleted}></TenYearBudget>
                )
            }
        </>
    );
}