import React from 'react'
import { Document } from '@react-pdf/renderer';
import Api from 'server/api'
import { Front } from './front';
import { Inspection } from './inspection';
import { Introduction } from './introduction';
import { Results } from './results';
import { Budget } from './budget';
import { Estate } from './estate';
import { TableOfContents } from './table-of-contents';
import { TOCHeader } from './controls';
import { TableSettings } from '../controls/table';
import { Options } from '../generate-report';
import { Cards } from './cards';

import ReportDto = Api.Modules.Obh.Queries.ObhReportDto;

type Props = {
    reportData: ReportDto,
    options: Options
}

export const ObhReport = (props: Props) => {
    const caseName = props.reportData.caseNickname || props.reportData.caseName;
    const isCompleted = props.reportData.isCompleted || props.options.asCompleted;

    let headers = [] as TOCHeader[];

    return (
        <TableSettings.Provider value={{ alwaysApplyCellStylesToChildren: true }}>
            <Document>
                <Front {...props.reportData} isCompleted={isCompleted} />
                <TableOfContents caseName={caseName} headers={headers} isCompleted={isCompleted} />
                <Introduction purposeText={props.reportData.purpose} background={props.reportData.background} caseName={caseName} headers={headers} isCompleted={isCompleted} />
                <Estate image={props.reportData.caseImage} buildings={props.reportData.buildings} caseName={caseName} caseDescription={props.reportData.description} headers={headers} isCompleted={isCompleted} />
                <Results {...props.reportData.results} caseName={caseName} headers={headers} isCompleted={isCompleted} />
                <Inspection caseName={caseName} headers={headers} priorities={props.reportData.inspection.priorities} isCompleted={isCompleted} />
                <Cards groups={props.reportData.groups} caseName={caseName} isCompleted={isCompleted} headers={headers} />
                <Budget tenYearBudgets={props.reportData.tenYearBudgets} caseName={caseName} headers={headers} isCompleted={isCompleted} />
            </Document>
        </TableSettings.Provider>
    );
};

