import React, { useState, useCallback } from "react";
import Api from "server/api";
import { TableRow, TableCell, makeStyles, Toolbar } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import DuplicateIcon from '@material-ui/icons/FileCopy';
import { WarningPopup } from "controls/warning-popup";
import styles from './styles'
import cx from 'classnames'
import _ from "lodash";
import { DuplicateCasePopup } from "./duplicate-case-popup";
import { IconButton } from "controls/button/icon-button";
import { Tooltip } from "controls/tooltip";
import { ChangeCaseTypePopup } from "./change-case-type-popup";
import CaseTypeDto = Api.Cases.Queries.GetCaseTypes.CaseTypeDto;

type Props = {
    openCase: (caseId: string) => void;
    deleteCase: (caseId: string) => Promise<unknown>;
    changeCaseType: (caseId: string, caseType: string) => Promise<unknown>;
    onCaseDuplicated: (caseId: string) => void;
    case: Api.Cases.Queries.GetCases.CaseDto & { typeDisplayName: string;};
    caseTypes: CaseTypeDto[]
}

const useStyles = makeStyles(styles);

export const CaseSummary = (props: Props) => {
    const styles = useStyles();
    const [deletePopupOpen, setDeletePopupOpen] = useState(false);
    const [duplicatePopupOpen, setDuplicatePopupOpen] = useState(false);
    const [changeCaseTypePopupOpen, setChangeCaseTypePopupOpen] = useState(false);

    const openDeletePopup = () => setDeletePopupOpen(true)
    const closeDeletePopup = () => setDeletePopupOpen(false)

    const openDuplicatePopup = () => setDuplicatePopupOpen(true);
    const closeDuplicatePopup = () => setDuplicatePopupOpen(false);

    const $case = props.case;

    const deleteCase = useCallback(async () => {
        closeDeletePopup();
        await props.deleteCase($case.id);
    }, [$case.id]);

    const changeCaseType = useCallback(async (caseType: string) => {
        setChangeCaseTypePopupOpen(false);
        await props.changeCaseType($case.id, caseType);
    }, [$case.id])

    const openCase = useCallback(() => {
        props.openCase($case.id);
    }, [$case.id]);

    const onCaseDuplicated = useCallback(() => {
        closeDuplicatePopup()
        props.onCaseDuplicated($case.id)
    }, [$case.id]);

    return (
        <TableRow key={$case.id} hover className={cx(styles.caseRow, styles.clickable)}>
            <TableCell component="th" scope="row" onClick={openCase}>{$case.name}</TableCell>
            <TableCell onClick={openCase}>{$case.nickname}</TableCell>
            <TableCell onClick={openCase}>{$case.address}</TableCell>
            <TableCell onClick={openCase}>{$case.customer}</TableCell>
            <TableCell onClick={openCase}>{$case.approvedBy}</TableCell>
            <TableCell onClick={openCase}>{$case.inspectionDate}</TableCell>
            <TableCell className={cx({[styles.caseType]: !props.case.isLocked})} onClick={() => !props.case.isLocked && setChangeCaseTypePopupOpen(true)}>{$case.typeDisplayName}</TableCell>
            
            {$case.isCompleted && <TableCell>{$case.completedAt}</TableCell>}
            
            <TableCell align='right'>
                <Toolbar variant='dense' disableGutters>
                    <Tooltip title="Kopier sag">
                        <IconButton
                            aria-label="Duplicate"
                            onClick={openDuplicatePopup}>
                            <DuplicateIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    {!$case.isLocked && <>
                        <Tooltip title="Slet sag">
                            <IconButton
                                aria-label="Delete"
                                onClick={openDeletePopup}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <WarningPopup
                            onOk={deleteCase}
                            onClose={closeDeletePopup}
                            isOpen={deletePopupOpen} 
                            title='Slet sagen?'
                            message='Du er ved at slette sagen. Er du sikker?'
                            okText='SLET'
                            cancelText='FORTRYD'
                        />
                    </>}
                </Toolbar>
                <DuplicateCasePopup
                    open={duplicatePopupOpen}
                    onSuccess={onCaseDuplicated}
                    onClose={closeDuplicatePopup}
                    case={$case}
                />
                <ChangeCaseTypePopup
                    open={changeCaseTypePopupOpen}
                    onOk={x => changeCaseType(x.caseType)}
                    onClose={() => setChangeCaseTypePopupOpen(false)}
                    case={$case}
                    caseTypes={props.caseTypes}
                />
            </TableCell>
                   
        </TableRow>
    )
}