import React, { useContext } from "react";
import { withStyles, WithStyles, Grid } from "@material-ui/core";
import Api from "server/api";
import styles from './styles';
import { FormField } from 'controls/field/form-field';
import { SelectField } from 'controls/field/select-field';
import { Paper } from 'controls/paper'
import { BudgetLines } from 'pages/budget-lines';
import Breadcrumbs from "controls/breadcrumbs";
import { UpdateAction, createUpdater2, via, createUpdater0 } from "utils/updaters";
import { MainContext } from "context";
import { Maybe } from "utils/maybe";

type Props = {
    taskId: string
    priorities: Api.Modules.Priority.Queries.GetPriorities.PriorityDto[]
    task: Api.Cases.Queries.GetTask.OneTimeTaskDto
    card: Api.Cases.Queries.GetTask.CardDto
    updateTask: (task: Api.Cases.Queries.GetTask.OneTimeTaskDto) => void
} & WithStyles<typeof styles>

export const OneTimeTask = withStyles(styles)((props: Props) => {
    let context = useContext(MainContext);

    let priorities = props.priorities.map(x => ({ label: x.translation, value: x.value }));

    const writeTask: UpdateAction = async (update) =>
        await context.server.command(new Api.Cases.Commands.WriteTaskField({
            caseId: null,
            cardId: props.card.id,
            taskId: props.taskId,
            fieldName: update.fieldName,
            value: update.value
        }));
    
    const update = createUpdater0(props.task, via(props.updateTask), writeTask);
    const updatePriority = createUpdater2(props.task, via(props.updateTask), ['extensions', 'priority'], writeTask);

    return (
        <>
            <Breadcrumbs crumbs={[props.card.group, props.card.type, props.card.description || 'Unavngivet kort', 'Engangsopgave']} />
            <Paper className={props.classes.container} header='Problem'>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <FormField
                            type='text'
                            label='Præcisér placering'
                            field={props.task.location}
                            onValidBlur={update("location")}
                            optional
                            placeholder={props.card.location}
                            inputLabelProps={{
                                shrink: !!props.card.location || undefined
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormField
                            type='text'
                            label='Tilføj til tilstandsbeskrivelsen'
                            multiline
                            optional
                            field={props.task.problemDescription}
                            onValidBlur={update("problemDescription")}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Paper className={props.classes.container} header='Opgave'>
                <Grid container spacing={1}>
                    <Grid item xs={props.task.extensions.priority ? 9 : 12}>
                        <FormField
                            type='text'
                            label="Opgavebeskrivelse"
                            field={props.task.description}
                            onValidBlur={update("description")}
                        />
                    </Grid>
                    {props.task.extensions.priority && <Grid item xs={3}>
                        <SelectField
                            optional
                            label="Prioritet"
                            field={props.task.extensions.priority.priority}
                            onChange={updatePriority("priority")}
                            items={priorities}
                        />
                    </Grid>}
                    <Grid item xs={12}>
                        <FormField
                            type='text'
                            label='Reparationsinstruks'
                            multiline
                            field={props.task.solutionDescription}
                            onValidBlur={update("solutionDescription")}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <BudgetLines
                cardId={props.card && props.card.id}
                taskId={props.taskId}
                budgetLines={props.task.budgetLines}
            />

            <div className={props.classes.spacer}></div>
        </>
    );
})