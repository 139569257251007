import React from 'react'
import { Canvas } from '@react-pdf/renderer';

export const NrgiLogo = ({ height }: { height: number }) => <Canvas
    style={{ width: height * 123 / 40, height: height }}
    paint={(painter) => painter
        .scale(height * 0.025)
        .path(`M0 0V40H111.647L123 0H0Z`)
        .fill("#006E64")
        .path(`M96.5714 12.1807H91.6836V17.3807H96.5714V12.1807Z`)
        .path(`M35.7992 12.1807V22.4581L26.6998 12.1807H19.5967V27.4645H24.6455V16.9936L33.9381 27.4645H40.848V12.1807H35.7992Z`)
        .path(`M96.5714 19.0518H91.6836V27.4647H96.5714V19.0518Z`)
        .path(`M61.6549 22.9612C63.5224 21.9289 64.4562 20.2085 64.4562 17.7999C64.4676 17.113 64.3675 16.4288 64.1599 15.7741C64.0011 15.2453 63.7528 14.7477 63.4258 14.3031C63.119 13.9087 62.7465 13.5704 62.3246 13.3031C61.9436 13.0404 61.5329 12.8238 61.101 12.658C60.6823 12.5104 60.2513 12.4003 59.8131 12.3289C59.4508 12.2627 59.0854 12.2153 58.7183 12.187C58.4607 12.187 58.1838 12.187 57.894 12.187H43.3916V27.4644H48.4211V16.6451H57.3144C58.6024 16.6451 59.2914 17.0773 59.2914 17.9354C59.2914 18.7935 58.6475 19.3741 57.3981 19.3741H52.3944V23.8709L57.3144 27.4644H64.5785L59.0081 23.6709C59.9108 23.5591 60.7931 23.3202 61.6291 22.9612`)
        .path(`M89.2493 19.3934C89.2493 19.258 89.2493 19.0515 89.2493 19.0515H78.8619L78.7653 19.3741V19.4128L78.0376 21.9354H83.8334C83.7111 22.3805 82.9769 23.4838 78.6816 23.6322C78.3339 23.6322 77.9732 23.6322 77.5997 23.6322C76.8293 23.6394 76.0598 23.5768 75.3007 23.4451C72.2869 22.9418 71.6622 21.3934 71.6622 19.716C71.6622 17.6709 72.7055 15.9612 77.6384 15.9676C80.4203 15.9676 81.5022 16.316 81.9273 16.516C82.1466 16.5809 82.3508 16.689 82.5278 16.8342C82.7047 16.9793 82.8509 17.1585 82.9576 17.3612H89.1656C89.0737 16.8623 88.9201 16.3768 88.7084 15.916C88.2606 15.0219 87.5964 14.2545 86.7764 13.6838C85.6817 12.9508 84.4508 12.4461 83.1573 12.1999C81.3379 11.824 79.4829 11.6488 77.6255 11.6773C75.9599 11.6553 74.2964 11.8023 72.6604 12.116C71.3955 12.3633 70.1892 12.8494 69.1056 13.5483C68.145 14.1816 67.3853 15.0771 66.9161 16.1289C66.4008 17.3527 66.1506 18.6721 66.182 19.9999C66.1505 21.2087 66.3472 22.4126 66.7615 23.5483C67.1682 24.5244 67.8369 25.3686 68.6935 25.987C69.7532 26.7366 70.9646 27.2432 72.2418 27.4709C74.0731 27.8211 75.9352 27.9832 77.7994 27.9547V27.9547C79.3911 27.969 80.9812 27.8459 82.5519 27.587C83.6914 27.4114 84.8038 27.0905 85.862 26.6322C86.6224 26.2801 87.3054 25.7801 87.8712 25.1612C88.3588 24.6404 88.7318 24.0227 88.966 23.3483C89.1329 22.7308 89.2323 22.097 89.2622 21.458C89.3008 20.8128 89.2622 19.587 89.2622 19.4128`)
        .fill("white")
    } />

export const EbasHouseGraphic = ({ height }: { height: number }) => <Canvas
    style={{ width: height * 600 / 397, height: height }}
    paint={(painter) => painter
        .scale(height * 0.0036)
        .translate(-142, -23)
        .path(`M184.2,199.6l39-6.1v61H184L184.2,199.6z M190,216.6l8.9-0.6v-14.2l-9,1.3L190,216.6z M207.6,215.4l9.6-0.4v-16
            l-9.5,1.5L207.6,215.4z M190,239.7h9v-15l-9,0.4V239.7z M207.7,239.6h9.6v-15.7l-9.7,0.4L207.7,239.6z`)
        .rect(389, 205.7, 2.6, 48.7)
        .polygon([278,152.4], [275,152.4], [275,140.7], [264.9,138.9], [265.5,136], [278,138.2])
        .polygon([334.6,163.2], [331.6,163.2], [331.6,152.5], [324,151], [324.6,148.1], [334.6,150])
        .polygon([379.6,172.1], [376.6,172.1], [376.6,162.5], [370.2,161], [370.9,158.1], [379.6,160.1])
        .polygon([257.8,171.2], [267.2,172.4], [267.2,157.1], [257.5,155.3])
        .polygon([318.9,166], [318.9,180.2], [326.3,181.2], [326.3,167.3])
        .polygon([365.9,174.3], [371.4,175.3], [371.4,188.1], [365.9,187.4])
        .polygon([245,215.1], [234.5,214.4], [234.5,197.7], [245.4,198.7])
        .polygon([254.3,215.6], [254.3,199.7], [264.2,200.6], [264.2,216.5])
        .polygon([280.3,202.1], [289.9,202.9], [289.9,218.1], [280.3,217.5])
        .polygon([304.6,204.1], [311.2,204.8], [311.2,219.4], [304.6,218.9])
        .polygon([318.9,205.5], [326.3,206.1], [326.3,220.4], [318.9,220])
        .polygon([332.4,207], [339.5,207.6], [339.5,221.6], [332.4,221])
        .polygon([234.5,223.8], [245.4,224.2], [245.4,240.5], [234.5,240.4])
        .polygon([254.3,224.8], [264.2,225.2], [264.2,241.1], [254.3,241.1])
        .polygon([279.5,225.4], [290.6,226], [290.6,254.5], [279.6,254.5])
        .polygon([304.6,227.3], [304.6,227.3], [311.2,227.6], [311.2,242], [304.6,242.1])
        .polygon([318.9,227.9], [326.3,228.3], [326.3,242.3], [318.9,242.3])
        .polygon([332.4,228.6], [339.5,228.8], [339.5,242.6], [332.4,242.6])
        .polygon([350,229.1], [358.1,229.6], [358.1,254.5], [350.1,254.5])
        .rect(388.8, 206.8, 2.9, 47.6)
        .polygon([350.2,208.4], [356.4,208.9], [356.4,222], [350,221.5])
        .polygon([367.2,210.2], [372.8,210.7], [372.8,223.3], [367.2,222.8])
        .polygon([377.7,211.4], [382.7,211.7], [382.5,223.8], [377.7,223.6])
        .polygon([367.5,230.3], [373.4,230.5], [373.4,243.2], [367.5,243.2])
        .polygon([377.6,230.9], [383.9,231.2], [383.9,243.5], [378.1,243.5])
        .rect(243.1, 150.3, 2.9, 29.1)
        .rect(306.8, 160.4, 3.1, 27.9)
        .rect(356.3, 169.2, 2.8, 25.5)
        .path(`M205.3,165.8v-4.5l17.3-15.3l40.2-13.4l17.3,21.5l42.8-9.7l12.7,19.2l33.6-9.5l11.6,19.1v6l18.2,29.2
            l-180.6-17.8l-39.9,8L205.3,165.8z M278.1,181.4v-24.4L262,136.9L245.4,151v25.8L278.1,181.4z M334.3,188.1v-21.4l-11.9-17.6
            l-12.5,13.1v22.6L334.3,188.1z M307.1,184.2v-21.8l-19.9,2.3L307.1,184.2z M356.3,190v-18l-14.7,1.6L356.3,190z M378.1,193.3v-17.9
            l-9.2-15.7l-9.3,11.8v19.4L378.1,193.3z M243.1,176.5l-0.5-24.8l-34.4,9.4l0.1,3.5l19.3-4.5L243.1,176.5z`)
        .fill("#005159")
    } />
    