import { doNothing } from "utils";
import { Dispatch } from "react";

import Api from "server/api";

type ValueOf<T extends Api.DV.Application.FieldInfo<unknown>> = T extends Api.DV.Application.FieldInfo<infer U> ? U : void;

export const via = <S extends any>(receiver: (s: S) => void) => (transform: (s: S) => S) => (s: S) => receiver(transform(s))

export type FieldUpdate = { fieldName: string, value: any };
type SetAction<S extends any> = (prevState: S) => S;
export type UpdateAction = (x: FieldUpdate) => Promise<unknown>;

type KeysOf<T, U> = { [k in keyof T]: T[k] extends U ? k : never }[keyof T];
type OnlyKeysOf<T, U> = { [k in KeysOf<T, U>]: U };

export const createStateUpdater = <T extends {}>(set: Dispatch<SetAction<T>>) => 
    <TKey extends keyof T>(key: TKey, action: ((value: T[TKey]) => void) = doNothing) => 
    (value: T[TKey]) => {

        set(s => ({...s, [key]: value }))
        action(value);
};

type Unarray<T> = T extends ReadonlyArray<infer U> ? U : never;

export const createSwitchElementsArrayUpdater = (index1: number, index2: number) =>
    <T>(array: readonly T[]): readonly T[] =>  array.map((element, i) => {
            if (i === index1) return array[index2];
            if (i === index2) return array[index1];
            return element;
        });

// immutable reorder https://stackoverflow.com/a/39271175
export const createReorderElementsArrayUpdater = (from: number, to: number) =>
    <T>(array: readonly T[]): readonly T[] => array.reduce((prev, current, idx, self) => {
        if (from === to) {
          prev.push(current);
        }
        if (idx === from) {
          return prev;
        }
        if (from < to) {
          prev.push(current);
        }
        if (idx === to) {
          prev.push(self[from]);
        }
        if (from > to) {
          prev.push(current);
        }
        return prev;
      }, [])

export const createArrayStateElementUpdater = <T extends {}, TArrayKey extends keyof T, TArray extends Unarray<T[TArrayKey]> >(
    set: Dispatch<React.SetStateAction<T>>, 
    arrayKey: TArrayKey
    ) => 
    (index: number) =>
    <TKey extends keyof TArray>(key: TKey) => 
        (value: TArray[TKey]) => {

            set(s => ({...s, [arrayKey]: (s[arrayKey] as unknown as any[]).map((e, i) => i !== index ? e : ({...e, [key]: value })) }) )
    };

export const createUpdater0 = <
    T extends {}, 
>(model: T, set: Dispatch<SetAction<T>>, action: UpdateAction) => <
    TKey1 extends keyof OnlyKeysOf<T, Api.DV.Application.FieldInfo<any>>,
    TValue extends ValueOf<T[TKey1]>
>(key: TKey1) =>
        async (value: TValue) => {
            
            const field = model[key] as Api.DV.Application.FieldInfo<TValue>;

            set(s => ({ ...s, [key]: value }));
            await action({ fieldName: field.name, value })
        };

export const createUpdater1 = <
    T extends {}, 
    TKey1 extends keyof T
>(model: T, set: Dispatch<SetAction<T>>, path: [TKey1], action: UpdateAction) => 
    <TKey2 extends keyof T[TKey1]>(key: TKey2) =>
        async <TValue extends ValueOf<T[TKey1][TKey2]>>(value: TValue) => {
            
            const field = model[path[0]][key] as Api.DV.Application.FieldInfo<TValue>;

            set(s => ({ ...s, 
                [path[0]]: { ...s[path[0]], 
                    [key]: { ...s[path[0]][key], value }}}));

            await action({ fieldName: field.name, value })
        };

export const createUpdater2 = <
    T extends {}, 
    TKey1 extends keyof T,
    TKey2 extends keyof T[TKey1],
>(model: T, set: Dispatch<SetAction<T>>, path: [TKey1, TKey2], action: UpdateAction) => 
    <TKey3 extends keyof T[TKey1][TKey2]>(key: TKey3) =>
        async <TValue extends ValueOf<T[TKey1][TKey2][TKey3]>>(value: TValue) => {
            
            const field = model[path[0]][path[1]][key] as Api.DV.Application.FieldInfo<TValue>;

            set(s => ({ ...s, 
                [path[0]]: { ...s[path[0]], 
                    [path[1]]: { ...s[path[0]][path[1]], 
                        [key]: { ...s[path[0]][path[1]][key], value }}}}));

            await action({ fieldName: field.name, value })
        };        

export const createUpdater3 = <
    T extends {}, 
    TKey1 extends keyof T,
    TKey2 extends keyof T[TKey1],
    TKey3 extends keyof T[TKey1][TKey2],
>(model: T, set: Dispatch<SetAction<T>>, path: [TKey1, TKey2, TKey3], action: UpdateAction) => 
    <TKey4 extends keyof T[TKey1][TKey2][TKey3]>(key: TKey4) =>
        async <TValue extends ValueOf<T[TKey1][TKey2][TKey3][TKey4]>>(value: TValue) => {
            
            const field = model[path[0]][path[1]][path[2]][key] as Api.DV.Application.FieldInfo<TValue>;

            set(s => ({ ...s, 
                [path[0]]: { ...s[path[0]], 
                    [path[1]]: { ...s[path[0]][path[1]], 
                        [path[2]]: { ...s[path[0]][path[1]][path[2]],
                            [key]: { ...s[path[0]][path[1]][path[2]][key], value }}}}}));

            await action({ fieldName: field.name, value })
};