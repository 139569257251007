import React, { useMemo, useState } from "react";
import { makeStyles, Grid, Typography, Toolbar } from "@material-ui/core";
import Api from "server/api";
import Page from 'controls/page'
import { Paper } from 'controls/paper'
import { Button } from 'controls/button'
import styles from './styles'
import { WarningPopup } from "controls/warning-popup";
import { ErrorListDisplay } from "controls/error-list-display";
import { useErrorDisplay, useCommand, pipe, useCommandSubscription, useOpenIds, useCaseLockState, decorator, useServer, useLoadingState, disableWhileRunningDecorator } from "custom-hooks";
import { WriteLockProvider } from "context";
import { useQuery } from "custom-hooks/use-query";
import { CaseType, generateReportBlob, getCurrentReportVersion } from "../report/generate-report";
import { Server } from "server";
import { blobToBase64 } from "utils/blobs";

import CaseCompletionDto = Api.Cases.Queries.GetCaseCompletion.CaseCompletionDto;

type Props = {}

const showCompletedContent = (caseCompletion : CaseCompletionDto) => 
    <Grid container spacing={1}>
        <Grid item xs={12}>
            <Typography align='left' variant='body1'>
                Sagen er afsluttet.
            </Typography>
        </Grid>

        <Grid item xs={12}>
            <Typography align='left' variant='body1'>
                Afsluttet d.: {caseCompletion.completedAt}
            </Typography>
        </Grid>

        <Grid item xs={12}>
            <Typography align='left' variant='body1'>
                Afsluttet af: {caseCompletion.completedBy}
            </Typography>
        </Grid>
    </Grid>;

const useStyles = makeStyles(styles);

const fetchReportDecorator = (server: Readonly<Server>) => decorator<Api.Cases.Commands.CompleteCase>(async (cmd, next) => {
    const reportBlobPromise = generateReportBlob(server, cmd.caseId, { asCompleted: true });
    const caseTypePromise = server.query<Api.Cases.Queries.GetCaseType.ResultDto>(new Api.Cases.Queries.GetCaseType({ caseId: cmd.caseId })).toPromise();

    const [reportBlob, caseTypeResult] = await Promise.all([reportBlobPromise, caseTypePromise]);

    const blobbedCommand = new Api.Cases.Commands.CompleteCase({
        caseId: cmd.caseId,
        reportVersion: getCurrentReportVersion(caseTypeResult.caseType as CaseType),
        reportData: await blobToBase64(reportBlob.documentBlob)
    });
    return await next(blobbedCommand);
});

const disableWhileRunning = disableWhileRunningDecorator();

export const Completion = (props : Props) => {
    
    const styles = useStyles();
    const server = useServer();

    const [caseCompletion, setCaseCompletion] = useState<CaseCompletionDto>(null);
    const [showWarningPopup, setShowWarningPopup] = useState(false);

    const errors = useErrorDisplay();
    const { caseId } = useOpenIds();
    const { locked, loadCaseLockedStatus } = useCaseLockState(caseId);
    const loadingState = useLoadingState(100);

    const openPopup = () => setShowWarningPopup(true);
    const closePopup = () => setShowWarningPopup(false);

    const updateGuidDecorator = useMemo(() => decorator<Api.Cases.Commands.CompleteCase>(async (cmd, next) => {
        closePopup();
        const result = await next(cmd);
        await loadCaseLockedStatus();
        return result;
    }), [loadCaseLockedStatus]);

    const completeCase = useCommand((caseId: string) => new Api.Cases.Commands.CompleteCase({ caseId: caseId, reportData: null, reportVersion: null })
        , pipe(disableWhileRunning, loadingState, updateGuidDecorator, fetchReportDecorator(server), errors));
        
    const getCaseCompletion = useQuery((caseId: string) => new Api.Cases.Queries.GetCaseCompletion({ caseId: caseId }));

    useCommandSubscription(() => getCaseCompletion(caseId).then(setCaseCompletion), [caseId])

    if (!caseCompletion) return null;

    const showDraftContent = () => 
        <div className={styles.completeContainer}>
            <Toolbar className={styles.completeToolbar}>
                <Button label='Afslut sag' onClick={openPopup} loading={loadingState.loaderVisible} /> 
            </Toolbar>
            <Typography align='left' variant='body1'>
                Ved at afslutte sagen fjernes kladde-vandmærket fra rapporten og sagen låses, så den ikke længere kan redigeres.
                Vær opmærksom på at du betaler for sagen ved afslutning. 
            </Typography>
            <WarningPopup
                onOk={async () => await completeCase(caseId)}
                onClose={closePopup}
                isOpen={showWarningPopup} 
                title='Afslut sagen?'
                message='Du er ved at afslutte sagen, så den låses og ikke længere kan redigeres. Vær opmærksom på at du betaler for sagen ved afslutning. Er du sikker?'
                okText='AFSLUT'
                cancelText='FORTRYD'
            />
        </div>;

    return (
        <WriteLockProvider locked={locked}>
            <Page>
                <Paper className={styles.container} header='Afslutning af sag'>
                    <ErrorListDisplay {...errors} />
                    {
                        caseCompletion.isCompleted 
                            ? showCompletedContent(caseCompletion) 
                            : showDraftContent()
                    }
                </Paper>
            </Page>
        </WriteLockProvider>
    );
}