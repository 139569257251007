import React from 'react';
import { FormControlLabel, Checkbox as MaterialUICheckbox, makeStyles } from '@material-ui/core';
import { useWriteLock } from 'custom-hooks';
import { Lockable } from 'utils/lockable';

export type Props = {
    checked: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    tabIndex?: number;
    label?: string;
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
    className?: string;
} & Lockable;

const useLabelStyles = makeStyles({
    label: {
        fontSize: 14
    }
});

const ControlLabel = (props: Props & { control: JSX.Element }) => {

    const styles = useLabelStyles();
    const writeLock = useWriteLock(props);

    return (
        <FormControlLabel
            label={props.label}
            labelPlacement={props.labelPlacement}
            disabled={writeLock}
            control={props.control}
            classes={{ label: styles.label }}
            className={props.className}
        />
    );
}

const useControlStyles = makeStyles({
    checked: {
        "&&": {        // to increase specificity
            color: '#54DFA7'
        },
        "&::after": {
            content: '""',
            height: 15,
            width: 15,
            position: "absolute",
            backgroundColor: 'rgba(0, 0, 0, 0.87)',
            zIndex: 0
        },
        '& svg': {
            zIndex: 1
        },
        '& input': {
            zIndex: 2
        }
    },
    disabled: {
        "&&": {        // to increase specificity
            color: '#e0e0e0',
            "&::after": {
                backgroundColor: '#a6a6a6',
            }
        }
    },
});

const Control = (props: Props) => {

    const styles = useControlStyles();
    const writeLock = useWriteLock(props);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (!writeLock) props.onChange(event, checked);
    }

    return <MaterialUICheckbox
        checked={props.checked}
        disabled={writeLock}
        tabIndex={props.tabIndex}
        onChange={onChange}
        classes={{ 
            checked: styles.checked,
            disabled: styles.disabled
        }}
        className={!props.label ? props.className : null}
    />;
}

export const Checkbox = (props: Props) => {

    const writeLock = useWriteLock(props);

    const checkbox = <Control {...props} />;

    return !!props.label
        ? <ControlLabel {...props} disabled={writeLock} control={checkbox} />
        : checkbox;
};