import React, { useState } from 'react';
import styles from './styles';
import { makeStyles } from "@material-ui/core";
import { sguid } from "utils";
import { Templates } from "templates";
import { useOpenIds, useCommand, decorator, useServer } from 'custom-hooks';
import { CreateTaskPopup } from "../create-task-popup";
import { intersection, lowerFirst } from 'lodash';
import { ToolbarButton } from './toolbar-button';

import Api from "server/api";
import CreateTaskCmd = Api.Cases.Commands.CreateTask;
import TaskType = Api.Cases.TaskType;
import Rule = Api.Cases.Queries.GetCompanyTemplatesForCase.RuleDto;
import TaskTemplate = Api.Cases.Queries.GetCompanyTemplatesForCase.TaskTemplateDto;
import CardDto = Api.Cases.Queries.GetCard.CardDto;

type Props = {
    taskType: CardTaskType;
    templates: Templates;
    label: string;
    icon: React.ReactElement;
    onTaskCreated: (taskId: string) => void;
    visible?: boolean;
}

type State = {
    createTaskPopup: boolean;
    card: CardDto;
}

export enum CardTaskType {
    RecurringCardTask,
    OneTimeCardTask,
    RecurringTaskWithoutCard,
    OneTimeTaskWithoutCard
}

export const compliesToRule = (cardValue: string, rule: Rule) => {
    if (rule.value && rule.value.trim() === '*') return true;

    const taskTemplateValues = (rule.value || '').split(';').map(x => x.trim()).filter(x => x !== '');
    const cardValues = (cardValue || '').split(';').map(x => x.trim()).filter(x => x !== '');

    switch (rule.operator) {
        case '=':
            return intersection(cardValues, taskTemplateValues).length > 0
        case '!=':
            return intersection(cardValues, taskTemplateValues).length === 0
    }

    return true;
}

const useStyles = makeStyles(styles);

export const CreateTask = (props: Props) => {
    const styles = useStyles();
    const server = useServer();
    const { caseId, cardId } = useOpenIds();

    const [state, setState] = useState<State>({
        createTaskPopup: false,
        card: null as CardDto
    });

    const openPopup = async () => {
        if (isCardTask()) await loadCard();

        // https://reactjs.org/docs/hooks-reference.html#usestate
        // Unlike the setState method found in class components, useState does not automatically merge update objects. 
        // You can replicate this behavior by combining the function updater form with object spread syntax:
        setState(s => ({ ...s, createTaskPopup: true }));
    }

    const createTask = useCommand((description: string, templateId: string) => 
        new CreateTaskCmd({
            type: getTaskType(),
            caseId: caseId,
            cardId: isCardTask() ? cardId : null as string,
            newTaskId: `task/${sguid()}`,
            description: description,
            templateId: templateId
        }), 
        decorator<CreateTaskCmd>(async (cmd, next) => {
            setState(s => ({ ...s, createTaskPopup: false }))
            const result = await next(cmd);
            props.onTaskCreated(cmd.newTaskId);
            return result;
        }))

    const loadCard = async () => {
        const card = await server.query<Api.Cases.Queries.GetCard.CardDto>(
            new Api.Cases.Queries.GetCard({ cardId: cardId })
        ).toPromise();

        setState({ ...state, card: card });
    }

    const getTemplatesForTask = () =>
        isCardTask()
            ? getTemplatesForCardTask()
            : getTemplatesForTaskWithoutCard();

    const getTemplatesForTaskWithoutCard = () => {
        const templateGroup = props.templates['Andet'];

        if (!templateGroup)
            return [];

        const templates = getTaskType() == TaskType.OneTimeTask
            ? templateGroup.oneTimeTaskTemplates
            : templateGroup.recurringTaskTemplates;

        return templates;
    }

    const getTemplatesForCardTask = () => {
        if (!state.card) return [];

        const templateGroup = props.templates[state.card.group];

        if (!templateGroup)
            return [];

        const templates = getTaskType() === TaskType.OneTimeTask
            ? templateGroup.oneTimeTaskTemplates
            : templateGroup.recurringTaskTemplates;

        const results = templates.filter(isApplicableTemplate);

        return results;
    }

    const isApplicableTemplate = (template: TaskTemplate) => {
        const applicableRules = template.rules.filter(x => x.operator != 'ignore');
        if (applicableRules.length == 0) return true

        return applicableRules.every(rule => {
            const card = state.card;
            const cardValue: string = Object.keys(card.tags).includes(rule.identifier)
                ? card.tags[rule.identifier]
                : ((card as any)[lowerFirst(rule.identifier)] || '').toString();

            return compliesToRule(cardValue, rule);
        });
    }

    const isCardTask = () => props.taskType === CardTaskType.OneTimeCardTask
        || props.taskType === CardTaskType.RecurringCardTask;

    const getTaskType = () => {
        switch (props.taskType) {
            case CardTaskType.OneTimeCardTask:
                return TaskType.OneTimeTask;
            case CardTaskType.RecurringCardTask:
                return TaskType.RecurringTask;
            case CardTaskType.OneTimeTaskWithoutCard:
                return TaskType.OneTimeTask;
            case CardTaskType.RecurringTaskWithoutCard:
                return TaskType.RecurringTask;
            default:
                throw new Error('invalid task type');
        }
    }

    const getPopupLabel = () => {
        switch (props.taskType) {
            case CardTaskType.OneTimeCardTask:
                return 'Opret engangsopgave'
            case CardTaskType.RecurringCardTask:
                return 'Opret løbende opgave'
            case CardTaskType.OneTimeTaskWithoutCard:
                return 'Opret engangsopgave'
            case CardTaskType.RecurringTaskWithoutCard:
                return 'Opret løbende opgave'
            default:
                throw new Error('invalid task type');
        }
    }

    return <div>
        <ToolbarButton
            visible={props.visible == null || props.visible}
            label={props.label}
            icon={props.icon}
            onClick={openPopup}
        />
        
        <CreateTaskPopup
            title={getPopupLabel()}
            open={state.createTaskPopup}
            onOk={createTask}
            onClose={() => setState({ ...state, createTaskPopup: false })}
            templates={getTemplatesForTask()} />

    </div>;
}