import React from 'react';

type Props = {
    height: number
};

export default class VerticalDivider extends React.Component<Props> {
    render() {
        return (
            <div style={{
                height: `${this.props.height}px`,
                borderLeft: '1px solid #B2B3AB',
                margin: '0 10px'
            }} />
        );
    }
}