import React from 'react'
import ReactPDF, { View } from '@react-pdf/renderer'
import { Row, RowProps, Table, TableProps } from 'pages/report/controls/table'
import { colors, Text } from './controls'

export const ObhTable = (props: TableProps) =>
    <Table {...props}
        style={{ marginBottom: 15, ...props.style }}
        rowStyle={{ minHeight: 18, ...props.rowStyle }}
    />

export const ObhHeaderRow = (props: RowProps) =>
    <Row {...props} minPresenceAhead={props.minPresenceAhead ?? 100} style={{ 
        backgroundColor: colors.darkBlue,
        ...props.style
    }} />

export const ObhDataRow = (props: RowProps) =>
    <Row {...props} style={{ 
        backgroundColor: colors.lightBlue,
        borderBottom: 1,
        borderBottomColor: colors.darkBlue,
            ...props.style
    }} />

export const ObhHeaderCell = (props: ReactPDF.TextProps & { left?: boolean }) => 
    <Text {...props} style={{
        color: colors.white,
        fontFamily: 'arial-bold',
        padding: '7,5,5,5',
        textAlign: props.left ? 'left' : 'right',
        ...props.style
    }} />

export const ObhDataCell = (props: (ReactPDF.TextProps | ReactPDF.ViewProps) & { left?: boolean }) => {   
    const styles: ReactPDF.Style = {
        padding: '5,5,2,5',
        textAlign: props.left ? 'left' : 'right',
        ...props.style
    }

    React.Children.map(props.children, (child) => {
        if (React.isValidElement<{ style?: ReactPDF.Style }>(child) && child.type !== 'TEXT') {
            return <View {...props} style={styles} />
        }
    })

    return <Text {...props} style={styles}/>
}