import React from 'react'
import { parse, Token, tokensToRegExp, tokensToFunction, Key } from 'path-to-regexp'
import { Location, History, parsePath, createPath } from 'history';
import { zipObject, Dictionary, isEqual, sortBy } from 'lodash'

export type Route = {
    name: string,
    args: Dictionary<string>,
    render: (props?: object) => React.ReactElement,
}

export type Navigation = {
    goto: (url: string) => void;
    setHash: (hash: number) => void;
    getHash: () => string;
}

export const createRouter = (routes: { [key: string]: Dictionary<(...args: any) => React.ReactElement> }) => {

    var compiledRoutes = Object.entries(routes).map(([key, value]) => {
        const tokens = parse(key);

        const [name, render] = Object.entries(value)[0];

        return {
            name: name,
            tokens: tokens,
            regex: tokensToRegExp(tokens),
            toUrl: tokensToFunction(tokens),
            render: render
        }
    });

    var hasMatchingArgs = (tokens: Token[], args: Dictionary<string>): boolean => {
        const tokenKeys = (tokens.filter(x => typeof x === 'object') as Key[]).map(x => x.name);
        const argsKeys = Object.keys(args);

        return isEqual(sortBy(tokenKeys), sortBy(argsKeys));
    };

    return {
        find: (location: Location<any>): Route => {
            for (const route of compiledRoutes) {
                const result = route.regex.exec(location.pathname);

                if (result) {
                    const argsArray = result.slice(1);
                    const argsObject = zipObject(route.tokens.slice(1).filter(x => typeof x === 'object').map(x => (x as Key).name), argsArray);
                    return { name: route.name, args: argsObject, render: (props?: any) => route.render.apply(this, [{...props, ...argsObject}]) };
                }
            }

            return { name: '404', args: {}, render: () => <div>Not found</div> }
        },
        toUrl: (name: string, args: Dictionary<string>): string => {
            const route = compiledRoutes
                .filter(x => x.name == name && hasMatchingArgs(x.tokens, args))
                .find(() => true);

            if (!route) throw new Error("Route cannot be found.");

            return route.toUrl(args, {
                encode: (value, token) => {
                    // don't encode the /'s in the id's 
                    return token.pattern.indexOf('\/') != -1 ? value : encodeURIComponent(value);
                }
            });
        }
    }
}

export function createNavigation(history: History<any>) : Navigation {
    return {
        goto: (url: string) => history.push(useImpersonation(url)),
        setHash: (hash: number) => 
            history.replace({
                ...history.location,
                hash: `${hash}`
            }),
        getHash: () => history.location.hash
    };
}

export function useImpersonation(url: string) {
    const newLocation = parsePath(url);

    const result = transferImpersonation(window.location.search, newLocation.search);

    if (result) {
        newLocation.search = result;
    }

    return createPath(newLocation);
}

const transferImpersonation = (oldSearch: string, newSearch: string) => {
    const oldParams = new URLSearchParams(oldSearch);
    const newParams = new URLSearchParams(newSearch);

    if (oldParams.has('impersonate') && !newParams.has('impersonate')) {
        const match = oldParams.get('impersonate');

        if (match) {
            newParams.set("impersonate", match)
            
            return newParams.toString();
        }
    }

    return false;
}
