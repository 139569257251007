import React from 'react';

import styles from '../styles'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);

export const LicenseContent = () => {
    const styles = useStyles();
    
    return <div className={styles.licenseContent}>
        <div className={styles.licenseContenOuterContainer}>
            <div className={styles.licenseContentInnerContainer}>
                <p style={{ marginTop: 0, marginBottom: 6 }}>Brugsbetingelser for D&V Plan anses som accepteret af den enkelte bruger ved login.</p>
                <a className={styles.link} style={{ marginTop: 8, fontSize: 10 }} href='https://nrgisystems.dk/hjaelp-til-energy10/brugsbetingelser-og-persondataforordning/brugsbetingelser-for-dv-plan/'>
                    Læs brugsbetingelserne hér.
                            </a>
            </div>

            <a href='http://www.nrgisystems.dk'><img src='/assets/nrgi-logo.svg' width={123} height={40} /></a>
        </div>
    </div>
}