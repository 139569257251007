import { Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

/* 
    This queue is used for concurrent queries. E.g. if a Query1 is sent and waits for response, and a Query2
    is then sent simultaneously, then Query1 will be cancelled and the eventual response will be ignored in favor
    of Query2 and its response.
*/

export class RequestQueue<T> {
    private currentSubscription = new Subscription();

    next = (input: Observable<T>): Promise<T> => new Observable<T>(output => {
        this.currentSubscription.unsubscribe(); // cancel a previous request
        const subscription = this.currentSubscription = input.subscribe(output); // begin this request
        return () => subscription.unsubscribe(); // if request succeeds, close the request
    }).pipe(first()).toPromise()
}
