import { v4 as uuidv4 } from 'uuid';
import Api from 'server/api';
import numeral from 'numeral'
import "numeral/locales/da-dk";

numeral.locale('da-dk');

export { numeral };

export const user = window.User as Api.Utils.LoginController.UserInfo;

export function sguid() {
    const buffer = Buffer.alloc(16);
    
    const sguid = uuidv4(null, buffer, 0).toString('base64')
        .replace(/\//g, '_')
        .replace(/\+/g, '-')
        .substring(0, 22);
    
    return sguid;
}

export function clearArray<T>(array: T[]) {
    array.splice(0, array.length);
}

export const sortArray = <T,>(array: readonly T[], ...selectors: ((element1: T) => number|string)[]): readonly T[] => {
    if (selectors.length === 0) throw Error("You need at least 1 selector for sorting");
    
    return [...array].sort((a, b) => {
        for (const selector of selectors) {
            const aSelected = selector(a);
            const bSelected = selector(b);

            if (typeof aSelected === 'number' && typeof bSelected === 'number') {
                const numberResult = aSelected - bSelected;
                if (numberResult !== 0) return numberResult;
            }
            else if (typeof aSelected === 'string' && typeof bSelected === 'string') {
                const stringResult =  aSelected.localeCompare(bSelected);
                if (stringResult !== 0) return stringResult;
            };
        }
        return 0;
    });
}

export function readSingleFile(file: File, onFileLoaded: (file: File, content: string) => void) {
    if (file === undefined) {
        // most likely the user has canceled the file upload
        return /* do nothing */;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
        const content = e.target.result.split(',')[1]; // first part is content type, second part is data in base64 encoding
        onFileLoaded(file, content);
    };

    reader.readAsDataURL(file);
}

export function hasValue(value: string, trim: boolean = true) {
    return trim ? value && value.trim() : value;
}

// https://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
export function isElementInViewport(el: HTMLElement) {
    var rect = el.getBoundingClientRect();

    return (rect.bottom >= 0 
        && rect.right >= 0 
        && rect.top <= (window.innerHeight || document.documentElement.clientHeight) 
        && rect.left <= (window.innerWidth || document.documentElement.clientWidth));
}

export function scrollIntoViewIfNeeded(el: HTMLElement, options?: boolean | ScrollIntoViewOptions) {
    if (!isElementInViewport(el)) {
        el.scrollIntoView(options || { behavior: 'smooth' } as ScrollIntoViewOptions);
    }
}

export function scrollIntoViewOffset(el: HTMLElement, parentEl: HTMLElement, offset: number, behavior?: ScrollBehavior) {
    const y = el.getBoundingClientRect().top + parentEl.scrollTop;
    parentEl.scrollTo({
        top: y + offset,
        behavior: behavior || 'smooth'
    });
}

export function scrollIntoViewOffsetIfNeeded(el: HTMLElement, parentEl: HTMLElement, offset: number, behavior?: ScrollBehavior) {
    if (!isElementInViewport(el)) {
        scrollIntoViewOffset(el, parentEl, offset, behavior);
    }
}

export const matched = <T extends any>(x: any) : matcher<T> => ({
    on: () => matched(x),
    else: () => x,
    orThrow: () => x
});

type predicate = (x: any) => boolean;
type result<TIn, T extends any> = (x: TIn) => T;

function isResult<TIn, TOut>(arg: any) : arg is result<TIn, TOut> {
    return typeof arg === 'function';
}

function isPredicateFunction(arg: any) : arg is predicate {
    return typeof arg === 'function';
}

type matcher<T extends any> = ({  
    on: <T2>(pred: predicate | T2, fn: result<T2, T> | T) => matcher<T>,
    else: (fn: result<unknown, T> | T) => T
    orThrow: () => T
});

export const match = <TOut extends any>(x: any) : matcher<TOut> => ({  
  on: <TIn extends any>(pred: predicate | TIn, fn: result<TIn, TOut> | TOut): matcher<TOut> => {
    var isMatch = isPredicateFunction(pred) 
        ? pred(x)
        : x === pred;

    return isResult<TIn, TOut>(fn)
        ? isMatch ? matched(fn(x)) : match(x)
        : isMatch ? matched(fn) : match(x);
  },
  else: (fn: result<unknown, TOut> | TOut): TOut => (isResult<unknown, TOut>(fn)) ? fn(x) : fn,
  orThrow: () => { throw new Error(); }
})

export const isNullOrWhitespace = (str : string) => str === null || str.match(/^\s*$/) !== null;

export const any = function <T>(predicate: ((val: T) => boolean), ...items : T[]) {
    for(let item of items)
        if(predicate(item))
            return true;
    return false;
}

export const all = function <T>(predicate: ((val: T) => boolean), ...items : T[]) {
    for(let item of items)
        if(!predicate(item))
            return false;
    return true;
}

export type ReplaceType<T, TKey extends keyof T, TReplacement> = Omit<T, TKey> & { [P in TKey]: TReplacement }
export type PropsWithChild<P> = P & { children?: React.ReactElement<any, any> };

export const doNothing = Object.freeze(() => {});
export const doNothingAsync = Object.freeze(() => Promise.resolve());

export const awaitableTimeout = async (delay: number) => {

    let timeout: number;

    try {
        return await new Promise<void>((resolve,) => timeout = self.setTimeout(() =>  resolve(), delay));
    } finally {
        self.clearTimeout(timeout);
    }
};

export const stringEllipsis = (maxChars: number) => (str: string) => str.length > maxChars ? str.substring(0, maxChars) + '...' : str;

export const move = <T,>(array: readonly T[], from: number, to: number): readonly T[] => {

    var copy = [...array];
    copy.splice(to, 0, copy.splice(from, 1)[0]);
    return copy;
};

export function partialCall<T extends readonly unknown[], U extends readonly unknown[], R>(
    f: (...args: [...T, ...U]) => R,
    ...headArgs: T
  ) {
    return (...tailArgs: U) => f(...headArgs, ...tailArgs);
  }

export const isParsableNumber = (test: unknown) => 
    typeof test === 'number'
        ? true
        : (typeof test === 'string')
            // parseFloat accepts numbers like "1sdfsdfsd" (Number-ctor does not)
            // Number-ctor parses null and '' as 0 (parseFloat does not)
            // Therefore these checks together covers cases for checking number-literals
            ? !Number.isNaN(Number.parseFloat(test)) && !Number.isNaN(Number(test)) 
            : false;


export type Selected<T, N> = T extends { tag: N } ? T : never;

export const ext = <T extends { tag: string }, U extends T['tag'], V>(extensions: readonly T[], tag: U, callback?: (e: Selected<T, U>) => V) : V => {
    let extension = get(extensions, tag);

    if (extension) return callback(extension);
}

export const get = <T extends { tag: string }, U extends T['tag']>(extensions: readonly T[], tag: U) : Selected<T, U> => {
    return extensions.find(x => x.tag == tag) as Selected<T, U>;
}
            