import React, { useState, useEffect } from 'react';
import { DialogTitle, DialogContent, DialogActions, Toolbar, FormGroup } from "@material-ui/core";
import {Popup} from 'controls/popup';
import { FormField } from "controls/field/form-field";
import { Button } from "controls/button";
import _ from 'lodash';
import { useLoadingState } from 'custom-hooks';
import { ErrorListDisplay } from "controls/error-list-display";
import { passwordError } from "../../validate-password";
import { hasValue } from "utils";

type Props = {
    onOk: (profileId: string, newPassword: string) => Promise<void>;
    onClose: () => void;
    open: boolean;
    profileId: string
} 

export const ChangePasswordPopup = (props: Props) =>  {
    const [password, setPassword] = useState('');
    const [passwordRepeated, setPasswordRepeated] = useState('');

    useEffect(() => {
        setPassword('');
        setPasswordRepeated('');
    }, [props.open]);

    const {
        load,
        loading,
        loaderVisible,
    } = useLoadingState(100);

    const canEdit = () => !loading;
    
    const changePassword = async () => {
        if (!canExecute) return;

        await load(props.onOk(props.profileId, password));
    }

    const passwordErrors = hasValue(passwordRepeated, false)
        ? passwordError(password, passwordRepeated) 
        : [];
    
    const canExecute = passwordErrors.length === 0 && hasValue(password) && hasValue(passwordRepeated);

    return (
        <Popup
            maxWidth='sm'
            fullWidth
            open={props.open}
            onClose={props.onClose}
            onOk={changePassword}
            onCancel={props.onClose}>

            <DialogTitle>Rediger adgangskode</DialogTitle>

            <DialogContent>
                <FormGroup>
                    <FormField
                        type='password'
                        label='Adgangskode'
                        disabled={!canEdit()}
                        value={password}
                        onValidChange={setPassword} />
                    <FormField
                        type='password'
                        disabled={!canEdit()}
                        label='Gentag adgangskode'
                        value={passwordRepeated}
                        onValidChange={setPasswordRepeated} />                    
                </FormGroup>

            <ErrorListDisplay errors={passwordErrors}/> 

            </DialogContent>
            <DialogActions>
                <Toolbar>
                    <Button label='Fortryd' disabled={loading} onClick={props.onClose} />
                    <Button
                        label='Gem'
                        loading={loading && loaderVisible}
                        disabled={!canExecute}
                        onClick={changePassword} />
                </Toolbar>
            </DialogActions>
        </Popup>);
};