import React from 'react'
import { View } from '@react-pdf/renderer';
import Api from 'server/api'
import { VerticalSpacer } from '../controls';
import { Header2, Link, Text } from '../text';
import { numeral } from 'utils'
import { BuildingInfo, CaseData, EstateDescription, Paragraph } from '../estate-page';

import Es = Api.Cases.Queries.Reports.Es;
import EstatePageDto = Es.EstatePageDto;

const EnergyStatus = (props: EstatePageDto) => {
    const building = props.buildings[0];

    return <View>
        <Header2>OVERORDNET ENERGI</Header2>
        {
            building &&
            building.energyLabelValidityStart &&
            building.extensions.ebas.energyLabel &&
            <View>
                <Text>
                    Den nuværende energimærkningsrapport er fra {building.energyLabelValidityStart} og giver ejendommen karakteren {building.extensions.ebas.energyLabel}.
                </Text>
                <VerticalSpacer height="small" />
            </View>
        }
        <Paragraph body={props.extensions.ebas.additionalEnergyComments}/>
    </View>
}

const IndoorEnvironmentStatus = (props: EstatePageDto) =>
    <View>
        <Header2>OVERORDNET INDEKLIMA</Header2>
        <Paragraph body={props.extensions.ebas.indoorEnvironmentComments} />
    </View>

const EconomyStatus = (props: EstatePageDto) => 
    <View>
        <Header2>ØKONOMI</Header2>
        <View>
            <Text>
                Budgettets beregninger viser omkostninger på omkring {numeral(props.extensions.ebas.budgetTotal).format('0,0')} kr.
                over {props.extensions.ebas.durationYears} år.
                Budgettet udregnes med en prisudvikling på {props.extensions.ebas.priceIndexInPercentage} pr. år.
            </Text>
            <VerticalSpacer height="small" />
            <Text>
                Økonomien på de enkelte drifts- og vedligeholdelsesopgaver er lavet ud fra Molios prisbøger,
                prissætninger fra energimærkningsrapporter, rådgivers erfaringstal eller fra bygherres egne
                historiske data på udgifter.
            </Text>
            <VerticalSpacer height="small" />
            <Text>
                Priser er derfor vejledende, og der kan forekomme ændringer alt efter udbud og efterspørgsel
                samt valg af løsninger. Det anbefales altid, at man indhenter mere end ét tilbud på løsninger.
            </Text>
            <VerticalSpacer height="small" />
            <Text>
                Som udgangspunkt er alle priser {props.extensions.ebas.includeVat ? "inklusiv " : "eksklusiv "}
                moms og {props.extensions.ebas.includeVat ? "" : "inklusiv "}eventuelt landsdelsfradrag/tillæg,
                med mindre andet er nævnt.
            </Text>
            <VerticalSpacer height="small" />
        </View>
    </View>

const AboutUs = (props: EstatePageDto) => 
    <View>
        <Header2>OM OS</Header2>
        <View>
            <Text>
                NRGi kan hjælpe med energi- og bygherrerådgivning i forhold til opgaver, der fremgår af vedligeholdelsesplanen.
                Hvis der er opgaver, vi ikke kan dække, har vi et bredt samarbejdsnetværk med udvalgte eksperter, der kan hjælpe dig.
            </Text>
            <VerticalSpacer height="small" />
            <Text>
                Vi rådgiver både den private boligejer og erhvervskunder i hele Danmark om projekter og tiltag, der understøtter
                den grønne omstilling.
                Vi er Danmarks største og mest digitale udbyder af tilstandsrapporter, energimærker og el-tjek til private.
                Til erhverv udbyder vi også energimærker samt energirådgivning, energisyn, energiledelse og energistyring.
                I industrien er vi blandt landets førende rådgivere indenfor optimering af indeklima, ventilation, miljø,
                procesudstyr- og systemer. Derudover har vi mange års erfaring med at hjælpe virksomheder og bygningsejere
                (boligforeninger) med at opnå tilskud til energieffektiviseringer.
            </Text>
            <VerticalSpacer height="small" />
            <Text>
                Eventuelle ændringer eller spørgsmål til den leverede rapport eller øvrige ydelser kan stilles via mail til info@nrgi.dk.
            </Text>
            <VerticalSpacer height="small" />
            <Text>
                Læs mere om drift og vedligehold her: <Link src='https://nrgi.dk/erhverv/vi-tilbyder/rapporter-og-data/drift-og-vedligeholdelsesplan/'>
                    https://nrgi.dk/erhverv/vi-tilbyder/rapporter-og-data/drift-og-vedligeholdelsesplan/
                </Link>
            </Text>
            <VerticalSpacer height="small" />
        </View>
    </View>

const EstateDescriptionEbas= (props: EstatePageDto) =>
    <EstateDescription imageUrl={props.imageUrl}>
        <Paragraph body={props.description}/>
        <VerticalSpacer height="medium" />
        <EnergyStatus {...props} />
        <VerticalSpacer height="medium" />
        <IndoorEnvironmentStatus {...props} />
        <VerticalSpacer height="medium" />
        <EconomyStatus {...props} />
        <VerticalSpacer height="medium" />
        <AboutUs {...props} />
    </EstateDescription >

export const EbasEstatePage = (props: EstatePageDto) =>{
    const colWidths = [90, 190, 90, 0];
    
    return <View>
        <CaseData {...props} header="Data" colWidths={colWidths} />
        <VerticalSpacer height="large" />
        <BuildingInfo {...props} colWidths={colWidths} />
        <VerticalSpacer height="large" />
        <EstateDescriptionEbas {...props} />
    </View>
}