import { useContext } from "react";
import { WriteLockContext } from "context";
import { Lockable } from "utils/lockable";

export function useWriteLock(props?: boolean|Lockable, ...otherLocks: boolean[]): boolean {
    const lockedContext = useContext(WriteLockContext);

    if (otherLocks.some(x => x)) return true;
    if (typeof props === 'boolean') return props;
    if (props === undefined) return lockedContext;

    return (props.locked === undefined ? lockedContext : props.locked) || !!props.disabled;
}