import React, { useContext } from 'react'
import ReactPdf, { View, Page as PdfPage } from '@react-pdf/renderer';
import { Text } from '../text';
import Api from 'server/api'
import _ from 'lodash';
import { NrgiLogo } from '../ebas/ebas-graphics'
import { DraftWatermark, Footer } from '../controls'
import { PageContext, ReportThemeContext } from '../context'

import MasterDataDto = Api.Cases.Queries.Reports.Es.MasterDataDto;

const Header = (props: {title: string, masterData: MasterDataDto}) => {
    const colorTheme = useContext(ReportThemeContext).color;

    return <View fixed style={{
        backgroundColor: colorTheme.secondaryColor,
        position: 'absolute',
        height: 56,
        left: 0,
        right: 0,
        paddingLeft: 5,
    }}>
        <View style={{ flexDirection: 'row', alignSelf: 'stretch', justifyContent: 'space-between', top: 18, marginLeft: 42, marginRight: 33 }}>
            <View style={{ maxWidth: 410, alignSelf: 'flex-end', justifyContent: 'flex-end', height: 25 }}>
                <Text style={{ fontSize: 8, marginBottom: 2, lineHeight: 1 }}>{props.title} - {props.masterData.case.name}</Text>
                <Text style={{ fontSize: 8, lineHeight: 1 }}>{_.compact([props.masterData.case.nickName, props.masterData.case.address]).join(' - ')}</Text>
            </View>
            <NrgiLogo height={25} />
        </View>
    </View>
}
export const Page = (props: React.PropsWithChildren<ReactPdf.PageProps> & { noHeader?: boolean, noFooter?: boolean }) => {
    const context = useContext(PageContext);

    return <PdfPage size="A4" {...props} style={{
        paddingTop: 69,
        paddingBottom: props.noFooter ? 35 : 69,
        paddingLeft: 48,
        paddingRight: 37,
        ...props.style
    }}>
        {props.noHeader || context.masterData && <Header title={context.reportTitle} masterData={context.masterData} />}
        {props.children}
        {props.noFooter || context.masterData && <Footer masterData={context.masterData} />}
        {!context.isCompleted && <DraftWatermark orientation={props.orientation} />}
    </PdfPage>;
}
