import React, { useState } from "react";
import { makeStyles} from "@material-ui/core";
import { Button } from "controls/button";
import { hasValue } from "utils";
import { passwordError } from "../validate-password";
import { AnonymousField } from 'controls/field/anonymous-field';
import { DvError } from "custom-hooks";
import { ErrorListDisplay } from "controls/error-list-display";

const useStyles = makeStyles({
    registerButton: {
        width: '100%', 
        height: 50, 
        fontSize: 14
    }
});

type Props = {
    errors: readonly DvError[],
    loading: boolean,
    changePassword: (password: string) => void,
}

export const NewPassword = (props: Props) =>  {
    const styles = useStyles();

    const [password, setPassword] = useState('');
    const [passwordRepeated, setPasswordRepeated] = useState('');

    const passwordErrors = hasValue(passwordRepeated, false) 
        ? passwordError(password, passwordRepeated) 
        : [];    

    const canExecute = hasValue(password) && hasValue(passwordRepeated) && passwordErrors.length === 0;
    const changePassword = () => props.changePassword(password)

    const onKeyDown = (event: React.KeyboardEvent<any>) => {
        if (event.key === 'Enter') {
            changePassword();
        }
    }

    return (
        <div style={{flex: 1, flexDirection:'column'}}>
            <div style={{display:'flex', flexDirection:'column', alignItems: 'center'}}>
                <div style={{fontSize: 30}}>Ny adgangskode i D&V Plan</div>
                <div style={{fontSize: 14, margin: 30}}>Indtast den ønskede adgangskode for din bruger.</div>
            </div>

            <div style={{display:'flex', flexDirection:'row', alignItems: 'stretch', fontSize: 14, marginTop:30}}>
                <div style={{flex: 1, paddingTop: 3}}><hr/></div>
                <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>Ny adgangskode</div>
                <div style={{flex: 1, paddingTop: 3}}><hr/></div>
            </div>

            <div style={{display:'flex', flexDirection:'column'}}>                       
                <AnonymousField     
                    type='password'
                    label='Adgangskode'
                    value={password}
                    showError={passwordErrors.length > 0}
                    onValidChange={setPassword}
                />

                <AnonymousField     
                    type='password'
                    label='Gentag Adgangskode'
                    value={passwordRepeated}
                    errors={passwordErrors}
                    onValidChange={setPasswordRepeated}
                    onKeyDown={onKeyDown}
                />
            </div>

            <div style={{marginTop:20}}>
                <Button loading={props.loading} disabled={!canExecute } className={styles.registerButton} onClick={changePassword} label='Gem' />
            </div>

            <ErrorListDisplay errors={ props.errors ? props.errors :[]}/> 

            {props.errors?.length > 0 && <p style={{fontSize: 14}}>Du kan komme til login-siden <a style={{color: '#56DFA6', textDecoration: 'None'}} href='/'>her</a>.</p>}
        </div>
    )
}