import React from "react";
import { withStyles, WithStyles, Toolbar, Grid, TableHead, TableRow, TableCell, Table, TableBody } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import Component from "component";
import styles from './styles'
import { Button } from "controls/button";
import { NewCompanyResult, NewCompanyPopup } from "./new-company-popup";
import { sguid } from "utils";
import { SearchableTextField } from "controls/field/searchable-text-field";
import { Subscription } from "rxjs";
import { Tooltip } from "controls/tooltip";
import { IconButton } from "controls/button/icon-button";

import Api from "server/api";
import CompanyDto = Api.Users.Queries.GetCompanies.CompanyDto;
import ProfileDto = Api.Users.Queries.GetProfiles.ProfileDto;

type Props = {
    isImpersonating: boolean;
} & WithStyles<typeof styles>

type State = {
    showNewCompanyPopup: boolean;
    companies: CompanyDto[];
    profiles: ProfileDto[];
    selectedProfile: SelectedProfile;
}

type SelectedProfile = { label: string, value: string } | null

export default withStyles(styles)(class AdminSettings extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        
        this.state = {
            showNewCompanyPopup: false,
            companies: [] as CompanyDto[],
            profiles: [] as ProfileDto[],
            selectedProfile: null as SelectedProfile
        };
    }

    commandsSubscription: Subscription;

    componentDidMount = () => {
        this.commandsSubscription = this.server.Commands.subscribe(this.getCompanies);
        this.getCompanies();
    };

    componentWillUnmount = () =>
        this.commandsSubscription.unsubscribe();
    
    render() {   

        const profiles = this.state.profiles.map(x => ({label: `${x.username} - ${x.fullName} ${(x.initials != null ? `(${x.initials})` : "")} - ${x.company}`, value: x.id}));

        return (
            <div style={{ width: '100%' }}>
                <Grid container spacing={3}>                        
                    <Grid item xs={10}>
                        <SearchableTextField
                            label="Brugere"
                            value={this.state.selectedProfile}
                            placeholder="Vælg bruger..."
                            options={profiles}
                            onChange={(value: SelectedProfile) => this.setState({ selectedProfile: value})}
                            disabled={this.props.isImpersonating}
                            />
                    </Grid>
                    <Grid item className={this.props.classes.impersonationButton} xs={2}>
                        {
                            this.props.isImpersonating 
                                ? <Button label='Unimpersonate' onClick={this.unImpersonate}/>
                                : <Button label='Impersonate' disabled={!this.state.selectedProfile} onClick={this.impersonate}/>
                        }                   
                    </Grid>
                </Grid>
                <Toolbar>
                    <Grid container>
                        <Grid item xs={11} />
                        <Grid item xs={1}>
                            <Grid container justify='flex-end'>
                                <Button label='Opret firma' onClick={() => this.setState({ showNewCompanyPopup: true })}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell variant='head'>Navn</TableCell>
                            <TableCell variant='head'>Aktivt?</TableCell>
                            <TableCell variant='head'></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{this.state.companies.map(row => 
                        (<TableRow key={row.id} hover>
                            <TableCell component="th" scope="row">{row.name}</TableCell>
                            <TableCell>{row.isEnabled ? 'Ja' : 'Nej'}</TableCell>
                            <TableCell>
                                <Toolbar variant='dense' disableGutters className={this.props.classes.toolbar}>
                                    <Tooltip title='Deaktivér firma'>
                                        <IconButton onClick={() =>{} } disabled>
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Toolbar>
                            </TableCell>
                        </TableRow>)
                    )}
                    </TableBody>
                </Table>
                <NewCompanyPopup
                    open={this.state.showNewCompanyPopup}
                    onClose={() => this.setState({ showNewCompanyPopup: false })}                    
                    onOk={this.newCompany}                              
                />
            </div>
        )
    }

    impersonate = () => {
        const search = window.location.search;

        window.location.search += search.includes('?')
            ? `&impersonate=${this.state.selectedProfile.value}`
            : `impersonate=${this.state.selectedProfile.value}`;
    }

    unImpersonate = () => {
        const search = window.location.search;

        window.location.search = search.includes('&')
            ? search.replace(/impersonate=[^&]+&/, '').replace(/&impersonate=[^&]+/, '')
            : '';
    }

    newCompany = async (result: NewCompanyResult) => {
        this.setState({showNewCompanyPopup: false});    
        
        await this.server.command(new Api.Users.Commands.NewCompany({ 
            newCompanyId: `company/${sguid()}`, 
            newProfileId: `profile/${sguid()}`, 
            ...result
        })); 
    }

    getCompanies = () => {
        this.server.query<CompanyDto[]>(
            new Api.Users.Queries.GetCompanies({})
        ).subscribe(companies => { this.setState({ companies }); })

        this.server.query<ProfileDto[]>(
            new Api.Users.Queries.GetProfiles({})
        ).subscribe(profiles => { this.setState({ profiles }); })
    };
})