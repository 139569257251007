import React, { useState, useEffect, useMemo } from "react";
import { Table, TableHead, TableBody, Typography, makeStyles } from "@material-ui/core";
import Api from "server/api";
import { replaceByRef, replace } from "utils/immutable-array";
import { HeaderRow } from "./header-row";
import _ from 'lodash';
import Page from "controls/page";
import styles from './styles';
import VerticalDivider from 'controls/vertical-divider';
import { CreateCard } from 'pages/toolbar/create-card';
import { CreateTask, CardTaskType } from 'pages/toolbar/create-task';
import Toolbar from 'pages/toolbar/toolbar';
import { ToolbarHeader } from 'pages/toolbar/toolbar-header';
import EditTaskPopup from "./edit-task-popup";
import { Templates } from "Templates";
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import TableChart from '@material-ui/icons/TableChart';
import { AddOnetimeIcon, AddRecurringIcon } from "controls/images/custom-icons";

import { useServer, useCommandSubscription, useOpenIds, useCaseLockState } from "custom-hooks";
import { WriteLockProvider } from "context";
import { Group } from "./group";
import { SumRow } from "./sum-row";
import { usePrevious } from "custom-hooks/use-previous";

import PriorityDto = Api.Modules.Priority.Queries.GetPriorities.PriorityDto
import GetBudget = Api.Cases.Queries.GetBudget;
import Budget = Api.Cases.Queries.GetBudget.Budget
import GroupLine = Api.Cases.Queries.GetBudget.GroupLine
import TaskLine = Api.Cases.Queries.GetBudget.TaskLine
import { ToolbarButton } from "../toolbar/toolbar-button";

type Props = {
    priorities: PriorityDto[];
    templates: Templates;
}

const useStyles = makeStyles(styles);

export const Budget = (props: Props) => {

    const [budget, setBudget] = useState(null as Budget | null);
    const prevBudget = usePrevious(budget);
    const [editTaskPopup, setEditTaskPopup] = useState<string | false>(false);

    const [collapsed, setCollapsed] = useState({} as { [id: string]: boolean });
    const [addedBudgetIds, setAddedBudgetIds] = useState({} as { [taskId: string]: string });

    const styles = useStyles();
    const server = useServer();
    const { caseId } = useOpenIds();
    const { locked } = useCaseLockState(caseId);

    const areAllCollapsed = useMemo(() =>
        budget?.groups
            .every(x => collapsed[x.classificationDescription]) ?? false,
        [collapsed, budget]);

    useCommandSubscription(() =>
        server.query<Budget>(
            new GetBudget({ caseId: caseId })
        ).subscribe(budget => { setBudget(budget); }), [server, caseId])

    useEffect(() => {
        if (!budget) return;

        const getTaskLineIds = (budget: Budget) =>
            _(budget.groups)
                .flatMap(line => line.taskLines)
                .filter(x => x.tag == 'TaskLine')
                .map(x => x as GetBudget.TaskLine)

        const diff = !!prevBudget
            ? getTaskLineIds(budget).differenceBy(getTaskLineIds(prevBudget).value(), 'id')
                .map(x => x.id)
                .keyBy()
                .value()
            : {};

        setAddedBudgetIds(diff);
    }, [budget]);

    const renderToggleVisibilityControl = () => {
        return areAllCollapsed
            ? <div className={styles.hideControl} onClick={expandAllGroups}> <ExpandLess /><Typography>VIS ALLE</Typography></div>
            : <div className={styles.hideControl} onClick={collapseAllGroups}> <ExpandMore /><Typography>SKJUL ALLE</Typography></div>;
    }

    const updateBudget = (budget: Partial<Budget>) => setBudget(s => ({ ...s, ...budget }));

    const updateGroup = (oldGroup: GroupLine, group: Partial<GroupLine>) => {
        updateBudget({ groups: replaceByRef<GroupLine>(oldGroup, { ...oldGroup, ...group })(budget.groups) });
    }

    const updateLine = (group: GroupLine) => async (line: TaskLine) =>
        updateGroup(group, { taskLines: replace<GetBudget.Line>(x => x.tag == 'TaskLine' && x.id === line.id, line)(group.taskLines) });

    const openEditTaskPopup = (taskId: string) => setEditTaskPopup(taskId)
    const closeEditTaskPopup = () => setEditTaskPopup(false);

    const onTaskCreated = (taskId: string) => openEditTaskPopup(taskId);

    const collapseAllGroups = () => {
        const groupTitles =
            budget.groups
                .reduce((prev, curr) => ({ ...prev, [curr.classificationDescription]: true }), {});

        setCollapsed(groupTitles);
    }

    const expandAllGroups = () => { setCollapsed({}); };

    const toggleGroupVisibility = (classificationDescription: string) => {

        setCollapsed(s => ({ ...s, [classificationDescription]: !s[classificationDescription] }));
    }

    const budgetExcelUrl = 
        server.queryUrl(new Api.Cases.Queries.ExportExcelBudget({
            caseId: caseId
        }));

    if (!budget) return null;

    const { tableContainer, table } = styles;

    return (
        <WriteLockProvider locked={locked}>
            <Page style={{ userSelect: 'none' }}>
                <div>
                    {budget.groups.some(x => x)
                        ? renderToggleVisibilityControl()
                        : null
                    }
                    <div className={tableContainer}>
                        <Table className={table}>
                            <TableHead>
                                <HeaderRow budget={budget} />
                            </TableHead>
                            <TableBody>
                                {budget.groups.map(line =>
                                    <Group
                                        budget={budget}
                                        key={line.classificationDescription}
                                        group={line}
                                        addedBudgetIds={addedBudgetIds}
                                        onOpenEditTaskPopup={taskId => openEditTaskPopup(taskId)}
                                        onUpdateLine={updateLine(line)}
                                        collapsed={collapsed[line.classificationDescription]}
                                        onClickRow={group => toggleGroupVisibility(group.classificationDescription)}
                                    />
                                )}
                                {budget.totals.map((line, index) =>
                                    <SumRow key={index} className={styles.sumRow} budget={budget} sum={line} />
                                )}
                            </TableBody>
                        </Table>
                        <Typography style={{ 
                            fontSize: "12px", 
                            fontStyle: "italic", 
                            marginLeft: 10, 
                            marginTop: 10 }}
                        >{budget.description}</Typography>
                    </div>
                </div>
                <div className={styles.spacer}></div>
            </Page>

            <EditTaskPopup
                open={!!editTaskPopup}
                onClose={closeEditTaskPopup}
                caseId={caseId}
                taskId={editTaskPopup && editTaskPopup}
                priorities={props.priorities} />

            <Toolbar>
                <ToolbarHeader header="ØVRIGE OPGAVER" />

                <CreateTask
                    taskType={CardTaskType.OneTimeTaskWithoutCard}
                    templates={props.templates}
                    label='ENGANGS OPGAVE'
                    icon={<AddOnetimeIcon />}
                    onTaskCreated={onTaskCreated}
                />
                <CreateTask
                    taskType={CardTaskType.RecurringTaskWithoutCard}
                    templates={props.templates}
                    label='LØBENDE OPGAVE'
                    icon={<AddRecurringIcon />}
                    onTaskCreated={onTaskCreated}
                />
                
                <VerticalDivider height={107} />

                <CreateCard caseId={caseId} templates={props.templates} />

                <ToolbarButton 
                    label='Hent som Excel' 
                    locked={!locked}
                    tooltip={!locked ? "Budgettet i Excel-format kan først hentes, når sagen er afsluttet." : null}
                    icon={<TableChart />}
                    href={budgetExcelUrl.url} />
            </Toolbar>
        </WriteLockProvider>
    );
}