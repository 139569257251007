import React, { PropsWithChildren, createContext } from "react";

export const WriteLockContext = createContext(false);

WriteLockContext.displayName = 'WriteLockContext';

export const WriteLockProvider = ({ locked, children }: PropsWithChildren<{locked: boolean}>) => {

    return (
        <WriteLockContext.Provider value={locked}>
            {children}
        </WriteLockContext.Provider>
    )
}