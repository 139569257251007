import React, { forwardRef } from "react";
import { Zoom, Fab, Typography, makeStyles, CircularProgress, createStyles } from "@material-ui/core";
import styles from './styles';
import cx from 'classnames';
import { buttonColors } from "theme";
import { useWriteLock } from "custom-hooks";
import { Lockable } from "utils/lockable";
import { Tooltip } from "controls/tooltip";

type Props = {
    onClick?: (() => Promise<void>) | (() => void);
    icon?: React.ReactElement;
    label?: string;
    className?: string;
    visible?: boolean;
    loading?: boolean;
    tooltip?: string;
    href?: string;
} & Lockable;

const useStyle = makeStyles(styles);
const useLoaderStyles = makeStyles(
    createStyles({
    loadingSpinner: {
        color: buttonColors.default.background1,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -18,
        marginLeft: -18,
    }
}))

export const ToolbarButton = (props: Props) => {
    const styles = useStyle();
    const loaderStyles = useLoaderStyles();
    const writeLock = useWriteLock(props, props.loading);

    const button = <Fab 
            className={cx(styles.fab, props.className)} 
            disabled={writeLock} 
            href={props.href}
            onClick={props.onClick}>
            {props.icon && props.icon}
            {props.loading && <CircularProgress size={36} thickness={6} className={loaderStyles.loadingSpinner} />}
            {props.label && <Typography color='textSecondary' variant='button'>{props.label}</Typography>}
        </Fab>;

    return <Zoom in={props.visible === undefined || props.visible}>
        {
            props.tooltip 
                ? <Tooltip title={props.tooltip}>
                    <span>{button}</span>
                  </Tooltip>
                : button
        }
    </Zoom>
};