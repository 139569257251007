import React, { useState, useCallback } from 'react';
import Api from "server/api";
import { OneTimeTask } from "./one-time-task";
import { RecurringTask } from "./recurring-task";
import { upperFirst } from "lodash";
import TaskType = Api.Cases.TaskType
import PriorityDto = Api.Modules.Priority.Queries.GetPriorities.PriorityDto
import TaskDto = Api.Cases.Queries.GetTask.TaskDto;
import CardDto = Api.Cases.Queries.GetTask.CardDto;
import { useServer, useCommandSubscription } from "custom-hooks";

import OneTimeTaskDto = Api.Cases.Queries.GetTask.OneTimeTaskDto;
import RecurringTaskDto = Api.Cases.Queries.GetTask.RecurringTaskDto;

type Props = {
    caseId: string
    cardId: string
    taskId: string
    priorities: PriorityDto[]
}

function isOneTimeTask(taskType: TaskType, task : TaskDto)
    : task is OneTimeTaskDto {
    return taskType === TaskType.OneTimeTask
        && (task as OneTimeTaskDto).problemDescription !== undefined;
}

function isRecurringTask(taskType: TaskType, task : TaskDto) 
    : task is RecurringTaskDto {
    return taskType === TaskType.RecurringTask
        && (task as RecurringTaskDto).interval !== undefined;
}

export const Task = (props : Props) => {
    
    const server = useServer();

    const [task, setTask] = useState(null as TaskDto | null);
    const [taskType, setTaskType] = useState(null as TaskType | null);
    const [card, setCard] = useState(null as CardDto | null);

    useCommandSubscription(() => {
        server.query<Api.Cases.Queries.GetTask.Result>(
            new Api.Cases.Queries.GetTask({ cardId: props.cardId, taskId: props.taskId })
        ).subscribe(taskResult => { 
            setTask(taskResult.task);
            setTaskType(taskResult.taskType);
            setCard(taskResult.card);
        })
    }, [server, props.cardId, props.taskId]);

    if (task === null || taskType === null || card === null) return null;

    if (isOneTimeTask(taskType, task)) {
        return <OneTimeTask 
                    taskId={props.taskId} 
                    priorities={props.priorities}
                    task={task} 
                    card={card} 
                    updateTask={setTask}                     
                />
    } else if (isRecurringTask(taskType, task)) {
        return <RecurringTask 
                    taskId={props.taskId} 
                    priorities={props.priorities}
                    task={task} 
                    card={card} 
                    updateTask={setTask}                     
                />
    } else return null;
}