import React from 'react';
import Field, { Props } from '.';
import { TypeSelector, FieldType } from '.';
import { useWriteLock } from 'custom-hooks';

export function TableField<T extends FieldType>(props: Props<T, TypeSelector<T>>) {
    const writeLock = useWriteLock(props);
    
    return (
        <Field
            {...props}
            variant='standard'
            margin='none'
            disabled={writeLock}
        />
    );
}
