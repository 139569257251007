import { useState, useEffect } from "react";

import { useServer } from "custom-hooks/use-server";
import { useLoadingState } from "custom-hooks/use-loading-state";

import Api from "server/api";
import GetCaseLockedStatus = Api.Cases.Queries.GetCaseLockedStatus;

export type CaseLockLoader = () => Promise<GetCaseLockedStatus>;

export type CaseLockStatus = {
    locked: boolean;
    loading: boolean;
    loaderVisible: boolean;
    loadCaseLockedStatus: () => Promise<unknown>;
}

export const useCaseLockState = (caseId: string): CaseLockStatus => {
 
    const server = useServer();
    const loadingState = useLoadingState();
    const [locked, setLocked] = useState(true);

    // This hook assumes 1 (or a very low amount of) uses of this hook per page.
    // Otherwise we will get multiple request loading the same value.
    // If ever we need to use this multiple times per page, we probably should switch implemntation to a context
    const loadCaseLockedStatus = async () => {
        const lockedStatus = await loadingState.load(
                server.query<boolean>(new GetCaseLockedStatus({caseId: caseId}))
            .toPromise());

        setLocked(lockedStatus);
    }

    useEffect(() => {
        loadCaseLockedStatus();

        return () => { setLocked(true); };
    }, [caseId]);

    return {
        locked: locked,
        loading: loadingState.loading,
        loaderVisible: loadingState.loaderVisible,
        loadCaseLockedStatus
    };
}