import React from 'react'
import { View } from '@react-pdf/renderer';
import { BulletPoint, Text as Text, Header1, Header3 } from '../text'
import { VerticalSpacer } from '../controls';
import _ from 'lodash';

export const ConstructionProjectPage = () =>
    <View>
        <View>
            <Header1>Byggeprojekt og udgifter</Header1>
            <Text>
                Såfremt der skal gennemføres et ombygnings- eller renoveringsprojekt, er der en række 
                omkostninger ud over selve udgiften til håndværkere.
            </Text>
            <VerticalSpacer height="small" />
            <Text>
                Aktiviteterne i vedligeholdelsesplanen er angivet som håndværksudgifter ekskl. moms.
            </Text>
            <VerticalSpacer height="small" />
            <Text>
                Disse omkostninger tillægges i planen 5% til byggepladsomkostninger, 10% til uforudseelige 
                udgifter, 15% til byggeteknisk rådgivning samt 25% moms.
            </Text>
            <VerticalSpacer height="small" />
            <Text>
                Ovenstående er i vedligeholdelsesplanen faste omkostninger, men det er vigtigt, at man 
                udarbejder et budget for det specifikke projekt, idet omkostninger til byggeplads og 
                byggeteknisk rådgivning vil variere afhængigt af arbejdets omfang og kompleksitet. 
                Når der er udarbejdet et budget og foretaget beregninger for finansiering, kan budgettet 
                benyttes som grundlag for vedtagelse og igangsætning af projektet.
            </Text>
            <VerticalSpacer height="small" />
            <Text>
                Ved en byggesag skal man desuden påregne en række administrative omkostninger til f.eks. forsikring, 
                finansiering og juridisk rådgivning. Disse udgifter prissættes og tilføjes budgettet af 
                advokat/administrator.
            </Text>
        </View>
        <VerticalSpacer height="medium"/>
        <View>
            <Header3>Byggeteknisk rådgivning</Header3>
            <VerticalSpacer height="small" />
            <Text>
                Byggeteknisk rådgivning ifm. et projekt vil typisk omfatte:
            </Text>
            <VerticalSpacer height="small" />
            <BulletPoint>Afgrænsning af projektet ud fra økonomi og ønsker</BulletPoint>
            <BulletPoint>Udarbejdelse af nødvendige skitser, overslag, forundersøgelser (forprojekt) og/eller overslagsbudget</BulletPoint>
            <BulletPoint>Løbende inddragelse af og orientering til ejendommens beboere om projektet</BulletPoint>
            <BulletPoint>Udarbejdelse af ansøgning om byggetilladelse</BulletPoint>
            <BulletPoint>Udarbejdelse af tegningsmateriale, detaljeret projektbeskrivelse, tilbudslister m.v. (hovedprojekt)</BulletPoint>
            <BulletPoint>Sikring af at bygherres forpligtelser ifm. arbejdsmiljø overholdes</BulletPoint>
            <BulletPoint>Miljøundersøgelser</BulletPoint>
            <BulletPoint>Indhentning af tilbud</BulletPoint>
            <BulletPoint>Vurdering af tilbud og bistand ifm. indgåelse af entreprisekontrakt</BulletPoint>
            <BulletPoint>Afholdelse af opstartsmøde og byggemøder under udførelsesfasen</BulletPoint>
            <BulletPoint>Tilsyn og kvalitetssikring af arbejdets udførelse</BulletPoint>
            <BulletPoint>Økonomistyring: Godkendelse af fakturaer og ajourføring af entrepriseregnskab</BulletPoint>
            <BulletPoint>Mangelgennemgang(e) og afleveringsforretning</BulletPoint>
            <VerticalSpacer height="small" />
            <Text>
                I vedligeholdelsesplanen afsætter vi 15% til byggeteknisk rådgivning. Dette er et overslag, og 
                honoraret vil variere afhængigt af den konkrete opgave. Når der udarbejdes et endeligt budget, 
                kan vi oftest give en fast pris på byggerådgivning.
            </Text>
        </View>
    </View>