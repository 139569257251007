import React = require("react");
import { TableRow, TableCell, makeStyles } from "@material-ui/core";

import Api from "server/api";
import styles from './styles';
import { get } from "utils";
import { TaskYearCell } from "./task-year-cell";

const useStyles = makeStyles(styles);

export const PercentageTaskRow = (props: { budget: Api.Cases.Queries.GetBudget.Budget, line: Api.Cases.Queries.GetBudget.PercentageTaskLine }) => {
    const styles = useStyles(props);
    
    return (
        <TableRow className={styles.taskRow}>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell
                colSpan={get(props.budget.extensions, 'PriorityHeader') ? 2 : 1}
            ><div className={styles.taskTextCell}>{props.line.title}</div></TableCell>
            {props.line.years.map((value, index) => <TaskYearCell key={index} price={value} />)}
        </TableRow>
    );
}