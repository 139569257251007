import ReactPdf from '@react-pdf/renderer';

export type TextTheme = {
    Text: ReactPdf.TextProps,
    BoldText: ReactPdf.TextProps,
    ItalicText: ReactPdf.TextProps,
    LightText: ReactPdf.TextProps,
    SmallText: ReactPdf.TextProps,
    SmallBoldText: ReactPdf.TextProps,
    SmallItalicText: ReactPdf.TextProps,
    SmallBoldItalicText: ReactPdf.TextProps,
    SmallLightText: ReactPdf.TextProps,
    Header1: ReactPdf.TextProps,
    Header2: ReactPdf.TextProps,
    Header3: ReactPdf.TextProps,
    TOCText: ReactPdf.TextProps
    TOCHeader: ReactPdf.TextProps,
}

const DefaultTextTheme: TextTheme = {
    BoldText: {},
    ItalicText: {},
    LightText: {},
    SmallText: {},
    SmallBoldText: {},
    SmallItalicText: {},
    SmallBoldItalicText: {},
    SmallLightText: {},
    Header1: {},
    Header2: {},
    Header3: {},
    Text: {},
    TOCHeader: {},
    TOCText: {}
}

export type ColorTheme = {
    primaryColor: string,
    secondaryColor: string,
    tertiaryColor: string,
    quaternaryColor: string
}

const DefaultColorTheme: ColorTheme = {
    primaryColor: '#999A94',
    secondaryColor: '#D6D7D0',
    tertiaryColor: '#EDEDEA',
    quaternaryColor: '#F4F5F2'
}

export type ReportTheme = {
    color: ColorTheme,
    text: TextTheme
}

export const DefaultReportTheme: ReportTheme = {
    color: DefaultColorTheme,
    text: DefaultTextTheme
}