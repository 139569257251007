
import React from 'react';

import styles from '../styles'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);

export const ContactInformation = () => {
    const styles = useStyles();
    
    return (
        <div>
            <table className={styles.supportTable}>
                <tbody>
                    <tr>
                        <td className={styles.labelTd}>Telefon:</td>
                        <td>70 22 93 10</td>
                    </tr>
                    <tr>
                        <td className={styles.labelTd}>E-mail:</td>
                        <td>                                    
                            <a className={styles.link} href='mailto: support@nrgisystems.dk'>support@nrgisystems.dk</a>
                        </td>
                    </tr>
                    <tr>
                        <td className={styles.labelTd}>Website:</td>
                        <td>
                            <a className={styles.link} href='http://www.nrgisystems.dk'>www.nrgisystems.dk</a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p>Åbningstider for supporten:</p>
            <table className={styles.supportTable}>
                <tbody>
                    <tr>
                        <td className={styles.labelTd}>Man-torsdag:</td>
                        <td className={styles.valueTd}>08:00 - 15:30 (pause 12:00 - 12:30)</td>
                    </tr>
                    <tr>
                        <td className={styles.labelTd}>Fredag:</td>
                        <td className={styles.valueTd}>08:00 - 14:30 (pause 12:00 - 12:30)</td>
                    </tr>
                </tbody>
            </table>
        </div>)
}