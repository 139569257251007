import { createStyles, Theme } from "@material-ui/core";

export default (theme: Theme) => createStyles({
    cell: {
        width: theme.spacing(8),
        padding: theme.spacing(1)
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2),
        width: theme.spacing(100)
    },
    descriptionCell: {
        width: theme.spacing(38),
        padding: theme.spacing(1)
    },
    footer: {
        '& td': {
            borderBottom: 'none'
        }
    },
    iconColumn: {
        display: 'flex',
        flexDirection: 'row'
    },
    italic: {
        fontStyle: "italic"
    },
    rowSpacer: {
        height: "0.5rem"
    },
    sumCell: {
        padding: theme.spacing(1),
        textAlign: "right",
        width: theme.spacing(8)
    },
    sumRow: {
        height: "1rem",
        '& td': {
            lineHeight: "0.8rem"
        }
    },
    underline: {
        borderBottom: "1px solid!important" as any
    },
    underlineDouble: {
        borderBottom: "3px double!important" as any
    }
});