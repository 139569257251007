import React, { useState, useCallback } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Api from "server/api";
import { FormField } from "controls/field/form-field";
import { Images } from "controls/images/image-tiles";
import { ActionButton } from "controls/button/action-button";
import { Paper } from "controls/paper";
import { upperFirst } from 'lodash'
import { SelectField } from "controls/field/select-field";
import _ from 'lodash';
import styles from './styles'
import ChangeCardTypePopup from "./change-card-type-popup";
import { RequestQueue } from "server/request-queue";
import { Templates } from "Templates";
import Breadcrumbs from "controls/breadcrumbs";
import { EditIcon } from "controls/images/custom-icons";
import { useServer,  useCommandSubscription, useCommand } from "custom-hooks";
import { Tooltip } from "controls/tooltip";

import CardDto = Api.Cases.Queries.GetCard.CardDto
import ConditionRatingDto = Api.Cases.Queries.GetConditionRatings.ConditionRatingDto

type Props = {
    caseId: string,
    cardId: string,
    templates: Templates,
    availableConditionRatings: ConditionRatingDto[],
    onFileSelected: (files: File[]) => Promise<void>,
}

const requestQueue = new RequestQueue<CardDto>();

const useStyles = makeStyles(styles);

export const Card = (props: Props) =>  {

    const server = useServer();
    const styles = useStyles();
    
    const [card, setCard] = useState<CardDto>(null);
    const [changeTypePopup, setChangeTypePopup] = useState(false);

    useCommandSubscription(() => {
        requestQueue.next(server.query<CardDto>(
            new Api.Cases.Queries.GetCard({ cardId: props.cardId })
        )).then(card => setCard(card))
    }, [server, props.cardId]);

    const orderImages = useCommand((imageIds: string[]) => 
        new Api.Cases.Commands.OrderCardImages({
            cardId: props.cardId,
            imageIds: imageIds
        }));

    const openChangeTypePopup = () => setChangeTypePopup(true)
    const closeChangeTypePopup = () => setChangeTypePopup(false)

    const changeCardType = useCommand((group: string, type: string) => new Api.Cases.Commands.ChangeCardType({
        caseId: props.caseId,
        cardId: card.id,
        group: group,
        type: type
    }));

    const changeType = useCallback(async (group: string, type: string) => {
        await changeCardType(group, type);

        closeChangeTypePopup();
    }, [server, changeCardType, closeChangeTypePopup]);

    const updateImage = useCommand((imageId: string, field: string, value: any) => new Api.Cases.Commands.WriteCardImageField({
        cardId: card.id, 
        imageId: imageId,
        fieldName: upperFirst(field), 
        value: value
    }));

    const deleteImage = useCommand((imageId: string) => new Api.Cases.Commands.DeleteCardImage({cardId: card.id, imageId: imageId}));
    const markImage = useCommand((imageId: string) => new Api.Cases.Commands.MarkCardImage({cardId: card.id, imageId: imageId}));
    const unmarkImage = useCommand((imageId: string) => new Api.Cases.Commands.UnmarkCardImage({cardId: card.id, imageId: imageId}));

    const update = <TKey extends keyof CardDto>(name: TKey) => async (value: CardDto[TKey]) => {
        setCard(s => ({ ...s, [name]: value }))

        await server.command(new Api.Cases.Commands.WriteCardField({
            cardId: props.cardId,
            fieldName: upperFirst(name),
            value: value
        }));
    };

    if (!card) return null;

    const availableRatings = props.availableConditionRatings.map(x => ({ label: x.translation, value: x.rating }));
    
    return (
        <>
            <div className={styles.typeAndGroupContainer}>
                <Tooltip title="Skift kort-type">
                    <ActionButton onClick={openChangeTypePopup} className={styles.editButton}>
                        <EditIcon className={styles.editButtonIcon} size={20} />
                    </ActionButton>
                </Tooltip>
                <Breadcrumbs crumbs={[card.group, card.type]} className={styles.breadcrumb} />
            </div>

            <Paper className={styles.container} header='Kort'>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <FormField
                            type='text'
                            label='Placering'
                            value={card.location}
                            onValidBlur={update("location")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectField 
                            optional
                            label='Tilstand' 
                            items={availableRatings} 
                            value={card.conditionRating} 
                            onChange={update("conditionRating")}
                        />
                    </Grid> 
                    <Grid item xs={12}>
                        <FormField
                            type='text'
                            label="Beskrivelse"
                            multiline
                            value={card.description}
                            onValidBlur={update("description")}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormField
                            label='Etableringsår'
                            placeholder="Angiv årstal"
                            value={card.installationYear}
                            type='number'
                            onValidBlur={update("installationYear")}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormField
                            label='Ombygningsår'
                            placeholder="Angiv årstal"
                            value={card.rebuildYear}
                            type='number'
                            onValidBlur={update("rebuildYear")}
                            optional
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormField
                            label='Teoretisk levetid'
                            placeholder="Angiv antal år"
                            value={card.lifespan}
                            type='number'
                            onValidBlur={update("lifespan")}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormField
                            label='Restlevetid'
                            placeholder='Angiv antal år'
                            tooltip='Restlevetiden udregnes automatisk ud fra besigtigelsesåret, men kan overskrives her'
                            value={card.remainingLifetime}
                            type='number'
                            onValidBlur={update("remainingLifetime")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormField
                            type='text'
                            label='Tilstandsbeskrivelse'
                            multiline
                            value={card.conditionDescription}
                            onValidBlur={update("conditionDescription")}
                            optional
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Images
                onFileSelected={props.onFileSelected}
                header='Billeder'
                allowDescription={true}
                images={card.images}
                deleteImage={deleteImage}
                updateImage={updateImage}
                markImage={markImage}
                unmarkImage={unmarkImage}
                orderImages={orderImages}
            />

            <div className={styles.spacer}></div>

            <ChangeCardTypePopup 
                open={changeTypePopup} 
                onOk={changeType}
                onClose={closeChangeTypePopup}
                currentGroup={card.group}
                currentType={card.type}
                templates={props.templates} />
        </>
    )
}