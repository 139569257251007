import Api from "server/api";
import { Server } from "server";
import ReactPdf from '@react-pdf/renderer';

import { ObhReport } from "pages/report/obh";
import { EsReport } from "pages/report/es";

import { esReportVersion } from "./es/_version";
import { deasReportVersion } from "./es/deas/_version";
import { mmakeReportVersion } from "./es/mmake/_version";
import { ebasReportVersion } from "./es/ebas/_version";
import { obhReportVersion } from "./obh/_version";
import { bjornsholmReportVersion } from "./es/bjornsholm/_version";

import { b64toBlob } from "utils/blobs";
import { match } from "utils";

export type Options =  {
    asCompleted: boolean
};

export type CaseType = "DefaultCase" | "ObhCase" | "DeasCase" | "MmakeCase" | "EbasCase" | "BjornsholmCase";

export type ReportData = {
    documentUrl: string|null,
    documentBlob: Blob|null,
    filename: string,
    companyDetailsAreIncomplete: boolean
}

export const getCurrentReportVersion = (caseType: CaseType) => match<number>(caseType)
    .on("DefaultCase", _ => esReportVersion)
    .on("DeasCase", _ => deasReportVersion)
    .on("MmakeCase", _ => mmakeReportVersion)
    .on("EbasCase", _ => ebasReportVersion)
    .on("ObhCase", _ => obhReportVersion)
    .on("BjornsholmCase", _ => bjornsholmReportVersion)
    .orThrow();

export const generateReportBlob = async (server: Readonly<Server>, caseId: string, options?: Partial<Options>): Promise<ReportData> => {
    
    console.time('GetReport');
    const response = await server.query<Api.Cases.Queries.GetReportData.ReportDto>(new Api.Cases.Queries.GetReportData({ caseId: caseId }) ).toPromise();
    console.timeEnd('GetReport');
    console.time('Generate');
    const fullOptions: Options = {
        asCompleted: false,
        ...options
    };

    const report = match<JSX.Element>(response.caseType)
        .on("DefaultCase" as const, _ => EsReport({ reportData: response.data, options: fullOptions }))
        .on("DeasCase" as const, _ => EsReport({ reportData: response.data, options: fullOptions }))
        .on("MmakeCase" as const, _ => EsReport({ reportData: response.data, options: fullOptions }))
        .on("EbasCase" as const, _ => EsReport({ reportData: response.data, options: fullOptions }))
        .on("BjornsholmCase" as const, _ => EsReport({ reportData: response.data, options: fullOptions }))
        .on("ObhCase" as const, _ => ObhReport({ reportData: response.data, options: fullOptions }))
        .orThrow();
    
    const blob = await ReactPdf.pdf(report).toBlob();
    const url = URL.createObjectURL(blob);
    console.timeEnd('Generate');
    
    return {
        documentUrl: url,
        documentBlob: blob,
        filename: response.filename,
        companyDetailsAreIncomplete: response.companyDetailsAreIncomplete
    };
}

export const generateReportBlobFromExisting = async (data: string, fileName: string): Promise<ReportData> => {
    const blob = b64toBlob(data)
    const url = URL.createObjectURL(blob);
    return {
        documentUrl: url,
        documentBlob: blob,
        filename: fileName,
        companyDetailsAreIncomplete: false
    };    
}