import React, { PropsWithChildren } from 'react'
import { PageContext, PageContextType, ReportThemeContext } from './context'
import { TOCHeader } from './text';
import { getConditionRatingColors } from './condition-rating-colors';
import { CaseType } from '../generate-report'
import { EsReportProps } from './index'
import { DefaultReportTheme, ReportTheme } from './report-theme'
import { EbasBudgetTheme, EbasReportTheme } from './ebas/ebas-theme';

export const BudgetThemeContextProvider = (props: PropsWithChildren<{ caseType: string }>) => {
    const reportThemeContext: ReportTheme = props.caseType === 'EbasCase'
        ? EbasBudgetTheme
        : DefaultReportTheme
        
    return <ReportThemeContext.Provider value={reportThemeContext}>
            {props.children}
        </ReportThemeContext.Provider>
};

export const ContextProvider = (props: PropsWithChildren<EsReportProps>) => {
    const caseType = props.reportData.caseType as CaseType;

    const pageContext: PageContextType = {
        masterData: props.reportData.masterData,
        conditionRatingColors: getConditionRatingColors(props.reportData.masterData.case.maxConditionRating),
        isCompleted: props.reportData.masterData.isCompleted || props.options.asCompleted,
        headers: [] as TOCHeader[],
        reportTitle: ["MmakeCase", "EbasCase"].includes(caseType)
            ? "Vedligeholdelsesplan"
            : "Drift- og vedligeholdelsesplan"
    };

    const reportThemeContext: ReportTheme = caseType === 'EbasCase'
        ? EbasReportTheme
        : DefaultReportTheme
        
    return <>
        <PageContext.Provider value={pageContext}>
            <ReportThemeContext.Provider value={reportThemeContext}>
                {props.children}
            </ReportThemeContext.Provider>
        </PageContext.Provider>
    </>
};