import { createStyles } from "@material-ui/core";

export default () => createStyles({
    toolbar: {
        justifyContent: 'flex-end'
    },
    impersonationSelector: {
        marginLeft: 24
    },
    impersonationButton: {
        marginTop: 14
    }
});
