//input h s b in [0,1]
const hsbOrHsvToHex = (value: { h: number, s: number, b: number }): string => {
    const hsl = hsv2hsl(value.h * 360, value.s, value.b);
    const rgb = hsl2rgb(hsl[0], hsl[1], hsl[2]);
    const hex = rgbToHex(Math.round(rgb[0] * 255), Math.round(rgb[1] * 255), Math.round(rgb[2] * 255));

    return hex;
}

//output h s b in [0,1]
const hexToHsbOrHsv = (value: string): { h: number, s: number, b: number } => {
    const rgb = hexToRgb(value);
    const hsl = rgb2hsl(rgb[0] / 255, rgb[1] / 255, rgb[2] / 255);
    const hsv = hsl2hsv(hsl[0], hsl[1], hsl[2]);

    return { h: hsv[0] / 360, s: hsv[1], b: hsv[2] };
}

export const hsbGradientHex = (steps: number, hexColors: string[]): string[] =>
    hsbGradient(steps, hexColors.map(x => hexToHsbOrHsv(x))).map(x => hsbOrHsvToHex(x));

//https://stackoverflow.com/questions/2593832/how-to-interpolate-hue-values-in-hsv-colour-space
// input: h s b in [0,1]
export function hsbGradient(steps: number, colours: { h: number, s: number, b: number }[]): { h: number, s: number, b: number }[] {
    let parts = colours.length - 1;
    let gradient = new Array(steps);
    let gradientIndex = 0;
    let partSteps = Math.floor(steps / parts);
    let remainder = steps - (partSteps * parts);
    for (let col = 0; col < parts; col++) {
        // get colours
        let c1 = colours[col];
        let c2 = colours[col + 1];
        // determine clockwise and counter-clockwise distance between hues
        let distCCW = (c1.h >= c2.h) ? c1.h - c2.h : 1 + c1.h - c2.h;
        let distCW = (c1.h >= c2.h) ? 1 + c2.h - c1.h : c2.h - c1.h;
        // ensure we get the right number of steps by adding remainder to final part
        if (col == parts - 1) partSteps += remainder;
        // make gradient for this part
        for (let step = 0; step < partSteps; step++) {
            let p = step / partSteps;
            // interpolate h, s, b
            let h = (distCW <= distCCW) ? c1.h + (distCW * p) : c1.h - (distCCW * p);
            if (h < 0) h = 1 + h;
            if (h > 1) h = h - 1;
            let s = (1 - p) * c1.s + p * c2.s;
            let b = (1 - p) * c1.b + p * c2.b;
            // add to gradient array
            gradient[gradientIndex] = { h: h, s: s, b: b };
            gradientIndex++;
        }
    }
    return gradient;
}

// hsb is same as hsv, but hsl requires conversion
// hsl can be used directly with css
//https://stackoverflow.com/questions/3423214/convert-hsb-hsv-color-to-hsl
// input: h in [0,360] and s,v,l in [0,1]
export const hsl2hsv = (h: number, s: number, l: number, v = s * Math.min(l, 1 - l) + l) => [h, v ? 2 - 2 * l / v : 0, v];
export const hsv2hsl = (h: number, s: number, v: number, l = v - v * s / 2, m = Math.min(l, 1 - l)) => [h, m ? (v - l) / m : 0, l];

//https://stackoverflow.com/questions/36721830/convert-hsl-to-rgb-and-hex
// input: h in [0,360] and s,v in [0,1] - output: r,g,b in [0,1]
export function hsl2rgb(h: number, s: number, l: number) {
    let a = s * Math.min(l, 1 - l);
    let f = (n: number, k = (n + h / 30) % 12) => l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return [f(0), f(8), f(4)];
}

//https://stackoverflow.com/questions/2348597/why-doesnt-this-javascript-rgb-to-hsl-code-work
// in: r,g,b in [0,1], out: h in [0,360) and s,l in [0,1]
export function rgb2hsl(r: number, g: number, b: number) {
    let v = Math.max(r, g, b), c = v - Math.min(r, g, b), f = (1 - Math.abs(v + v - c - 1));
    let h = c && ((v == r) ? (g - b) / c : ((v == g) ? 2 + (b - r) / c : 4 + (r - g) / c));
    return [60 * (h < 0 ? h + 6 : h), f ? c / f : 0, (v + v - c) / 2];
}

//https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export const hexToRgb = (hex: string) =>
    hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
        , (m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1).match(/.{2}/g)
        .map(x => parseInt(x, 16))

export const rgbToHex = (r: number, g: number, b: number) => '#' + [r, g, b].map(x => {
    const hex = x.toString(16)
    return hex.length === 1 ? '0' + hex : hex
}).join('')