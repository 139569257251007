import * as React from 'react'
import { View } from '@react-pdf/renderer';
import { Divider, VerticalSpacer } from '../controls';
import { Header1 } from '../text';
import { Table, Row } from 'pages/report/controls/table'
import Api from 'server/api'
import { Annotations, Condition, FactBox1, FactBox2, Guide, Prices } from '../guide-page'

import Es = Api.Cases.Queries.Reports.Es;
import GuidePageDto = Es.GuidePageDto;

export const EsGuidePage = (props: { data: GuidePageDto }) =>
    <View>
        <View style={{ width: 250 }}>
            <Header1>Læsevejledning</Header1>
        </View>
        <Table colWidths={[300, 200]}>
            <Row>
                <View>
                    <Guide 
                        durationYears={props.data.durationYears}
                        typeMappingsName={props.data.typeMappingsName} />
                    <Divider size={'thin'} margin={'large'} />
                    <Condition conditionRatings={props.data.conditionRatings} />
                    <Divider size={'thin'} margin={'large'} />
                    <Annotations />
                    <Divider size={'thin'} margin={'large'} />
                    <Prices
                        indexYear={props.data.indexYear}
                        priceIndexInPercentage={props.data.priceIndexInPercentage}
                        includeVat={props.data.includeVat} />
                </View>
                <View style={{ marginLeft: 20 }} >
                    <FactBox1/>
                    <VerticalSpacer height="large" />
                    <FactBox2/>
                </View>
            </Row>
        </Table>
    </View>