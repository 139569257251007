import * as React from 'react'
import { View } from '@react-pdf/renderer';
import { Divider, VerticalSpacer } from '../controls';
import { Header1, Header3, Text } from '../text';
import { Table, Row } from 'pages/report/controls/table'
import Api from 'server/api'
import { Annotations, Condition, FactBox1, FactBox2, Guide } from '../guide-page'

import Es = Api.Cases.Queries.Reports.Es;
import GuidePageDto = Es.GuidePageDto;

export const Prices = (props: { indexYear: number, priceIndexInPercentage: string, includeVat: boolean }) =>
    <View>
        <Header3>Priser</Header3>
        <Text>
            Priserne i planen er med udgangspunkt i år {props.indexYear}, og reguleres i budgettet 
            årligt med {props.priceIndexInPercentage}.
        </Text>
        <VerticalSpacer height="small" />
        <Text>
            Alle priser er {(props.includeVat ? "inkl." : "ekskl.")} moms - budgetpriser indeholder ikke rådgiverhonorar.
        </Text>
    </View>

export const DeasGuidePage = (props: { data: GuidePageDto }) =>
    <View>
        <View style={{ width: 250 }}>
            <Header1>Læsevejledning</Header1>
        </View>
        <Table colWidths={[300, 200]}>
            <Row>
                <View>
                    <Guide 
                        durationYears={props.data.durationYears}
                        typeMappingsName={props.data.typeMappingsName} />
                    <Divider size={'thin'} margin={'large'} />
                    <Condition conditionRatings={props.data.conditionRatings} />
                    <Divider size={'thin'} margin={'large'} />
                    <Annotations />
                    <Divider size={'thin'} margin={'large'} />
                    <Prices
                        indexYear={props.data.indexYear}
                        priceIndexInPercentage={props.data.priceIndexInPercentage}
                        includeVat={props.data.includeVat} />
                </View>
                <View style={{ marginLeft: 20 }} >
                    <FactBox1/>
                    <VerticalSpacer height="large" />
                    <FactBox2/>
                </View>
            </Row>
        </Table>
    </View>