import React from 'react'
import ReactPdf, { StyleSheet, View, Text as PdfText } from '@react-pdf/renderer';
import { Text, Page, TOCHeader, Header1, VerticalSpacer } from './controls';
import _ from 'lodash';

const HeaderLine = (props: React.PropsWithChildren<{style?: ReactPdf.Style}>) => 
    <View style={{ flexDirection: 'row' }}>
        <PdfText style={styles.header}>{props.children}</PdfText>
        <PdfText style={{ ...styles.header, flex: 1, marginLeft: 3 }}>{'.'.repeat(200)}</PdfText>
        <View style={{ width: 70, backgroundColor: 'white', right: -85 }}></View>
    </View>

export const TableOfContents = (props: {caseName: string, headers: TOCHeader[], isCompleted: boolean}) =>
    <Page style={{ textAlign: 'justify' as any }} size="A4" caseName={props.caseName} showWatermark={!props.isCompleted}>
        <VerticalSpacer />        
        <Header1>Indholdsfortegnelse</Header1>       
        <View style={{display: 'flex', flexDirection: 'row'}}>
            <View style={{width: 451, display: 'flex'}} >
                {/* 
                    TODO: currently the TOC is with static labels and dynamic page numbers. React-Pdf does not support creating a TOC atm.

                    Creating a TOC this way makes it extremely difficult to style it and thus it is kept very simple.

                    The TOCHeader contains both label, pagenumber and headerlevel to be used at a later time. The data is collected in the render
                    function in the respective headers. To use the collected data, it must also be applied through a 
                    render function (which are run 2 times - the last time the data is available). See: https://react-pdf.org/advanced#dynamic-content     
                    
                    We have following ideas for creating a better TOC dynamically:

                    1: Render the document 2 times. The first time to collect the headers, which is then used as input in the next rendering.
                        pros: Headers arenormal input to this component and can be rendered as usual. Creating a TOC would be easy and could be implemented
                              without hacks.
                        cons: Rendering-overhead. With the current react-pdf, double rendering causes ~1 extra second (from ~2.5sec to ~3.5 sec)
                        note: A new version of React-pdf (v2.0) is close at hand. Performance should be boosted here - perhaps makign this solution desirable.
                    2: Placing the TOC-component as the last element in the Document. This way we can use <View render={....}> instead of <Text> - and the
                       <View>'s render is able to return complex components (and not just a string).
                        pros: The TOC could be created without the current hacks and static headers. Styling would (probably) be a lot easier
                        cons: The TOC is the last page(s). Pagenumbering needs to be manipulated (incremented). The pdf needs to be spliced somehow, so the 
                              the TOC is placed at page 3 (depending on whether or not one or more case images are added). If the TOC is more than one page
                              how do we know how much each pagenumber should be incremented?
                    3: Using out-of-the-box support for TOC generation - when/if it is supported.
                */}
                <HeaderLine>Indledning</HeaderLine>
                <HeaderLine>Formål</HeaderLine>
                <HeaderLine>Baggrund</HeaderLine>
                <HeaderLine>Ejendommen</HeaderLine>
                <HeaderLine>Bygninger</HeaderLine>
                <HeaderLine>Ejendomsbeskrivelse</HeaderLine>
                <HeaderLine>Resultater</HeaderLine>
                <HeaderLine>Resumé</HeaderLine>
                <HeaderLine>Tilstandsvurdering</HeaderLine>
                <HeaderLine>Bygningsgennemgang</HeaderLine>
                <HeaderLine>Beskrivelse</HeaderLine>
                <HeaderLine>Bygningsdelskort</HeaderLine>
                <HeaderLine>Budget</HeaderLine>
            </View>
            <View style={{width: 20,  display: 'flex', textAlign: 'right'}} >
                <Text fixed render={() => <PdfText style={styles.pageNumbers}>
                        {/*Subtract one from react-pdf's built-in page number to exclude frontpage*/}
                        {_.uniqBy(props.headers, 'header').map(x => `  ${x.pageNumber - 1}`).join('\n')}
                    </PdfText>}
                />
            </View>
        </View>
    </Page>

const styles = StyleSheet.create({
    pageNumbers: {
        backgroundColor: 'white',
        fontFamily: 'arial-bold',
        fontSize: 10,
        lineHeight: 2,
        paddingLeft: 3
    },
    header: {
        fontFamily: 'arial-bold',
        fontSize: 10,
        lineHeight: 2
    }
});   