import React, { ReactChildren, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { DialogTitle, DialogContent, DialogActions, Toolbar, Table, TableHead, TableRow, TableBody, TableCell, MuiThemeProvider } from "@material-ui/core";
import { Button } from "controls/button";
import { Popup } from 'controls/popup';
import { useCommand, useImmutableArrayState } from "custom-hooks";
import { createArrayStateElementUpdater, createReorderElementsArrayUpdater } from "utils/updaters";
import { TableField } from "controls/field/table-field";
import { Tooltip } from "controls/tooltip";
import { IconButton } from "controls/button/icon-button";
import { theme } from "theme";
import { hasValue } from "utils";
import { DraggableTableRow } from 'controls/draggable-table-row';
import { add, pipe, remove, replaceFunc } from "utils/immutable-array";

import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Delete from '@material-ui/icons/Delete';

import Api from "server/api";
import TypeMappingsDto = Api.Users.Queries.GetTypeMappings.TypeMappingsDto;
import MappingDto = Api.Users.Queries.GetTypeMappings.MappingDto;
import UpdateTypeMappings = Api.Users.Commands.UpdateTypeMappings;

type Props = {
    typeMappings: TypeMappingsDto;
    open: boolean,
    setOpen: (open: boolean) => void;
}

const ThemedTable = (props: { children: ReactNode }) => (
    <MuiThemeProvider theme={{
        ...theme,
        overrides: {
            ...theme.overrides,
            MuiTableCell: {
                root: {
                    padding: '4px',
                },
                body: {
                    fontSize: 13,
                    padding: 5,
                    "border-bottom": 'none',
                    '&:last-child': {
                        paddingRight: 24
                    }
                },
                head: {
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontSize: 12,
                    whiteSpace: 'nowrap'
                }
            },
            MuiFormControl: {
                root: {
                    margin: '4px 4px'
                },
                marginNormal: {
                    "margin-top": 4,
                    "margin-bottom": 4
                }
            },
            MuiFilledInput: {
                input: {
                    fontSize: 12,
                    padding: '2px 4px'
                }
            },
            MuiIconButton: {
                root: {
                    padding: 12,
                    fontSize: 12
                }
            }
        }
    }}>
        <Table>
            {props.children}
        </Table>
    </MuiThemeProvider>)

const HeaderRow = () => <TableRow>
    <TableCell variant='head' />
    <TableCell variant='head'>Gruppe</TableCell>
    <TableCell variant='head'>Type</TableCell>
    <TableCell variant='head'>Klassifikation</TableCell>
    <TableCell variant='head'>Beskrivelse</TableCell>
    <TableCell variant='head'>Overskrift</TableCell>
    <TableCell variant='head'></TableCell>
</TableRow>

const FilterRow = (props:
    {
        groupFilter: string,
        typeFilter: string,
        classificationFilter: string,
        classificationDescriptionFilter: string,
        headerFilter: string,
        setGroupFilter: React.Dispatch<React.SetStateAction<string>>,
        setTypeFilter: React.Dispatch<React.SetStateAction<string>>,
        setClassificationFilter: React.Dispatch<React.SetStateAction<string>>,
        setClassificationDescriptionFilter: React.Dispatch<React.SetStateAction<string>>,
        setHeaderFilter: React.Dispatch<React.SetStateAction<string>>,
        setNewMappingsIndexes: (newArray: React.SetStateAction<readonly number[]>) => void,
        resetFilters: () => void
    }) => {

    const { groupFilter, typeFilter, classificationFilter, classificationDescriptionFilter, headerFilter,
        setGroupFilter, setTypeFilter, setClassificationFilter, setClassificationDescriptionFilter,
        setHeaderFilter, setNewMappingsIndexes, resetFilters } = props;

    const updateFilter = (filterSetter: React.Dispatch<React.SetStateAction<string>>) => (val: string) => {

        setNewMappingsIndexes([]);
        filterSetter(val);
    }

    const filterActive = !!groupFilter || !!typeFilter || !!classificationFilter || !!classificationDescriptionFilter || !!headerFilter;

    return (
        <TableRow>
            <TableCell />
            <TableCell>
                <TableField type="text" placeholder="Filter" optional value={groupFilter} onValidChange={updateFilter(setGroupFilter)} />
            </TableCell>
            <TableCell>
                <TableField type="text" placeholder="Filter" optional value={typeFilter} onValidChange={updateFilter(setTypeFilter)} />
            </TableCell>
            <TableCell>
                <TableField type="text" placeholder="Filter" optional value={classificationFilter} onValidChange={updateFilter(setClassificationFilter)} />
            </TableCell>
            <TableCell>
                <TableField type="text" placeholder="Filter" optional value={classificationDescriptionFilter} onValidChange={updateFilter(setClassificationDescriptionFilter)} />
            </TableCell>
            <TableCell>
                <TableField type="text" placeholder="Filter" optional value={headerFilter} onValidChange={updateFilter(setHeaderFilter)} />
            </TableCell>
            <TableCell>
                {filterActive && <Button
                    label="Nulstil filter"
                    onClick={resetFilters} />}
            </TableCell>
        </TableRow>)
}

const TypeMappingsRow = (props: {
    index: number,
    mapping: MappingDto,
    typeMappings: TypeMappingsDto,
    setTypeMappings: React.Dispatch<React.SetStateAction<TypeMappingsDto>>,
    setNewMappingsIndexes: (newArray: React.SetStateAction<readonly number[]>) => void,
    isDraggingGroup: number | false,
    setIsDraggingGroup: React.Dispatch<React.SetStateAction<number | false>>,
    moveMapping: (index: number | false, toIndex: number) => void
}) => {
    const { setTypeMappings, index, mapping, setNewMappingsIndexes, typeMappings, isDraggingGroup, setIsDraggingGroup, moveMapping } = props;

    const addMappingAt = (index: number, group: string) => () => {
        setNewMappingsIndexes(pipe(replaceFunc(i => i > index, i => i + 1), add(index + 1)));
        setTypeMappings({
            ...typeMappings,
            mappings: [
                ...typeMappings.mappings.slice(0, index + 1),
                { group: group, type: '', classification: '', classificationDescription: '', header: '' },
                ...typeMappings.mappings.slice(index + 1)]
        })
    }

    const removeMapping = (index: number) => () => {
        setNewMappingsIndexes(pipe(remove(i => i === index), replaceFunc(i => i >= index, i => i - 1)));
        setTypeMappings({
            ...typeMappings,
            mappings: [
                ...typeMappings.mappings.slice(0, index),
                ...typeMappings.mappings.slice(index + 1)]
        })
    }

    const updater = createArrayStateElementUpdater(setTypeMappings, "mappings")(index);

    return <DraggableTableRow
        rowKey={index}
        onDragStart={() => setIsDraggingGroup(index)}
        onDragEnd={() => setIsDraggingGroup(false)}
        onDrop={async () => moveMapping(isDraggingGroup, index)}
        handleCellStyle={{ paddingRight: 20 }}>
        <TableCell>
            <TableField variant="standard" type="text" value={mapping.group} onValidBlur={updater("group")} />
        </TableCell>
        <TableCell>
            <TableField type="text" value={mapping.type} onValidBlur={updater("type")} />
        </TableCell>
        <TableCell>
            <TableField type="text" value={mapping.classification} onValidBlur={updater("classification")} />
        </TableCell>
        <TableCell>
            <TableField type="text" value={mapping.classificationDescription} onValidBlur={updater("classificationDescription")} />
        </TableCell>
        <TableCell>
            <TableField type="text" value={mapping.header} optional onValidBlur={updater("header")} />
        </TableCell>
        <TableCell>
            <Toolbar variant='dense' disableGutters>
                <Tooltip title='Tilføj ny under denne'>
                    <span>
                        <IconButton onClick={addMappingAt(index, mapping.group)}>
                            <AddCircleOutline fontSize="inherit" />
                        </IconButton>
                    </span>
                </Tooltip>
                {typeMappings.mappings.length > 1 && <Tooltip title='Slet'>
                    <span>
                        <IconButton onClick={removeMapping(index)}>
                            <Delete fontSize="inherit" />
                        </IconButton>
                    </span>
                </Tooltip>}
            </Toolbar>
        </TableCell>
    </DraggableTableRow>
}

export const UpdateTypeMappingsPopup = (props: Props) => {

    const [typeMappings, setTypeMappings] = useState<TypeMappingsDto>();

    const canExecute = useMemo(() =>
        typeMappings && !typeMappings.mappings.some(x =>
            !hasValue(x.group) ||
            !hasValue(x.type) ||
            !hasValue(x.classification) ||
            !hasValue(x.classificationDescription))
        , [typeMappings])

    const updateTypeMapping = useCommand(() => new UpdateTypeMappings({ mappingsId: typeMappings.id, mappings: typeMappings.mappings }))

    const onCancel = () => props.setOpen(false);
    const onAttemptExit = () => { };

    const onOk = () => {
        if (!canExecute) return;
        props.setOpen(false);
        updateTypeMapping();
    };

    const [groupFilter, setGroupFilter] = useState(null as string | null);
    const [typeFilter, setTypeFilter] = useState(null as string | null);
    const [classificationFilter, setClassificationFilter] = useState(null as string | null);
    const [classificationDescriptionFilter, setClassificationDescriptionFilter] = useState(null as string | null);
    const [headerFilter, setHeaderFilter] = useState(null as string | null);

    const [newMappingsIndexes, setNewMappingsIndexes] = useImmutableArrayState<number>(); //This is here to keep showing new entries, until the filter is changed

    useEffect(() => {
        if (!open) setTypeMappings(null);

        resetFilters();

        setTypeMappings(props.typeMappings && props.typeMappings.mappings && props.typeMappings.mappings.length === 0
            ? ({
                ...props.typeMappings,
                mappings: [{ group: '', type: '', classification: '', classificationDescription: '', header: '' }]
            })
            : props.typeMappings);

    }, [open, props.typeMappings])

    const filteredMappings = useMemo(() => {

        if (typeMappings == null) return null;

        const groupFilterUpper = groupFilter?.toUpperCase() ?? null;
        const typeFilterUpper = typeFilter?.toUpperCase() ?? null;
        const classificationUpper = classificationFilter?.toUpperCase() ?? null;
        const classificationDescUpper = classificationDescriptionFilter?.toUpperCase() ?? null;
        const headerUpper = headerFilter?.toUpperCase() ?? null;

        return typeMappings.mappings
            .map((m, i) => ({ index: i, mapping: m }))
            .filter(e => {
                if (newMappingsIndexes.some(i => i === e.index)) return true;
                if (groupFilter && !e.mapping.group?.toUpperCase().includes(groupFilterUpper)) return false;
                if (typeFilter && !e.mapping.type?.toUpperCase().includes(typeFilterUpper)) return false;
                if (classificationUpper && !e.mapping.classification?.toUpperCase().includes(classificationUpper)) return false;
                if (classificationDescUpper && !e.mapping.classificationDescription?.toUpperCase().includes(classificationDescUpper)) return false;
                if (headerUpper && !e.mapping.header?.toUpperCase().includes(headerUpper)) return false;
                return true;
            });
    }, [typeMappings, groupFilter, typeFilter, classificationFilter, classificationDescriptionFilter, headerFilter])

    const moveMapping = (index: number | false, toIndex: number) => {
        if (index === false) return;

        setTypeMappings(tm => ({ ...tm, mappings: createReorderElementsArrayUpdater(index, toIndex)(tm.mappings) }))
    }

    const [isDraggingGroup, setIsDraggingGroup] = useState<number | false>(false);

    const resetFilters = useCallback(() => {
        setNewMappingsIndexes([]);
        setGroupFilter(null);
        setTypeFilter(null);
        setClassificationFilter(null);
        setClassificationDescriptionFilter(null);
        setHeaderFilter(null);
    }, []);

    if (typeMappings == null) return null;

    return (
        <Popup
            maxWidth='lg'
            fullWidth
            open={props.open}
            onClose={onAttemptExit}
            onOk={onOk}
            onCancel={onAttemptExit}>

            <DialogTitle>
                Opdatér konfiguration af klassifikationer
            </DialogTitle>

            <DialogContent>
                <ThemedTable>
                    <TableHead>
                        <HeaderRow />
                        <FilterRow groupFilter={groupFilter}
                            typeFilter={typeFilter}
                            classificationFilter={classificationFilter}
                            classificationDescriptionFilter={classificationDescriptionFilter}
                            headerFilter={headerFilter}
                            setGroupFilter={setGroupFilter}
                            setTypeFilter={setTypeFilter}
                            setClassificationFilter={setClassificationFilter}
                            setClassificationDescriptionFilter={setClassificationDescriptionFilter}
                            setHeaderFilter={setHeaderFilter}
                            setNewMappingsIndexes={setNewMappingsIndexes}
                            resetFilters={resetFilters} />
                    </TableHead>
                    <TableBody>
                        {filteredMappings.map(x => <TypeMappingsRow
                            key={x.index}
                            index={x.index} mapping={x.mapping}
                            setNewMappingsIndexes={setNewMappingsIndexes}
                            setTypeMappings={setTypeMappings}
                            typeMappings={typeMappings}
                            isDraggingGroup={isDraggingGroup}
                            setIsDraggingGroup={setIsDraggingGroup}
                            moveMapping={moveMapping} />)}
                    </TableBody>
                </ThemedTable>
            </DialogContent>
            <DialogActions>
                <Toolbar>
                    <Button label='Fortryd' onClick={onCancel} />
                    <Button
                        label='Gem'
                        onClick={onOk}
                        disabled={!canExecute} />
                </Toolbar>
            </DialogActions>
        </Popup>);
}