
import { createStyles, Theme } from "@material-ui/core";
import { lineClampStyles } from "theme";

export default (theme: Theme) => createStyles({
    tableContainer: {
        display: 'inline-block',
        minWidth: '100%'
    },
    hideControl: {
        display: 'inline-flex',
        alignItems: 'center',
        position: 'sticky',
        left: 24,
        margin: '10px 24px',
        borderBottom: '1px solid black',
        cursor: 'pointer',
        '& svg': {
            fontSize: 22,
            paddingRight: 4
        },
        '& p': {
            fontSize: 10,
            fontWeight: 'bold',
            paddingRight: 6
        }
    },
    table: {
        borderCollapse: 'separate',
        '& th, td': {
            fontSize: 10,
            paddingLeft: 12,
            paddingRight: 12
        },
        '& th': {
            height: 14,
            color: '#9D9E98',
            textAlign: 'right',

            position: 'sticky',
            top: 0,
            backgroundColor: '#FFFFFF',
        },
        '& th:nth-child(2), th:nth-child(3)': {
            textAlign: 'left',
            paddingLeft: 24,
            paddingRight: 24
        },
        '& th:nth-child(1)': {
            width: 54,
            minWidth: 54,
            left: 0,
        },
        '& th:nth-child(2)': {
            width: 200,
            minWidth: 200,
            left: 54,
        },
        '& th:nth-child(3)': {
            width: 300,
            minWidth: 300,
            left: 200+54,
        },
        '& th:nth-child(1), th:nth-child(2), th:nth-child(3)': {
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#FFFFFF',
        }
    },
    headerRowPriorityColumn: {
        textAlign: 'center',
        width: 80,
        minWidth: 80,
        left: 200+54+300,

        zIndex: 2,
        position: 'sticky',
        backgroundColor: '#FFFFFF',
    },
    groupRow: {
        backgroundColor: '#CACBC4',
        height: 22,
        cursor: 'pointer',
        '& td': {
            padding: 0,
            paddingRight: 12,
            fontWeight: 'bold',
            textAlign: 'right',
            borderRight: '1px solid lightgrey'
        },
        '& td:nth-child(1)': {
            textAlign: 'left',
            paddingLeft: 24,
            paddingRight: 24,
            position: 'sticky',
            left: 0,
            backgroundColor: '#CACBC4',

            '& > div':{
                display: 'flex', 
                flexDirection: 'row',
 
                '& > div': {
                    flex: 1,
                    paddingTop: 3
                }
            }
        },
        '& svg': {
            fontSize: 22,
            paddingRight: 4
        }
    },
    taskRow: {
        '& td': {
            fontSize: 12,
            paddingTop: 8,
            paddingBottom: 8,
            textAlign: 'right',
            borderRight: '1px solid rgb(247,247,247)'
        },
        '& td:nth-child(2), td:nth-child(3)': {
            cursor: 'pointer',
            textAlign: 'left',
            paddingLeft: 24,
            paddingRight: 24,
            '&:hover': {
                color: '#56DFA6'
            }
        },
        '& td:nth-child(1)': {
            left: 0,
        },
        '& td:nth-child(2)': {
            left: 54,
        },
        '& td:nth-child(3)': {
            left: 200+54
        },
        '& td:nth-child(1), td:nth-child(2), td:nth-child(3)': {
            position: 'sticky',
            backgroundColor: '#FFFFFF',
            zIndex: 1
        }
    },
    taskTextCell: {
        ...lineClampStyles(2)
    },
    taskRowPriorityColumn: {
        textAlign: 'center',
        left: 200+54+300,

        position: 'sticky',
        backgroundColor: '#FFFFFF',
        zIndex: 1
    },
    taskRowHighlight: {
        // increasing specificity is necessary and this approach gives better control than !important does
        // see https://stackoverflow.com/questions/19399625/what-is-the-most-character-efficient-way-to-increase-css-specificity
        // and see https://github.com/styled-components/styled-components/issues/1253
        '&& td': {
            backgroundColor: '#F4F5F2'
        }
    },
    taskRowContent: {
        position: 'relative'
    },
    taskRowContentValue: {
        paddingLeft: '25px'
    },
    taskRowContentImg: {
        position: 'absolute'
    },
    sumRow: {
        '& td': {
            fontSize: 12,
            fontWeight: 'bold',
            paddingTop: 8,
            paddingBottom: 8,
            textAlign: 'right',
            borderRight: '1px solid rgb(247,247,247)',
            borderBottom: 'none'
        },
        '& td:nth-child(1)': {
            textAlign: 'left',
            position: 'sticky',
            left: 0,
            backgroundColor: '#FFFFFF'
        }
    },
    yearColumnHeader: {
        minWidth: 80
    },
    dropTarget: {
        '&&&': {
            backgroundColor: 'grey'
        }
    },
    draggingOver: {
        '&&&': {
            backgroundColor: '#D6D7D0'
        }
    },
    draggable: {
        cursor: 'grab',
        transition: 'transform 0.1s ease-in',
        '&&:hover': {
            backgroundColor: '#D6D7D0',
            transform: 'perspective(200px) translateZ(20px) translateX(-3px)'
        }
    },
    buttonSection: {
        position: 'sticky',
        left: 0,
        marginTop: theme.spacing(3),
        paddingLeft: theme.spacing(4)
    },
    popupContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2),
        width: theme.spacing(100),
    },
    deleteCell: {
        padding: '0 !important',
        paddingLeft: '5px !important',
        paddingRight: '5px !important'
    },
    taskRowDeleteCell: {
        borderRightWidth: '0px !important'
    },
    deleteIcon: {
        fontSize: 20
    },
    spacer: {
        minHeight: theme.spacing(21)
    }
});