import { createStyles, Theme } from "@material-ui/core";

export default (theme: Theme)  => createStyles({      
    outerContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        fontFamily: 'Open Sans', 
        height: '100%'
    },
    topContainer: {
        minHeight: 300, 
        boxShadow: '0 0 22px 0 rgba(0,0,0,0.3)', 
        display: 'flex', 
        flexDirection: 'row', 
        paddingTop:41, 
        justifyContent: 'center' 
    },
    middleContainer: {
        flex: 1, 
        justifyContent: 'center', 
        display: 'flex', 
        overflow: 'auto'
    },
    bottomContainer: {
        height: 100, 
        alignSelf: 'flex-end', 
        background: 'linear-gradient(180deg, #9EE994 0%, #94E9C9 100%)',
        width:'100%'
    },
    loginContent: {
        display: 'flex', 
        flexDirection: 'column', 
        width: 310
    },
    loginButtonOuterContainer: {
        display: 'flex', 
        flexDirection: 'column-reverse'
    },
    loginButtonInnerContainer: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'space-between'
    },
    loginCheckboxContainer: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center'
    },
    loginButton: {
        width: 83, 
        height: 28, 
        fontSize: 10
    },
    createNewUserButton: {
        width: 100, 
        height: 28, 
        fontSize: 10
    },
    checkboxLabel: {
        margin: 0,
        '& .MuiFormControlLabel-label': {
            fontSize: 10, 
            fontWeight: 'bold', 
            marginTop: -1,
            marginLeft: 2
        },
        '& .MuiCheckbox-root':{
            padding: 0
        }
    },
    input: {
        fontSize: 16,
        '&::before': {
            borderBottom: '1px solid #B2B3AB'
        }
    },
    inputAdornment: {
        '& span': {
            '& svg': {
                fontSize: 14
            }
        }
    },
    inputLabel: {
        fontSize: 13,
        opacity: 0.3
    },
    supportContainer:{
        width: 350,
        fontSize: 13,
        marginLeft: 90
    },    
    supportTitle: {
        fontSize: 25,
        fontWeight: 'bold'
    },    
    supportTable: {
        borderSpacing: 0
    },
    errorMessage: {
        minHeight: 34,
        color: 'red',
        fontSize: 12
    },
    labelTd: {
        width: 100,
        padding: 0
    },
    valueTd: {
        width: 250,
        padding: 0
    },
    licenseContent: {
        height: 96, 
        width: '100%',
        backgroundColor: 'white', 
        marginTop: 4, 
        justifyContent: 'center', 
        display: 'flex'
    },
    licenseContenOuterContainer: {
        display: 'flex', 
        flexDirection: 'row', 
        width: 750, 
        paddingTop: 22, 
        justifyContent: 'space-between'
    },
    licenseContentInnerContainer: {
        width: 280,
        fontSize: 13, 
        fontWeight: 'bold'
    },
    descriptionContent: {
        width: 750, 
        fontSize: 13, 
        paddingTop: 48
    },
    subHeader: {    
        fontSize: 13,    
        fontWeight: 'bold'
    },
    descriptionText: {        
        marginBottom: 10
    },
    contactOuterContainer: {
        display: 'flex', 
        flexDirection: 'row', 
        marginTop: 40
    },
    contactInnerContainer: {
        marginLeft: 40, 
        display: 'flex', 
        alignItems: 'center'
    },
    link: {
        color: '#56DFA6', 
        textDecoration: 'None'
    }
});
