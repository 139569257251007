import React from "react";
import Api from "server/api";
import { TableCell, Toolbar, withStyles, WithStyles } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import SaveAlt from '@material-ui/icons/SaveAlt';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { WarningPopup } from "controls/warning-popup";
import { FileUpload } from "controls/file-upload";
import { DraggableTableRow } from 'controls/draggable-table-row';
import styles from './styles'
import Component from "component";
import { Tooltip } from "controls/tooltip";
import { IconButton } from "controls/button/icon-button";
import { join } from "lodash";

type Props = {
    deleteTemplate: (group: string) => Promise<void>;
    replaceTemplate: (files: File[], groupName: string) => Promise<void>;
    template: Api.Templates.Queries.GetCompanyTemplates.TemplateGroupDto;
    onDragStart: () => void;
    onDragEnd: () => void;
    onDrop: () => Promise<void>;
} & WithStyles<typeof styles>

type State = {
    isWarningPopupOpen: boolean,
};

export default withStyles(styles)(class TemplateRow extends Component<Props, State> {

    state: Readonly<State> = { isWarningPopupOpen: false };

    render() {
        const template = this.props.template;

        const templateUrl = this.server.queryUrl(new Api.Templates.Queries.ExportCompanyTemplates({
            groupName: this.props.template.group
        }));

        return (
            <DraggableTableRow 
                rowKey={template.group}
                onDragStart={this.props.onDragStart}
                onDrop={this.props.onDrop}
                onDragEnd={this.props.onDragEnd}
                handleCellStyle={{paddingRight: 0}}>
                <TableCell component="th" scope="row">{template.group}</TableCell>
                <TableCell>{join(template.types, ', ')}</TableCell>
                <TableCell>
                    <Toolbar variant='dense' disableGutters className={this.props.classes.toolbar}>
                        <FileUpload accept='.xls, .xlsx' onUpload={files => this.replaceTemplate(files)}>
                            <Tooltip title='Erstat skabelon'>
                                <IconButton>
                                    <SwapHorizIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </FileUpload>
                        <Tooltip title='Hent skabelon'>
                            <IconButton href={templateUrl.url}>
                                <SaveAlt />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Slet skabelon'>
                            <IconButton onClick={() => this.setState({ isWarningPopupOpen: true })}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                    <WarningPopup
                        onOk={() => this.deleteTemplate()}
                        onClose={() => this.setState({ isWarningPopupOpen: false })}
                        isOpen={this.state.isWarningPopupOpen}
                        title='Slet skabelonen?'
                        message='Du er ved at slette skabelonen. Er du sikker?'
                        okText='SLET'
                        cancelText='FORTRYD'
                    />
                </TableCell>
            </DraggableTableRow>
        )
    }

    deleteTemplate = async () => {
        this.setState({ isWarningPopupOpen: false });
        await this.props.deleteTemplate(this.props.template.group);
    };

    replaceTemplate = async (files: File[]) => {
        this.setState({ isWarningPopupOpen: false });
        await this.props.replaceTemplate(files, this.props.template.group);
    };
})