import React, { useState, useEffect } from "react";
import styles from './styles'
import { makeStyles } from "@material-ui/core";

import { useErrorDisplay, includeAllErrors } from "custom-hooks";

import { doNothing } from "utils";
import { ErrorPanel } from "controls/global-error-panel";

import { WelcomeMessageContent } from './content/welcome-message-content';
import { SupportContent } from './content/support-content';
import { DescriptionContent } from './content/description-content';
import { LicenseContent } from './content/license-content';
import { LoginForm } from "./content/login-form";

export type Props = {
    showWelcomeMessage: boolean
}

const useStyles = makeStyles(styles);

export const Login = (props: Props) => {

    const styles = useStyles();
    const [showWelcomeMessage, _] = useState(props.showWelcomeMessage);
    const errorDisplay = useErrorDisplay(includeAllErrors, 10000);

    useEffect(() => {
        window.history.replaceState(null, null, window.location.pathname); 
    }, []);

    return (
        <div className={styles.outerContainer}>
            <ErrorPanel errors={errorDisplay.errors} onRemove={doNothing} />
            <div className={styles.topContainer}>
                <LoginForm errorDisplay={errorDisplay} />
                {
                    showWelcomeMessage
                        ? <WelcomeMessageContent/>  
                        : <SupportContent/> 
                }
            </div>

            <div className={styles.middleContainer}>
                <DescriptionContent />
            </div>

            <div className={styles.bottomContainer}>
                <LicenseContent />
            </div>
        </div>
    )
}