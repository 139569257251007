import React from 'react'
import ReactPDF, { Image as PdfImage, View } from '@react-pdf/renderer';
import { Text as Text, BoldText, Header1, Header3, Markdown } from './text'
import { Table, Row as TableRow, RowProps } from 'pages/report/controls/table'
import Api from 'server/api'
import _ from 'lodash';

import Es = Api.Cases.Queries.Reports.Es;
import EstatePageDto = Es.EstatePageDto;

interface CaseDataProps extends EstatePageDto { header: string, colWidths: number[] }
interface BuildingInfoProps extends EstatePageDto { colWidths: number[] }

export const Row = (props: RowProps) =>
    <TableRow  {...props}
        style={{
            paddingTop: 5,
            borderBottom: 0.5,
            paddingBottom: 5,
            borderBottomColor: '#EDEDEA',
            ...props.style
        }} />

export const BottomRow = (props: RowProps) =>
    <TableRow  {...props}
        style={{
            paddingBottom: 10,
            paddingTop: 5,
            ...props.style
        }} />

const Image = (props: { imageUrl: string}) =>
    <View style={{ width: 190, height: '100%', maxHeight: 236 }}>
        {props.imageUrl
            ? <PdfImage style={{ 
                objectFit: 'contain',
                objectPosition: '100% 0'
            }} source={'/api/' + props.imageUrl + '&width=570'} />
            : <View style={{ width: '100%', height: '100%', backgroundColor: '#CCCCCC' }} />}
    </View>

export const Value = (props: ReactPDF.TextProps) =>
    <View style={{ paddingLeft: 5, paddingRight: 5 }}>
        <Text {...props} />
    </View>

export const CaseData = (props: CaseDataProps) => {

    return <View>
        <Header1>{props.header}</Header1>
        <Table colWidths={props.colWidths}>
            <Row>
                <BoldText>Sag</BoldText>
                <Value>{props.caseData.name}</Value>
                <BoldText>Udførende firma</BoldText>
                <Value>{props.caseData.companyName}</Value>
            </Row>
            <Row>
                <BoldText>Sted</BoldText>
                <Value>{props.caseData.nickname}</Value>
                <BoldText>Konsulent</BoldText>
                <Value>{props.caseData.consultant}</Value>
            </Row>
            <Row>
                <BoldText>Adresse</BoldText>
                <Value>{props.caseData.address}</Value>
                <BoldText>Telefon</BoldText>
                <Value>{props.caseData.phoneNumber}</Value>
            </Row>
            <Row>
                <BoldText>Kunde</BoldText>
                <Value>{_.compact([props.caseData.customerCompany, props.caseData.customerContact]).join(' / ')}</Value>
                <BoldText>E-mail</BoldText>
                <Value>{props.caseData.email}</Value>
            </Row>
            <Row>
                <BoldText>Dato for besigtigelse</BoldText>
                <Value>{props.caseData.inspectionDate}</Value>
                <BoldText>Godkendt af</BoldText>
                <Value>{props.caseData.approvedBy}</Value>
            </Row>
            <BottomRow>
                <BoldText>Dato for rapport</BoldText>
                <Value>{props.caseData.dateOfReport}</Value>
                <BoldText></BoldText>
                <Value></Value>
            </BottomRow>
        </Table>
    </View>
}

export const BuildingInfo = (props: BuildingInfoProps) =>
    <View>
        <Header1>Ejendomsoplysninger</Header1>
        {props.buildings.map(building =>
            <View key={building.name}>
                <Header3 style={{ paddingTop: 10 }} >{building.name}</Header3>
                <Table colWidths={props.colWidths}>
                    <Row>
                        <BoldText>Opført år</BoldText>
                        <Value>{building.buildingYear}</Value>
                        <BoldText>Bebygget areal</BoldText>
                        <Value>{building.buildingArea} m²</Value>
                    </Row>
                    <BottomRow>
                        <BoldText>EM gyldighedsperiode</BoldText>
                        {building.energyLabelValidityStart && building.energyLabelValidityEnd
                            ? <Value>{building.energyLabelValidityStart} til {building.energyLabelValidityEnd}</Value>
                            : <Value>-</Value>}
                        <BoldText></BoldText>
                        <Value></Value>
                    </BottomRow>
                </Table>
            </View>)}
    </View>

export const Paragraph = (props: {header?: string, body: string}) =>
    <View>
        {props.header && <Header3 minPresenceAhead={50}>{props.header}</Header3>}
        {
            // work-around for failure to split texts that span > 3 pages
            // inside a page with top/bottom paddings
            (props.body ?? "")
                .split('\n\n')
                .map((text, i) =>
                    <View key={i} style={{ marginBottom: 10 }}><Markdown>{text}</Markdown></View>)
        }
    </View>

export const EstateDescription = ({ imageUrl, children }: React.PropsWithChildren<{imageUrl: string}>) =>
    <View>
        <Header1>Ejendomsbeskrivelse</Header1>
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{ width: 297, marginRight: 15 }}>
                {children}
            </View>
            <Image imageUrl={imageUrl} />
        </View>
    </View>