export function jsonDateConverter(_key: string, value: string|Date): string|Date {
    
    if(value instanceof Date) return value;
    
    // We use custom date parsing instead of moment to make sure we support exactly these two formats
    // in deserialization. We might be able to switch in the future but not without further automatic testing,
    // the error cases are subtle.
    const iso8601DateFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})(Z|[+-](\d{2})\:(\d{2}))?$/i;
    const javascriptJsonDateFormat = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)?$/i;
    if (iso8601DateFormat.test(value) || javascriptJsonDateFormat.test(value)) {
        return new Date(value);
    }

    return value;
}