import { createStyles, Theme } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

export default (theme: Theme) => {
    return createStyles({
        document: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        toolBarIcons: {
            color: theme.palette.text.secondary,
            fontSize: 30,
            '& svg': {
                width: 30,
                height: 30
            }
        }
    });
};
