import React, { PropsWithChildren, useMemo, useDebugValue } from "react";
import { ReplaceType } from "utils";

export type OpenIds = {
    caseId?: string
    cardId?: string
    taskId?: string
}

export type OpenIdsWithCase = ReplaceType<OpenIds, 'caseId', string>

export const OpenIdsContext = React.createContext<Readonly<OpenIds>>({});

OpenIdsContext.displayName = "OpenIdsContext";

export const OpenIdsContextProvider = (props: PropsWithChildren<OpenIds>) => {

    const context = useMemo(() : Readonly<OpenIds> => {
        //If the context changes each consumer will be rerendered, so passing the same reference is important
        return Object.freeze({
            caseId: props.caseId,
            cardId: props.cardId,
            taskId: props.taskId
        });
    }, [props.caseId, props.cardId, props.taskId])

    return (
        <OpenIdsContext.Provider value={context}>
                {props.children}
        </OpenIdsContext.Provider>
    );
}