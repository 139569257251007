import React, { useState } from "react";
import { TableRow, TableCell } from "@material-ui/core";
import DragHandleIcon from '@material-ui/icons/DragHandle';

type Props = {
    rowKey: string | number;
    children?: React.ReactNode;
    onDragStart: () => void;
    onDragEnd: () => void;
    onDrop: () => Promise<void>;
    handleCellStyle?: React.CSSProperties;
}

export const DraggableTableRow = (props: Props) => {

    const [draggable, setDraggable] = useState<boolean>(false);

    const onDragEnd = () => {
        setDraggable(false);
        props.onDragEnd();
    }

    const onDragOver = (event: React.DragEvent<HTMLTableRowElement>) => event.preventDefault();

    const onMouseDown = () => setDraggable(true);

    const onMouseUp = () => setDraggable(false);

    return (
        <TableRow key={props.rowKey} hover
            onDragStart={props.onDragStart} 
            onDrop={props.onDrop}
            onDragOver={onDragOver} 
            onDragEnd={onDragEnd}
            draggable={draggable}>
            <TableCell onMouseDown={onMouseDown} onMouseUp={onMouseUp} style={{ cursor: 'pointer', ...props.handleCellStyle }}>
                <DragHandleIcon />
            </TableCell>
            {props.children}
        </TableRow>
    );
}