import React from 'react'
import { TOCHeader } from './text';
import Api from 'server/api'
import { DefaultReportTheme, ReportTheme } from './report-theme'

import MasterDataDto = Api.Cases.Queries.Reports.Es.MasterDataDto;

export type PageContextType = {
    masterData: MasterDataDto,
    conditionRatingColors: { rating: number, color: string }[]
    isCompleted: boolean,
    headers: TOCHeader[],
    reportTitle: string
}

export const PageContext = React.createContext<PageContextType>({
    masterData: null,
    conditionRatingColors: [],
    isCompleted: null,
    headers: [] as TOCHeader[],
    reportTitle: null
})

export const ReportThemeContext = React.createContext<ReportTheme>(DefaultReportTheme)