import React, { useState } from "react";
import styles from './styles';
import { makeStyles } from "@material-ui/core";
import { sguid } from "utils";
import { Templates } from "templates";
import Api from "server/api";
import { CreateCardPopup, CardData } from "pages/cards/create-card-popup";

import { ToolbarButton } from './toolbar-button';
import { AddIcon } from "controls/images/custom-icons";
import { useOpenIds, useCommand, decorator, useNavigateTo } from "custom-hooks";

import CreateCardCmd = Api.Cases.Commands.CreateCard;

type Props = {
    templates: Templates;
    caseId: string;
}

const useStyles = makeStyles(styles);

export const CreateCard = (props: Props) => {

    const styles = useStyles();
    const [createCardPopupOpen, setCreateCardPopupOpen] = useState(false);
    const { caseId } = useOpenIds();
    const navigateTo = useNavigateTo();

    const createCard = useCommand((group: string, type: string, data: CardData, templateId: string) => 
        new CreateCardCmd({
            caseId: caseId,
            newCardId: `card/${sguid()}`,
            description: data.description,
            location: data.location,
            group: group,
            type: type,
            templateId: templateId
        }), decorator<CreateCardCmd>(async (cmd, next) => {
            setCreateCardPopupOpen(false);
            const result = await next(cmd);
            navigateTo.card(cmd.newCardId);
            return result;
        })
    )

    return <div>
        <ToolbarButton
            onClick={() => setCreateCardPopupOpen(true)}
            icon={<AddIcon />}
            label='KORT'
            visible={true}
        />

        <CreateCardPopup
            open={createCardPopupOpen}
            onOk={createCard}
            onClose={() => setCreateCardPopupOpen(false)}
            templates={props.templates} />
    </div>
}