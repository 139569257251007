import React from "react";

import { AppBar as MaterialUIAppBar, AppBarProps } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    colorPrimary: {
        backgroundColor: 'white'
    },
    root: {
        borderStyle: 'solid',
        borderImage: 'linear-gradient(135deg, #83E576 0%, #54DFA7 100%) 1 / 0 0 4px 0 ',
        flexDirection: 'row',

        '& > div:first-child': {
            flex: 1
        }
    }
});

export const AppBar = (props: AppBarProps) => {
    const styles = useStyles();
    return <MaterialUIAppBar classes={styles} {...props} />;
}