import React from "react";

import { Button } from "controls/button";
import { Popup } from "controls/popup";

import { DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Typography } from "@material-ui/core";

type Props = {
    missingTypeMappings: { [group: string]: readonly string[] };
    extraTypeMappings: { [group: string]: readonly string[] };
    open: boolean;
    onClose: () => void;
}

const ErrorTable = ({ items, title }: { items: { [group: string]: readonly string[] }, title: string }) => <>
    <Typography variant="h6">{title}</Typography>
    <Table aria-label="simple table">
        <TableHead>
            <TableRow>
                <TableCell component="th">Gruppe</TableCell>
                <TableCell component="th">Typer</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {Object.entries(items).map(([group, types]) => (
                <TableRow key={group}>
                    <TableCell component="td" scope="row">
                        {group}
                    </TableCell>
                    <TableCell component="td" scope="row">
                        {types.map(type => <div key={type}>{type}</div>)}
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
</>

export const TypeMappingsErrorsPopup = (props: Props) => {

    const missingGroups = Object.keys(props.missingTypeMappings);
    const extraGroups = Object.keys(props.extraTypeMappings);
    const hasMissing = missingGroups.length > 0;
    const hasExtra = extraGroups.length > 0;
    const hasError = hasMissing || hasExtra;

    return <>
        {hasError && <Popup
            maxWidth='md'
            fullWidth
            open={props.open}
            onClose={props.onClose}
            onOk={props.onClose}
            onCancel={props.onClose}>

            <DialogTitle>Fejl i konfiguration</DialogTitle>
            <DialogContent>

                {hasMissing && <ErrorTable title='Manglende klassifikationer' items={props.missingTypeMappings} />}

                {hasExtra && <div style={{ marginTop: 30 }} />}
                {hasExtra && <ErrorTable title='Ekstra klassifikationer' items={props.extraTypeMappings} />}

            </DialogContent>

            <DialogActions>
                <Toolbar>
                    <Button
                        label='Luk'
                        onClick={props.onClose} />
                </Toolbar>
            </DialogActions>
        </Popup>}
    </>;
}
