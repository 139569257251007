import React from "react";
import { makeStyles } from "@material-ui/core";
import _ from 'lodash';

const useStyles = makeStyles({
    imageToolbar: {
        zIndex: 1,
        position: 'absolute',
        top: 10,
        right: 10
    }
});

export const ImageToolbar = (props: React.PropsWithChildren<{}>) => {
    const styles = useStyles();

    return (<div className={styles.imageToolbar}>
        {props.children}
    </div>);
}