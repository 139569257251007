import { createStyles, Theme } from "@material-ui/core";
import { buttonGradient, buttonColors } from "theme";


export default (theme: Theme) => createStyles({
    button: {
        background: buttonGradient(buttonColors.default.background1, buttonColors.default.background2),
        boxShadow: '0 0 2px 0 rgba(0,0,0,0.5)',
        width: 168,
        height: 40,
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 'bold',
        color: theme.palette.text.secondary,
        padding: 0,
        textTransform: 'uppercase',
        marginRight: theme.spacing(1),
        '&:hover': {
            color: theme.palette.text.secondary,
            background: buttonGradient(buttonColors.hover.background1, buttonColors.hover.background2)
        },
        '&:last-child': {
            marginRight: 0
        }
    },
    disabled: {
        boxShadow: 'none',
        color: buttonColors.disabled.text,
        background: buttonColors.disabled.background1
    },
    loading: {
        color: buttonColors.loading.text,
        background: buttonColors.loading.background1
    },
    actionButton: {
        margin: 0,
        padding: 0,
        width: 30,
        height: 30,
        minWidth: 0,
        boxShadow: 'none'
    },
    loadingSpinner: {
        color: buttonColors.default.background1,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
});
