import { createStyles, Theme } from "@material-ui/core";

export default (theme: Theme) => createStyles({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(3),
        maxWidth: theme.spacing(100),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    buttonSection: {
        marginTop: theme.spacing(5)
    },
    fillwidth: {
        minWidth: '100%',
        height: 'auto'
    },
    selector: {
        width: '100%',
        marginTop: theme.spacing(2),
        textAlign: 'initial'
    },
    spacer: {
        '@media (max-width: 1700px)': {
            height: theme.spacing(21)
        }
    },
    typeAndGroupContainer: {
        display: 'flex',
        margin: theme.spacing(2),
        alignItems: 'center'
    },
    breadcrumb: {
        flex: 1,
        margin: 0,
        marginLeft: 13
    },
    editButton: {
        boxShadow: '0 0 6px 0 rgba(0,0,0,0.5)'
    },
    editButtonIcon: {
    }
});