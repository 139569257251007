import React from 'react';

import styles from '../styles'
import { makeStyles } from '@material-ui/core/styles';
import { ContactInformation } from './contact-information';

const useStyles = makeStyles(styles);

export const SupportContent = () => {
    const styles = useStyles();
    return (
        <div className={styles.supportContainer}>
            <div className={styles.supportTitle}>Gratis support</div>
            
            <p>
                Send en e-mail eller ring til vores gratis telefon-support, hvis du har brug for hjælp.
            </p>
            <ContactInformation />                                   
        </div>)
}