import React, { PropsWithChildren } from "react";
import { makeStyles } from "@material-ui/core";
import DragIndicator from '@material-ui/icons/DragIndicator';
import cx from 'classnames';
import { useWriteLock } from "custom-hooks";
import { Lockable } from "utils/lockable";

type Props = {} & Lockable;

const useStyles = makeStyles({
    handle: {
        color: '#c0c0c0',
        backgroundColor: '#f4f5f2',
        width: 35,
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'none',
        cursor: 'grab'
    }
});

export const ImageDragHandle = (props: Props) => {
    const styles = useStyles();
    const writeLock = useWriteLock(props);
    
    if (writeLock) return null;

    return (
        <div 
            className={cx(styles.handle, 'ImageDragHandle')}
            onClick={e => e.stopPropagation()} //Dont' active click events when people are draggin' along
        >
            <DragIndicator  />
        </div>
    );
}

