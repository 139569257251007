import React from 'react'
import { Text, Page, Header2, TOCHeader, Markdown, Header1 } from './controls'
import { StyleSheet } from '@react-pdf/renderer';

type Props = {
    purposeText: string,
    background: string,
    caseName: string,
    headers: TOCHeader[],
    isCompleted: boolean
}

const textStyles = StyleSheet.create({
    body: {
        paddingTop: 5,
        paddingLeft: 1,
        paddingBottom: 15
    }
});

export const Introduction = (props: Props) => 
    <Page size="A4" caseName={props.caseName} showWatermark={!props.isCompleted}>
        <Header1 headers={props.headers}>Indledning</Header1>
        <Header2 headers={props.headers}>Formål</Header2>
        <Text style={textStyles.body}>{props.purposeText}</Text>
        <Header2 headers={props.headers}>Baggrund</Header2>
        <Markdown>{props.background}</Markdown>
    </Page>