import { useRef } from "react";
import { HasCommandDecorator, CommandDecorator } from "./use-command";
import { sguid } from "utils";
import { useImmutableArrayState } from "./use-array-state";
import { add, removeById } from "utils/immutable-array";

const isNetworkError = (error: any) : boolean => {
    
    if (!error) return false;

    if (error.hasOwnProperty('statusText') && typeof error.statusText === 'string'
        && error.hasOwnProperty('status') && typeof error.status === 'number' && error.status === 0)
        return true;

    if (error instanceof Error && error.name === 'NetworkError')
        return true;

    return false;
}

type SimplifiedJQueryXHR = Pick<JQueryXHR, 'responseText' | 'status' | 'getResponseHeader'>;
const isJQueryXhrError = (error: any) : error is SimplifiedJQueryXHR => {
    
    if(!error) return false;

    if(!error.hasOwnProperty('responseText') || typeof error.responseText !== 'string')
        return false;
    
    if(!error.hasOwnProperty('status') || typeof error.status !== 'number')
        return false;
    
    return true;
}

const isDvError = (error : unknown) : error is DvError => {
    if(!error) return false;

    if (typeof (error as DvError).message !== 'string') return false;

    if (typeof (error as DvError).originalError === 'undefined') return false;

    if(typeof (error as DvError).id !== 'string') return false;

    return true;
}

export interface DvError {
    id: string;
    message: string;
    originalError: unknown;
    tag: string;
}

const dvError = (message: string, originalError: any, tag: string): DvError => ({
    id: sguid(),
    message,
    originalError,
    tag
})

export const toDvError = (error: unknown, tag: string = 'Unspecified'): DvError => {

    if (isDvError(error))
        return error;

    if (isJQueryXhrError(error)) {           
        if(error.status >= 400 && error.status < 500)
            return dvError(error.responseText, error, tag);
        
        if(error.status === 504 && (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'))
            return dvError('Gateway Timeout. Er backenden kørende?', error, tag);
        
        if(error.status >= 500)
            return dvError(`Der skete en uventet fejl på serveren: ${error.status}.`, error, tag);
    }

    if (isNetworkError(error))
        return dvError("Der skete en netværksfejl. Har du forbindelse til internettet?", error, tag);

    if (error instanceof Error)
        return dvError(`Der skete en uventet fejl: ${error.message}`, error, tag);

    if (typeof error === 'string')
        return dvError(error, error, tag);
    
    return dvError('Der skete en uventet fejl.', error, tag);
}

export type ErrorDisplay = { 
    add: (e: unknown, tag?: string) => DvError|false,
    clear: () => void,
    errors: readonly DvError[],
    remove: (errorId: string|DvError) => void;
} & HasCommandDecorator

export type ErrorFilter = (error: DvError) => boolean;

export const any = (...filters: ErrorFilter[]): ErrorFilter => error => filters.some(filter => filter(error));
export const all = (...filters: ErrorFilter[]): ErrorFilter => error => filters.every(filter => filter(error));
export const isCommandError: ErrorFilter = (error: DvError) => isJQueryXhrError(error.originalError) && !!error.originalError.getResponseHeader('x-command-error')
export const hasTag = (tag: string): ErrorFilter => (error: DvError) => error.tag === tag;
export const includeAllErrors = (error: DvError) => true;

export const useErrorDisplay = <T extends any>(
    filter?: ErrorFilter, fadeAfter? : number) : ErrorDisplay => {
    
    filter = filter || isCommandError;

    const [errors, setErrors] = useImmutableArrayState<DvError>()
    const timeouts = useRef({} as { [errorId: string]: number })

    const removeError = (errorHandle: string|DvError) => {
        let id = typeof errorHandle === 'string' 
            ? errorHandle 
            : errorHandle.id;
        
        setErrors(removeById(id))
        self.clearTimeout(timeouts.current[id])
    }

    const addError = (error: unknown, tag?: string) : DvError | false => {

        const dvError = toDvError(error, tag);
        if (!filter(dvError)) return false;

        setErrors(add(dvError))

        if (fadeAfter) {
            timeouts.current[dvError.id] = self.setTimeout(() => removeError(dvError.id), fadeAfter)
        }

        return dvError;
    }

    const clearErrors = () => {
        setErrors([]);

        Object.values(timeouts).forEach(timeout => self.clearTimeout(timeout))
        timeouts.current = {};
    }

    const errorDisplay: ErrorDisplay = {
        decorator: (async (command : Command, next : (command: Command) => Promise<CommandResult>) => {
            clearErrors();

            //there is some reason we are using catch method instead of async-function with a try/catch block, 
            //but none of us can remember what that reason is
            return next(command)             
                .catch(e => { if (!addError(e)) throw e; });
        }) as CommandDecorator,
        add: addError,
        remove: removeError,
        clear: clearErrors,
        errors: errors
    };

    return errorDisplay;
}
