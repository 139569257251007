import * as React from 'react'
import { View } from '@react-pdf/renderer';
import { Text as Text, Header1, Header3 } from '../text'
import { Divider, VerticalSpacer } from '../controls';
import { Table, Row } from 'pages/report/controls/table'
import Api from 'server/api'
import _ from 'lodash';
import { Annotations, Condition, FactBox, Prices } from '../guide-page'

import Es = Api.Cases.Queries.Reports.Es;
import GuidePageDto = Es.GuidePageDto;

const Guide = (props: { durationYears: number, customerName: string, inspectionDate: string }) =>
    <View>
        <Text>
            Nærværende rapport gennemgår den overordnede vedligeholdelsesmæssige stand af ejendommen.
        </Text>
        <VerticalSpacer height="small"/>
        <Text>
            Rapportens tekniske vurdering, anbefalinger og anslåede udgifter hertil er samlet i en 
            vedligeholdelsesplan for de kommende {props.durationYears} år. Denne supplerer 
            tilstandsvurderingen.
        </Text>
        <VerticalSpacer height="small"/>
        <Text>
            Rapporten ser hovedsageligt på vedligeholdelsesarbejder, der er foreningens ansvar, og tager 
            generelt ikke hensyn til vedligeholdelsesarbejder, der påhviler den enkelte ejer/lejer af de 
            enkelte boliger.
        </Text>
        <VerticalSpacer height="small"/>
        <Text>
            Vedligeholdelsesplanens forslag og prisoverslag må ikke anses som endeligt tilbud eller færdigt 
            budget.
        </Text>
        <VerticalSpacer height="small"/>
        <Header3>Baggrund</Header3>
        <Text>
            {props.customerName ?? "Kunden"} har bedt MMAKE – Ingeniører og Bygningskonstruktører om at gennemgå ejendommen med 
            henblik på vurdering af ejendommens stand og udarbejdelse af en {props.durationYears}-års vedligeholdelsesplan.
        </Text>
        <VerticalSpacer height="small"/>
        <Text>
            Målet med vedligeholdelsesplanen er at give {props.customerName ?? "kunden"} et brugbart redskab til planlægning og 
            prioritering af ejendommens fremtidige vedligeholdelses- og renoveringsarbejde.
        </Text>
        <VerticalSpacer height="small"/>
        <Header3>Metode</Header3>
        <Text>
            MMAKE har {props.inspectionDate ? props.inspectionDate + " " : ""}visuelt inspiceret ejendommen sammen med repræsentant(er) 
            fra ejendommen.
        </Text>
        <VerticalSpacer height="small"/>
        <Text>
            På udvalgte bygningsdele er den visuelle registrering suppleret med fugtmålinger med 
            indstiksmåler for vurdering af bygningsdeles fugtindhold.
        </Text>
        <VerticalSpacer height="small"/>
        <Text>
            Der er som udgangspunkt ikke udført destruktive undersøgelser af bygningsdele, medmindre det 
            forinden er specifikt aftalt. I så fald er udfaldet af undersøgelserne beskrevet i afsnit om den 
            pågældende bygningsdel i rapporten.
        </Text>
    </View>

const FactBox1 = () =>
    <FactBox title="Genopretning">
        <Text>
            Når skaden er sket – eller er overhængende – skal der ske en genopretning.
        </Text>
        <VerticalSpacer height="small" />
        <Text>
            Eksempel: Hvis et vindue er punkteret, skal det udskiftes, eller hvis der er hul i taget, skal det repareres.
        </Text>
    </FactBox>

const FactBox2 = () =>
    <FactBox title="Løbende vedligehold">
        <Text>
            Opgaver, der bør gennemføres regelmæssigt, så nye skader undgås, eller for at optimere brugen af bygningen.
        </Text>
        <VerticalSpacer height="small" />
        <Text>
            Eksempel: Løbende maling af vinduer hvert 6.-8. år for at forebygge trænedbrydning.
        </Text>
    </FactBox>

export const MmakeGuidePage = (props: { data: GuidePageDto }) =>
    <View>
        <View style={{ width: 250 }}>
            <Header1>Indledning</Header1>
        </View>
        <Table colWidths={[300, 200]}>
            <Row>
                <View>
                    <Guide
                        durationYears={props.data.durationYears}
                        customerName={props.data.extensions.mmake.customerCompany}
                        inspectionDate={props.data.extensions.mmake.inspectionDate} />
                    <Divider size={'thin'} margin={'large'} />
                    <Condition conditionRatings={props.data.conditionRatings} />
                    <Divider size={'thin'} margin={'large'} />
                    <Annotations />
                    <Divider size={'thin'} margin={'large'} />
                    <Prices indexYear={props.data.indexYear} priceIndexInPercentage={props.data.priceIndexInPercentage} includeVat={props.data.includeVat}/>
                </View>
                <View style={{ marginLeft: 20 }} >
                    <FactBox1/>
                    <VerticalSpacer height="large" />
                    <FactBox2/>
                </View>
            </Row>
        </Table>
    </View>