import { useState, SetStateAction, useCallback } from "react"
import { emptyImmutableArray, toImmutable } from "utils/immutable-array";


export const useImmutableArrayState = <T>(initialValue?: readonly T[]|null)
    : [readonly T[]|null, typeof set] => {
    
    const [array, setArray] = useState<readonly T[]|null>(() => {
        if(initialValue === undefined || Array.isArray(initialValue) && initialValue.length === 0)
            return emptyImmutableArray;
        return toImmutable(initialValue);
    });

    const set = useCallback((newArray: SetStateAction<readonly T[]|null>) => {
        if(Array.isArray(newArray))
            setArray(toImmutable(newArray))
        else if(typeof newArray === 'function')
            setArray(s => toImmutable(newArray(s)))
        else if(newArray === null)
            setArray(null)
    }, [setArray]);

    return [array, set];
}