import React, { useState } from "react";
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Toolbar } from "@material-ui/core";
import { Button } from "controls/button";
import { Popup } from 'controls/popup';
import { after, disableWhileRunningDecorator, pipe, useCommand, useCommandSubscription, useLoadingState, useServer } from "custom-hooks";
import { sguid } from "utils";
import { FormField } from "controls/field/form-field";
import { SearchableTextField } from "controls/field/searchable-text-field";

import Api from "server/api";
import ImportTypeMappings = Api.Users.Commands.ImportTypeMappings;
import ImportableTypeMappings = Api.Users.Queries.GetImportableTypeMappings.ImportableTypeMappings;

type Props = {
    companyId: string;
    open: boolean,
    setOpen: (open: boolean) => void;
    canExecute: (name: string) => boolean;
}

type SelectedType = { label: string, value: string } | null

const disableWhileRunning = disableWhileRunningDecorator();

export const ImportTypeMappingsPopup = (props: Props) => {

    const [typeMappings, setTypeMappings] = useState<ImportableTypeMappings>(null);
    const [selected, setSelected] = useState<SelectedType>(null);
    const [name, setName] = useState<string>(null);

    const server = useServer();

    const close = () => props.setOpen(false);

    const loadingState = useLoadingState();

    const createTypeMapping = useCommand(() => new ImportTypeMappings({
        oldTypeMappingsId: selected.value,
        newTypeMappingsId: `mappings/${props.companyId}/${sguid()}`,
        name: name
    }), pipe(disableWhileRunning, after(async () => close()), loadingState))

    useCommandSubscription(() => {
        server.query<ImportableTypeMappings>(
            new Api.Users.Queries.GetImportableTypeMappings({})
        ).subscribe(x => setTypeMappings(x));
    }, [props.companyId]);

    const onOk = () => {
        if (!props.canExecute(name)) return;
        createTypeMapping();
    }

    const getOptions = (mappings: ImportableTypeMappings) =>
        mappings && (
            [mappings.systemTypeMappings && { label: 'system', options: mappings.systemTypeMappings.map(x => ({ label: x.name, value: x.id })) },
            mappings.companyTypeMappings && { label: 'intern', options: mappings.companyTypeMappings.map(x => ({ label: x.name, value: x.id })) }])

    return (
        <Popup
            maxWidth='md'
            fullWidth
            open={props.open}
            onClose={close}
            onOk={onOk}
            onCancel={close}>

            <DialogTitle>
                Importér konfiguration af klassifikationer
            </DialogTitle>

            <DialogContent>
                <DialogContentText>Vælg konfiguration</DialogContentText>

                <SearchableTextField
                    label="Type"
                    value={selected}
                    options={getOptions(typeMappings)}
                    placeholder="Vælg type..."
                    autoFocus
                    onChange={(value: SelectedType) => {
                        setSelected(value);
                        setName(value.label);
                    }}
                />

                <FormField
                    type='text'
                    label='Ny navn'
                    value={name}
                    onValidChange={setName}
                />

            </DialogContent>

            <DialogActions>
                <Toolbar>
                    <Button label='Fortryd' onClick={close} />
                    <Button
                        label='Gem'
                        loading={loadingState.loaderVisible}
                        onClick={onOk}
                        disabled={!props.canExecute(name)} />
                </Toolbar>
            </DialogActions>
        </Popup>);
}