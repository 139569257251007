import Api from "server/api";
import { TableRow, TableCell, withStyles, WithStyles } from "@material-ui/core";
import React from 'react';
import styles from './styles'
import { ext } from "utils";

type Props = {
    budget: Api.Cases.Queries.GetBudget.Budget;
} & WithStyles<typeof styles>

export const HeaderRow = withStyles(styles)((props: Props) => {
    return (
        <TableRow>
            <TableCell></TableCell>
            <TableCell>Placering</TableCell>
            <TableCell>Opgave</TableCell>
            {ext(props.budget.extensions, 'PriorityHeader', ({ header }) => <TableCell className={props.classes.headerRowPriorityColumn}>{header}</TableCell>)}
            {props.budget.years.map((value, index) => <TableCell key={index} className={props.classes.yearColumnHeader}>{value}</TableCell>)}
        </TableRow>
    );
});