import React, { forwardRef } from 'react';
import Component from 'component';
import Api from 'server/api';
import { DvError, ErrorFilter, toDvError } from 'custom-hooks';

import { TextField, PropTypes, Tooltip } from '@material-ui/core';
import { InputLabelProps } from '@material-ui/core/InputLabel';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import CalendarTodaySharp from '@material-ui/icons/CalendarTodaySharp'

import { Maybe } from 'utils/maybe';
import { match, sguid } from 'utils';

import LocalDate = Api.Cases.LocalDate

export type FieldType = 'number' | 'date' | 'text' | 'password';

export type TypeSelector<T extends FieldType> = (
    T extends 'date' ? LocalDate
    : T extends 'number' ? number
    : string
) | null

export type AutoProps<TProps> = {
    field: Api.DV.Application.FieldInfo<Date | number | string | {} | null>
} & Omit<TProps, 'value'>

export type FieldInfo = {
    icon: JSX.Element,
    info: React.ReactNode
}

export const isAuto = <TProps,>(props: TProps | AutoProps<TProps>)
    : props is AutoProps<TProps> =>
    (props as AutoProps<TProps>).field !== undefined

export type Props<T, U> = {
    type: T,
    label?: string,
    placeholder?: string,
    value: U,
    readonly?: boolean,
    optional?: boolean,
    multiline?: boolean,
    rowsMax?: string | number,
    autoFocus?: boolean,
    variant?: 'standard' | 'filled' | 'outlined',
    margin?: PropTypes.Margin,
    onChange?: (value: Maybe<U>) => void | Promise<void>,
    onBlur?: (value: Maybe<U>) => void | Promise<void>,
    onValidChange?: (value: U) => void | Promise<void>,
    onValidBlur?: (value: U) => void | Promise<void>,
    onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>, value: U) => void,
    inputLabelProps?: Partial<InputLabelProps>
    tooltip?: string
    errors?: readonly DvError[]
    errorFilter?: ErrorFilter
    showError?: boolean
    showRequired?: boolean
    transformValue?: (value: Maybe<U>, label: string) => Maybe<U>
    disabled?: boolean
    fieldInfo?: FieldInfo
}

type State = {
    rawValue: string
}

export default class Field<T extends FieldType> extends Component<Props<T, TypeSelector<T>>, State> {

    static defaultProps = {
        config: 'form'
    }

    private readonly dateFormat = 'dd-MM-yyyy';

    private input: HTMLInputElement = null;
    private labelId;

    constructor(props: Props<T, TypeSelector<T>>) {
        super(props);

        this.state = { rawValue: this.toRawValue(props.value) };
        this.labelId = sguid();
    }

    validatePropValue = (props: Props<T, TypeSelector<T>>) => {
        if (DEV) {
            if (props.value == null) return;

            match(this.props.type)
                .on("date", (type: 'date') => { if (!this.isValidDate(props.value)) throw new Error(`Received a non-Date in '${type}'-Field ('${props.value}', ${typeof props.value})`); })
                .on("number", (type: 'number') => { if (typeof props.value !== 'number') throw new Error(`Receive a non-number value in '${props.type}'-Field ('${props.value}', ${typeof props.value}).`); })
                .on("text", (type: 'text') => { if (typeof props.value !== 'string') throw new Error(`Received a non-string in a '${props.type}' Field ('${props.value}', ${typeof props.value}).`) })
                .on("password", (type: 'password') => { if (typeof props.value !== 'string') throw new Error(`Received a non-string in a '${props.type}'-Field. Typeof value: '${typeof props.value}'`); })
                .orThrow();
        }
    }

    //NOTE: this deprecated and the old (non UNSAFE_) version will be removed in React 17. We should refactor this.
    UNSAFE_componentWillReceiveProps(nextProps: Props<T, TypeSelector<T>>) {

        if (this.props.type !== nextProps.type) throw new Error('Type of Field cannot be changed after construction.');

        this.validatePropValue(nextProps);

        const newRawValue = this.toRawValue(nextProps.value);

        if (this.state.rawValue == newRawValue) return;

        const initialRawValue = this.toRawValue(this.props.value);

        if (document.activeElement != this.input || this.state.rawValue == initialRawValue) {
            this.setState({ rawValue: newRawValue });
        }
    }

    render() {
        return (
            <>
                <Tooltip arrow={true} disableFocusListener disableHoverListener={!this.props.tooltip} title={this.props.tooltip || ''} placement='top'>
                    {this.props.type === 'date' ? <this.DatePickerWithProps /> : <this.TextFieldWithProps />}
                </Tooltip>
            </>
        );
    }
    
    private TextFieldWithProps = forwardRef((_, ref: any) => {
        if (DEV && this.props.type === 'date') throw new Error('TextFieldWithProps called with date value. Use DatePickerWithProps instead.');

        return <TextField
            required={this.props.showRequired}
            ref={ref}
            inputRef={ref => this.input = ref}
            variant={this.props.variant}
            margin={this.props.margin}
            fullWidth
            placeholder={this.props.placeholder}
            error={this.hasError()}
            label={this.props.label}
            value={this.state.rawValue ?? ''}
            type={this.props.type === 'number' ? 'text' : this.props.type}
            autoFocus={this.props.autoFocus}
            disabled={this.props.disabled}
            multiline={this.props.multiline}
            rowsMax={this.props.rowsMax}
            InputProps={{
                inputProps: {
                    spellCheck: true,
                    "aria-labelledby": this.labelId,
                    readOnly: this.props.readonly,
                    // Default size is 16px, but due to a bug with using Open Sans font, it gives a 1px scroll-bug in text fields.
                    style: { fontSize: 15 }
                }
            }}
            onChange={e => this.updateRawValueAndNotify(e.target.value)}
            onBlur={() => this.setState(s => ({rawValue: this.toRawValue(s.rawValue)}), () => this.notify(this.props.onBlur, this.props.onValidBlur)) }
            onKeyDown={this.props.onKeyDown && (e => this.props.onKeyDown(e, this.getTypedValue(this.state.rawValue).validOrThrow()))}
            InputLabelProps={{
                id: this.labelId,
                // Fixes issue with some field's shrink not updating correctly
                // Returning undefined on no value is important, in order to shrink on focus events (without explicitly implementing that)
                shrink: !!this.props.value || !!this.state.rawValue || undefined, 
                ...this.props.inputLabelProps
            }}                
            helperText={this.helperText()}
        />
    })

    onAcceptDate = (date: Date) => {
        if (this.props.type !== 'date') throw new Error(`Cannot accept date when type is '${this.props.type}'.`);
        self.setTimeout(() => this.input.focus(), 0);
    }

    private DatePickerWithProps = forwardRef((_, ref: any) => {
        return <KeyboardDatePicker 
            variant="inline"
            ref={ref}
            inputVariant={this.props.variant}
            margin={this.props.margin}
            fullWidth
            error={this.hasError()}
            keyboardIcon={<CalendarTodaySharp />}
            label={this.props.label}
            style={{
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4
            }}
            autoOk={true}
            onAccept={this.onAcceptDate}
            value={this.rawValueToDate(this.state.rawValue)}
            autoFocus={this.props.autoFocus}
            disabled={this.props.disabled}
            format={this.dateFormat}
            rowsMax={this.props.rowsMax}
            FormHelperTextProps={{
                variant: this.props.variant
            }}
            InputProps={{               
                inputProps: {
                    "aria-labelledby": this.labelId,
                    // Using ref on inputProps, and not inputRef on InputProps, prevents the calendar positioning from messing up, somehow. 
                    // Probably because the datepicker uses the inputRef to do some calculations, but doesn't use this one?
                    ref: (ref: any) => this.input = ref,
                    readOnly: this.props.readonly,
                    // Default size is 16px, but due to a bug with using Open Sans font, it gives a 1px scroll-bug in text fields.
                    style: { fontSize: 15 },
                    placeholder: 'dd-mm-åååå'
                }
            }}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
            onChange={(date: Date, rawValue: string) => this.updateRawValueAndNotify(rawValue)}
            onBlur={() => this.notify(this.props.onBlur, this.props.onValidBlur)}
            onKeyDown={this.props.onKeyDown && (e => this.props.onKeyDown(e, this.getTypedValue(this.state.rawValue).validOrThrow()))}
            InputLabelProps={{ id: this.labelId, shrink: true, ...this.props.inputLabelProps }}                
            helperText={this.helperText()}
        />
    })

    filteredErrors = (): readonly DvError[] => {
        const validationErrors = this.getTypedValue(this.state.rawValue).caseOf<DvError[]>({
            invalid: invalid => invalid.reason !== 'Denne værdi skal udfyldes.' ? [toDvError(invalid.reason)] : [],
            valid: val => []
        });

        const errors = this.props.errors;
        const errorFilter = this.props.errorFilter;

        if (!errors)
            return [...validationErrors];

        const allError = [...errors, ...validationErrors];
        if (!errorFilter)
            return allError;

        return allError.filter(errorFilter)
    } 

    rawValueToDate = (rawValue: string): ParsableDate => {
        if (rawValue == null || rawValue === '') return null; // date picker cannot handle an invalid date with an invalid raw value on init (and that includes '' the empty string)

        return this.maybeRawValueToLocalDate(rawValue)
            .caseOf<ParsableDate>({
                valid: (date) => new Date(date.year, date.month-1, date.day),
                invalid: (_) => "_" //passing anything invalid to the date picker, prevents it from updating.
        });
    };

    rawValueToLocalDate  = (rawValue: string): LocalDate => {
        if (rawValue == null || rawValue === '' || rawValue.length !== this.dateFormat.length) return null;

        const yearIndex = this.dateFormat.indexOf("yyyy");
        const monthIndex = this.dateFormat.indexOf("MM");
        const dayIndex = this.dateFormat.indexOf("dd");

        const year = rawValue.substring(yearIndex, yearIndex+4);
        const month = rawValue.substring(monthIndex, monthIndex+2);
        const date = rawValue.substring(dayIndex, dayIndex+2);

        return { year: Number(year), month: Number(month), day: Number(date) };
    };

    maybeRawValueToLocalDate = (rawValue: string): Maybe<LocalDate> => {
        return Maybe.of(rawValue)
            .require(x => x.indexOf('_') === -1, "Denne værdi skal udfyldes.")
            .map<LocalDate>((rawValue) => this.rawValueToLocalDate(rawValue))
            .require(date => this.isValidDate(date), 'Ugyldig dato.');
    }

    helperText = (): string|undefined =>  {
        const filteredErrors = this.filteredErrors();
        return filteredErrors.length > 0 ? filteredErrors[0].message : undefined;
    }

    hasError = () => {
        if (this.props.showError) return true;

        if (this.props.readonly) return false;

        if (this.filteredErrors().length > 0) return true;

        if (!this.props.optional && (this.state.rawValue === '' || this.state.rawValue == null)) return true;

        if (this.getTypedValue(this.state.rawValue).isInvalid()) return true;

        return false;
    }

    getTypedValue = (rawValue: string): Maybe<TypeSelector<T>> => {
        const maybe = () => {
            if (this.props.readonly || rawValue === '' || rawValue == null) return Maybe.valid(null);

            return match(this.props.type)
                .on("date",     () => this.maybeRawValueToLocalDate(rawValue))
                .on("number",   () => Maybe.of(Number(rawValue?.replace(',', '.')))
                                    .require(value => !Number.isNaN(value) && Number.isFinite(value), "Ugyldigt tal.") )
                .on("text",     () => Maybe.of(rawValue))
                .on("password", () => Maybe.of(rawValue))
                .orThrow() as Maybe<TypeSelector<T>>;
        }

        return this.props.transformValue ? this.props.transformValue(maybe(), this.props.label) : maybe();
    }

    notify = async (listener: undefined|((value: Maybe<TypeSelector<T>>) => void | Promise<void>), validValueListener: undefined|((value: TypeSelector<T>) => void | Promise<void>)) => {
        // Not strictly necessary since events don't fire on disabled elements, but if we later use NotifyChange outside of DOM-events, this will prevent dangerous errors
        if (this.props.readonly || this.props.disabled) return;

        const typedValue = this.getTypedValue(this.state.rawValue);

        const promises = [];

        if (this.state.rawValue != this.toRawValue(this.props.value)) {
            if (listener)           promises.push(listener(typedValue)); 
            if (validValueListener) promises.push(typedValue.ifValid(validValueListener));
        }
        
        await Promise.all(promises);
    }

    updateRawValueAndNotify = (rawValue: string) => {
        this.setState({ rawValue: rawValue }, () => this.notify(this.props.onChange, this.props.onValidChange));
    }

    private toRawValue = (value: TypeSelector<T>|string): string => {
        if (value == null) return '';

        return match<string>(this.props.type)
                    .on("date",     () => this.formatDate(value as LocalDate))
                    .on("number",   () => value.toString().replace('.', ','))
                    .on("text",     () => value.toString())
                    .on("password", () => value.toString())
                    .orThrow();
    };

    formatDate = (date: LocalDate) => {
        if (!this.isValidDate(date)) return '';

        return this.dateFormat
            .replace('yyyy', date.year.toString())
            .replace('MM', (date.month < 10 ? "0" : "") + date.month.toString())
            .replace('dd', (date.day < 10 ? "0" : "") + date.day.toString());
    }

    isValidDate = (value: any) => {
        const localDate = value as LocalDate;

        if (localDate == null) return false;

        const date = new Date(localDate.year, localDate.month-1, localDate.day);

        if (date.getFullYear() !== localDate.year ||
            date.getMonth() + 1 !== localDate.month ||
            date.getDate() !== localDate.day) return false;
        
        return true;
    }
}
