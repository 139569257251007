import React from "react";
import { Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Button, withStyles, WithStyles, Theme, createStyles, makeStyles } from "@material-ui/core";
import { LighterThemeProvider } from "theme";

const styles = () => createStyles({
    block: {
        border: '1px solid #CACBC4',
        marginTop: 10,
        marginBottom: 10,
        padding: 12,
        backgroundColor: '#F4F5F2'
    },
    blocks: {
        marginTop: 25
    }
})

type Props = {
    onOk: (event: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<unknown>;
    onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    isOpen: boolean;
    title: string;
    message: string;
    additionalMessages?: string[];
    okText: string;
    cancelText: string
}

const useStyles = makeStyles(styles);

export const WarningPopup = (props: Props) => {

    const classes = useStyles();

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.message}
                </DialogContentText>
                {
                    props.additionalMessages &&
                    <div className={classes.blocks}>
                        {props.additionalMessages.map((x, index) =>
                            <div className={classes.block} key={index}>
                                <DialogContentText>{x}</DialogContentText>
                            </div>
                        )}
                    </div>
                }
            </DialogContent>
            <DialogActions>
                <LighterThemeProvider>
                    <Button onClick={props.onClose} color="primary">
                        {props.cancelText}
                    </Button>
                    <Button onClick={props.onOk} color="primary">
                        {props.okText}
                    </Button>
                </LighterThemeProvider>
            </DialogActions>
        </Dialog>
    );
}