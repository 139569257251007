import React from "react";
import { sguid } from "utils";
import Api from "server/api";

import { ToolbarButton } from './toolbar-button';
import { AddBuildingIcon } from 'controls/images/custom-icons'
import { useCommand, useOpenIds } from "custom-hooks";

export const CreateBuilding = () => {

    const { caseId } = useOpenIds();
    
    const createBuilding = useCommand(() => 
        new Api.Cases.Commands.CreateBuilding({
            caseId: caseId,
            buildingId: `building/${sguid()}`
        }));

    return <div>
        <ToolbarButton
            label='BYGNING'
            icon={<AddBuildingIcon />}
            onClick={createBuilding}
            visible={true}
        />
    </div>
}