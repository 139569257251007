import React, { useState } from "react";
import { DialogTitle, DialogContent, DialogActions, Grid, makeStyles } from "@material-ui/core";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Field from "controls/field";
import { Button } from "controls/button";
import {Popup} from 'controls/popup';
import {ImageToolbar} from "./image-toolbar";
import { ImageToolButton } from "./image-tool-button";
import { Image } from "./image-tiles";
import { IconButton } from "../button/icon-button";

type Props = {
    onClose: () => void;
    rotateLeft: (image: Image) => Promise<void>;
    rotateRight: (image: Image) => Promise<void>;
    update: <TKey extends keyof Image>(image: Image, name: TKey, value: Image[TKey]) => Promise<void>;
    previousImage: () => void;
    nextImage: () => void;
    confirmDeleteImage: (image: Image) => void;
    allowDescription: boolean;
    image: Image;
}

const useStyles = makeStyles({
    previewImage: {
        maxHeight: '60vh',
        minWidth: 200,
        maxWidth: '100%',        
    }
});

export const ImagePreviewPopup = (props: Props) => {

    if (!props.image) return null;

    const styles = useStyles();

    return (
        <Popup
            maxWidth={'lg'}
            fullWidth={true}   
            open={props.image != null}
            onClose={() => props.onClose()}
            onKeyDown={(e) => {
                if (!props.image) return;

                switch (e.key) {
                    case 'Enter':
                    case 'Escape':
                        props.onClose();
                        break;
                    case 'ArrowLeft':
                        props.previousImage();
                        break;
                    case 'ArrowRight':
                        props.nextImage();
                        break;
                    default:
                        break;
                }
            }}
        >

            <DialogTitle>Rediger billede</DialogTitle>

            <DialogContent>
                <Grid container direction='row' alignItems='stretch' justify='space-between' wrap='nowrap' className='ENSTORTEST'>
                    {/* Left grid */}
                    <Grid item style={{ display: 'flex'}}>
                        <IconButton color='primary' onClick={props.previousImage}>
                            <NavigateBeforeIcon />
                        </IconButton>
                    </Grid>

                    {/* Content grid */}
                    <Grid item>
                        <Grid container direction='column' wrap='nowrap'>
                            <Grid item style={{position: 'relative'}}>
                                <img src={'/api/' + props.image.url + '&width=1024&height=768'} className={styles.previewImage} />
                                <ImageToolbar>
                                    <ImageToolButton tooltip='Roter til venstre' onClick={() => props.rotateLeft(props.image)}>
                                        <RotateLeftIcon />
                                    </ImageToolButton>
                                    <ImageToolButton tooltip='Roter til højre' onClick={() => props.rotateRight(props.image)}>
                                        <RotateRightIcon />
                                    </ImageToolButton>
                                    <ImageToolButton tooltip='Hent' href={'/api/' + props.image.url} download={props.image.filename}>
                                        <SaveAlt />
                                    </ImageToolButton>
                                    <ImageToolButton tooltip='Slet' onClick={() => 
                                        props.confirmDeleteImage(props.image)
                                    }>
                                        <DeleteIcon />
                                    </ImageToolButton>
                                </ImageToolbar>
                            </Grid>

                            {props.allowDescription && <Grid item>
                                <Field
                                    type='text'
                                    label='Beskrivelse'
                                    value={props.image.description}
                                    onValidBlur={(value) => props.update(props.image, 'description', value)}
                                    onKeyDown={(e, value) => {
                                        //We dont want arrow keys to go to next/previous image when in text field
                                        if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                                            e.stopPropagation();
                                        }

                                        if (e.key === 'Enter') props.update(props.image, 'description', value);
                                    }}
                                    autoFocus={true}
                                />
                            </Grid>}
                        </Grid>
                    </Grid>

                    {/* Right grid */}
                    <Grid item style={{ display: 'flex' }}>
                        <IconButton color='primary' onClick={props.nextImage}>
                            <NavigateNextIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Grid container justify='flex-end' spacing={1}>
                    <Grid item>
                        <Button label='Luk' onClick={props.onClose} />
                    </Grid>
                </Grid>
            </DialogActions>
        </Popup>);
}