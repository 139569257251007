import React from "react";
import Api from "server/api";

import { useOpenIds, useNavigateTo } from "custom-hooks";

import { makeStyles } from "@material-ui/core/styles";
import styles from './styles';

import { GroupRow } from "./group-row";
import { TaskRow } from "./task-row";
import { PercentageTaskRow } from "./percentage-task-row";
import { SubTotalRow } from "./sub-total-row";

import GroupLine = Api.Cases.Queries.GetBudget.GroupLine
import TaskLine = Api.Cases.Queries.GetBudget.TaskLine

const useStyles = makeStyles(styles);

export const Group = (props: { 
    budget: Api.Cases.Queries.GetBudget.Budget,
    group: GroupLine, 
    collapsed: boolean,
    addedBudgetIds: { [taskId: string]: string },
    onOpenEditTaskPopup: (taskId: string) => void,
    onUpdateLine: (group: TaskLine) => void,
    onClickRow: (group: GroupLine) => void }) => {

    const styles = useStyles();
    const { caseId } = useOpenIds();
    const navigateTo = useNavigateTo();

    const { group } = props;

    return <>
        <GroupRow
            budget={props.budget}
            className={styles.groupRow}
            group={group}
            onClick={() => props.onClickRow(group)}
            collapsed={props.collapsed}
        />
        {props.collapsed || group.taskLines.map((task, i) => {
            switch(task.tag) {
                case 'TaskLine':
                    return <TaskRow
                        key={`task-row-${i}`}
                        caseId={caseId}
                        line={task}
                        updateLine={props.onUpdateLine}
                        isNewest={!!props.addedBudgetIds[task.id]}
                        gotoCard={() => task.cardId ? navigateTo.card(task.cardId) : props.onOpenEditTaskPopup(task.id)}
                        gotoTask={() => task.cardId ? navigateTo.task(task.id, task.cardId) : props.onOpenEditTaskPopup(task.id)}
                    />
                case 'PercentageTaskLine': 
                    return <PercentageTaskRow key={`task-row-${i}`} budget={props.budget} line={task} />
                case 'SubTotalTaskLine':
                    return <SubTotalRow key={`task-row-${i}`} line={task} budget={props.budget} />
                default: throw new Error()
            }
        })}
    </>
}