import Api from "server/api";
import React = require("react");
import { TaskYearCell } from './task-year-cell';
import { useState, useCallback } from "react";

type Props = {
    years: readonly Api.Cases.Price[];
    hover: boolean;
    onMove?: (fromYearOffset: number, toYearOffset: number) => void;
}

export const TaskYearsColumns = (props: Props) => {

    const [isDragging, setIsDragging] = useState(false);
    const [offsetYearBeingDragged, setOffsetYearBeingDragged] = useState(-1);
    const [offsetYearDraggingOver, setOffsetYearDraggingOver] = useState(-1);

    const onDrag = useCallback((offsetYear: number) => {
        setIsDragging(true);
        setOffsetYearBeingDragged(offsetYear);
        if(!props.hover) setOffsetYearDraggingOver(-1);
    }, [props.hover])

    const onDragEnd = useCallback(() => {
        setIsDragging(false);
        setOffsetYearBeingDragged(-1);
        setOffsetYearDraggingOver(-1);
    }, []);

    const onDrop = useCallback(async (offsetYear: number) => {
        onDragEnd(); // ensure onDragEnd is invoked for task-year-cell for rerendering of cell previously wrapped in a ToolTip
        props.onMove && props.onMove(offsetYearBeingDragged, offsetYear)
    }, [props.onMove, offsetYearBeingDragged])

    const isDropTarget = (offsetYear: number) =>
        isDragging && // Ensured that only current row is targetable
        offsetYearBeingDragged != offsetYear;

    const onDragOver = (offsetYear: number, event: React.DragEvent<HTMLTableCellElement>) => {
        if (isDropTarget(offsetYear)) {
            event.preventDefault();
            if (offsetYear !== offsetYearDraggingOver) {
                setOffsetYearDraggingOver(offsetYear)
            }                    
        } else if(offsetYear === offsetYearBeingDragged){
            setOffsetYearDraggingOver(-1)
        }
    }

    const startOffset = props.years.findIndex(value => value.indexed != 0);

    return <>{props.years.map((value, offsetYear) => (
        <TaskYearCell 
            key={offsetYear} 
            price={value} 
            hover={props.hover}
            dragProps={{
                isDraggable: offsetYear == startOffset,
                isDropTarget: isDropTarget(offsetYear),
                isDraggingOver: offsetYear === offsetYearDraggingOver,
                onDrag: () => onDrag(offsetYear),
                onDragEnd: onDragEnd,
                onDragOver: (event) => onDragOver(offsetYear, event),
                onDrop: async () => await onDrop(offsetYear)
            }} />))}
        </>;
}