import React from 'react'
import { Image, StyleSheet, View } from '@react-pdf/renderer';
import { Text } from './controls'

const styles = StyleSheet.create({
    footer: {
        position: 'absolute',
        left: 50,
        right: 50,
        bottom: 35,
        flexDirection: 'column'
    },
    image: {
        height: 25
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
    },
    right: {
        justifyContent: 'flex-end'
    },
    companyName: {
        fontFamily: 'arial-bold',
    },
    phoneNumber: {
        marginTop: 15
    }
});

export const Footer = () =>
    <View style={styles.footer} fixed>
        <View style={styles.row}>
            <Image style={styles.image} src="/assets/obh-logo.png"/>
            <View style={styles.right}>
                {/*Subtract one from react-pdf's built-in page number and total page count to exclude frontpage*/}
                <Text render={({ pageNumber, totalPages }) => pageNumber > 1 && `Side ${pageNumber - 1}/${totalPages - 1}`} fixed/>
                <View render={({ pageNumber }) => pageNumber === 1 && <Image style={styles.image} src="/assets/obh-motto.png"/>} />
            </View>
        </View>
    </View>