import React from "react";
import { GridList, makeStyles } from "@material-ui/core";
import { SortableContainer } from 'react-sortable-hoc';
import { Image } from './image-tiles';
import _ from 'lodash';
import { SortableImageTile } from './sortable-image-tile';
import { useWriteLock } from "custom-hooks";
import { Lockable } from "utils/lockable";

const useStyles = makeStyles((theme) => ({
    thumbnails: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: theme.spacing(100) - theme.spacing(4)
    }
}));

type Props = {
    openImage: (index: number) => void,
    markImage: (imageId: string) => Promise<any>,
    unmarkImage: (imageId: string) => Promise<any>,
    confirmDeleteImage: (image: Image) => void,
    images: readonly Image[],
    imageOrders: readonly string[],
    addedImageIds: { [id: string]: string },
    underSorting: boolean,
    allowDescription: boolean
} & Lockable;

export const SortableImageTiles = SortableContainer((props: Props) => {
    const { images, imageOrders, addedImageIds, markImage, unmarkImage, confirmDeleteImage, openImage } = props;

    const styles = useStyles();
    const writeLock = useWriteLock(props);

    return <GridList cellHeight={220} cols={3} spacing={2} className={styles.thumbnails}>
        {imageOrders.map((id, index) => <SortableImageTile
            openImage={openImage}
            confirmDeleteImage={confirmDeleteImage}
            markImage={markImage} 
            unmarkImage={unmarkImage}
            image={images.find(x => x.id === id)} 
            imageIndex={index}
            index={index} 
            key={id}
            isNewest={!!addedImageIds[id]}
            underSorting={props.underSorting}
            allowDescription={props.allowDescription}
            disabled={writeLock}
        />)}
    </GridList>;
});