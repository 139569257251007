import React, { useState, useEffect, useMemo } from "react";
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Toolbar } from "@material-ui/core";
import { FormField } from "controls/field/form-field";
import { SearchableTextField } from "controls/field/searchable-text-field";
import { Button } from "controls/button";
import Api from "server/api";
import {Popup} from 'controls/popup';

import TaskType = Api.Cases.TaskType;
import TaskTemplateDto = Api.Cases.Queries.GetCompanyTemplatesForCase.TaskTemplateDto;

type Props = {
    title: string;
    open: boolean; 
    onOk: (description: string, templateId: string) => Promise<unknown>;
    onClose: () => void;
    templates: readonly TaskTemplateDto[];
}

type OptionType = { label:string, value:string } | null;

export const CreateTaskPopup = (props: Props) => {
    const [description, setDescription] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState<OptionType>(null);
    
    useEffect(() => {
        setDescription('');
        setSelectedTemplate(null);
    }, [props.open]);

    const templates = useMemo(() => props.templates.map(x => ({ label: x.searchTitle, value: x.id, description: x.description })), [props.templates]);

    useEffect(() => {
        if (props.templates.length == 1) setSelectedTemplate(templates[0]);
    }, [props.templates]);

    const create = () => props.onOk(description, selectedTemplate && selectedTemplate.value);

    return (
        <Popup
            fullWidth={true}
            maxWidth='md'
            open={props.open}
            onClose={props.onClose}
            onOk={create}
            onCancel={props.onClose}
            >

            <DialogTitle>{props.title}</DialogTitle>
            
            <DialogContent>
                <DialogContentText>
                    Opret via skabelonerne
                </DialogContentText>
                <SearchableTextField
                    label='Skabelon'
                    options={templates}
                    value={selectedTemplate}
                    autoFocus
                    onChange={(value: any) => {
                        setSelectedTemplate(value);
                        setDescription(value ? value.description : '');
                    }}
                />
                <FormField
                    optional
                    type='text'
                    label='Opgavebeskrivelse'
                    value={description}
                    onValidChange={setDescription}
                />
            </DialogContent>

            <DialogActions>
                <Toolbar>
                    <Button label='Fortryd' onClick={props.onClose} />
                    <Button label='Opret'
                        onClick={create} />
                </Toolbar>
            </DialogActions>
        </Popup>);
}