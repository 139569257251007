import React from 'react'
import { createStyles, Theme, WithStyles, withStyles, Paper as PaperMaterial, Typography, makeStyles } from "@material-ui/core";
import cx from 'classnames'

const useStyles = makeStyles((theme: Theme) => createStyles({
    content: {
        margin: theme.spacing(2), 
    },
    header: {
        textTransform: 'uppercase'
    }
}));

type Props = {
    className?: string,
    children?: React.ReactNode
    header?: string,
}

export const Paper = (props: Props) => {
    const styles = useStyles(props);
    return (
    <PaperMaterial elevation={2} className={cx(styles.content, props.className)}>
        {props.header && <Typography className={styles.header} variant='h6'>{props.header}</Typography>}
        {props.children}
    </PaperMaterial>
    )
}