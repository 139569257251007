import React = require("react");
import { user } from "./utils";
import { createRouter, Route, useImpersonation, createNavigation } from "./router";
import { Server } from "./server";
import { AppBarTab } from 'controls/appBarTab';
import AppBarTabs from 'controls/appBarTabs';
import { AppBar } from 'controls/appbar';
import { CssBaseline } from "@material-ui/core";
import LogoutIcon from '@material-ui/icons/ExitToApp'
import { MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';
import { MainContext } from "./context";
import styles from "./styles";
import { Case } from "./pages/case";
import { Report } from "./pages/report";
import { Completion } from "./pages/completion";
import SettingsIcon from '@material-ui/icons/Settings'
import { Settings } from "./pages/settings";
import { Cases } from "./pages/cases";
import { Budget } from "./pages/budget";
import { Cards } from "./pages/cards";
import { Login } from "./pages/login";
import { NewCompanyBySelfRegistration } from "./pages/new-company-by-self-registration";
import { RequestResetPassword } from "./pages/request-reset-password";
import { ResetPassword } from "./pages/reset-password";
import ApproveGdpr from "./pages/approve-gdpr";
import { theme } from "./theme";
import { useState, useEffect } from "react";
import { useServer, useErrorDisplay } from "custom-hooks";
import { errors } from 'global-errors'
import { asapScheduler } from 'rxjs'
import { observeOn } from 'rxjs/operators'
import { ErrorPanel } from "controls/global-error-panel";
import { RootErrorBoundary } from "./root-error-boundary";
import { OpenIdsContextProvider } from "./context/open-ids-context";
import { Tooltip } from "./controls/tooltip";
import { DateUtilsProvider } from "./utils/dates";
import { LoadCaseData } from "./load-case-data";
import { IconButton } from "./controls/button/icon-button";

export type Authentication = {
    authenticated: boolean,
    companyId: string,
    profileId: string,
    isSystemAdmin: boolean,
    isCompanyAdmin: boolean,
    isImpersonating: boolean,
    isGdprApproved: boolean
}

const history = createBrowserHistory();
const server = new Server(useImpersonation);
const navigation = createNavigation(history);

const useRoutePages = () : [{current: Route | null, previous: Route | null}, (next : Route) => void] => {
    const [routePages, setRouteInfo] = useState<{current: Route | null, previous: Route | null}>({
        current: null,
        previous: null
    });

    const nextPage = (next : Route) => setRouteInfo(s => ({
        previous: s.current,
        current: next
    }));

    return [routePages, nextPage];
}

const router = createRouter({

    '/': { 'cases': ({caseTypes}) => (
        <OpenIdsContextProvider>
            <Cases caseTypes={caseTypes} />
        </OpenIdsContextProvider>) },

    '/cases/:caseId(case/[^/]+)/:cardId(card/[^/]+)?/:taskId(task/[^/]+)?': {
        'cases': ({caseId, caseTypes}) => <OpenIdsContextProvider caseId={caseId}><Cases caseTypes={caseTypes} /></OpenIdsContextProvider>
    },

    '/details/:caseId(case/[^/]+)/:cardId(card/.+?)?/:taskId(task/.+?)?': {
        'details': ({caseId, typeMappings, updateSettings, templates}) => (
            <OpenIdsContextProvider caseId={caseId}>
                <Case typeMappings={typeMappings} updateSettings={updateSettings} templates={templates} />
            </OpenIdsContextProvider>
        )
    },
    '/cards/:caseId(case/[^/]+)/:cardId(card/[^/]+)?/:taskId(task/[^/]+)?': {
        'cards': ({caseId, cardId, taskId, templates, conditionRatings, priorities}) => (
            <OpenIdsContextProvider 
                caseId={caseId} 
                cardId={cardId} 
                taskId={taskId}>
                    <Cards 
                        templates={templates}
                        availableConditionRatings={conditionRatings} 
                        priorities={priorities} />
            </OpenIdsContextProvider>
            )
    },
    '/budget/:caseId(case/[^/]+)/:cardId(card/[^/]+)?/:taskId(task/[^/]+)?': {
        'budget': ({caseId, cardId, taskId, templates, priorities}) => (
            <OpenIdsContextProvider 
                caseId={caseId} 
                cardId={cardId} 
                taskId={taskId}>
                    <Budget priorities={priorities} templates={templates} />
            </OpenIdsContextProvider>
        )
    },

    '/report/:caseId(case/[^/]+)/:cardId(card/[^/]+)?/:taskId(task/[^/]+)?': {
        'report': ({ caseId, authentication }) => (
            <OpenIdsContextProvider caseId={caseId}>
                    <Report authentication={authentication} />
            </OpenIdsContextProvider>
        )
    },

    '/completion/:caseId(case/[^/]+)/:cardId(card/[^/]+)?/:taskId(task/[^/]+)?': {
        'completion': ({caseId}) => (
        <OpenIdsContextProvider caseId={caseId}>
                <Completion />
        </OpenIdsContextProvider>)
    },

    '/settings': { 'settings': ({authentication}) => <Settings authentication={authentication} /> }
});

const useStyles = makeStyles(styles);

const Content = () => {
    const server = useServer();
    const styles = useStyles();

    const [authentication, setAuthentication] = useState<Authentication>({
        authenticated: user && user.profileId ? true : false,
        ...user
    });

    const [routePages, setCurrentPage] = useRoutePages();

    useEffect(() => {
        setCurrentPage(router.find(history.location));

        const unlistenHistory = history.listen((location) => {
            setCurrentPage(router.find(location));
        });

        return () => unlistenHistory()
    }, []);

    const errorDisplay = useErrorDisplay(_ => true, 60000);

    useEffect(() => {
        const subscription = errors.pipe(observeOn(asapScheduler)).subscribe((error: any) => {
            errorDisplay.clear();

            if (error && typeof error.status === 'number' && error.status === 401) {
                if (authentication.authenticated) {
                    window.location.reload();
                }
                return;
            }

            errorDisplay.add(error);
        });

        return () => subscription.unsubscribe();
    }, []);

    const gotoPage = (currentPage: Route) => (_: React.ChangeEvent<{}>, name: any) => navigation.goto(router.toUrl(name, currentPage.args));
    
    const logout = () =>
        server.logout(() => {
            setAuthentication({
                authenticated: false,
                companyId: '',
                profileId: '',
                isSystemAdmin: false,
                isCompanyAdmin: false,
                isImpersonating: false,
                isGdprApproved: false
            });

            window.location.href = '/';
        });

    if (routePages.current == null)
        return null;
        
    if (history.location.pathname == "/registercompany") {
        return <NewCompanyBySelfRegistration />
    }

    if (history.location.pathname == "/requestresetpassword") {
        return <RequestResetPassword />
    }

    if (history.location.pathname == "/resetpassword") {
        const token = new URLSearchParams(history.location.search).get('token');
        return <ResetPassword token={token}/>
    }

    if (!authentication.authenticated) {
        const showWelcomeMessage = new URLSearchParams(history.location.search).get('isnewuser');        
        return <Login showWelcomeMessage={showWelcomeMessage === 'true'} />
    }

    if (!authentication.isGdprApproved && authentication.isCompanyAdmin) {
        return <ApproveGdpr server={server} logout={logout} />
    }

    const caseSelected = !!routePages.current.args.caseId;

    return (
        <>
            <CssBaseline />
            <div className={styles.root}>
                <AppBar position="static">
                    <AppBarTabs variant="standard" value={routePages.current.name} onChange={gotoPage(routePages.current)}>
                        <AppBarTab label="Sager" value="cases" />
                        <AppBarTab label="Stamdata" value="details" disabled={!caseSelected} />
                        <AppBarTab label="Kort" value="cards" disabled={!caseSelected} />
                        <AppBarTab label="Budget" value="budget" disabled={!caseSelected} />
                        <AppBarTab label="Rapport" value="report" disabled={!caseSelected} />
                        <AppBarTab label="Afslutning" value="completion" disabled={!caseSelected} />
                    </AppBarTabs>
                    <div>
                        {
                            authentication.isSystemAdmin || authentication.isCompanyAdmin || authentication.isImpersonating
                                ? (
                                <Tooltip title="Indstillinger">
                                    <IconButton onClick={() => navigation.goto('/settings')} className={styles.settingIcon}>
                                        <SettingsIcon className={styles.navbarIcon} />
                                    </IconButton>
                                </Tooltip>)
                                : null
                        }

                        <Tooltip title="Log ud">
                            <IconButton onClick={logout} className={styles.settingIcon}>
                                <LogoutIcon className={styles.navbarIcon} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </AppBar>
                <ErrorPanel 
                    errors={errorDisplay.errors}
                    onRemove={errorDisplay.clear}
                     />
                <LoadCaseData caseId={routePages.current.args.caseId}>
                    {props => <>{routePages.current.render({
                        ...props,
                        authentication
                    })}</>}
                </LoadCaseData>
            </div>
        </>
    );
}

const Root = () => {
    const context = React.useMemo(() => ({ server, navigation }), [server, navigation]);

    return <MuiThemeProvider theme={theme}>
        <DateUtilsProvider>
            <MainContext.Provider value={context}>
                <RootErrorBoundary>
                    <Content />
                </RootErrorBoundary>
            </MainContext.Provider>
        </DateUtilsProvider>
    </MuiThemeProvider>
};
export default Root;