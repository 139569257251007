import React, { useState, useEffect } from "react";
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Toolbar, RadioGroup, Radio, FormControlLabel, makeStyles } from "@material-ui/core";
import styles from './styles'
import { Button } from "controls/button";
import {Popup} from 'controls/popup';
import Api from "server/api";
import { LighterThemeProvider } from "theme";

import CaseTypeDto = Api.Cases.Queries.GetCaseTypes.CaseTypeDto;
import CaseDto = Api.Cases.Queries.GetCases.CaseDto;

export type CaseData = {
    readonly caseType: string
}

type Props = {
    open: boolean;
    onOk: (data: CaseData) => Promise<unknown>;
    onClose: () => void;
    caseTypes: CaseTypeDto[]
    case: CaseDto
}

const useStyles = makeStyles(styles);

export const ChangeCaseTypePopup = (props: Props) => {
    
    const [selectedCaseType, setSelectedCaseType] = useState<string>();

    const styles = useStyles();

    useEffect(() => {
        if (!props.case) return;

        setSelectedCaseType(props.case.type);
    }, [props.case, props.open]);

    const canExecute = () => selectedCaseType && selectedCaseType != props.case.type;

    const execute = () => canExecute() && props.onOk({ caseType: selectedCaseType })

    if (!props.case) return null;

    return (
        <Popup
            maxWidth='md'
            open={props.open}
            onClose={props.onClose}
            onOk={execute}
            onCancel={props.onClose}>

            <DialogTitle>Skift rapporttype</DialogTitle>

            <DialogContent>
                <DialogContentText gutterBottom={true}>
                    Skift rapporttype for sagen '{props.case.name}'
                </DialogContentText>

                <LighterThemeProvider>
                    <RadioGroup aria-label="rapport type" value={selectedCaseType} onChange={(_, value) => setSelectedCaseType(value)}>
                        {(props.caseTypes ?? []).map(caseType =>
                            <FormControlLabel key={caseType.name} value={caseType.name} 
                                label={caseType.displayName + (props.case.type == caseType.name ? " (nuværende)" : "")}    
                                control={<Radio color="primary" style={{ padding: '5px 12px' }} />} />
                        )}
                    </RadioGroup>
                </LighterThemeProvider>
            </DialogContent>

            <DialogActions>
                <Toolbar>
                    <Button label='Fortryd' onClick={props.onClose} />
                    <Button label='Skift' disabled={!canExecute()} onClick={execute} />
                </Toolbar>
            </DialogActions>
        </Popup>);
}