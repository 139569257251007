import Component from "component";
import React from "react";
import { DialogTitle, DialogContent, DialogActions, WithStyles, withStyles, Grid, DialogContentText } from "@material-ui/core";
import styles from './styles'
import {Popup} from 'controls/popup';
import { Button } from "controls/button";
import _ = require("lodash");
import { SearchableTextField } from "controls/field/searchable-text-field";
import { Templates, getGroupedOptions } from "templates";

type Props = {
    currentGroup: string;
    currentType: string;

    onOk: (group: string, type: string) => Promise<void>;
    onClose: () => void;
    open: boolean;
    templates: Templates;
} & WithStyles<typeof styles>

type SelectedType = { label: string, value: string, group: string } | null

type State = {
    selectedType: SelectedType;
}

export default withStyles(styles)(class ChangeCardTypePopup extends Component<Props, State>{
    
    state = { selectedType: null as SelectedType }

    render() {
        const groupedOptions = getGroupedOptions(this.props.templates);

        return (
            <Popup
                maxWidth='lg'
                open={this.props.open}
                onClose={this.props.onClose}
                onOk={this.change}
                onCancel={this.props.onClose}
                onEnter={this.setCurrentGroup}>

                <DialogTitle>Skift typen på kortet</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        Vælg en ny type på listen for at skifte både type og gruppe.
                    </DialogContentText>

                    <SearchableTextField
                        label="Type"
                        value={this.state.selectedType}
                        options={groupedOptions}
                        placeholder="Vælg type..."
                        autoFocus
                        onChange={(value: SelectedType) => this.setState({ selectedType: value })}
                    />
                </DialogContent>

                <DialogActions>
                    <Grid container justify='flex-end' spacing={1}>
                        <Grid item>
                            <Button label='Fortryd' onClick={this.props.onClose} />
                        </Grid>
                        <Grid item>
                            <Button label='Skift' 
                                disabled={!this.canExecute()}
                                onClick={this.change} />
                        </Grid>
                    </Grid>
                </DialogActions>
            </Popup>);
    }

    setCurrentGroup = () => {
        const currentGroup = getGroupedOptions(this.props.templates)
            .find(x => x.label == this.props.currentGroup);

        this.setState({selectedType: currentGroup ? currentGroup.options.find(x => x.value == this.props.currentType) : null});
    }

    canExecute = () => this.state.selectedType;

    change = () => {
        if (!this.canExecute()) return;

        return this.props.onOk(
            this.state.selectedType.group,
            this.state.selectedType.value);
    }
})