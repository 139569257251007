import React from 'react'
import ReactPDF from '@react-pdf/renderer';

export const Square = (props: { hasMore: boolean }) =>
    <ReactPDF.Canvas
        style={{ width: 15 }}
        paint={(painter, width, height) => {
            painter.path('M7,2.76 L11.242641,7.00416306 L7,11.2452814 L2.757359,7 L7,2.76 Z M7,4.5 L4.5,6.99844396 L7,9.5 L9.5,7.00089706 L7,4.5 Z')

            if (props.hasMore) {
                painter.rect(6.25, 9.5, 1.5, height);
            }

            return painter.fill('#D6D7D0');
        }} />

export const Line = () =>
    <ReactPDF.Canvas
        style={{ width: 15 }}
        paint={(painter, width, height) => painter
            .rect(6.25, 0, 1.5, height)
            .fill('#D6D7D0')
        } />

export const Circle = (props: { hasMore: boolean }) =>
    <ReactPDF.Canvas
        style={{ width: 15 }}
        paint={(painter, width, height) => {
            painter
                .path('M6.9,3.5 C8.77776815,3.5 10.3,5.02223185 10.3,6.9 C10.3,8.77776815 8.77776815,10.3 6.9,10.3 C5.02223185,10.3 3.5,8.77776815 3.5,6.9 C3.5,5.02223185 5.02223185,3.5 6.9,3.5 Z M6.9,4.72051282 C5.69630247,4.72051282 4.72051282,5.69630247 4.72051282,6.9 C4.72051282,8.10369753 5.69630247,9.07948718 6.9,9.07948718 C8.10369753,9.07948718 9.07948718,8.10369753 9.07948718,6.9 C9.07948718,5.69630247 8.10369753,4.72051282 6.9,4.72051282 Z')
                .rect(6.25, 0, 1.5, 4)
                .rect(10, 6.25, 4, 1.5);

            if (props.hasMore) {
                painter.rect(6.25, 9.5, 1.5, height);
            }

            return painter.fill('#D6D7D0');
        }} />