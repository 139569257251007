import { useContext } from "react";
import { OpenIds, OpenIdsContext, OpenIdsWithCase } from "context";

export function useOpenIds(requireCase: false) : Readonly<OpenIds>
export function useOpenIds(requireCase?: true) : Readonly<OpenIdsWithCase>
export function useOpenIds(requireCase: boolean = true) : Readonly<OpenIds> | Readonly<OpenIdsWithCase>  {
    const openIds = useContext(OpenIdsContext);

    if (requireCase && !openIds.caseId) {
        throw Error('Case was required, but no value for caseId was supplied.');
    }

    return openIds;
}