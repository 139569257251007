import React from "react";
import { sguid } from "utils";
import Api from "server/api";
import { ToolbarButton } from './toolbar-button';
import { DuplicateIcon } from "controls/images/custom-icons";
import { useNavigation, useCommand, useLoadingState, pipe, after } from "custom-hooks";

import DuplicateCardCmd = Api.Cases.Commands.DuplicateCard;

type Props = {
    caseId: string;
    oldCardId: string;
    visible: boolean;
}

export const DuplicateCard = (props: Props) => {

    const navigation = useNavigation();

    const loadingState = useLoadingState();

    const duplicateCard = useCommand((oldCardId: string) => new DuplicateCardCmd({ 
            newCardId: `card/${sguid()}`, 
            oldCardId: oldCardId
        }), pipe(loadingState, after(async (cmd : DuplicateCardCmd) => {
            navigation.goto(`/cards/${props.caseId}/${cmd.newCardId}`);
        })))

    if(!props.visible) return null;

    return <>
        <ToolbarButton
            onClick={() => duplicateCard(props.oldCardId)}
            icon={<DuplicateIcon />}
            label='Kopier kort'
            visible={props.visible}
            disabled={loadingState.loading}
            loading={loadingState.loaderVisible}
        />
    </>
}