import * as React from 'react'
import ReactPDF, { View } from '@react-pdf/renderer';
import { VerticalSpacer } from './controls';
import { Header3, Text } from './text';
import { Table, Row } from 'pages/report/controls/table'
import Api from 'server/api'
import { ConditionRating } from './condition-rating';
import { Square, Circle } from './shapes';

import Es = Api.Cases.Queries.Reports.Es;
import GuidePageDto = Es.GuidePageDto;

export const Guide = (props: { durationYears: number, typeMappingsName: string }) =>
    <View>
        <Text>
            Drift- og vedligeholdelsesplanen anviser og prioriterer vedligeholdelsesopgaver og skader 
            på din bygning.
        </Text>
        <VerticalSpacer height="small" />
        <Text>
            Planen er en aktuel skadesoversigt for bygningen med forslag til udbedring og løbende 
            vedligehold, og skal ikke ses som en komplet bygningsregistrering.
        </Text>
        <VerticalSpacer height="small" />
        <Text>
            Vi anbefaler at gennemføre udbedring og vedligehold med øje for energibesparelser - for 
            eksempel via energimærkning af bygningen.
        </Text>
        <VerticalSpacer height="small" />
        <Text>
            Denne plan dækker over en {props.durationYears}-årig periode og følger {props.typeMappingsName}.
        </Text>
    </View>

const AnnotationIcon = (props: React.PropsWithChildren<ReactPDF.ViewProps>) =>
    <View style={{
        minHeight: 15,
        flexDirection: 'row',
        marginRight: 3
    }}>{props.children}</View>

const ConditionRow = (props: GuidePageDto.ConditionRatingDto) =>
    <View style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
        <ConditionRating rating={props.rating} maxRating={props.maxRating} width={16} spacing={4} />
        <Text style={{ marginLeft: 7, marginTop: 3 }}>{props.label}</Text>
    </View>

const GetNumberWord = (number: number) => ["nul", "en", "to", "tre", "fire", "fem", "seks"][number];

export const Annotations = () =>
    <View>
        <Header3 minPresenceAhead={50}>Tegnforklaring</Header3>
        <Table colWidths={[30, 270]}>
            <Row>
                <AnnotationIcon>
                    <Square hasMore={false} />
                </AnnotationIcon>
                <View>
                    <Text>
                        Placering af den registrerede bygningsdel eller installation samt beskrivelse 
                        af eventuelle skader
                    </Text>
                </View>
            </Row>
            <Row style={{marginTop: 10}}>
                <AnnotationIcon>
                    <Square hasMore={true} />
                </AnnotationIcon>
                <View />
            </Row>
            <Row>
                <AnnotationIcon>
                    <Circle hasMore={false} />
                </AnnotationIcon>
                <View>
                    <Text>
                        Præcisering af en skades placering
                    </Text>
                </View>
            </Row>
        </Table>
    </View>

export const Condition = (props: { conditionRatings: readonly GuidePageDto.ConditionRatingDto[] }) =>
    <View>
        <Header3>Tilstand</Header3>
        <View>
            <Text>
                Hver bygningsdel eller installations aktuelle tilstand angives 
                i {GetNumberWord(props.conditionRatings.length)} kategorier:
            </Text>
        </View>
        {props.conditionRatings.map((x, i) => <ConditionRow key={i} {...x} />)}
    </View>

export const FactBox = (props: { title: string, children: React.ReactNode }) =>
    <View style={{
        width: 190,
        backgroundColor: '#F1C264',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5
    }} >
        <View style={{ padding: 16 }}>
            <Header3>{props.title}</Header3>
            {props.children}
        </View>
    </View>

export const FactBox1 = () =>
    <FactBox title="Genopretning">
        <Text>
            Når skaden er sket – eller er overhængende – skal der ske en genopretning.
        </Text>
        <VerticalSpacer height="small" />
        <Text>
            Eksempel: Hvis et vindue er punkteret, skal det udskiftes, eller hvis der er
            hul i taget, skal det repareres.
        </Text>
    </FactBox>
    
    export const FactBox2 = () =>
    <FactBox title="Løbende drift og vedligehold">
        <Text>
            Opgaver der bør gennemføres regelmæssigt, så nye skader undgås eller for at
            optimere brugen af bygningen.
        </Text>
        <VerticalSpacer height="small" />
        <Text>
            Eksempel: Årlig rensning af tagbrønde og tagrender, for at undgå skader ved 
            sokkel eller i tagkonstruktion.
        </Text>
    </FactBox>

export const Prices = (props: { indexYear: number, priceIndexInPercentage: string, includeVat: boolean }) =>
    <View>
        <Header3>Priser</Header3>
        <Text>
            Priserne i planen er med udgangspunkt i år {props.indexYear}, og reguleres i budgettet 
            årligt med {props.priceIndexInPercentage}.
        </Text>
        <VerticalSpacer height="small" />
        <Text>
            Alle priser er {(props.includeVat ? "inkl." : "ekskl.")} moms.
        </Text>
    </View>
    
