import React from "react";
import { createStyles, Typography, Button as MaterialUiButton, makeStyles } from "@material-ui/core";
import { FileUpload } from "controls/file-upload";

import { AddImageIcon } from "./custom-icons";
import { buttonGradient, buttonColors } from "theme";
import { Theme } from "react-select/lib/types";
import { useWriteLock } from 'custom-hooks';

import cx from 'classnames';
import { Lockable } from "utils/lockable";

const styles = (theme: Theme) => createStyles({
    iconButton: {
        padding: 0,
        marginBottom: 15
    },
    iconButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 180,
        height: 180,
        backgroundColor: '#F4F5F2'        
    },
    iconButtonImage: {
        alignSelf: 'center',
        margin: '0 auto',
        height: 50,
        width: 50,
    },
    iconButtonLabel: {
        fontSize: 13
    },
    iconButtonImageContainer: {
        margin: 10,
        alignSelf: 'center',
        display: 'flex',
        background: buttonGradient(buttonColors.default.background1, buttonColors.default.background2),
        boxShadow: '0 0 2px 0 rgba(0,0,0,0.5)',
        width: 100,
        height: 100,
        borderRadius: 50,
        '&:hover': {
            background: buttonGradient(buttonColors.hover.background1, buttonColors.hover.background2),
        },        
        '& > div': {
            alignSelf: 'center',
            margin: '0 auto',
            height: 50,
            width: 50,
        }
    },
    disabled: {
        "background": buttonColors.disabled.background1,
        "&:hover": {
          "background": buttonColors.disabled.background1
        }
    }
})

type Props = {
    onFileSelected: (files: File[]) => Promise<void>
    label?: string
} & Lockable

const useStyles = makeStyles(styles);

export const ImageUploadButton = (props: Props) => {

    const styles = useStyles();
    const writeLock = useWriteLock(props);
    
    return <FileUpload label='Upload billeder' accept='.png, .jpg, .jpeg' multiple={true} onUpload={files => props.onFileSelected(files)}>
        <MaterialUiButton disabled={writeLock} className={styles.iconButton}>
            <div className={styles.iconButtonContainer}>
                <AddImageIcon 
                    size={50} 
                    className={cx(styles.iconButtonImageContainer, writeLock ? styles.disabled : '')} />
                <Typography className={styles.iconButtonLabel} variant='h6'>{props.label || 'UPLOAD BILLEDER'}</Typography>
            </div>
        </MaterialUiButton>
    </FileUpload>;
};
