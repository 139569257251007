import Api from "server/api";
import { useEffect, useState } from "react";
import { useLoadingState, useCommand, useErrorDisplay, pipe, isCommandError, any as anyError, hasTag } from "custom-hooks";
import { any, isNullOrWhitespace, sguid, ReplaceType } from "utils";
import React from "react";
import { DialogTitle, DialogContent, FormGroup, DialogActions, Toolbar } from "@material-ui/core";
import { FormField } from "controls/field/form-field";
import { Button } from "controls/button";
import {Popup} from 'controls/popup';
import { ErrorListDisplay } from "controls/error-list-display";

import CaseDto = Api.Cases.Queries.GetCases.CaseDto;
import DuplicateCase = Api.Cases.Commands.DuplicateCase;


type CaseDuplicationState = Pick<DuplicateCase, 'newName' | 'newCaseId'>;

type Props = {
    onSuccess: () => void;
    onClose: () => void;
    open: boolean;
    case: CaseDto | false
}

const newCaseId = () => `case/${sguid()}`;

export const DuplicateCasePopup = (props: Props) => props.case
    ? <DuplicateCasePopupContent {...props} case={props.case} />
    : null;

const DuplicateCasePopupContent = (props: ReplaceType<Props, 'case', CaseDto>) => {

    const [duplication, setDuplication] = useState<CaseDuplicationState>({
        newCaseId: newCaseId(),
        newName: props.case.name
    });

    useEffect(() => {
        if (!props.case) return;

        setDuplication({
            newName: props.case.name,
            newCaseId: newCaseId()
        });
    }, [props.case, props.open]);

    const loadingState = useLoadingState();
    const { loading, loaderVisible } = loadingState;

    const errorListDisplay = useErrorDisplay(isCommandError);

    const updateNewName = (newName: string) => setDuplication(s => ({ ...s, newName: newName }))

    const executeDuplicate = useCommand((newCaseId: string) => new DuplicateCase({
        newCaseId: newCaseId,
        oldCaseId: props.case.id,
        newName: duplication.newName
    }), pipe(
        errorListDisplay,
        loadingState
    ));

    const originalCase = props.case;
    if (!duplication || !originalCase) return null;

    const duplicateCase = async () => {
        if (!canExecute()) return;

        const result = await executeDuplicate(duplication.newCaseId);
        if(result)
            props.onSuccess();
    }

    const canEdit = () => !loading;
    const canExecute = (): boolean => {

        if (any(isNullOrWhitespace, duplication.newName))
            return false;

        return !loading;
    }

    return (
        <Popup
            maxWidth='lg'
            open={props.open}
            onClose={props.onClose}
            onOk={duplicateCase}
            onCancel={props.onClose}>

            <DialogTitle>Kopier sag</DialogTitle>

            <DialogContent>
                <ErrorListDisplay {...errorListDisplay} />
                <FormGroup>
                    <FormField
                        type='text'
                        label='Kopiens navn'
                        autoFocus={true}
                        disabled={!canEdit()}
                        value={duplication.newName}
                        onValidChange={updateNewName}
                         />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Toolbar>
                    <Button label='Fortryd' disabled={loading} onClick={props.onClose} />
                    <Button
                        label='Kopier'
                        loading={loading && loaderVisible}
                        disabled={!canExecute()}
                        onClick={duplicateCase} />
                </Toolbar>
            </DialogActions>
        </Popup>);
}