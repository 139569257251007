import { createStyles, Theme } from "@material-ui/core";
import { lineClampStyles } from "theme";

export default (theme: Theme) => createStyles({
    taskItem: {
        padding: 0,
        backgroundColor: '#F4F5F2',
        display: 'flex',
        alignItems: 'stretch',

        '& > div:nth-child(1)': {
            width: 44
        },

        '& > div:nth-child(2)': {
            flex: 1,
            paddingTop: 12,
            paddingBottom: 12,
            fontFamily: theme.typography.fontFamily,
            fontSize: 12,
        },

        '& > div:nth-child(3)': {
            display: 'flex',
            alignItems: 'center',
            width: 47,
            paddingTop: 5,
            paddingBottom: 5,
        },
    },
    taskItemHeader: {
        display: 'flex',
        '& span': {
            flex: 1,
            marginLeft: 5,
            fontSize: 10,
            fontWeight: 'bold'
        },
        '& svg': {
            fill: 'black'
        },
    },
    taskItemInset: {
        marginLeft: 44
    },
    taskListName: {
        overflow: 'hidden',
        ...lineClampStyles(2)
    },
    listGroup: {
        backgroundColor: 'inherit',
        padding: '0'
    }
});
