import React from "react";
import { ReactSVG } from 'react-svg';
import { Theme, makeStyles } from "@material-ui/core";
import { ClassArray } from "classnames/types";
import cx from 'classnames';

export type CustomIconProps = {
    className?: string;
    classSelector?: ClassSelector;
    size?: IconSize;
    disable?: boolean;
}

function svgSize(width: number, height: number) {
    return { width: width, height: height };
}

function svgSizeStyle(size: IconSizeRect) {
    return {
        width: size.width,
        height: size.height,
        '& svg': {
            width: size.width,
            height: size.height
        }
    }
}

type IconSizeRect = {
    width: number,
    height: number
}

export type IconSize = IconSizeRect | number;

const sizeToRect = (size: IconSize): IconSizeRect => {
    if (typeof size === 'number') return { width: size, height: size }
    return size;
}

const useCustomIconStyles = makeStyles((theme: Theme) => ({
    iconSize: (props: CustomIconProps) => svgSizeStyle(sizeToRect(props.size)),
    toolbarButtonColors: {
        '& path': {
            fill: theme.palette.text.secondary
        }
    },
    contentColors: {
        '& path': {
            fill: 'currentColor'
        }
    },
    moveSides: {
        '& polygon': {
            fill: 'currentColor'
        }
    },
    rootFontSize: {
        fontSize: '1rem' //This is a bit of a hack, but I don't want other things to be affected by this before the material Ui update
    }
}));

type ClassSelector = (classes: ReturnType<typeof useCustomIconStyles>) => string | ClassArray;

const CustomIconBase =
    (
        src: string,
        defaultSize: IconSize = { width: 30, height: 30 },
        staticClassSelector: ClassSelector = (() => "")
    ) => (React.memo((props: CustomIconProps) => {

        const styleProps = {
            ...props, 
            size: props.size || defaultSize 
        };

        const classes = useCustomIconStyles(styleProps);
        const propsClassSelector = props.classSelector || (() => "");

        const staticClasses = cx(staticClassSelector(classes));
        const propClasses = cx(propsClassSelector(classes));

        return <ReactSVG className={cx(
            props.className,
            classes.iconSize,
            propClasses,
            staticClasses,
            classes.toolbarButtonColors
        )} src={src} />
    }));

export const AddIcon = CustomIconBase('/assets/icons/add.svg');
export const AddImageIcon = CustomIconBase('/assets/icons/add_image.svg');
export const AddDamageIcon = CustomIconBase('/assets/icons/damage-gray-add-backend.svg');
export const AddBuildingIcon = CustomIconBase('/assets/icons/new-building.svg');
export const AddMaintenanceIcon = CustomIconBase('/assets/icons/maintenance-add.svg');
export const AddOnetimeIcon = CustomIconBase('/assets/icons/onetime-task.svg');
export const AddRecurringIcon = CustomIconBase('/assets/icons/repeating-task.svg');
export const AddCaseIcon = CustomIconBase('/assets/icons/add.svg');

export const DeleteIcon = CustomIconBase('/assets/icons/delete.svg', svgSize(12, 16));
export const DuplicateIcon = CustomIconBase('/assets/icons/dupliker.svg');
export const DamageIcon = CustomIconBase('/assets/icons/damage-backend.svg');
export const MaintenanceIcon = CustomIconBase('/assets/icons/maintenance.svg');
export const MaintenanceIconSmall = CustomIconBase('/assets/icons/maintenance-filled-small.svg', 12);
export const EditIcon = CustomIconBase('/assets/icons/edit.svg');
export const MoveSidesIcon = CustomIconBase('/assets/icons/move-tool-sides.svg', svgSize(16, 7), c => c.moveSides);