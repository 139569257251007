import React from 'react';
import {Button as MaterialUiButton, CircularProgress, makeStyles, ButtonProps } from "@material-ui/core";
import cx from 'classnames';
import styles from './styles';
import { useWriteLock } from 'custom-hooks';
import { Lockable } from 'utils/lockable';

type Props = {
    label: string;
    onClick: () => void;
    loading?: boolean;
    className?: string;
} & Lockable;

const useStyles = makeStyles(styles);

export const Button = (props: Props) => {
    const classes = useStyles(props);
    const writeLock = useWriteLock(props, props.loading);

    return (
        <MaterialUiButton
            className={cx(
                classes.button, 
                props.className,
                writeLock ? classes.disabled : '',
                props.loading ? classes.loading : ''
                )}                
            onClick={props.onClick}
            disabled={writeLock}>
                {props.label}
                {props.loading && <CircularProgress size={24} thickness={4} className={classes.loadingSpinner} />}
        </MaterialUiButton>
    );
};

export const UnstyledButton = (props: ButtonProps & Lockable) => {
    const writeLock = useWriteLock(props);

    return (
        <MaterialUiButton {...props} disabled={writeLock}>
               {props.children}
        </MaterialUiButton>
    );
}