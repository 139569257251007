import red from '@material-ui/core/colors/red';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { PropsWithChildren } from 'react';
import React from 'react';

export const buttonColors = {
    default: {
        background1: '#83E376',
        background2: '#54DFA7',
        text: '#162a1b'
    },
    hover: {
        background1: '#83E375',
        background2: '#82DBB9',
        text: '#302e2f'
    },
    disabled: {
        background1: 'rgba(0, 0, 0, 0.12)',
        background2: 'rgba(0, 0, 0, 0.12)',
        text: '#162a1b'
    },
    loading: {
        background1: '#b2b3ab',
        background2: '#b2b3ab',
        text: '#162a1b'
    }
}

export const lineClampStyles = (lineClamp: number) => ({
    overflow: 'hidden',
    display: '-webkit-box',
    lineClamp: lineClamp,
    '-webkit-line-clamp': lineClamp,
    '-webkit-box-orient': 'vertical'
})

export const buttonGradient = (color1: string, color2 : string) => `linear-gradient(135deg, ${color1} 0%, ${color2} 100%)`;

export const theme = createMuiTheme({
    palette: {
        primary: { main: '#67A38C'  },
        secondary: { main: '#fafafa' },
        error: red
    },
    typography: {
        fontSize: 16,
        fontFamily: 'Open Sans',
        fontWeightMedium: 'bold',
        htmlFontSize: 16
    },
    overrides: {
        MuiFab: {
            root: {
                background: buttonGradient(buttonColors.default.background1, buttonColors.default.background2),
                '&:hover': {
                    background: buttonGradient(buttonColors.hover.background1, buttonColors.hover.background2),
                },
                boxShadow: '0 0 2px 0 rgba(0,0,0,0.5)',
                height: '87px',
                width: '87px',
                borderRadius: '10%',
                zIndex: 2,
                "&$disabled": {
                    "background": buttonColors.disabled.background1,
                    "&:hover": {
                      "background": buttonColors.disabled.background1
                    }
                }
            },
            label: {
                height: '70px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly'
            }
        },
        MuiSelect: {
            root: {
                fontSize: 15
            }
        },
        MuiTab: {
            root: {
                fontSize: 13
            }
        },
        MuiFormLabel: {
            root: {
                fontSize: '1rem'
            }
        },
        MuiFormControlLabel: {
            label: {
                fontSize: '1rem'
            }
        },
        MuiFormHelperText: {
            root: {
                fontSize: '0.75rem'
            },
            contained: {
                marginLeft: 12
            }
        },
        MuiInputBase: {
            root: {
                fontSize: '1rem'                
            }
        },
        MuiIconButton: {
            root: {
                fontSize: '1em'
            }
        },
        MuiSvgIcon: {
            root: {
                fontSize: '1.5rem'
            },
            fontSizeSmall: {
                fontSize: 20
            }
        },
        MuiTableCell:{
            root: {
                padding: '4px 56px 4px 24px',
            },
            body: {
                fontSize: 13,
                '&:last-child': {
                    paddingRight: 24
                }
            },
            head: {
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: 12,
                whiteSpace: 'nowrap'
            }
        },
        MuiPaper: {
            root: {
                lineHeight: 1
            }
        },
        MuiTypography: {
            button: {
                fontSize: 10,
                width: '60px'
            },
            body1: {
                fontSize: '1rem'
            },
            h6: {
                fontSize: 20,
                color: 'rgba(0, 0, 0, 0.87)'
            }
        },
        MuiTableRow: {
            root: {
                height: 48
            }
        },
        MuiListItem: {
            root: {
                paddingTop: 11,
                paddingBottom: 11
            }
        },
        MuiListItemText: {
            root: {
                marginTop: 0,
                marginBottom: 0
            }
        },
        MuiExpansionPanelSummary: {
            root: {
                padding: 0,
                paddingLeft: 24,
                paddingRight: 24,
            }
        },
        MuiTooltip: {
            tooltip: {
                fontWeight: 400,
                fontSize: 12,
                color: 'rgba(0, 0, 0, 0.87)',
                border: '1px solid #83E376',
                boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                backgroundColor: '#fff'
            },
            arrow: {
                "&::before": {
                    border: "1px solid #83E376",
                    backgroundColor: "#fff",
                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                    boxSizing: "border-box"
                  }
            }
        }
    }
});

const lighterTheme = createMuiTheme({
    ...theme,
    palette: {
        ...theme.palette,
        primary: { main: '#54DFA7' },
        secondary: { main: '#83E576' },
    },
    overrides: {
        ...theme.overrides,
        MuiFormControlLabel: {
            ...theme.overrides.MuiFormControlLabel,
            label: {
                ...theme.overrides.MuiFormControlLabel.label,
                fontSize: 14
            }
        }
    }
});

//We dont have enough colors for our design. Since we use a lighter green color some places, this is use to switch that out
//This approach should not be taken where it can be avoided.
export const LighterThemeProvider = (props: PropsWithChildren<{}>) => {
    return <MuiThemeProvider theme={lighterTheme}>{props.children}</MuiThemeProvider>
}