import React, { useMemo, useState } from "react";
import { TableRow, TableCell, Toolbar, makeStyles, IconButton } from "@material-ui/core";

import _ from 'lodash';
import styles from './styles'
import { Tooltip } from "controls/tooltip";
import { TypeMappingsErrorsPopup } from "./type-mappings-errors-popup";

import EditIcon from '@material-ui/icons/Edit';
import WarningIcon from '@material-ui/icons/Warning';

import Api from "server/api";
import TypeMappingsDto = Api.Users.Queries.GetTypeMappings.TypeMappingsDto;
import TemplateGroupDto = Api.Templates.Queries.GetCompanyTemplates.TemplateGroupDto;

type Props = {
    updateTypeMappings: (id: string) => Promise<void>;
    typeMappings: TypeMappingsDto;
    templates: readonly TemplateGroupDto[];
}

const useStyles = makeStyles(styles);

export const TypeMappingsRow = (props: Props) => {

    const styles = useStyles();

    const typeMappings = props.typeMappings;
    const editTypeMapping = () => props.updateTypeMappings(props.typeMappings.id);

    const errors = useMemo(() =>
        props.templates
            .map(template => {
                const typeMappingsTypes = typeMappings.mappings.filter(x => x.group === template.group).map(x => x.type);
                var templateTypes = template.types;

                const missing = _.difference(templateTypes, typeMappingsTypes);
                const extra = _.difference(typeMappingsTypes, templateTypes);

                return { group: template.group, missing: missing, extra: extra }
            })
            .filter(x => x.missing.length > 0 || x.extra.length > 0)
        , [props.templates, typeMappings]);

    const [popupOpen, setPopupOpen] = useState(false);

    const hasError = errors.length > 0;

    return (
        <TableRow key={typeMappings.id}>
            <TableCell component="td" scope="row">{typeMappings.name}</TableCell>
            <TableCell>
                <Toolbar variant='dense' disableGutters className={styles.toolbar}>
                    {hasError && (
                        <Tooltip title={'Grupper med fejl i konfiguration: ' + errors.map(x => x.group).join(' / ')}>
                            <IconButton onClick={() => { setPopupOpen(true) }}>
                                <WarningIcon color="error" fontSize="small" />
                            </IconButton>
                        </Tooltip>)}
                    <Tooltip title='Rediger klassifikationer'>
                        <IconButton onClick={editTypeMapping}>
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </TableCell>

            <TypeMappingsErrorsPopup
                missingTypeMappings={errors.filter(x => x.missing.length > 0).reduce((a, c) => (a[c.group] = c.missing, a), {} as { [group: string]: readonly string[] })}
                extraTypeMappings={errors.filter(x => x.extra.length > 0).reduce((a, c) => (a[c.group] = c.extra, a), {} as { [group: string]: readonly string[] })}
                open={popupOpen} onClose={() => setPopupOpen(false)} />
        </TableRow>
    )
}