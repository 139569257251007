import React from "react";
import { withStyles, WithStyles, Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Api from "server/api";
import Component from "component";
import styles from './styles'
import { BuildingDetails } from './building-details';
import { scrollIntoViewIfNeeded } from "utils";

import BuildingDto = Api.Cases.Queries.GetCase.BuildingDto

type Props = {
    building: BuildingDto;
    isOpen: boolean;
    isNewest: boolean;
    canClose: () => boolean;
    updateBuilding: (building: BuildingDto) => void;
    deleteBuilding: (id: string) => Promise<void | unknown>;
    setOpenBuildingId: (buildingId: string) => void;
} & WithStyles<typeof styles>

export default withStyles(styles)(class Building extends Component<Props, {}> {

    ref : HTMLElement;

    componentDidMount = () => {
        if(this.props.isNewest){
            scrollIntoViewIfNeeded(this.ref, { behavior: 'smooth', block: 'center' });
            this.props.setOpenBuildingId(this.props.building.id);
        }
    }

    onChange = (_: any, expanded: boolean) => {
        this.props.setOpenBuildingId(expanded || !this.props.canClose() ? this.props.building.id : null);
    }

    render() {
        const building = this.props.building;

        return (
            <Accordion key={building.id} expanded={this.props.isOpen} onChange={this.onChange}
                className={this.props.classes.building}>
                <AccordionSummary expandIcon={this.props.canClose() ? <ExpandMoreIcon /> : undefined}>
                    <div ref={ref => this.ref = ref} />
                    <Grid container style={{ alignItems: 'baseline' }}>
                        <Grid item xs={6}><Typography variant='h6'>{building.name.value?.toUpperCase()}</Typography></Grid>
                        {
                            !this.props.isOpen ? (<>
                                <Grid item xs={2}><Typography variant='body1'>{building.buildingArea.value || ''}</Typography></Grid>
                                <Grid item xs={2}><Typography variant='body1'>{building.buildingYear.value || ''}</Typography></Grid>
                            </>) : null
                        }
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <BuildingDetails
                        updateBuilding={this.props.updateBuilding}
                        deleteBuilding={this.props.deleteBuilding}
                        building={building} />
                </AccordionDetails>
            </Accordion>
        )
    }
})

