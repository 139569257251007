import { useServer } from "./use-server"
import { useEffect, useCallback } from "react";

type CommandCallback = (command?: Command) => unknown | Promise<unknown>;

export const useCommandSubscription = (func: CommandCallback, dependencies: React.DependencyList) => {
    const server = useServer();

    const funcCallback = useCallback(func, dependencies)

    useEffect(() => {
        const subscription = server.Commands.subscribe(funcCallback);
        funcCallback();
        return () => subscription.unsubscribe();
    }, [server, funcCallback])
}