import React from 'react'
import { MainContext } from './context';
import { Server } from './server';

export default class Component<Props, State> extends React.Component<Props, State> {

    static contextType = MainContext;
    context!: React.ContextType<typeof MainContext>;

    get server() : Server { return this.context.server; }

    goto = (url: string) => {
        this.context.navigation.goto(url);
    }
}
