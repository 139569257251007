import { createStyles, Theme } from "@material-ui/core";

export default (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    navbarIcon: {
        fontSize: '1.5rem'
    },
    settingIcon: {
        color: '#B2B3AB'
    }
});