import { Observable } from "rxjs";

export const errors = new Observable<any>(subscriber => {
    const windowErrorEventListener = (e: ErrorEvent) => {
        subscriber.next(e.error)
        return true;
    }

    const promiseRejectionEventListener = (e: PromiseRejectionEvent) => {
        subscriber.next(e.reason)
        return true;
    }

    window.addEventListener("error", windowErrorEventListener);
    window.addEventListener('unhandledrejection', promiseRejectionEventListener);

    return () => {
        window.removeEventListener("error", windowErrorEventListener);
        window.removeEventListener('unhandledrejection', promiseRejectionEventListener);
    }
})