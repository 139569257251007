import { useServer } from "./use-server";

export type CommandDecorator = (command: Command, next: (command: Command) => Promise<CommandResult>) => Promise<CommandResult>;
export type CommandDecoratorTyped<TCommand extends Command> = (command: TCommand, next: (command: Command) => Promise<CommandResult>) => Promise<CommandResult>;
export type HasCommandDecorator = { decorator: CommandDecorator }

export function decorator<TCommand extends Command = Command>(decorator : CommandDecoratorTyped<TCommand>) {
    return {
        decorator: decorator
    }
}

export const pipe = (...steps: HasCommandDecorator[]) : HasCommandDecorator => {
    if (!steps.length) return {
        decorator: (command, next) => next(command)
    };

    return decorator(steps
            .map(({ decorator }) => decorator)
            .reduceRight((previous, current) => (command, next) => current(command, c => previous(c, next)))
    )
};

export const disableWhileRunningDecorator = (): HasCommandDecorator => {
    let running = false;
    return decorator(async (command, next) => {    
        if (running) return { commitId: null };
        try {
            running = true;
            return await next(command);
        }
        finally {
            running = false;
        }
})};

export function before<TCommand extends Command = Command>(callback : ((command : TCommand) => Promise<unknown>)) 
    : HasCommandDecorator
{
    return decorator(async (command, next) => {
            await callback(command as TCommand);
            return await next(command);
        })
};

export function after<TCommand extends Command = Command>(callback : ((command : TCommand) => Promise<unknown>)) 
    : HasCommandDecorator
{
    return decorator(async (command, next) => {
            const result = await next(command);
            await callback(command as TCommand);
            return result;
        });
}

export const useCommand = <TFactory extends ((...args: readonly unknown[]) => TCommand), TCommand extends Command>(
    commandFactory : TFactory, 
    decorator?: HasCommandDecorator,
    baseUrl?: string)
     : (...args: Parameters<TFactory>) => Promise<CommandResult> =>
{
    const server = useServer();

    decorator = decorator || { decorator: ((c, next) => next(c)) }

    return (...args: Parameters<TFactory>) => 
        decorator.decorator(commandFactory(...args), (c: Command) => server.command(c, baseUrl));
}

export const useCommandAsync = <TFactory extends ((...args: readonly unknown[]) => Promise<TCommand>), TCommand extends Command>(
    commandFactory : TFactory, 
    decorator?: HasCommandDecorator,
    baseUrl?: string)
     : (...args: Parameters<TFactory>) => Promise<CommandResult> =>
{
    const server = useServer();

    decorator = decorator || { decorator: ((c, next) => next(c)) }

    return async (...args: Parameters<TFactory>) => 
        decorator.decorator(await commandFactory(...args), (c: Command) => server.command(c, baseUrl));
}