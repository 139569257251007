import React, { useContext } from 'react'
import ReactPdf, { View, Text as PdfText } from '@react-pdf/renderer';
import { TOCHeader } from './text';
import _ from 'lodash';
import { PageContext, ReportThemeContext } from './context'

const TOCText = (props: ReactPdf.TextProps) => {
    const textTheme = useContext(ReportThemeContext).text;

    return (
        <PdfText
            {...props}
            {...textTheme.TOCText}
            style={{
                fontFamily: 'Open Sans',
                fontSize: 7,
                lineHeight: 13 / 7,
                ...textTheme.TOCText.style,
                ...props.style
            }}
        />);
}

const TOCTitle = (props: ReactPdf.TextProps) => {
    const textTheme = useContext(ReportThemeContext).text;

    return (
        <PdfText
            {...props}
            {...textTheme.TOCHeader}
            style={{
                fontFamily: 'Open Sans Bold',
                fontSize: 19,
                lineHeight: 25 / 19,
                marginBottom: 15,
                textTransform: 'uppercase',
                ...textTheme.TOCHeader.style,
                ...props.style
        }} />);
}

const filterHeaders = (headers: TOCHeader[]) => {
    return _.sortBy(headers, ['pageNumber', 'headerLevel']).filter(x => x.headerLevel < 2)
}

// because of multiple rendering of this page (dynamic content from ReactPdf),
// timing of when styles are generated and loaded is unpredictable.
// so we have to use inline styling, and not in-file or classname styling
// so NO const styles = StyleSheet.create({...

// currently table of contents with more than one page is not supported
// this could be possible with triple rendering
export default () => {
    const context = useContext(PageContext);
    const ellipsis = '.'.repeat(250); // ellipsis cannot exceed a certain limit otherwise it breaks to next line

    return <View>
        <TOCTitle>Indholdsfortegnelse</TOCTitle>

        <View style={{ display: 'flex', flexDirection: 'row' }}>

            <View style={{ width: 900, display: 'flex' }} >
                <TOCText fixed render={() => ( // due to dynamic content, custom component cannot be used her
                    <PdfText>
                        {filterHeaders(context.headers)
                            .map(x => x.header.substring(0, 65) + ' ' + ellipsis)
                            .join('\n')}
                    </PdfText>)
                } />
            </View>

            <View style={{
                width: 60,
                height: 721,
                position: 'absolute',
                right: -37,
                paddingRight: 46,
                backgroundColor: 'white'
            }}>
                <TOCText fixed render={() => ( // due to dynamic content, custom component cannot be used her
                    <PdfText style={{
                        textAlign: 'right'
                    }}>
                        {filterHeaders(context.headers).map(x => x.pageNumber).join('\n')}
                    </PdfText>)
                } />
            </View>
        </View>
    </View>
}

