import * as React from 'react'
import { View } from '@react-pdf/renderer';
import { Text as Text, BoldText, Header1, Markdown } from '../text'
import Api from 'server/api'
import _ from 'lodash';
import { VerticalSpacer } from '../controls';

import DeasPurposePage = Api.Modules.Deas.Queries.DeasPurposePageDto;

export const PurposePage = (props: DeasPurposePage) =>
    <View>
        <View>
            <Header1>Formål</Header1>
            <Text>
                Formålet med nærværende bygningsgennemgang og redegørelse er at bedømme ejendommens 
                vedligeholdelsesstand; herunder at vurdere og opstille en plan for de kommende 
                vedligeholdelsesudgifter over en {props.durationYears}-årig periode.
            </Text>
            <VerticalSpacer height="small"/>
            <Text>
                Vedligeholdelsesplanen kan bruges som planlægningsværktøj i forbindelse med ansøgninger 
                om eventuelle tilskudsmidler.
            </Text>
            <VerticalSpacer height="small"/>
            <Text>
                Vedligeholdelsesplanen bør som minimum opdateres <BoldText>hvert 3. år</BoldText>. Herved 
                kan der indarbejdes eventuelle nye eller ændrede ønsker til ejendommen samtidig med, at det 
                vurderes, om der er planlagte arbejder, der som følge af ændrede forudsætninger skal udføres 
                tidligere eller senere end oprindeligt fastsat.
            </Text>
        </View>
        <VerticalSpacer height="medium"/>
        <View>
            <Header1>Grundlag og forudsætninger</Header1>
            <Text>
                Vedligeholdelsesplanen er udarbejdet på baggrund af registreringer af DEAS A/S
            </Text>
            <VerticalSpacer height="small"/>
            <Text>
                Ejendommene har været besigtiget udvendig med tilhørende udenoms arealer og fællesarealer.
            </Text>
            <VerticalSpacer height="small"/>
            <Text>
                Bedømmelsen af den bygningsmæssige tilstand inklusive installationer er baseret 
                på en visuel bedømmelse foretaget fra terrænniveau.
            </Text>
            <VerticalSpacer height="small"/>
            <Text>
                I forbindelse med den bygningsmæssige gennemgang er der ikke foretaget undersøgelser, 
                som kræver indgreb i bygningen og dens konstruktioner og installationer som f.eks. 
                boringer, nedbrydning af konstruktioner eller lignende. 
            </Text>
            <VerticalSpacer height="small"/>
            <Text>
                El-installationer er kun visuelt bedømt af DEAS A/S for umiddelbare forekommende fejl, 
                og anbefales følgende gennemgået og bedømt af en autoriseret el-installatør, for at 
                skabe overblik over elinstallationernes fuldstændige tilstand.
            </Text>
            <VerticalSpacer height="small"/>
            <Text>
                DEAS A/S har intet ansvar for forhold som ikke kan iagttages og vurderes på ovenstående grundlag.
            </Text>
            <VerticalSpacer height="small"/>
            <Text>
                DEAS A/S påtager sig intet ansvar overfor tredjemand i forbindelse med rådgivningen.
            </Text>
            <VerticalSpacer height="small"/>
            <Text>
                Rådgivningen ydes i overensstemmelse med ABR 18, idet DEAS A/S samlede ansvar er 
                begrænset til tre gange honoraret for bygningseftersynet af nærværende ejendom.
            </Text>
            <VerticalSpacer height="small"/>
            <Text>
                Vurderingen af vedligeholdelsesudgifterne er foretaget på baggrund af ovennævnte 
                bygningssyn samt erfaringer fra tilsvarende ejendomme.
            </Text>
            <VerticalSpacer height="small"/>
            <Text>
                Alle priser er vejledende overslagspriser, idet endelig fastsættelse af omkostningerne 
                for de større arbejder vil kræve et nærmere projekt med indhentning af tilbud, ligesom 
                prisen vil afhænge af den ønskede kvalitet og niveau for færdiggørelse af arbejder.
            </Text>
            <VerticalSpacer height="small"/>
            {
                props.backgroundMaterialComments &&
                <>
                    <BoldText>
                        Som grundlag for vedligeholdelsesrapporten har følgende været til rådighed:
                    </BoldText>
                    <VerticalSpacer height="small"/>
                    <Markdown>
                        {props.backgroundMaterialComments}
                    </Markdown>
                </>
            }
        </View>
    </View>