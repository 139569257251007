import React, { useContext } from 'react'
import { View } from '@react-pdf/renderer';
import Api from 'server/api'
import { VerticalSpacer } from '../controls';
import { PageContext } from '../context';
import { BuildingInfo, EstateDescription, Paragraph, Row, BottomRow, Value } from '../estate-page';
import { BoldText, Header1 } from '../text'
import { Table } from 'pages/report/controls/table'
import _ from 'lodash';

import Es = Api.Cases.Queries.Reports.Es;
import EstatePageDto = Es.EstatePageDto;

interface CaseDataProps extends EstatePageDto { header: string, colWidths: number[] }

const CaseData = (props: CaseDataProps) => {

    return <View>
        <Header1>{props.header}</Header1>
        <Table colWidths={props.colWidths}>
            <Row>
                <BoldText>Sag</BoldText>
                <Value>{props.caseData.name}</Value>
                <BoldText>Udførende firma</BoldText>
                <Value>{props.caseData.companyName}</Value>
            </Row>
            <Row>
                <BoldText>Sted</BoldText>
                <Value>{props.caseData.nickname}</Value>
                <BoldText>Udarbejdet af</BoldText>
                <Value>{props.caseData.consultant}</Value>
            </Row>
            <Row>
                <BoldText>Adresse</BoldText>
                <Value>{props.caseData.address}</Value>
                <BoldText>Telefon</BoldText>
                <Value>{props.caseData.phoneNumber}</Value>
            </Row>
            <Row>
                <BoldText>Kunde</BoldText>
                <Value>{_.compact([props.caseData.customerCompany, props.caseData.customerContact]).join(' / ')}</Value>
                <BoldText>E-mail</BoldText>
                <Value>{props.caseData.email}</Value>
            </Row>
            <Row>
                <BoldText>Dato for besigtigelse</BoldText>
                <Value>{props.caseData.inspectionDate}</Value>
                <BoldText>Godkendt af</BoldText>
                <Value>{props.caseData.approvedBy}</Value>
            </Row>
            <BottomRow>
                <BoldText>Dato for rapport</BoldText>
                <Value>{props.caseData.dateOfReport}</Value>
                <BoldText></BoldText>
                <Value></Value>
            </BottomRow>
        </Table>
    </View>
}

export const BjornsholmEstatePage = (props: EstatePageDto) => {
    const context = useContext(PageContext);
    const colWidths = [80, 170, 80, 0];
    
    return <View>
        <CaseData {...props} header={`Data for ${context.reportTitle.toLowerCase()}`} colWidths={colWidths} />
        <VerticalSpacer height="xlarge" />
        <BuildingInfo {...props} colWidths={colWidths} />
        <VerticalSpacer height="xlarge" />
        <EstateDescription imageUrl={props.imageUrl}>
          <Paragraph body={props.description}/>
        </EstateDescription >
    </View>
}