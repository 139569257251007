import { useContext } from "react";
import { MainContext } from "context";
import { useOpenIds } from "./use-open-ids-context";

export const useNavigation = () => {
    const { navigation } = useContext(MainContext);
    return navigation;
}

export const useNavigateTo = () => {
    
    const navigation = useNavigation();
    const { caseId, cardId, taskId } = useOpenIds();

    return {
        cards: (newCaseId: string = caseId) => navigation.goto(`/cards/${newCaseId}`),
        card: (newCardId: string = cardId) => navigation.goto(`/cards/${caseId}/${newCardId}`),
        task: (newTaskId: string = taskId, newCardId: string = cardId) => navigation.goto(`/cards/${caseId}/${newCardId}/${newTaskId}`)
        //Add to here everytime we need a new route
    }
}