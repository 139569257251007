export const blobToBase64 = (blob: Blob): Promise<string> => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => reader.onloadend = () => {
        if (reader.result instanceof ArrayBuffer) throw new Error('Expected string as result type.');
        resolve(reader.result.substring(reader.result.indexOf(',') + 1));
    });
};

//https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
export const b64toBlob = (b64Data: string, contentType='application/pdf', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }