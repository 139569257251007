import React from 'react'
import ReactPDF, { StyleSheet, Image, View } from '@react-pdf/renderer';
import { Text, Page, Header3, Header2, TOCHeader, Markdown, Header1, colors, Section, BoldText, Paragraph } from './controls'
import Api from 'server/api'
import ResultsDto = Api.Modules.Obh.Queries.ResultsDto;
import { padStart } from 'lodash';
import { ObhDataCell, ObhDataRow, ObhHeaderCell, ObhHeaderRow, ObhTable } from './obh-table';

type Props = ResultsDto & {
    caseName: string,
    headers: TOCHeader[],
    isCompleted: boolean
};

type CheckboxProps = {
    checked: boolean
    style?: ReactPDF.Style
}

const styles = StyleSheet.create({
    shaded: {
        backgroundColor: colors.lightBlue
    },
    unshaded: {
        backgroundColor: colors.white
    },
    boldText: {
        fontFamily: 'arial-bold'
    },
    boxImage: {
        width: 15,
        height: 15,
        marginTop: 3,
        marginLeft: 15,
        marginRight: 5,
        marginBottom: 3
    },
})

const Checkbox = (props: CheckboxProps) => (
    <View style={{ alignItems: 'flex-end', justifyContent: 'center', ...props.style }}>
        <Image style={styles.boxImage} src={props.checked ? "/assets/checkbox_checked.png" : "/assets/checkbox_unchecked.png"}/>
    </View>)

export const Results = (props: Props) => 
    <Page size="A4" caseName={props.caseName} style={{ paddingBottom: 132 }} showWatermark={!props.isCompleted}>
        
        <Header1 headers={props.headers}>Resultater</Header1>
        <Header2 headers={props.headers}>Resumé</Header2>
        <Section>
            <Markdown>{props.resultResume}</Markdown>
        </Section>

        <Header2 headers={props.headers}>Tilstandsvurdering</Header2>
        <Header3>Ejendomsniveau</Header3>
        <Section>
            <Paragraph>Tilstand vurderes på bygningsdelsniveau, der efterfølgende opsummeres på ejendomsniveau (under middel, middel og over middel).</Paragraph>
        </Section>
        <ObhTable colWidths={[0, 60]} style={{ marginBottom: 20 }}>
            <ObhHeaderRow>
                <ObhHeaderCell left>Beskrivelse</ObhHeaderCell>
                <ObhHeaderCell>Vurdering</ObhHeaderCell>
            </ObhHeaderRow>
            <ObhDataRow>
                <ObhDataCell left>
                    <Text>Ejendommens tilstand er <BoldText>over middel</BoldText>{'\n'}</Text>
                    <Text>- kræver fortsat kun god, normal vedligeholdelse</Text>
                </ObhDataCell>
                <Checkbox checked={props.buildingConditionRating === 3}/>
            </ObhDataRow>
            <ObhDataRow>
                <ObhDataCell left>
                    <Text>Ejendommens tilstand er <BoldText>middel</BoldText>{'\n'}</Text>
                    <Text>- der må forventes nogle reparationer og en del vedligeholdelse</Text>
                </ObhDataCell>
                <Checkbox checked={props.buildingConditionRating === 2}/>
            </ObhDataRow>
            <ObhDataRow>
                <ObhDataCell left>
                    <Text>Ejendommens tilstand er <BoldText>under middel</BoldText>{'\n'}</Text>
                    <Text>- større reparations- og vedligeholdelsesarbejder må påregnes</Text>
                </ObhDataCell>
                <Checkbox checked={props.buildingConditionRating === 1} />
            </ObhDataRow>
        </ObhTable>

        <Header3>Bygningsdelsniveau</Header3>
        <Section>
            <Paragraph>De enkelte bygningsdeles tilstand set i forhold til, hvad man kan forvente af en bygning af den aktuelle type og alder.</Paragraph>
        </Section>
        <ObhTable colWidths={[60, 0, 55, 55, 55]} style={{ marginBottom: 20 }}>
            <ObhHeaderRow>
                <ObhHeaderCell left>{'\nNr.'}</ObhHeaderCell>
                <ObhHeaderCell left>{'\nBygningsdel'}</ObhHeaderCell>
                <ObhHeaderCell>{'Over\nmiddel'}</ObhHeaderCell>
                <ObhHeaderCell>{'\nMiddel'}</ObhHeaderCell>
                <ObhHeaderCell>{'Under\nmiddel'}</ObhHeaderCell>
            </ObhHeaderRow>
            {props.cardGroups.map((group, i) =>
                <ObhDataRow key={group.name} style={{
                    ...(i % 2 === 0 ? styles.shaded : styles.unshaded)
                }}>
                    {/*TODO: Make cells support vertical-align center*/}
                    <ObhDataCell left>{padStart((i + 1).toString(), 2, '0')}</ObhDataCell>
                    <ObhDataCell left>{group.name}</ObhDataCell>
                    <Checkbox checked={group.conditionRating === 3} />
                    <Checkbox checked={group.conditionRating === 2} />
                    <Checkbox checked={group.conditionRating === 1} />
                </ObhDataRow>
            )}
        </ObhTable>
        
        <Header3>Samlet opgaveoversigt</Header3>
        <Section>
            <Paragraph>Antal og prioritering af opgaver, som skal gennemføres på ejendommen.</Paragraph>
        </Section>
        <ObhTable colWidths={[0, 120]}>
            <ObhHeaderRow>
                <ObhHeaderCell left>Prioritering</ObhHeaderCell>
                <ObhHeaderCell>Antal opgaver</ObhHeaderCell>
            </ObhHeaderRow>
            {Object.entries(props.taskPriorityHistogram).map(([key, value], i) => 
                <ObhDataRow key={key} style={i % 2 === 0 ? styles.shaded : styles.unshaded}>
                    <ObhDataCell left>{key}</ObhDataCell>
                    <ObhDataCell>{value}</ObhDataCell>
                </ObhDataRow>
            )}
        </ObhTable>
    </Page>