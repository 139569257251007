import React, { useState, useEffect } from 'react';
import { DialogTitle, DialogContent, DialogActions, WithStyles, Toolbar, FormControlLabel, FormGroup } from "@material-ui/core";
import {Popup} from 'controls/popup';
import { FormField } from "controls/field/form-field";
import { Button } from "controls/button";
import _ from 'lodash';
import { user, isNullOrWhitespace, any } from "utils";
import Api from "server/api";
import { useLoadingState } from 'custom-hooks';
import { Checkbox } from 'controls/checkbox';
import { createStyles } from "@material-ui/core";

import ProfileDto = Api.Users.Queries.GetProfilesInCompany.ProfileDto;

type Props = {
    onOk: (priority: ProfileDto) => Promise<void>;
    onClose: () => void;
    open: boolean;
    profile: ProfileDto | false
}

export const EditUserPopup = (props: Props) => {

    const [profile, setProfile] = useState(props.profile);

    useEffect(() => {
        setProfile(props.profile);
    }, [props.profile]);

    const {
        load,
        loading,
        loaderVisible,
    } = useLoadingState(100);

    if (!profile || !props.profile) return null;

    const isEditingOwnProfile = () => user.profileId === profile.id;

    const canEdit = () => !loading;
    const canExecute = (): boolean => {

        if (any(isNullOrWhitespace, profile.fullName, profile.initials))
            return false;

        if (isEditingOwnProfile())
            return profile.isCompanyAdministrator;

        return !loading;
    }

    const updateField = <TKey extends keyof ProfileDto>(name: TKey) => (value: ProfileDto[TKey]) => 
        setProfile({ ...profile, [name]: value });

    const updateProfile = async () => {
        if (!canExecute()) return;

        await load(props.onOk(profile));
    }

    return (
        <Popup
            maxWidth='lg'
            open={props.open}
            onClose={props.onClose}
            onOk={updateProfile}
            onCancel={props.onClose}>

            <DialogTitle>Rediger bruger</DialogTitle>

            <DialogContent>
                <FormGroup>
                    <FormField
                        type='text'
                        label='Navn'
                        disabled={!canEdit()}
                        value={profile.fullName}
                        onValidChange={updateField('fullName')} />
                    <FormField
                        type='text'
                        label='Initialer'
                        disabled={!canEdit()}
                        value={profile.initials}
                        onValidChange={updateField("initials")} />
                    <FormField
                        type='text'
                        label='Alternativ e-mail'
                        disabled={!canEdit()}
                        value={profile.alternativeEmail}
                        optional
                        onValidChange={updateField("alternativeEmail")} />

                    <Checkbox
                        label={'Admin' + (isEditingOwnProfile() && props.profile.isCompanyAdministrator ? ' (kan ikke fjernes fra dig selv)' : '')}
                        disabled={!canEdit() || isEditingOwnProfile() && props.profile.isCompanyAdministrator}
                        checked={profile.isCompanyAdministrator}
                        onChange={(e, checked) => updateField("isCompanyAdministrator")(checked)}
                    />
                    <Checkbox
                        label='Kan afslutte'
                        disabled={!canEdit()}
                        checked={profile.allowAsCompleter}
                        onChange={(e, checked) => updateField("allowAsCompleter")(checked)}
                    />
                    <Checkbox
                        label='Kan godkende'
                        disabled={!canEdit()}
                        checked={profile.allowAsApprover}
                        onChange={(e, checked) => updateField("allowAsApprover")(checked)}
                    />
                    <Checkbox
                        label='Konsulent'
                        disabled={!canEdit()}
                        checked={profile.allowAsConsultant}
                        onChange={(e, checked) => updateField("allowAsConsultant")(checked)}
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Toolbar>
                    <Button label='Fortryd' disabled={loading} onClick={props.onClose} />
                    <Button
                        label='Gem'
                        loading={loading && loaderVisible}
                        disabled={!canExecute()}
                        onClick={updateProfile} />
                </Toolbar>
            </DialogActions>
        </Popup>);
}