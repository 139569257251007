import React from "react";
import Api from "app/server/api";
import _ from 'lodash';
import { numeral } from 'utils'
import { Header2, TOCHeader, Page } from "./controls";
import { StyleSheet, Image, View } from '@react-pdf/renderer';
import { colors, Header3, Text, VerticalSpacer } from './controls'
import { Row } from 'pages/report/controls/table'
import { ObhDataRow, ObhHeaderCell, ObhHeaderRow, ObhTable, ObhDataCell } from './obh-table';

import GroupDto = Api.Modules.Obh.Queries.GroupDto;

type Props = GroupDto & {caseName: string, isCompleted: boolean};

const imageStyles = StyleSheet.create({  
    section:{
        paddingTop: 5
    },
    container: {
        backgroundColor: colors.lightBlue,
        height: 196,
        width: 232,
        marginBottom: 8,
        justifyContent: 'space-between'
    },
    image: {
        alignSelf: 'center',
        maxWidth: 232,
        maxHeight: 161,
        padding: 5
    },
    imageDescription: {
        backgroundColor: colors.darkBlue,
        color: colors.white,
        fontSize: 8,
        minHeight: 35,
        width: 232,
        padding: 5
    }
});  

const ImageCell = (props: { image: Api.Modules.Obh.Queries.ImageDto }) => {
    const { image } = props;

    return <View style={imageStyles.container}>
        <Image minPresenceAhead={1} style={imageStyles.image}
            // Get image three times (3*232 = 696) larger for better image quality for print
            source={'/api/' + image.url + `&width=696`} /> 
        {image.description
            ? <Text style={imageStyles.imageDescription}>{image.description}</Text>
            : <VerticalSpacer/>
        }
    </View>
}

const ImageColumn = (image: Api.Modules.Obh.Queries.ImageDto, index: number) =>
    index === 0
        ? <ImageCell image={image} />
        : [<ObhTable colWidths={[0]}></ObhTable>, <ImageCell image={image} />];

const ImagesSection = (props: Pick<Props, 'images'>) => {
    const { images } = props;

    if (images.length == 0) return null;

    return <ObhTable colWidths={[260]} style={imageStyles.section}>
        <Header3 style={{ marginBottom: 10 }}>Billeder</Header3>
        {
            _.chunk(images, 2)
                .map((group, i) =>
                    <Row key={i} colWidths={group.length === 2 ? [260, 0, 260] : [260]}>
                        {group.map((image, index) => ImageColumn(image, index))}
                    </Row>)
        }
    </ObhTable>;
}

export const Card = (props: Props) => 
    <>
        <ObhTable colWidths={[0, 230]}>
            <ObhHeaderRow>
                <ObhHeaderCell left>Beskrivelse</ObhHeaderCell>
                <ObhHeaderCell></ObhHeaderCell>
            </ObhHeaderRow>
            <ObhDataRow>
                <ObhDataCell left>Bygningsdel</ObhDataCell>
                <ObhDataCell>{props.classificationDescription}</ObhDataCell>
            </ObhDataRow>
            <ObhDataRow>
                <ObhDataCell left>Klassifikation</ObhDataCell>
                <ObhDataCell>{props.classification}</ObhDataCell>
            </ObhDataRow>
            <ObhDataRow>
                <ObhDataCell left>Placering</ObhDataCell>
                <ObhDataCell>{props.buildingLocation}</ObhDataCell>
            </ObhDataRow>
            <ObhDataRow>
                <ObhDataCell left>Kategori, tilstandsvurdering</ObhDataCell>
                <ObhDataCell>{props.conditionRating}</ObhDataCell>
            </ObhDataRow>
        </ObhTable>
        <ObhTable colWidths={[75, 0, 115, 115]}>
            <ObhHeaderRow colWidths={[0, 115, 115]}>
                <ObhHeaderCell left>Bygningsdelsbeskrivelse</ObhHeaderCell>
                <ObhHeaderCell>Indbygningsår</ObhHeaderCell>
                <ObhHeaderCell>Forventet restlevetid</ObhHeaderCell>
            </ObhHeaderRow>
            {
                props.cards.map(card => 
                    <ObhDataRow key={card.cardId}>
                        <ObhDataCell left>{card.location}</ObhDataCell>
                        <ObhDataCell left>{card.description}</ObhDataCell>
                        <ObhDataCell>{card.year}</ObhDataCell>
                        <ObhDataCell>{card.remainingLifetime}</ObhDataCell>
                    </ObhDataRow>
                )
            }
        </ObhTable>
        <ObhTable colWidths={[75, 0]}>
            <ObhHeaderRow colWidths={[0]}>
                <ObhHeaderCell left>Tilstandsvurdering</ObhHeaderCell>
            </ObhHeaderRow>
            {
                props.conditions.map((condition) =>
                    <ObhDataRow key={condition.location} >
                        <ObhDataCell left>{condition.location}</ObhDataCell>
                        <ObhDataCell left>{condition.description}</ObhDataCell>
                    </ObhDataRow>
                )
            }
        </ObhTable>
        {props.instructions.length > 0 &&
            <ObhTable colWidths={[75, 0]}>
                <ObhHeaderRow colWidths={[0]}>
                    <ObhHeaderCell left>Vedligeholdelses- og reparationsinstruks</ObhHeaderCell>
                </ObhHeaderRow>
                {
                    props.instructions.map((instruction) =>
                        <ObhDataRow key={instruction.location}>
                            <ObhDataCell left>{instruction.location}</ObhDataCell>
                            <ObhDataCell left>{instruction.description}</ObhDataCell>
                        </ObhDataRow>
                    )
                }
            </ObhTable>
        }
        {props.tasks.length > 0 &&
            <ObhTable colWidths={[75, 0, 50, 75, 75]}>
                <ObhHeaderRow colWidths={[0, 50, 75, 75]}>
                    <ObhHeaderCell left>Opgaver</ObhHeaderCell>
                    <ObhHeaderCell>Prioritet</ObhHeaderCell>
                    <ObhHeaderCell>Interval</ObhHeaderCell>
                    <ObhHeaderCell>Prisoverslag</ObhHeaderCell>
                </ObhHeaderRow>
                {
                    props.tasks.map((task) =>
                        <ObhDataRow key={task.taskId}>
                            <ObhDataCell left>{task.location}</ObhDataCell>
                            <ObhDataCell left>{task.description}</ObhDataCell>
                            <ObhDataCell>{task.priority}</ObhDataCell>
                            <ObhDataCell>{task.interval}</ObhDataCell>
                            <ObhDataCell>{numeral(task.totalPrice).format('0,0')}</ObhDataCell>
                        </ObhDataRow>
                    )
                }
            </ObhTable>
        }
        <ImagesSection images={props.images} />
    </>

export const Cards = (props: { groups: readonly Api.Modules.Obh.Queries.GroupDto[], caseName: string, isCompleted: boolean, headers: TOCHeader[] }) => {
    return props.groups.map((group, index) =>
        <Page size="A4" caseName={props.caseName} showWatermark={!props.isCompleted}>
            {index === 0 && <Header2 headers={props.headers}>Bygningsdelskort</Header2>}
            <Card key={group.classification} {...group} caseName={props.caseName} isCompleted={props.isCompleted} />        
        </Page>)     
}