import React, { useDebugValue } from 'react';
import Field, { Props as FieldProps, FieldType, TypeSelector, AutoProps, isAuto } from '.';
import { useWriteLock } from 'custom-hooks';
import { View } from '@react-pdf/renderer';
import { Tooltip } from 'controls/tooltip';

type Props<T extends FieldType> = FieldProps<T, TypeSelector<T>>;

const FormField = <T extends FieldType>(props: Props<T> | AutoProps<Props<T>>) => {
    const style: Partial<Props<T>> = {
        variant: 'filled',
        margin: 'normal'
    }
    const writeLock = useWriteLock(props);
    const fieldInfo = props.fieldInfo && <Tooltip title={props.fieldInfo.info}>
                    <View style={{ position: "absolute", top: "1.4em", right: "0.2em" }}>{props.fieldInfo.icon}</View>
                </Tooltip>

    useDebugValue(`Label: ${props.label}`);

    if (isAuto(props)) {
        if (props.field == null) return null; 

        return <View style={{ position: "relative" }}>
            <Field
                {...props}
                {...style}
                value={props.field.value as TypeSelector<T>}
                disabled={!props.field.writable || writeLock}
            />
            {fieldInfo}
        </View>
    }

    return (
        <View style={{ position: "relative" }}>
            <Field
                {...props}
                {...style}
                disabled={writeLock}
            />
            {fieldInfo}
        </View>
    );
}

export { FormField };