import { makeStyles } from "@material-ui/core";
import { WriteLockProvider } from "context";
import { Checkbox } from 'controls/checkbox';
import { AddDamageIcon, AddMaintenanceIcon, DeleteIcon } from "controls/images/custom-icons";
import VerticalDivider from "controls/vertical-divider";
import { WarningPopup } from "controls/warning-popup";
import { useCommand, useCommandSubscription, useImmutableArrayState, useServer, useNavigateTo as useNavigateTo, useCaseLockState } from "custom-hooks";
import _ from 'lodash';
import { Card } from 'pages/card';
import { Task } from "pages/card/task";
import { CreateCard } from 'pages/toolbar/create-card';
import { CardTaskType, CreateTask } from 'pages/toolbar/create-task';
import Toolbar from 'pages/toolbar/toolbar';
import { UploadImage } from 'pages/toolbar/upload-image';
import React, { useMemo, useState } from "react";
import Api from "server/api";
import { Templates } from "templates";
import { readSingleFile, sguid } from "utils";
import { DuplicateCard } from "../toolbar/duplicate-card";
import { MenuList } from "./menu-list";
import { UnstyledButton } from "controls/button";
import { useOpenIds } from "custom-hooks";

import ConditionRatingDto = Api.Cases.Queries.GetConditionRatings.ConditionRatingDto
import PriorityDto = Api.Modules.Priority.Queries.GetPriorities.PriorityDto
import GroupDto = Api.Cases.Queries.GetCards.GroupDto


type Props = {
    templates: Templates;
    availableConditionRatings: ConditionRatingDto[];
    priorities: PriorityDto[];
}

const useStyles = makeStyles(theme => ({
    content: {
        display: 'flex',
        flexDirection: 'row',
        height: 'calc(100% - 54px)'
    },
    menu: {
        width: 360,
        overflowY: 'scroll',
        overflowX: 'hidden',
        minHeight: '100%'
    },
    details: {
        flex: 1,
        overflow: 'auto',
        minHeight: '100%',
        paddingLeft: theme.spacing(1),
    },
    menuToolbar: {
        marginTop: 1,
        height: 40,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2)
    },
    deleteButton: {
        fontSize: 10,
        fontWeight: 'bold',
        textTransform: 'none'
    },
    deleteIcon: {
        marginRight: 5
    },
    checkbox: {
        margin: 0,
        '& .MuiFormControlLabel-label': {
            fontSize: 10,
            fontWeight: 'bold',
            marginRight: 12
        },
        '& .MuiCheckbox-root':{
            padding: 0
        }
    }
}));

export const Cards = (props: Props) => {

    const [groupsOfCards, setGroupsOfCards] = useImmutableArrayState<GroupDto>();
    const [allCards, setAllCards] = useImmutableArrayState<{ id: string, isChecked: boolean, cardName: string }>();
    const [deletePopUpOpen, setDeletePopUpOpen] = useState(false);

    const server = useServer();
    const navigateTo = useNavigateTo();

    const styles = useStyles();

    const { caseId, cardId, taskId } = useOpenIds();
    const { locked } = useCaseLockState(caseId);

    useCommandSubscription(() => {
        server.query<GroupDto[]>(
            new Api.Cases.Queries.GetCards({ caseId: caseId, showAllClassifications: true })
        ).subscribe(cards => {
            setGroupsOfCards(cards);
            setAllCards(_.flatten(cards.map(group => group.cards)).map(card => ({ id: card.id, isChecked: false, cardName: card.name })));
        })
    }, [server, caseId])
    
    const onTaskCreated = (taskId: string) => {
        navigateTo.task(taskId);
    }

    const uploadImage = useCommand((cardId: string, data: string) => new Api.Cases.Commands.UploadCardImage({cardId: cardId, imageId: `image/${sguid()}`, data: data}))

    const onFileSelected = async (files: File[]) => {
        if(!cardId) return;

        const onFileLoaded = async (_: File, content: string) => {
            await uploadImage(cardId, content);
        };
        
        files.map(file => {
            readSingleFile(file, onFileLoaded);
        })
    };

    const deleteCard = useCommand((cardId: string) => new Api.Cases.Commands.DeleteCard({ cardId }));

    const deleteSelectedCards = async () => {
        setDeletePopUpOpen(false);

        const cardIds = selectedCards.map(x => x.id);

        const deleteCommands = cardIds.map(cardId => deleteCard(cardId));

        if (cardId && cardIds.some(x => x == cardId)) {
            navigateTo.cards();
        }

        await Promise.all(deleteCommands);
    }

    const toggleSelect = (id: string) => {
        setAllCards(allCards.map(x => x.id === id ? { ...x, isChecked: !x.isChecked } : x));
    }

    const toggleAll = (toggle: boolean) => {
        setAllCards(allCards.map(x => ({ ...x, isChecked: toggle })));
    }

    const { content, menu, menuToolbar, deleteButton, deleteIcon, checkbox, details } = styles;

    const isAllSelected = allCards.length > 0 && allCards.every(x => x.isChecked);

    const selectedCards = useMemo(() => allCards.filter(x => x.isChecked) , [allCards])

    return (
        <WriteLockProvider locked={locked}>
            <div className={content}>
                <div className={menu} >
                    <div className={menuToolbar}>
                        <UnstyledButton
                            className={deleteButton}
                            onClick={() => selectedCards.length > 0 && setDeletePopUpOpen(true)}>
                            <DeleteIcon className={deleteIcon} />
                            Slet valgte
                        </UnstyledButton>
                        <WarningPopup
                            onOk={async () => deleteSelectedCards()}
                            onClose={() => setDeletePopUpOpen(false)}
                            isOpen={deletePopUpOpen}
                            title={selectedCards.length <= 1 ? 'Slet kortet?' : 'Slet kortene?'}
                            message={selectedCards.length <= 1 ? 'Du er ved at slette dette kort. Er du sikker?' : 'Du er ved at slette disse kort. Er du sikker?'}
                            additionalMessages={selectedCards.map(card => card.cardName)}
                            okText='SLET'
                            cancelText='FORTRYD'
                        />
                        <Checkbox
                            checked={isAllSelected}
                            className={checkbox}
                            label="Vælg alle"
                            labelPlacement='start'
                            onChange={() => toggleAll(!isAllSelected)}
                        />
                    </div>
                    <MenuList cardId={cardId} taskId={taskId} 
                        groupsOfCards={groupsOfCards} selectedCards={allCards} toggleSelect={toggleSelect} />
                </div>
                <div className={details}>
                {(taskId)
                        ? <Task caseId={caseId} cardId={cardId} taskId={taskId} priorities={props.priorities} />
                        : (cardId)
                            ? <Card key={cardId} caseId={caseId} cardId={cardId} templates={props.templates} 
                                availableConditionRatings={props.availableConditionRatings} onFileSelected={onFileSelected} />
                            : null
                }
                </div>

                <Toolbar>

                    <UploadImage onUpload={onFileSelected} visible={!!cardId && !taskId} />

                    <CreateTask
                        taskType={CardTaskType.OneTimeCardTask}
                        templates={props.templates}
                        label='ENGANGS OPGAVE'
                        icon={<AddDamageIcon />}
                        onTaskCreated={onTaskCreated}
                        visible={!!cardId}
                    />
                    <CreateTask
                        taskType={CardTaskType.RecurringCardTask}
                        templates={props.templates}
                        onTaskCreated={onTaskCreated}
                        label='LØBENDE OPGAVE'
                        icon={<AddMaintenanceIcon />}
                        visible={!!cardId}
                    />



                    <DuplicateCard 
                        oldCardId={cardId}
                        visible={!!cardId}
                        caseId={caseId}
                    />

                    {!!cardId && <VerticalDivider height={107} />}

                    <CreateCard caseId={caseId} templates={props.templates} />
                </Toolbar>

            </div>
        </WriteLockProvider>
    );
}