
import React from 'react';
import { TableRow, TableCell, makeStyles } from "@material-ui/core";
import cx from 'classnames';
import styles from './styles';
import { numeral } from 'utils';

type Props = {
    sum: number,
    text: string,
    underline?: "solid" | "double",
    italic?: boolean
}

const useStyles = makeStyles(styles);

export const SumLine = (props: Props) => {

    const styles = useStyles();

    return <TableRow className={cx(styles.sumRow)}>
        <TableCell className={cx(
            styles.descriptionCell,
            {[styles.italic]: props.italic})
        }>
            {props.text}
        </TableCell>
        <TableCell className={styles.cell}></TableCell>
        <TableCell className={styles.cell}></TableCell>
        <TableCell className={styles.cell}></TableCell>
        <TableCell className={styles.cell}></TableCell>
        <TableCell className={cx(
            styles.sumCell,
            {
                [styles.italic]: props.italic,
                [styles.underline]: props.underline === "solid",
                [styles.underlineDouble]: props.underline === "double"
            }
        )}>
            {numeral(props.sum).format('0,0')}
        </TableCell>
        <TableCell></TableCell>
    </TableRow>
}