import Component from "component";
import React, { useContext } from "react";
import { WithStyles, withStyles, Grid } from "@material-ui/core";
import styles from './styles'
import { FormField } from "controls/field/form-field";
import _ = require("lodash");
import Api from "server/api";
import { Paper } from 'controls/paper'
import { SelectField } from 'controls/field/select-field';
import { BudgetLines } from 'pages/budget-lines';
import GetCaseTask = Api.Cases.Queries.GetCaseTask;
import { createUpdater0, via, createUpdater2, UpdateAction, FieldUpdate } from "utils/updaters";
import { useCommand } from "custom-hooks";

type Props = {
    caseId: string;
    task: GetCaseTask.RecurringTaskDto;
    priorities: Api.Modules.Priority.Queries.GetPriorities.PriorityDto[];
    updateTask: (task: GetCaseTask.RecurringTaskDto) => void;
} & WithStyles<typeof styles>

export const RecurringTask = withStyles(styles)((props: Props) => {

    const priorities = props.priorities.map(x => ({ label: x.translation, value: x.value }));

    const writeTask: UpdateAction = useCommand((update: FieldUpdate) => new Api.Cases.Commands.WriteTaskField({
        caseId: props.caseId,
        cardId: null,
        taskId: props.task.id,
        fieldName: update.fieldName,
        value: update.value
    }))

    const update = createUpdater0(props.task, via(props.updateTask), writeTask);
    const updatePriority = createUpdater2(props.task, via(props.updateTask), ['extensions', 'priority'], writeTask);

    return (
        <>
            <Paper header='Opgave' className={props.classes.popupContainer}>
                <Grid container spacing={1}>
                    <Grid item xs={props.task.extensions.priority ? 9 : 12}>
                        <FormField
                            type='text'
                            label="Opgavebeskrivelse"
                            field={props.task.description}
                            onValidBlur={update('description')}
                        />
                    </Grid>
                    {props.task.extensions.priority && <Grid item xs={3}>
                        <SelectField
                            optional
                            label="Prioritet"
                            field={props.task.extensions.priority.priority}
                            items={priorities}
                            onChange={updatePriority("priority")}
                        />
                    </Grid>}
                    <Grid item xs={3}>
                        <FormField
                            label='Interval'
                            tooltip='Antal år mellem gentagelse af opgaven'
                            field={props.task.interval}
                            type='number'
                            onValidBlur={update('interval')}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <BudgetLines
                caseId={props.caseId}
                taskId={props.task.id}
                budgetLines={props.task.budgetLines}
            />
        </>);
})
