import { useState, useEffect, useDebugValue, useCallback } from "react";
import { CommandDecorator, HasCommandDecorator } from "./use-command";

export type LoadingState = {
    loading: boolean;
    loaderVisible: boolean;
    load: <T>(work: PromiseLike<T>) => Promise<T>;
} & HasCommandDecorator

/**
 * Creates a state that tracks an elements loading state, and updates a matching loaderVisible state after the given number of milliseconds.
 * Loading starts after the load method is called, and end on completion or if the provided work fails. 
 * loaderVisible will be set to false after loading is set to false, without the delay.
 * @param delay The delay before the loaderVisible state is updated after load is set to true. Default is 700
 */
export function useLoadingState<T>(delay?: number): LoadingState {
    //It's a common saying that users won't notice a delay of 100ms or below, so that's the default
    if (!delay || delay < 0) delay = 100;

    const [loading, setLoading] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);
    
    useDebugValue(loading ? 'Loading' : 'Not loading');
    useDebugValue(loaderVisible ? 'Loader visible' : 'Loader hidden');

    useEffect(() => {

        if (loading && !loaderVisible) {
            const timeOut = self.setTimeout(() => setLoaderVisible(true), delay);

            return () => clearTimeout(timeOut);
        }

        if (!loading && loaderVisible) setLoaderVisible(false);

    }, [loading, loaderVisible]);

    const load = useCallback(async <T>(work: Promise<T>): Promise<T> => {
        try {
            setLoading(true);
            return await work;
        } finally {
            setLoading(false);
        }
    }, [])

    const decorator : CommandDecorator =  async (cmd, next) => {
        return await load(next(cmd));
    }

    return {
        loading: loading,
        load: load,
        loaderVisible: loaderVisible,
        decorator: decorator
    };
}