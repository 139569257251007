import React, { useEffect, useContext, useRef } from "react";
import { ListItem, List, ListItemText, Collapse, makeStyles } from "@material-ui/core";
import _ from 'lodash';
import Api from "server/api";
import cx from 'classnames';
import TaskItem from "./menu-task-item";
import { Checkbox } from 'controls/checkbox';
import { scrollIntoViewIfNeeded } from "utils";
import { useOpenIds, useNavigateTo } from "custom-hooks";
import { lineClampStyles } from "theme";

import TaskType = Api.Cases.TaskType;
import CardDto = Api.Cases.Queries.GetCards.CardDto;
import TaskDto = Api.Cases.Queries.GetCards.TaskDto;


type Props = {
    taskId?: string;
    isSelected: boolean;
    isChecked: boolean;
    check: () => void;
    card: CardDto;
}

const useStyles = makeStyles(() => ({
    cardItem: {
        paddingLeft: 16,
        paddingTop: 19,
        paddingBottom: 19,

        '&:focus': {
            backgroundColor: 'white'    // This is necessary to disable material ui's default focus color.
        }
    },
    cardItemOpen: {
        backgroundColor: '#F4F5F2'
    },
    cardItemHeader: {
        flex: 1,
        fontSize: 10,
        fontWeight: 'bold',
        ...lineClampStyles(1)
    },
    cardItemText: {
        color: 'black',
        fontSize: 12,
        ...lineClampStyles(2)
    },
    taskList: {
        padding: 0,

        '& > div > div > div:nth-child(2), > div > div > div:nth-child(3)': {
            borderBottom: '1px solid #CACBC4',
        },

        '& > div:last-child > div > div': {
            borderBottom: 0
        },
    },
    checkbox: {
        padding: 0
    }
}));

export const CardItem = (props: Props) => {

    const ref = useRef(null);

    const styles = useStyles();

    const navigateTo = useNavigateTo();
    const { caseId } = useOpenIds();

    useEffect(() => {
        if (props.isSelected) {
            scrollIntoViewIfNeeded(ref.current, { behavior: 'smooth', block: 'center' });
        }
    }, []);

    const renderTaskItem = (task: TaskDto, type: TaskType) =>
        <TaskItem
            key={task.id}
            task={task}
            taskType={type}
            caseId={caseId}
            cardId={props.card.id}
            isSelected={task.id === props.taskId} />

    const { cardItem, cardItemOpen, cardItemHeader, cardItemText, taskList, checkbox } = styles;

    const card = props.card;

    return (
        <div ref={ref} >
            <ListItem
                className={cx({ cardItem, [cardItemOpen]: props.isSelected && props.taskId })}
                selected={props.isSelected && !props.taskId}
                onClick={() => {
                    navigateTo.card(card.id)
                }}
                divider
                button>
                <ListItemText classes={{ primary: cardItemHeader, secondary: cardItemText }} primary={card.location} secondary={card.name} />
                <Checkbox
                    checked={props.isChecked}
                    onChange={props.check}
                    className={checkbox}
                />
            </ListItem>
            <Collapse in={props.isSelected}>
                <List className={taskList}>
                    {card.oneTimeTasks.map(task => renderTaskItem(task, TaskType.OneTimeTask))}
                    {card.recurringTasks.map(task => renderTaskItem(task, TaskType.RecurringTask))}
                </List>
            </Collapse>
        </div>);
}