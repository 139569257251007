import React from 'react';

import styles from '../styles'
import { makeStyles } from '@material-ui/core/styles';
import { ContactInformation } from './contact-information';

const useStyles = makeStyles(styles);

export const WelcomeMessageContent = () => {
    const styles = useStyles();

    return (
        <div className={styles.supportContainer}>                    
            <div style={{ fontSize: 18, fontWeight: 'bold'}}>Du er nu oprettet i D&V Plan</div> 
            
            <p>
                Du kan logge ind med dit brugernavn og din adgangskode til venstre eller kontakte vores support, hvis du har brug for hjælp.
            </p>
            <ContactInformation/>
        </div>)
}