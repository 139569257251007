import React, { useState, useEffect } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { Paper } from 'controls/paper'
import { ImageUploadButton } from './image-upload-button';
import { ImagePreviewPopup } from "./image-preview-popup";
import { upperFirst } from "lodash";
import { WarningPopup } from "controls/warning-popup";
import { SortableImageTiles } from './sortable-image-tiles';
import { move } from 'utils';
import _ from 'lodash';
import { useImmutableArrayState } from "custom-hooks";

type Props = {
    images: readonly Image[]
    header: string
    allowDescription: boolean
    onFileSelected: (files: File[]) => Promise<any>
    updateImage: (imageId: string, field: string, value: any) => Promise<any>
    deleteImage: (imageId: string) => Promise<any>
    markImage: (imageId: string) => Promise<any>
    orderImages: (imageIds: readonly string[]) => Promise<any>
    unmarkImage?: (imageId: string) => Promise<any>
}

export type Image = {
    id: string;
    url: string;
    description: string;
    rotation: number;
    isMarked: boolean;
    filename: string;
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3),
        maxWidth: theme.spacing(100),
        color: theme.palette.text.secondary,
    },
    label: {
        marginBottom: 16
    },
    dragging: {
        display: 'block',
        '& .ImageDragHandle': {
            zIndex: 1,
            position: 'absolute',
            top: 'calc((100% - 50px) / 2)',
            display: 'flex',
        }
    },
}));

export const Images = (props: Props) => {

    const [selectedImageIndex, setSelectedImageIndex] = useState(null as number | null)
    const [deleteImageConfirmation, setDeleteImageConfirmation] = useState(false as Image | false)
    const [addedImageIds, setAddedImageIds] = useState({} as { [id: string]: string })
    const [imageIds, setImageIds] = useImmutableArrayState<string>()
    const [underSorting, setUnderSorting] = useState(false);

    useEffect(() => {
        const currentIds = props.images.map(x => x.id);

        if (currentIds.length !== imageIds.length) {
            const diff = imageIds.length > 0
                ? _(currentIds).differenceBy(imageIds).keyBy().value()
                : {};

            setAddedImageIds(diff);
        }

        setImageIds(currentIds);
    }, [props.images]);

    const styles = useStyles();

    const confirmDeleteImage = (image: Image) => setDeleteImageConfirmation(image);

    const deleteImage = async () => {
        if (!deleteImageConfirmation) return;

        setDeleteImageConfirmation(false);

        setImageIds(ids => _.reject(ids, id => id === deleteImageConfirmation.id));

        await props.deleteImage(deleteImageConfirmation.id);
    };

    const rotateLeft = async (image: Image) =>  await update(image, 'rotation', (image.rotation + 360 - 90) % 360);

    const rotateRight = async (image: Image) =>  await update(image, 'rotation', (image.rotation + 90) % 360);

    const update = async <TKey extends keyof Image>(image: Image, name: TKey, value: Image[TKey]) => await props.updateImage(image.id, upperFirst(name), value);

    const previousImage = () => {
        if (selectedImageIndex == null) return;
        if (props.images.length === 0) return;

        setSelectedImageIndex((selectedImageIndex + props.images.length - 1) % props.images.length);
    }

    const nextImage = () => {
        if (selectedImageIndex == null) return;
        if (props.images.length === 0) return;

        setSelectedImageIndex((selectedImageIndex + 1) %  props.images.length);
    }

    const orderImages = async ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
        const newOrder = move(imageIds, oldIndex, newIndex);
        setImageIds(newOrder);
        await props.orderImages(newOrder);
    }

    return (
        <Paper className={styles.container}>
            <Grid container>
                <Grid item xs={12} className={styles.label}>
                    <Typography variant='h6'>
                        {props.header.toUpperCase()}
                    </Typography>
                </Grid>
                {props.images && props.images.length === 0 &&
                    <ImageUploadButton onFileSelected={props.onFileSelected} />
                }
            </Grid>

            <SortableImageTiles
                openImage={setSelectedImageIndex}
                confirmDeleteImage={confirmDeleteImage}
                markImage={props.markImage}
                unmarkImage={props.unmarkImage}
                addedImageIds={addedImageIds}
                images={props.images}
                imageOrders={imageIds}
                axis='xy'
                distance={2} // only start ordering after 2 pixels of dragging, so that click is not affected
                helperClass={styles.dragging} // style applied during dragging motion
                onSortEnd={async ({ oldIndex, newIndex }) => {
                    setUnderSorting(false);
                    await orderImages({ oldIndex, newIndex });
                }}
                onSortStart={() => setUnderSorting(true)}
                underSorting={underSorting}
                allowDescription={props.allowDescription}
            />

            <ImagePreviewPopup
                onClose={() => setSelectedImageIndex(null)}
                update={update}
                rotateLeft={rotateLeft}
                rotateRight={rotateRight}
                previousImage={previousImage}
                nextImage={nextImage}
                confirmDeleteImage={confirmDeleteImage}
                image={selectedImageIndex != null && props.images[selectedImageIndex]}
                allowDescription={props.allowDescription}
            />

            <WarningPopup
                onOk={() => deleteImage()}
                onClose={() => setDeleteImageConfirmation(false)}
                isOpen={!!deleteImageConfirmation}
                title='Slet billedet?'
                message='Du er ved at slette billedet. Er du sikker?'
                okText='SLET'
                cancelText='FORTRYD'
            />
        </Paper>
    );
}