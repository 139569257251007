import React, { useState } from "react";
import AnonymousPage from "controls/anonymous-page";
import { NewPassword } from "./new-password";
import { useCommand, pipe, after, useLoadingState} from "custom-hooks";
import { includeAllErrors, DvError, useErrorDisplay } from "custom-hooks";
import Api from "server/api";

const PasswordChanged = () =>
    <div style={{flex: 1, flexDirection:'column'}}>
        <div style={{display:'flex', flexDirection:'column', alignItems: 'center'}}>
            <div style={{fontSize: 30}}>Din adgangskode er nu ændret!</div>
            
            <p style={{fontSize: 14}}>Du skal bruge din nye adgangskode, når du logger ind i D&V Plan.</p>
            
            <p style={{fontSize: 14}}>Du kan komme til login-siden <a style={{color: '#56DFA6', textDecoration: 'None'}} href='/'>her</a>.</p>
        </div>  
    </div>    

export const ResetPassword = (props: {token: string}) =>  {
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const errorDisplay = useErrorDisplay<DvError>(includeAllErrors);
    const loadingState = useLoadingState();
    
    const changePassword = useCommand((password: string) => 
        new Api.Users.Commands.ResetPassword({
            token: props.token,
            newPassword: password
        }),
        pipe(
            errorDisplay,
            after(async x => setIsCompleted(true)),
            loadingState
        ),
        '/api/user/'
    );
   
    return ( 
        <AnonymousPage>
            {
                !isCompleted 
                    ? <NewPassword loading={loadingState.loading} changePassword={changePassword}  errors={errorDisplay.errors}/> 
                    : <PasswordChanged/>
            }
        </AnonymousPage>
        )    
}