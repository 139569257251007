import React from 'react'
import ReactPdf, { View, Text as PdfText, Page as PdfPage, StyleSheet } from '@react-pdf/renderer';
import { Footer } from './footer'
import { CreateMarkdown } from '../controls/markdown';

type ListProps = React.PropsWithChildren<{
    style?: ReactPdf.Style,
    itemStyle?: ReactPdf.Style,
    bullet?: '&bull;' | '-'
}>

export const List = (props: ListProps) => {
    const children = React.Children.toArray(props.children);

    return <View style={{ ...props.style, flexDirection: 'column' }}>
        {children.map((child) => {
            if (React.isValidElement<{ style?: ReactPdf.Style }>(child)) {
                return (
                    <View key={child.key} style={{ flexDirection: 'row', marginLeft: 15 }}>
                        <Text style={{ fontFamily: "arial-bold" }}>{props.bullet || '-'}</Text>
                        <View style={{ marginLeft: 15 }}>
                            {React.cloneElement(child, { style: { ...props.itemStyle, ...child.props.style }})}
                        </View>                        
                    </View>);
            }
        })}
    </View>;
}

export const ListElement = (props: {label: string, value: string}) => 
    <Text style={{paddingVertical: 1}}><Text style={{ fontFamily: 'arial-bold' }}>{props.label}</Text><Text>{props.value}</Text></Text>;

export const Text = (props: ReactPdf.TextProps) => <PdfText {...props} style={{ ...styles.text, ...props.style }} />
export const BoldText = (props: ReactPdf.TextProps) => <PdfText {...props} style={{ ...styles.text, ...props.style, fontFamily: 'arial-bold' }} />
export const BlueText = (props: ReactPdf.TextProps) => <PdfText {...props} style={{ ...styles.text, ...props.style, color: colors.darkBlue }} />

export const Title = (props: ReactPdf.TextProps) => <PdfText {...props} style={{ ...styles.title, ...props.style }} />
export const SubTitle = (props: ReactPdf.TextProps) => <PdfText {...props} style={{ ...styles.subTitle, ...props.style }} />

export const Header1 = (props: ReactPdf.TextProps & { headers?: TOCHeader[] }) =>
    (<PdfText {...props} minPresenceAhead={props.minPresenceAhead ?? 300} style={{ ...styles.header1, ...props.style }} render={({ pageNumber }) => {
        props.headers && props.headers.push({ header: props.children.toString(), pageNumber: pageNumber, headerLevel: 1 });
        return props.children;
    }} />)

export const Header2 = (props: ReactPdf.TextProps & { headers?: TOCHeader[] }) =>
    (<PdfText {...props} minPresenceAhead={props.minPresenceAhead ?? 270} style={{ ...styles.header2, ...props.style }} render={({ pageNumber }) => {
        props.headers && props.headers.push({ header: props.children.toString(), pageNumber: pageNumber, headerLevel: 1 });
        return props.children;
    }} />)

export const Header3 = (props: ReactPdf.TextProps & { headers?: TOCHeader[] }) =>
    (<PdfText {...props} minPresenceAhead={props.minPresenceAhead ?? 240} style={{ ...styles.header3, ...props.style }} render={({ pageNumber }) => {
        props.headers && props.headers.push({ header: props.children.toString(), pageNumber: pageNumber, headerLevel: 2 });
        return props.children;
    }} />)

export const Section = (props: ReactPdf.ViewProps) => <View style={{ marginBottom: 15, ...props.style }}>
    {props.children}
</View>

export type TOCHeader = {
    header: string,
    pageNumber: number,
    headerLevel: number
}

export const Paragraph = (props: ReactPdf.TextProps) => <Text {...props} style={{ paddingTop: 5, ...props.style}} />

export const VerticalSpacer = (props: { height?: "small" | "medium" | "large" | "xlarge" | number }) => {
    const height = {
        "small": 10,
        "medium": 15,
        "large": 30,
        "xlarge": 50
    }[props.height] ?? props.height ?? 10

    return <View style={{ height: height }} />
}

export const Page = (props: React.PropsWithChildren<ReactPdf.PageProps & { caseName?: string, showWatermark: boolean }>) =>
    <PdfPage {...props} style={{ ...styles.page, ...props.style }}>
        <View fixed style={styles.pageHeader}>
            <SubTitle render={({ pageNumber }) => pageNumber > 1 && "Drift- og vedligeholdelsesplan"} />
        </View>
        {props.children}
        <Footer />
        {props.showWatermark && <DraftWatermark orientation={props.orientation} />}
    </PdfPage>

const DraftWatermark = (props: { orientation: ReactPdf.Orientation }) =>
    <PdfText style={{
        fontSize: 200,
        color: '#808080',
        opacity: 0.2,
        position: 'absolute',
        transform: props.orientation !== "landscape"
            ? 'translateX(-20) translateY(450) rotate(300deg)'
            : 'translateX(40) translateY(220) rotate(330deg)'
    }}>KLADDE</PdfText>

export const colors = {
    darkBlue: '#000047',
    lightBlue: '#D3E4F4',
    white: 'white'
}

const styles = StyleSheet.create({
    text: {
        fontFamily: 'arial',
        fontSize: 10,
        lineHeight: 1.3
    },
    header1: {
        color: colors.darkBlue,
        fontFamily: 'arial-bold',
        fontSize: 18,
        marginBottom: 15
    },
    header2: {
        color: colors.darkBlue,
        fontFamily: 'arial-bold',
        fontSize: 12,
        marginBottom: 10,
        textTransform: 'uppercase'
    },
    header3: {
        color: colors.darkBlue,
        fontSize: 10,
        fontFamily: 'arial-bold',
        marginBottom: 5,
    },
    page: {
        paddingTop: 100,
        paddingBottom: 120,
        paddingHorizontal: 62,
    },
    pageHeader: {
        position: 'absolute',
        top: 50,
        left: 62,
        flexDirection: 'row'
    },
    logo: {
        position: 'absolute',
        width: 120,
        top: 30,
        right: 62
    },
    draft: {
        position: 'absolute',
        width: 500,
        bottom: 20,
        left: 20
    },
    caseIcon: {
        width: 5,
        height: 8
    },
    caseLabel: {
        margin: '0,0,0,12'
    },
    title: {
        marginTop: 15,
        marginBottom: 40,
        fontSize: 24,
        textAlign: 'left',
        fontFamily: 'arial-bold',
        color: colors.darkBlue
    },
    subTitle: {
        color: colors.darkBlue,
        fontFamily: 'arial-bold',
        fontSize: 12,
        marginBottom: 30
    }
});

export const Markdown = CreateMarkdown({
    renderText: (text) =>
        <Text>{text}</Text>,
    renderBoldText: (text) => 
        <BoldText>{text}</BoldText>,
    renderBulletPoint: (text) => 
        <Text style={{ marginLeft: 15 }}>
            <BoldText>•</BoldText><Text> {text}</Text>
        </Text>
    })
