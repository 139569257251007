import { toDvError, DvError } from "custom-hooks";

export const passwordError = (password: string, passwordRepeated: string): DvError[] => {
    if(password !== passwordRepeated) return [toDvError('Adgangskoderne skal være ens.')]

    if(password === null) return [toDvError('Adgangskoden må ikke være tom.')]

    if(password.length < 5) return [toDvError('Adgangskoden skal være længere end 5 karakterer.')]

    return [];
}