import React from "react";

import { makeStyles, List, ListSubheader } from "@material-ui/core";
import { CardItem } from "./menu-card-item";
import Api from "server/api";

import GroupDto = Api.Cases.Queries.GetCards.GroupDto

const useStyles = makeStyles({
    menuList: {
        padding: 0
    },
    subHeader: {
        fontSize: 10,
        lineHeight: '20px',
        fontWeight: 'bold',
        height: 22,
        paddingLeft: 16,
        color: 'black',
        backgroundColor: '#CACBC4'
    },
});

type Props = { 
    cardId: string,
    taskId: string, 
    groupsOfCards: readonly GroupDto[],
    selectedCards: readonly { id: string, isChecked: boolean, cardName: string }[],
    toggleSelect: (id: string) => void 
}

export const MenuList = (props: Props) => {

    const styles = useStyles();

    return <List className={styles.menuList}> 
        {props.groupsOfCards.map((group, i) =>
            <div key={group.name + i}>
                <ListSubheader className={styles.subHeader}>{group.name}</ListSubheader>
                {group.cards.map(card =>
                    <CardItem key={card.id}
                    taskId={props.taskId}
                    card={card}
                    isSelected={props.cardId === card.id}
                    isChecked={!!props.selectedCards.find(x => x.id === card.id) && props.selectedCards.find(x => x.id === card.id).isChecked}
                    check={() => props.toggleSelect(card.id)} />
                )}
            </div>
        )}
    </List>
}