import Component from "component";
import React from "react";
import { DialogTitle, DialogContent, DialogContentText, DialogActions, WithStyles, withStyles, Grid, Toolbar } from "@material-ui/core";
import styles from './styles'
import { Button } from "controls/button";
import { FormField } from "controls/field/form-field";
import _ from 'lodash';
import { upperFirst } from 'lodash'
import {Popup} from 'controls/popup';

type Props = {
    open: boolean;
    onOk: (groupName: string) => Promise<void>;
    onClose: () => void;
    existingGroupNames: string[];
    suggestedGroupName: string;
    isReplacingGroup?: boolean;
} & WithStyles<typeof styles>

type State = {
    groupName: string | null;
}

export default withStyles(styles)(class SelectGroupNamePopup extends Component<Props, State>{
    constructor(props: Props) {
        super(props);
        
        this.state = {
            groupName: null
        }
    }

    render() {
        return (
            <Popup
                maxWidth='md'
                open={this.props.open}
                onClose={this.props.onClose}
                onOk={() => this.canExecute() && this.props.onOk(this.state.groupName)}
                onCancel={this.props.onClose}
                onEnter={() => this.setState({groupName: upperFirst(this.props.suggestedGroupName) })}>

                <DialogTitle>
                    {this.props.isReplacingGroup ? 'Erstat skabelon-gruppe' : 'Opret skabelon-gruppe'}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        {
                            this.props.isReplacingGroup 
                                ? 'Du erstatter en eksisterende gruppe. Gruppenavnet kan derfor ikke ændres.' 
                                :  'Angiv gruppe for de valgte skabeloner'
                        }
                    </DialogContentText>
                            
                    <FormField
                        type='text'
                        label='Gruppe'
                        value={this.state.groupName}
                        onValidChange={(value) => this.setState({groupName: value})}
                        disabled={this.props.isReplacingGroup}
                    />                                        
                </DialogContent>

                <DialogActions>
                    <Toolbar>
                        <Button label='Fortryd' onClick={this.props.onClose}/>                        
                        <Button 
                            label={this.props.isReplacingGroup ? 'Erstat' : 'Opret'}
                            disabled={!this.canExecute()}
                            onClick={() => this.props.onOk(this.state.groupName)} />
                    </Toolbar>
                </DialogActions>
            </Popup>);
    }

    canExecute = () => 
        !_.includes(this.props.existingGroupNames, this.state.groupName) ||
        this.props.isReplacingGroup;
})