import { useServer } from "./use-server";

export type QueryDecorator = (query: Query, next: (query: Query) => Promise<unknown>) => Promise<unknown>;
export type HasQueryDecorator = { decorator: QueryDecorator }

export function decorator<TQuery extends Query = Query>(decorator : QueryDecorator) {
    return {
        decorator: decorator
    }
}

export const pipe = (...steps: HasQueryDecorator[]) : HasQueryDecorator => {
    if (!steps.length) return {
        decorator: (query, next) => next(query)
    };

    return decorator(steps
            .map(({ decorator }) => decorator)
            .reduceRight((previous, current) => (query, next) => current(query, c => previous(c, next)))
    )
};

export function before(callback : ((query : Query) => Promise<unknown>)) 
    : HasQueryDecorator
{
    return decorator(async (query, next) => {
            await callback(query);
            return await next(query);
        })
};

export function after(callback : ((query : Query) => Promise<unknown>)) 
    : HasQueryDecorator
{
    return decorator(async (query, next) => {
            const result = await next(query);
            await callback(query);
            return result;
        });
}

// This is a completely functional start, but needs design improvement
// This version is largly based on the useCommand hook. 
// I think there is a better signature we could figure out for queries.
// Especially for enforcing the return type through the compiler somehow
// This version also converts the observables to promises, so with this version
//, we loose the ability to observe queries
//, and I think that it might be useful we the hook allowed for that
// Should not be used widely yet
export const useQuery = <TFactory extends ((...args: any[]) => Query), TQuery extends Query>(
    queryFactory : TFactory, 
    decorator?: HasQueryDecorator)
     : (...args: Parameters<TFactory>) => Promise<unknown> =>
{
    const server = useServer();

    decorator = decorator || { decorator: ((c, next) => next(c)) }

    return (...args: Parameters<TFactory>) => 
        decorator.decorator(queryFactory(...args), (c: Query) => server.query(c).toPromise());
}