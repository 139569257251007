import React from "react";
import { Tab } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { TabProps } from "@material-ui/core/Tab";
import cx from 'classnames';

const useStyles = makeStyles(theme => ({
    root: {
        opacity: 1,
        [theme.breakpoints.down('md')]: {
            minWidth: 72
        }
    },
    selected: {
        color: '#56DFA6'
    }
}));

export const AppBarTab = (props: TabProps) => {
    const styles = useStyles();

    return <Tab 
                classes={styles} 
                className={cx(props.className)} 
                {...props}  />
};