import React, { useContext, useState } from "react";
import { withStyles, WithStyles, Table, TableHead, TableCell, TableRow, TableBody, Typography, Toolbar, Grid } from "@material-ui/core";
import Api from "server/api";
import styles from './styles'
import TemplateRow from "./template-row";
import { readSingleFile } from "utils";
import SelectGroupNamePopup from "./select-group-name-popup";
import { FileUpload} from "controls/file-upload";
import { MainContext } from "context";

import TemplateGroupDto = Api.Templates.Queries.GetCompanyTemplates.TemplateGroupDto;

type Props = {
    templates: readonly TemplateGroupDto[]
} & WithStyles<typeof styles>

export const Templates = withStyles(styles)((props: Props) => {

    let context = useContext(MainContext);

    const [selectGroupNamePopup, setSelectGroupNamePopup] = useState(false);
    const [isReplacingGroup, setIsReplacingGroup] = useState<string | false>(false);
    const [file, setFile] = useState<File>(null);
    const [isDraggingGroup, setIsDraggingGroup] = useState<string | false>(false);

    const moveTemplate = async (group: string | false, toIndex: number) => {
        if (group == false) return;

        await context.server.command(new Api.Templates.Commands.ChangeTemplateGroupOrder({
            groupName: group,
            newOrder: toIndex
        }));
    }

    const deleteTemplate = async (group: string) => {
        await context.server.command(new Api.Templates.Commands.DeleteTemplateGroup({groupName: group}));            
    }

    const replaceTemplate = async (files: File[], groupName: string) => {
        setFile(files[0]);
        setSelectGroupNamePopup(true);
        setIsReplacingGroup(groupName);
    };

    const onFileSelected = async (files: File[]) => {
        setFile(files[0]);
        setSelectGroupNamePopup(true);
        setIsReplacingGroup(false);
    };
    
    const onUploadFile = async (groupName: string)  => {
        setSelectGroupNamePopup(false);
        setIsReplacingGroup(false);

        const onFileLoaded = async (_: File, content: string) => {
            await context.server.command(new Api.Templates.Commands.ImportTemplates({groupName: groupName, data: content}));            
        };

        readSingleFile(file, onFileLoaded);
    }

    const getSuggestedGroupName = () =>
        isReplacingGroup || (file ? file.name.replace(/\.[^/.]+$/, "") : null);

    return (
        <div style={{ width: '100%' }}>
            <Toolbar>
                <Grid container>
                    <Grid item xs={11}>
                        <Typography variant="h6">Skabeloner</Typography>    
                    </Grid>
                    <Grid item xs={1}>
                        <Grid container justify='flex-end'>
                            <Grid item>
                                <FileUpload label='Importér gruppe' accept='.xls, .xlsx' onUpload={files => onFileSelected(files)} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell variant='head' style={{paddingRight: 0}}></TableCell>
                        <TableCell variant='head'>Gruppe</TableCell>
                        <TableCell variant='head'>Typer</TableCell>
                        <TableCell variant='head'></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{props.templates.map((row, i) => (
                    <TemplateRow key={row.group}
                        template={row}
                        onDragStart={() => setIsDraggingGroup(row.group)}
                        onDragEnd={() => setIsDraggingGroup(false)}
                        onDrop={async () => await moveTemplate(isDraggingGroup, i)}
                        deleteTemplate={deleteTemplate} 
                        replaceTemplate={replaceTemplate} />))}
                </TableBody>
            </Table> 
            <SelectGroupNamePopup
                suggestedGroupName={getSuggestedGroupName()}
                existingGroupNames={props.templates.map(t => t.group)}    
                isReplacingGroup={!!isReplacingGroup}
                open={selectGroupNamePopup}
                onClose={() => setSelectGroupNamePopup(false)}
                onOk={onUploadFile}                              
            />
        </div>            
    );
});