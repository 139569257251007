import React from "react";
import ReactDOM from "react-dom";
import Root from 'root';
import $ from 'jquery';

$(() => {
    ReactDOM.render(
        <Root />,
        document.getElementById("app-container")
    );
});