import React, { useContext } from 'react'
import ReactPdf, { Link as PdfLink, Text as PdfText } from '@react-pdf/renderer';
import { PageContext, ReportThemeContext } from './context';
import { CreateMarkdown } from '../controls/markdown';

export const Text = (props: ReactPdf.TextProps) => {
    const textTheme = useContext(ReportThemeContext).text;

    return <PdfText
        {...props}
        {...textTheme.Text}
        style={{
            fontFamily: 'Open Sans',
            fontSize: 7, 
            lineHeight: 11/7,
            ...textTheme.Text.style,
            ...props.style
        }} />;
}

export const BoldText = (props: ReactPdf.TextProps) => {
    const textTheme = useContext(ReportThemeContext).text;

    return <PdfText 
        {...props}
        {...textTheme.BoldText}
        style={{
            fontFamily: 'Open Sans Semi Bold',
            fontSize: 7,
            lineHeight: 10 / 7,
            ...textTheme.BoldText.style,
            ...props.style
        }} />;
}

export const ItalicText = (props: ReactPdf.TextProps) => {
    const textTheme = useContext(ReportThemeContext).text;

    return <PdfText
        {...props}
        {...textTheme.ItalicText}
        style={{
            fontFamily: 'Open Sans Italic',
            fontSize: 7,
            ...textTheme.ItalicText.style,
        ...props.style}} 
    />;
}

export const LightText = (props: ReactPdf.TextProps) => {
    const textTheme = useContext(ReportThemeContext).text;

    return <PdfText
        {...props}
        {...textTheme.LightText}
        style={{
            fontFamily: 'Open Sans Light',
            fontSize: 7,
            ...textTheme.LightText.style,
        ...props.style}} 
    />;
}

export const SmallText = (props: ReactPdf.TextProps) => {
    const textTheme = useContext(ReportThemeContext).text;

    return <PdfText
        {...props}
        {...textTheme.SmallText}
        style={{
            fontFamily: 'Open Sans',
            fontSize: 6,
            ...textTheme.SmallText.style,
        ...props.style}} 
    />;
}

export const SmallBoldText = (props: ReactPdf.TextProps) => {
    const textTheme = useContext(ReportThemeContext).text;

    return <PdfText
        {...props}
        {...textTheme.SmallBoldText}
        style={{
            fontFamily: 'Open Sans Semi Bold',
            fontSize: 6,
            ...textTheme.SmallBoldText.style,
        ...props.style}} 
    />;
}

export const SmallItalicText = (props: ReactPdf.TextProps) => {
    const textTheme = useContext(ReportThemeContext).text;

    return <PdfText
    {...props}
    {...textTheme.SmallItalicText}
        style={{
            fontFamily: 'Open Sans Italic',
            fontSize: 6,
            ...textTheme.SmallItalicText.style,
        ...props.style}} 
    />;
}

export const SmallBoldItalicText = (props: ReactPdf.TextProps) => {
    const textTheme = useContext(ReportThemeContext).text;

    return <PdfText
    {...props}
    {...textTheme.SmallBoldItalicText}
        style={{
            fontFamily: 'Open Sans Bold Italic',
            fontSize: 6,
            ...textTheme.SmallBoldItalicText.style,
        ...props.style}} 
    />;
}

export const SmallLightText = (props: ReactPdf.TextProps) => {
    const textTheme = useContext(ReportThemeContext).text;

    return <PdfText
    {...props}
    {...textTheme.SmallLightText}
        style={{
            fontFamily: 'Open Sans Light',
            fontSize: 6,
            ...textTheme.SmallLightText.style,
        ...props.style}} 
    />;

}
export const InvisibleHeader1 = (props: ReactPdf.TextProps) => {
    const context = useContext(PageContext);   

    return (
        <PdfText {...props} style={{
            // Workaround because display: 'none' does not work properly with PdfText (see https://stackoverflow.com/q/65361270/5792352):
            height: 0, // remove height
            opacity: 0 // remove visibility
        }} render={renderHeader(1, props, context.headers)} />);
}

export const Header1 = (props: ReactPdf.TextProps) => {
    const pageContext = useContext(PageContext);
    const textTheme = useContext(ReportThemeContext).text;

    return (
        <PdfText minPresenceAhead={40}
            {...props}
            {...textTheme.Header1}
            style={{
                fontFamily: 'Open Sans Bold',
                fontSize: 19,
                lineHeight: 25 / 19,
                marginBottom: 15,
                textTransform: 'uppercase',
                ...textTheme.Header1.style,
                ...props.style
            }}
            render={renderHeader(1, props, pageContext.headers)} />);
}

export const Header2 = (props: ReactPdf.TextProps) => {
    const pageContext = useContext(PageContext);  
    const textTheme = useContext(ReportThemeContext).text; 

    return (
        <PdfText minPresenceAhead={40}
        {...props}
        {...textTheme.Header2}
        style={{
            fontFamily: 'Open Sans Bold',
            fontSize: 15,
            lineHeight: 21 / 15,
            marginBottom: 10,
            textTransform: 'uppercase',
            ...textTheme.Header2.style,
            ...props.style
        }} render={renderHeader(2, props, pageContext.headers)} />);
}

export const Header3 = (props: ReactPdf.TextProps) => {
    const pageContext = useContext(PageContext);
    const textTheme = useContext(ReportThemeContext).text;

    return (
        <PdfText minPresenceAhead={40}
            {...props}
            {...textTheme.Header3}
            style={{
                fontFamily: 'Open Sans Semi Bold',
                fontSize: 9,
                lineHeight: 13 / 9,
                marginBottom: 5,
                ...textTheme.Header3.style,
                ...props.style
            }}
            render={renderHeader(3, props, pageContext.headers)} />);
}

export const Link = (props: ReactPdf.LinkProps) => {
    const textTheme = useContext(ReportThemeContext).text;

    return <PdfLink
        {...props}
        {...textTheme.Text}
        style={{
            color: 'black',
            fontFamily: 'Open Sans',
            fontSize: 7, 
            lineHeight: 11/7,
            textDecoration: 'none',
            ...textTheme.Text.style,
            ...props.style
        }} />;
}

const renderHeader = (level: number, props: ReactPdf.TextProps, headers?: TOCHeader[]) => ({ pageNumber }: { pageNumber: number; totalPages: number; }) => {
    headers && headers.push({
        header: React.Children.map(props.children, x => x && x.toString()).join(''),
        pageNumber: pageNumber, headerLevel: level });

    return props.children;
}

export type TOCHeader = {
    header: string,
    pageNumber: number,
    headerLevel: number
}

export const Markdown = CreateMarkdown({
    renderText: (text) =>
        <Text>{text}</Text>,
    renderBoldText: (text) => 
        <BoldText>{text}</BoldText>,
    renderBulletPoint: (text) => 
        <BulletPoint>{text}</BulletPoint>
    })

export const BulletPoint = (props: ReactPdf.TextProps) =>
    <Text style={{ marginLeft: 15 }}>
        <BoldText>•</BoldText><Text> {props.children}</Text>
    </Text>