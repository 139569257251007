import React, { useState } from "react";
import { sguid } from "utils";
import Api from "server/api";
import { CreateCasePopup, CaseData } from "pages/cases/create-case-popup";
import { ToolbarButton } from './toolbar-button';
import { AddCaseIcon } from "controls/images/custom-icons";
import { useNavigation, useCommand, decorator } from "custom-hooks";

import CreateCaseCmd = Api.Cases.Commands.CreateCase;
import CaseTypeDto = Api.Cases.Queries.GetCaseTypes.CaseTypeDto;

type Props = {
    caseTypes: CaseTypeDto[];
}

export const CreateCase = (props: Props) => {

    const [createCasePopupOpen, setCreateCasePopupOpen] = useState(false);

    const navigation = useNavigation();

    const openPopup = () => setCreateCasePopupOpen(true)
    const closePopup = () => setCreateCasePopupOpen(false)

    const createCase = useCommand((data: CaseData) => new CreateCaseCmd({ 
            caseId: `case/${sguid()}`, 
            name: data.name, 
            indexYear: new Date().getFullYear(), 
            durationYears: 10,
            caseType: data.caseType
        }), decorator(async (cmd : CreateCaseCmd, next) => {
            closePopup();
            const result = await next(cmd);
            navigation.goto(`/details/${cmd.caseId}`)
            return result;
        }))

    return <div>
        <ToolbarButton
            onClick={openPopup}
            icon={<AddCaseIcon />}
            label='SAG'
            visible={true}
        />

        <CreateCasePopup
            open={createCasePopupOpen}
            onOk={createCase}
            onClose={closePopup}
            caseTypes={props.caseTypes}
        />
    </div>
}