import React from "react";
import { withStyles, WithStyles, Grid, Typography } from "@material-ui/core";
import Component from "component";
import styles from './styles'

import Api from "server/api";
import GdprApprovalDto = Api.Users.Queries.GetGdprApproval.GdprApprovalDto;

type Props = {
} & WithStyles<typeof styles>

type State = {
    gdprApproval: GdprApprovalDto
}

export default withStyles(styles)(class GdprApproval extends Component<Props, State> {
    
    state = { 
        gdprApproval: null as GdprApprovalDto 
    };

    componentDidMount = () => {
        this.getGdprApproval();
    }

    render() {
        if (!this.state.gdprApproval) return null;
        
        return (
            <div style={{ width: '100%' }}>
                <Grid container >
                    <div className={this.props.classes.contentContainer}>
                        <Grid item xs={12} >
                            <Typography align='left' variant='body1'>
                                Godkendt af: {this.state.gdprApproval.approverFullName} ({this.state.gdprApproval.approverUsername})
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography align='left' variant='body1'>
                                Godkendt d.: {this.state.gdprApproval.timeOfApproval}
                            </Typography>
                        </Grid>    
                    </div>                                  
                </Grid>
            </div>
        )
    }

    getGdprApproval = () => {
        this.server.query<GdprApprovalDto>(
            new Api.Users.Queries.GetGdprApproval({})
        ).subscribe(result => { this.setState({ gdprApproval: result }); })
    };
})
