import { ColorTheme, ReportTheme, TextTheme } from '../report-theme'

const Text = {
    style: {
        fontFamily: 'DINPro Regular',
        fontSize: 9
    }
}

const BoldText = {
    style: {
        fontFamily: 'DINPro Medium',
        fontSize: 9
    }
}

const ItalicText = {
    style: {
        fontFamily: 'DINPro Italic',
        fontSize: 9
    }
}

const LightText = {
    style: {
        fontFamily: 'DINPro Light',
        fontSize: 9
    }
}

const SmallText = {
    style: {
        fontFamily: 'DINPro Regular',
        fontSize: 7
    }
}

const SmallBoldText = {
    style: {
        fontFamily: 'DINPro Medium',
        fontSize: 7
    }
}

const SmallItalicText = {
    style: {
        fontFamily: 'DINPro Italic',
        fontSize: 7
    }
}

const SmallBoldItalicText = {
    style: {
        fontFamily: 'DINPro Bold Italic',
        fontSize: 7
    }
}

const SmallLightText = {
    style: {
        fontFamily: 'DINPro Light',
        fontSize: 7
    }
}

const Header1 = {
    style: {
        color: '#005158',
        fontFamily: 'DINPro Bold',
        fontSize: 19
    }
}

const Header2 = {
    style: {
        color: '#005158',
        fontFamily: 'DINPro Bold',
        fontSize: 15,
        marginBottom: 0
    }
}

const Header3 = {
    style: {
        fontFamily: 'DINPro Medium',
        fontSize: 12
    }
}

const TOCHeader = {
    style: {
        color: '#005158',
        fontFamily: 'DINPro Bold',
        fontSize: 19
    }
}

const TOCText = {
    style: {
        fontFamily: 'DINPro Regular',
        fontSize: 9,
        lineHeight: 13 / 7
    }
}

const BudgetText = {
    style: {
        fontFamily: 'DINPro Regular',
        fontSize: 7
    }
}

const BudgetBoldText = {
    style: {
        fontFamily: 'DINPro Medium',
        fontSize: 7
    }
}

const BudgetItalicText = {
    style: {
        fontFamily: 'DINPro Italic',
        fontSize: 7
    }
}

const BudgetSmallText = {
    style: {
        fontFamily: 'DINPro Regular',
        fontSize: 6
    }
}

const BudgetSmallBoldText = {
    style: {
        fontFamily: 'DINPro Medium',
        fontSize: 6
    }
}

const BudgetSmallItalicText = {
    style: {
        fontFamily: 'DINPro Italic',
        fontSize: 6
    }
}

const BudgetSmallBoldItalicText = {
    style: {
        fontFamily: 'DINPro Bold Italic',
        fontSize: 6
    }
}

const EbasBudgetTextTheme: TextTheme = {
    Text: BudgetText,
    BoldText: BudgetBoldText,
    ItalicText: BudgetItalicText,
    LightText: LightText,
    SmallText: BudgetSmallText,
    SmallBoldText: BudgetSmallBoldText,
    SmallItalicText: BudgetSmallItalicText,
    SmallBoldItalicText: BudgetSmallBoldItalicText,
    SmallLightText: SmallLightText,
    Header1: Header1,
    Header2: Header2,
    Header3: Header3,
    TOCHeader: TOCHeader,
    TOCText: TOCText
}

const EbasTextTheme: TextTheme = {
    Text: Text,
    BoldText: BoldText,
    ItalicText: ItalicText,
    LightText: LightText,
    SmallText: SmallText,
    SmallBoldText: SmallBoldText,
    SmallItalicText: SmallItalicText,
    SmallBoldItalicText: SmallBoldItalicText,
    SmallLightText: SmallLightText,
    Header1: Header1,
    Header2: Header2,
    Header3: Header3,
    TOCHeader: TOCHeader,
    TOCText: TOCText
}

const EbasColorTheme: ColorTheme = {
    primaryColor: '#005158',
    secondaryColor: '#B2D5CE',
    tertiaryColor: '#C9E1DC',
    quaternaryColor: '#DFECE9'
}

export const EbasReportTheme: ReportTheme = {
    color: EbasColorTheme,
    text: EbasTextTheme
}

export const EbasBudgetTheme: ReportTheme = {
    color: EbasColorTheme,
    text: EbasBudgetTextTheme
}