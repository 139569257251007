import React, { useRef } from 'react';
import { makeStyles } from "@material-ui/core";
import { Button } from "../button";
import _ from 'lodash';
import { useWriteLock } from 'custom-hooks';
import { Lockable } from 'utils/lockable';
import $ from 'jquery';

const maxSize = 90 * 1024 * 1024;

type Props = React.PropsWithChildren<{
    onUpload: (files: File[]) => void,
    accept?: string,
    label?: string,
    multiple?: boolean,
}> & Lockable;

const useStyles = makeStyles({
    fileInput: {
        display: 'none'
    },
    control: {
        display: 'inherit'
    }
});

export const FileUpload = (props: Props) => {

    const fileInputElement = useRef(null);

    const styles = useStyles();
    const writeLock = useWriteLock(props);

    const onBrowseFiles: React.FormEventHandler<any> = (event) => {
        uploadFiles(Array.from((event.target as HTMLInputElement).files));
    }

    const uploadFiles = (files: File[]) => {
        if (!validateUpload(files) || writeLock) {
            return;
        }
        
        props.onUpload(files);
        $(fileInputElement.current).val(null); // This ensures we can download the same file again.
    }

    const validateUpload = (files: File[]) => {
        return _.reduce(files, ((memo: any, file: any) => memo + file.size), 0) <= maxSize;
    }    

    return (
        <div className={styles.control}>
            <input
                type='file'
                disabled={writeLock}
                multiple={props.multiple}
                className={styles.fileInput}
                ref={fileInputElement}
                accept={props.accept || '*'}
                onChange={onBrowseFiles}
            />
            {
                props.children 
                    ? <div onClick={() => $(fileInputElement.current).click()} className={styles.control}>{props.children}</div> 
                    : <Button label={props.label || 'Vælg fil' } onClick={() => $(fileInputElement.current).click()}/> 
            }                                   
        </div>
    );
}