import React, { useState, useEffect } from "react";
import { DialogTitle, DialogContent, DialogActions, Toolbar, DialogContentText } from "@material-ui/core";
import { Button } from "controls/button";
import { FormField } from "controls/field/form-field";
import { hasValue } from "utils";
import {Popup} from 'controls/popup';
import { v4 as uuid } from 'uuid';
import Api from "server/api";

type Props = {
    open: boolean;
    onOk: (result: NewCompanyResult) => Promise<void>;
    onClose: () => void;
}

export type NewCompanyResult = {
    companyName: string;
    username: string;
    fullName: string;
    initials: string;
    alternativeEmail: string;
    accountId: Api.Guid
}

export const NewCompanyPopup = (props: Props) =>  {

    const [companyName, setCompanyName] = useState<string>(null);
    const [username, setUsername] = useState<string>(null);
    const [fullName, setFullName] = useState<string>(null);
    const [initials, setInitials] = useState<string>(null);
    const [alternativeEmail, setAlternativeEmail] = useState<string>(null);

    useEffect(() => {
        setCompanyName(null);
        setUsername(null);
        setFullName(null);
        setInitials(null);
        setAlternativeEmail(null);
    }, [props.open]);

    const canExecute = hasValue(companyName) && hasValue(username) && hasValue(fullName) && hasValue(initials);

    const onOk = () => {
        if(!canExecute) return;
        
        return props.onOk({
            companyName,
            username,
            fullName,
            initials,
            alternativeEmail,
            accountId: uuid()
        });
    }

    return <Popup
        maxWidth='md'
        open={props.open}
        onClose={props.onClose}                
        onCancel={props.onClose}
        onOk={onOk}
        >
        <DialogTitle>Opret firma</DialogTitle>
        <DialogContent>
            <DialogContentText>Indtast firmanavn og administrator-oplysninger.</DialogContentText>
            <FormField
                type='text'
                label='Firmanavn'
                value={companyName}
                onValidChange={setCompanyName}
                autoFocus
            />
            <FormField
                type='text'
                label='Administrators brugernavn (email)'
                value={username}
                onValidChange={setUsername}
            />
            <FormField
                type='text'
                label='Administrators navn'
                value={fullName}
                onValidChange={setFullName}
            />
            <FormField
                type='text'
                label='Administrators initialer'
                value={initials}
                onValidChange={setInitials}
            />
            <FormField
                type='text'
                label='Administrators alternative email'
                value={alternativeEmail}
                optional
                onValidChange={setAlternativeEmail}          
            />
        </DialogContent>
        <DialogActions>
            <Toolbar>
                <Button label='Fortryd' onClick={props.onClose}/>                        
                <Button 
                    label='Opret'
                    disabled={ !canExecute }
                    onClick={ onOk } />
            </Toolbar>
        </DialogActions>
    </Popup>
}