import React, { useState, useEffect } from "react";
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Toolbar, RadioGroup, Radio, FormControlLabel, makeStyles } from "@material-ui/core";
import styles from './styles'
import { Button } from "controls/button";
import { FormField } from "controls/field/form-field";
import { hasValue } from "utils";
import {Popup} from 'controls/popup';
import Api from "server/api";
import CaseTypeDto = Api.Cases.Queries.GetCaseTypes.CaseTypeDto;
import { LighterThemeProvider } from "theme";

export type CaseData = {
    readonly name: string
    readonly caseType: string
}

type Props = {
    open: boolean;
    onOk: (data: CaseData) => Promise<unknown>;
    onClose: () => void;
    caseTypes: CaseTypeDto[]
}

const useStyles = makeStyles(styles);

export const CreateCasePopup = (props: Props) => {
    
    const [name, setName] = useState<string>();
    const [selectedCaseType, setSelectedCaseType] = useState<string>();

    const styles = useStyles();

    useEffect(() => {
        setName('');
        setSelectedCaseType(props.caseTypes[0]?.name);
    }, [props.open]);

    const canExecute = () => hasValue(name) && selectedCaseType;

    const execute = () => canExecute() && props.onOk({ name: name, caseType: selectedCaseType })

    return (
        <Popup
            maxWidth='md'
            open={props.open}
            onClose={props.onClose}
            onOk={execute}
            onCancel={props.onClose}>

            <DialogTitle>Opret sag</DialogTitle>

            <DialogContent>
                <DialogContentText gutterBottom={true}>
                    Vælg rapporttype og angiv sagsnavn for at oprette en sag
                </DialogContentText>

                <LighterThemeProvider>
                    <RadioGroup aria-label="rapport type" value={selectedCaseType} onChange={(_, value) => setSelectedCaseType(value)}>
                        {props.caseTypes.map(caseType =>
                            <FormControlLabel key={caseType.name} value={caseType.name} control={<Radio color="primary" style={{ padding: '5px 12px' }} />} label={caseType.displayName} />
                        )}
                    </RadioGroup>
                </LighterThemeProvider>

                <FormField
                    type='text'
                    label='Sagsnavn'
                    value={name}
                    onValidChange={setName}
                    autoFocus={true} />
            </DialogContent>

            <DialogActions>
                <Toolbar>
                    <Button label='Fortryd' onClick={props.onClose} />
                    <Button label='Opret' disabled={!canExecute()} onClick={execute} />
                </Toolbar>
            </DialogActions>
        </Popup>);
}