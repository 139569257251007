import React, { useState } from "react";
import AnonymousPage from "controls/anonymous-page";
import { sguid } from 'utils';
import { RequestByUsername } from "./request-by-username";
import { useCommand, pipe, after, useLoadingState} from "custom-hooks";
import { includeAllErrors, DvError, useErrorDisplay } from "custom-hooks";
import Api from "server/api";

const PasswordResetRequested = () =>
    <div style={{flex: 1, flexDirection:'column'}}>
        <div style={{display:'flex', flexDirection:'column', alignItems: 'center'}}>
            <div style={{fontSize: 30}}>Nulstilling af adgangskode påbegyndt.</div>
            <p style={{fontSize: 14}}>
                Inden for få minutter vil du modtage en email med hjælp til at oprette 
                en ny adgangskode.
            </p>

            <p style={{fontSize: 14}}>Nulstillingen skal af sikkerhedsmæssige årsager ske inden for én time.</p>

            <p style={{fontSize: 14}}>Hvis du ikke modtager denne email, kan det ske, at den findes i uønsket post. Du er altid velkommen til at kontakte vores support på tlf. 7022 9310, såfremt du har spørgsmål.</p>
            
            <p style={{fontSize: 14}}>Du kan komme til login-siden <a style={{color: '#56DFA6', textDecoration: 'None'}} href='/'>her</a>.</p>
        </div>  
    </div>   

export const RequestResetPassword = () =>  {
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const errorDisplay = useErrorDisplay<DvError>(includeAllErrors);
    const loadingState = useLoadingState();
    
    const requestResetPassword = useCommand((username: string) => 
        new Api.Users.Commands.RequestPasswordReset({
            newPasswordResetProcessId: `password-reset-process/${sguid()}`,
            username: username
        }),
        pipe(
            errorDisplay,
            after(async x => setIsCompleted(true)),
            loadingState
        ),
        '/api/user/'
    );
            
    return ( 
        <AnonymousPage>
            {
                !isCompleted 
                    ? <RequestByUsername loading={loadingState.loading} requestResetPassword={requestResetPassword} errors={errorDisplay.errors}/> 
                    : <PasswordResetRequested/>
            }
        </AnonymousPage>
        )   
}