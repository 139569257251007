import React from 'react'
import { Document } from '@react-pdf/renderer';
import Api from 'server/api'
import { match } from 'utils';
import { Page as EsPage } from './controls'
import FrontPage from './front-page';
import BudgetPage from './budget-page';
import TableOfContents from './table-of-contents';
import { FutureWorkPage } from './deas/future-work-page'
import { Options } from '../generate-report';
import _ from 'lodash'
import { CaseType } from '../generate-report'
import { BudgetThemeContextProvider, ContextProvider } from './context-provider'

import { EsGuidePage } from './es/es-guide-page';
import { EsEstatePage } from './es/es-estate-page';
import { EsCardPage } from './es/es-card-page';

import { PurposePage } from './deas/purpose-page';
import { DeasEstatePage } from './deas/deas-estate-page';
import { DeasGuidePage } from './deas/deas-guide-page';

import { Page as EbasPage } from './ebas/ebas-controls';
import { EbasFrontPage } from './ebas/ebas-front-page'
import { EbasGuidePage } from './ebas/ebas-guide-page';
import { EbasEstatePage } from './ebas/ebas-estate-page';
import { EbasCardPage } from './ebas/ebas-card-page';

import { MmakeGuidePage } from './mmake/mmake-guide-page';
import { ConstructionProjectPage } from './mmake/construction-project-page';
import { MmakeCardPage } from './mmake/mmake-card-page';

import { BjornsholmEstatePage } from './bjornsholm/bjornsholm-estate-page';

import ReportDto = Api.Cases.Queries.Reports.Es.EsReportDto;

export type EsReportProps = {
    reportData: ReportDto,
    options: Options
}

export const EsReport = (props: EsReportProps) => {
    const caseType = props.reportData.caseType as CaseType;

    const Page = caseType === 'EbasCase'
        ? EbasPage
        : EsPage
        
    return <Document>
        <ContextProvider {...props}>
            {
                match(caseType)
                    .on("EbasCase", <Page key="front-page" noHeader noFooter style={{ backgroundColor: '#B2D5CE' }}>
                            <EbasFrontPage masterData={props.reportData.masterData} />
                        </Page>)
                    .else(<Page key="front-page" noHeader noFooter>
                            <FrontPage masterData={props.reportData.masterData} />
                        </Page>)
            }
            <Page key="table-of-contents-page">
                <TableOfContents />
            </Page>
            <Page key="guide-page" >
                {
                    match(caseType)
                        .on("EbasCase", <EbasGuidePage data={props.reportData.guidePage} />)
                        .on("MmakeCase", <MmakeGuidePage data={props.reportData.guidePage} />)
                        .on("DeasCase", <DeasGuidePage data={props.reportData.guidePage} />)
                        .else(<EsGuidePage data={props.reportData.guidePage} />)
                }
            </Page>
            <Page>
                {
                    match(caseType)
                        .on("DeasCase", <DeasEstatePage {...props.reportData.estatePage} />)
                        .on("EbasCase", <EbasEstatePage {...props.reportData.estatePage} />)
                        .on("BjornsholmCase", <BjornsholmEstatePage {...props.reportData.estatePage} />)
                        .else(<EsEstatePage {...props.reportData.estatePage} />)
                }
            </Page>
            {
                caseType === "DeasCase" && props.reportData.extensions.deas &&
                <>
                    <Page key="purpose-page" >
                        <PurposePage {...props.reportData.extensions.deas.deasPurposePage} />
                    </Page>
                    <Page key="future-work-page" >
                        <FutureWorkPage />
                    </Page>
                </>
            }
            {
                caseType === "MmakeCase" &&
                <>
                    <Page key="construction-project-page" >
                        <ConstructionProjectPage />
                    </Page>
                </>
            }
            {props.reportData.cardPages.map(page =>
                <Page key={page.header.number}>
                    {
                        match(caseType)
                            .on("EbasCase", <EbasCardPage {...page} />)
                            .on("MmakeCase", <MmakeCardPage {...page} />)
                            .else(<EsCardPage {...page} />)
                    }
                </Page>
            )}
            <BudgetThemeContextProvider caseType={caseType}>
                {props.reportData.budgetPages.map((page, i) =>
                    <Page key={`bugdet-page-${i}`} orientation="landscape">
                        {
                            match(caseType)
                                .on("MmakeCase", <BudgetPage {...{ index: i, ...page, footNote: '' }} />)
                                .else(<BudgetPage {...{ index: i, ...page, footNote: props.reportData.budgetNote }} />)
                        }
                    </Page>)}
            </BudgetThemeContextProvider>
        </ContextProvider>
    </Document>
};