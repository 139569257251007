import React from "react";
import { toDvError, DvError } from "./custom-hooks/use-error-display";
import { PropsWithChild } from "./utils";
import { ErrorPanel } from "./controls/global-error-panel";

export class RootErrorBoundary extends React.Component<PropsWithChild<{}>, { error: DvError | null }> {
    constructor(props: {}) {
        super(props);

        this.state = { error: null }
    }

    static getDerivedStateFromError = (error: any) => ({ 'error': toDvError(error) });

    render = () => 
        this.state.error
            ? <ErrorPanel errors={[this.state.error]} onRemove={() => this.setState({ error: null })}/>
            : React.Children.only(this.props.children);
}
