import React, { useState, useMemo } from "react";
import Api from "server/api";
import { Accordion, AccordionDetails, AccordionSummary, Box, FormControlLabel, makeStyles, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CaseSummary } from "./case-summary";
import styles from './styles'
import Page from 'controls/page'
import { Paper } from 'controls/paper'
import { CreateCase } from 'pages/toolbar/create-case';
import Toolbar from 'pages/toolbar/toolbar';
import _, { mapValues, keyBy } from "lodash";
import { useServer, useNavigation, useCommand, useImmutableArrayState, useCommandSubscription, useOpenIds } from "custom-hooks";
import { decorator } from "custom-hooks/use-command";
import { remove } from "utils/immutable-array";
import { LighterThemeProvider } from "theme";
import { sguid } from "utils";

import CaseDto = Api.Cases.Queries.GetCases.CaseDto;
import CaseProgress = Api.Cases.Queries.GetCases.CaseProgress;
import CaseTypeDto = Api.Cases.Queries.GetCaseTypes.CaseTypeDto;

const useStyles = makeStyles(styles);

const SearchField = (props: { label: string, onChange: React.ChangeEventHandler }) => {
    const styles = useStyles();

    return <TextField
        className={styles.searchField}
        color='secondary'
        label={props.label}
        margin='dense'
        variant="outlined"
        onChange={props.onChange} />
}

type ProgressPickerChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>, value: 'Draft' | 'Completed' | 'Deleted') => void;

const ProgressPicker = (props: { value: CaseProgress, onChange: ProgressPickerChangeEventHandler }) =>{
    const styles = useStyles();

    return <RadioGroup
        aria-label='progress'
        className={styles.radioButtons}
        value={CaseProgress[props.value]}
        onChange={props.onChange}
    >
        <FormControlLabel value='Draft' control={<Radio color='secondary' />} label="Kladder" />
        <FormControlLabel value='Completed' control={<Radio color='secondary' />} label="Afsluttede" />
        <FormControlLabel value='Deleted' control={<Radio color='secondary' />} label="Slettede" />
    </RadioGroup>
}

type CasesProps = {
    caseTypes: CaseTypeDto[];
}

export const Cases = (props: CasesProps) => {
    
    const styles = useStyles();
    const server = useServer();
    const navigation = useNavigation();
    const openIds = useOpenIds(false);
 
    const [cases, setCases] = useImmutableArrayState<CaseDto>();
    const [progressFilter, setProgressFilter] = useState(CaseProgress.Draft);
    const [caseNameFilter, setCaseNameFilter] = useState("");
    const [nickNameFilter, setNickNameFilter] = useState("");
    const [addressFilter, setAddressFilter] = useState("");

    useCommandSubscription(() => server.query<CaseDto[]>(
        new Api.Cases.Queries.GetCases({
            progressFilter: progressFilter,
            caseNameFilter: caseNameFilter,
            nickNameFilter: nickNameFilter,
            addressFilter: addressFilter
        })).subscribe(cases => setCases(cases)), [server, progressFilter, caseNameFilter, nickNameFilter, addressFilter]);

    const deleteCase = useCommand((caseId: string) =>
        new Api.Cases.Commands.DeleteCase({ caseId }),
        decorator<Api.Cases.Commands.DeleteCase>(async (command, next) => {
            setCases(remove(c => c.id === command.caseId));
            const result = await next(command);
            navigation.goto('/'); //doing this prevents people from trying to do stuff with the deleted case
            return result;
        }));

    const changeCaseType = useCommand((caseId: string, caseType: string) => 
        new Api.Cases.Commands.ChangeCaseType({
            oldCaseId: caseId,
            newCaseId: `case/${sguid()}`,
            caseType: caseType
        }),
        decorator<Api.Cases.Commands.ChangeCaseType>(async (command, next) => {
            const result = await next(command);

            // Navigate away from deleted old case
            navigation.goto(openIds.caseId == command.oldCaseId 
                ? `/cases/${command.newCaseId}`
                : '/');

            return result;
        }));

    const updateProgressFilter =  ({}, value: 'Draft' | 'Completed' | 'Deleted') => setProgressFilter(CaseProgress[value]);
    const updateCaseNameFilter = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => setCaseNameFilter(e.target.value), 250);
    const updateNickNameFilter = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => setNickNameFilter(e.target.value), 250);
    const updateAddressFilter = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => setAddressFilter(e.target.value), 250);
    const caseDuplicated = () => setProgressFilter(CaseProgress.Draft);

    const casesWithDisplayName = useMemo(() => {
        const displayNamesByType = mapValues(keyBy(props.caseTypes, x => x.name), x => x.displayName);

        return cases.map(c => ({ ...c, typeDisplayName: displayNamesByType[c.type] }));
    }, [cases, props.caseTypes]);

    return <>
        <Page>
            <Paper>
                <LighterThemeProvider>
                    <Accordion defaultExpanded>
                        <AccordionSummary id="case-search-header" aria-controls="case-search-content" expandIcon={<ExpandMoreIcon />}>
                            <Typography variant='h6' className={styles.title}>Søgning</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ flexWrap: 'wrap' }}>
                            <Box style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                                <SearchField label="Sagsnavn" onChange={updateCaseNameFilter} />
                                <SearchField label="Kaldenavn" onChange={updateNickNameFilter} />
                                <SearchField label="Adresse" onChange={updateAddressFilter} />
                            </Box>
                            <ProgressPicker value={progressFilter} onChange={updateProgressFilter} />
                        </AccordionDetails>
                    </Accordion>
                </LighterThemeProvider>
            </Paper>
            <Paper>
                <Table>
                    <colgroup>
                        <col width="20%" />
                        <col width="20%" />
                        <col width="20%" />
                        <col width="10%" />
                        <col width="10%" />
                        <col width="10%" />
                        <col width="10%" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell>Sagsnavn</TableCell>
                            <TableCell>Kaldenavn</TableCell>
                            <TableCell>Adresse</TableCell>
                            <TableCell>Kunde</TableCell>
                            <TableCell>Godkendt af</TableCell>
                            <TableCell>Besigtigelse</TableCell>
                            <TableCell>Rapporttype</TableCell>
                            <TableCell>{progressFilter === CaseProgress.Completed ? 'Afsluttet' : ''}</TableCell>
                            {progressFilter === CaseProgress.Completed && <TableCell />}
                        </TableRow>
                    </TableHead>
                    <TableBody>{casesWithDisplayName.map($case => (
                        <CaseSummary key={$case.id}
                            case={$case}
                            onCaseDuplicated={caseDuplicated}
                            openCase={(caseId) => navigation.goto(`/budget/${caseId}`)}
                            changeCaseType={changeCaseType}
                            deleteCase={deleteCase}
                            caseTypes={props.caseTypes} />))}
                    </TableBody>
                </Table>
            </Paper>
            <div className={styles.spacer}></div>
        </Page>
        <Toolbar>
            <CreateCase caseTypes={props.caseTypes} />
        </Toolbar>
    </>
}