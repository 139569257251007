/*
    Generated with Sideshow.TypeScript.exe
    Should not be edited directly - should probably be regenerated instead :)
*/

namespace Api {

/* Domain commands */
export namespace Users.Commands {
    export class ApproveGdpr implements Command {

        constructor(args: {}) {
            
        }

        $commandType = 'DV.Application.Users.Api.Commands.ApproveGdpr, DV.Application';
        $commandName = 'Users.Commands.ApproveGdpr';
    }
}

export namespace Users.Commands {
    export class ChangePassword_ByConsultant implements Command {

        constructor(args: {oldPassword: string, newPassword: string}) {
            this.oldPassword = args.oldPassword;
            this.newPassword = args.newPassword;
        }

        $commandType = 'DV.Application.Users.Api.Commands.ChangePassword_ByConsultant, DV.Application';
        $commandName = 'Users.Commands.ChangePassword_ByConsultant';
        oldPassword: string;
        newPassword: string;
    }
}

export namespace Users.Commands {
    export class ChangePassword_BySysAdmin implements Command {

        constructor(args: {profileId: string, newPassword: string}) {
            this.profileId = args.profileId;
            this.newPassword = args.newPassword;
        }

        $commandType = 'DV.Application.Users.Api.Commands.ChangePassword_BySysAdmin, DV.Application';
        $commandName = 'Users.Commands.ChangePassword_BySysAdmin';
        profileId: string;
        newPassword: string;
    }
}

export namespace Users.Commands {
    export class CreateAccount implements Command {

        constructor(args: {accountId: Guid}) {
            this.accountId = args.accountId;
        }

        $commandType = 'DV.Application.Users.Api.Commands.CreateAccount, DV.Application';
        $commandName = 'Users.Commands.CreateAccount';
        accountId: Guid;
    }
}

export namespace Users.Commands {
    export class CreateTypeMappings implements Command {

        constructor(args: {newTypeMappingsId: string, name: string}) {
            this.newTypeMappingsId = args.newTypeMappingsId;
            this.name = args.name;
        }

        $commandType = 'DV.Application.Users.Api.Commands.CreateTypeMappings, DV.Application';
        $commandName = 'Users.Commands.CreateTypeMappings';
        newTypeMappingsId: string;
        name: string;
    }
}

export namespace Users.Commands {
    export class DeleteCompanyLogo implements Command {

        constructor(args: {}) {
            
        }

        $commandType = 'DV.Application.Users.Api.Commands.DeleteCompanyLogo, DV.Application';
        $commandName = 'Users.Commands.DeleteCompanyLogo';
    }
}

export namespace Users.Commands {
    export class DisableCompany implements Command {

        constructor(args: {companyId: string}) {
            this.companyId = args.companyId;
        }

        $commandType = 'DV.Application.Users.Api.Commands.DisableCompany, DV.Application';
        $commandName = 'Users.Commands.DisableCompany';
        companyId: string;
    }
}

export namespace Users.Commands {
    export class DisableProfile implements Command {

        constructor(args: {profileId: string}) {
            this.profileId = args.profileId;
        }

        $commandType = 'DV.Application.Users.Api.Commands.DisableProfile, DV.Application';
        $commandName = 'Users.Commands.DisableProfile';
        profileId: string;
    }
}

export namespace Users.Commands {
    export class EnableCompany implements Command {

        constructor(args: {companyId: string}) {
            this.companyId = args.companyId;
        }

        $commandType = 'DV.Application.Users.Api.Commands.EnableCompany, DV.Application';
        $commandName = 'Users.Commands.EnableCompany';
        companyId: string;
    }
}

export namespace Users.Commands {
    export class ImportTypeMappings implements Command {

        constructor(args: {oldTypeMappingsId: string, newTypeMappingsId: string, name: string}) {
            this.oldTypeMappingsId = args.oldTypeMappingsId;
            this.newTypeMappingsId = args.newTypeMappingsId;
            this.name = args.name;
        }

        $commandType = 'DV.Application.Users.Api.Commands.ImportTypeMappings, DV.Application';
        $commandName = 'Users.Commands.ImportTypeMappings';
        oldTypeMappingsId: string;
        newTypeMappingsId: string;
        name: string;
    }
}

export namespace Users.Commands {
    export class NewCompany implements Command {

        constructor(args: {newCompanyId: string, companyName: string, newProfileId: string, username: string, fullName: string, initials: string, alternativeEmail: string, accountId: Guid}) {
            this.newCompanyId = args.newCompanyId;
            this.companyName = args.companyName;
            this.newProfileId = args.newProfileId;
            this.username = args.username;
            this.fullName = args.fullName;
            this.initials = args.initials;
            this.alternativeEmail = args.alternativeEmail;
            this.accountId = args.accountId;
        }

        $commandType = 'DV.Application.Users.Api.Commands.NewCompany, DV.Application';
        $commandName = 'Users.Commands.NewCompany';
        newCompanyId: string;
        companyName: string;
        newProfileId: string;
        username: string;
        fullName: string;
        initials: string;
        alternativeEmail: string;
        accountId: Guid;
    }
}

export namespace Users.Commands {
    export class NewConsultant_ByCompanyAdmin implements Command {

        constructor(args: {newProfileId: string, username: string, fullName: string, initials: string, alternativeEmail: string}) {
            this.newProfileId = args.newProfileId;
            this.username = args.username;
            this.fullName = args.fullName;
            this.initials = args.initials;
            this.alternativeEmail = args.alternativeEmail;
        }

        $commandType = 'DV.Application.Users.Api.Commands.NewConsultant_ByCompanyAdmin, DV.Application';
        $commandName = 'Users.Commands.NewConsultant_ByCompanyAdmin';
        newProfileId: string;
        username: string;
        fullName: string;
        initials: string;
        alternativeEmail: string;
    }
}

export namespace Users.Commands {
    export class NewConsultant_BySelfRegistration implements Command {

        constructor(args: {newUserProcessId: string, username: string, password: string, fullName: string, companyPhoneNumber: string, companyName: string, accountId: Guid}) {
            this.newUserProcessId = args.newUserProcessId;
            this.username = args.username;
            this.password = args.password;
            this.fullName = args.fullName;
            this.companyPhoneNumber = args.companyPhoneNumber;
            this.companyName = args.companyName;
            this.accountId = args.accountId;
        }

        $commandType = 'DV.Application.Users.Api.Commands.NewConsultant_BySelfRegistration, DV.Application';
        $commandName = 'Users.Commands.NewConsultant_BySelfRegistration';
        newUserProcessId: string;
        username: string;
        password: string;
        fullName: string;
        companyPhoneNumber: string;
        companyName: string;
        accountId: Guid;
    }
}

export namespace Users.Commands {
    export class NewConsultant_BySelfRegistration_ConfirmEmail implements Command {

        constructor(args: {newUserProcessId: string}) {
            this.newUserProcessId = args.newUserProcessId;
        }

        $commandType = 'DV.Application.Users.Api.Commands.NewConsultant_BySelfRegistration_ConfirmEmail, DV.Application';
        $commandName = 'Users.Commands.NewConsultant_BySelfRegistration_ConfirmEmail';
        newUserProcessId: string;
    }
}

export namespace Users.Commands {
    export class RequestPasswordReset implements Command {

        constructor(args: {newPasswordResetProcessId: string, username: string}) {
            this.newPasswordResetProcessId = args.newPasswordResetProcessId;
            this.username = args.username;
        }

        $commandType = 'DV.Application.Users.Api.Commands.RequestPasswordReset, DV.Application';
        $commandName = 'Users.Commands.RequestPasswordReset';
        newPasswordResetProcessId: string;
        username: string;
    }
}

export namespace Users.Commands {
    export class ResetPassword implements Command {

        constructor(args: {token: string, newPassword: string}) {
            this.token = args.token;
            this.newPassword = args.newPassword;
        }

        $commandType = 'DV.Application.Users.Api.Commands.ResetPassword, DV.Application';
        $commandName = 'Users.Commands.ResetPassword';
        token: string;
        newPassword: string;
    }
}

export namespace Users.Commands {
    export class UpdateProfile implements Command {

        constructor(args: {profileId: string, fullName: string, initials: string, alternativeEmail: string, rights: DV.Application.Users.ProfileRights}) {
            this.profileId = args.profileId;
            this.fullName = args.fullName;
            this.initials = args.initials;
            this.alternativeEmail = args.alternativeEmail;
            this.rights = args.rights;
        }

        $commandType = 'DV.Application.Users.Api.Commands.UpdateProfile, DV.Application';
        $commandName = 'Users.Commands.UpdateProfile';
        profileId: string;
        fullName: string;
        initials: string;
        alternativeEmail: string;
        rights: DV.Application.Users.ProfileRights;
    }
}

export namespace Users.Commands {
    export class UpdateTypeMappings implements Command {

        constructor(args: {mappingsId: string, mappings: readonly Users.Commands.UpdateTypeMappings.MappingDto[]}) {
            this.mappingsId = args.mappingsId;
            this.mappings = args.mappings;
        }

        $commandType = 'DV.Application.Users.Api.Commands.UpdateTypeMappings, DV.Application';
        $commandName = 'Users.Commands.UpdateTypeMappings';
        mappingsId: string;
        mappings: readonly Users.Commands.UpdateTypeMappings.MappingDto[];
    }
}

export namespace Users.Commands {
    export class UploadCompanyLogo implements Command {

        constructor(args: {logoId: string, data: string}) {
            this.logoId = args.logoId;
            this.data = args.data;
        }

        $commandType = 'DV.Application.Users.Api.Commands.UploadCompanyLogo, DV.Application';
        $commandName = 'Users.Commands.UploadCompanyLogo';
        logoId: string;
        data: string;
    }
}

export namespace Users.Commands {
    export class WriteCompanyField implements Command {

        constructor(args: {fieldName: string, value: any}) {
            this.fieldName = args.fieldName;
            this.value = args.value;
        }

        $commandType = 'DV.Application.Users.Api.Commands.WriteCompanyField, DV.Application';
        $commandName = 'Users.Commands.WriteCompanyField';
        fieldName: string;
        value: any;
    }
}

export namespace Users.Commands {
    export class WriteProfileField implements Command {

        constructor(args: {profileId: string, fieldName: string, value: any}) {
            this.profileId = args.profileId;
            this.fieldName = args.fieldName;
            this.value = args.value;
        }

        $commandType = 'DV.Application.Users.Api.Commands.WriteProfileField, DV.Application';
        $commandName = 'Users.Commands.WriteProfileField';
        profileId: string;
        fieldName: string;
        value: any;
    }
}

export namespace Templates.Commands {
    export class ChangeTemplateGroupOrder implements Command {

        constructor(args: {groupName: string, newOrder: number}) {
            this.groupName = args.groupName;
            this.newOrder = args.newOrder;
        }

        $commandType = 'DV.Application.Templates.Api.Commands.ChangeTemplateGroupOrder, DV.Application';
        $commandName = 'Templates.Commands.ChangeTemplateGroupOrder';
        groupName: string;
        newOrder: number;
    }
}

export namespace Templates.Commands {
    export class DeleteTemplateGroup implements Command {

        constructor(args: {groupName: string}) {
            this.groupName = args.groupName;
        }

        $commandType = 'DV.Application.Templates.Api.Commands.DeleteTemplateGroup, DV.Application';
        $commandName = 'Templates.Commands.DeleteTemplateGroup';
        groupName: string;
    }
}

export namespace Templates.Commands {
    export class ImportTemplates implements Command {

        constructor(args: {groupName: string, data: string}) {
            this.groupName = args.groupName;
            this.data = args.data;
        }

        $commandType = 'DV.Application.Templates.Api.Commands.ImportTemplates, DV.Application';
        $commandName = 'Templates.Commands.ImportTemplates';
        groupName: string;
        data: string;
    }
}

export namespace Cases.Commands {
    export class ChangeCardType implements Command {

        constructor(args: {caseId: string, cardId: string, group: string, type: string}) {
            this.caseId = args.caseId;
            this.cardId = args.cardId;
            this.group = args.group;
            this.type = args.type;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.ChangeCardType, DV.Application';
        $commandName = 'Cases.Commands.ChangeCardType';
        caseId: string;
        cardId: string;
        group: string;
        type: string;
    }
}

export namespace Cases.Commands {
    export class ChangeCaseType implements Command {

        constructor(args: {oldCaseId: string, newCaseId: string, caseType: string}) {
            this.oldCaseId = args.oldCaseId;
            this.newCaseId = args.newCaseId;
            this.caseType = args.caseType;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.ChangeCaseType, DV.Application';
        $commandName = 'Cases.Commands.ChangeCaseType';
        oldCaseId: string;
        newCaseId: string;
        caseType: string;
    }
}

export namespace Cases.Commands {
    export class CompleteCase implements Command {

        constructor(args: {caseId: string, reportData: string, reportVersion: number}) {
            this.caseId = args.caseId;
            this.reportData = args.reportData;
            this.reportVersion = args.reportVersion;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.CompleteCase, DV.Application';
        $commandName = 'Cases.Commands.CompleteCase';
        caseId: string;
        reportData: string;
        reportVersion: number;
    }
}

export namespace Cases.Commands {
    export class CreateBudgetLine implements Command {

        constructor(args: {caseId: string, cardId: string, taskId: string, newBudgetLineId: string}) {
            this.caseId = args.caseId;
            this.cardId = args.cardId;
            this.taskId = args.taskId;
            this.newBudgetLineId = args.newBudgetLineId;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.CreateBudgetLine, DV.Application';
        $commandName = 'Cases.Commands.CreateBudgetLine';
        caseId: string;
        cardId: string;
        taskId: string;
        newBudgetLineId: string;
    }
}

export namespace Cases.Commands {
    export class CreateBuilding implements Command {

        constructor(args: {caseId: string, buildingId: string}) {
            this.caseId = args.caseId;
            this.buildingId = args.buildingId;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.CreateBuilding, DV.Application';
        $commandName = 'Cases.Commands.CreateBuilding';
        caseId: string;
        buildingId: string;
    }
}

export namespace Cases.Commands {
    export class CreateCard implements Command {

        constructor(args: {caseId: string, newCardId: string, templateId: string, group: string, type: string, description: string, location: string}) {
            this.caseId = args.caseId;
            this.newCardId = args.newCardId;
            this.templateId = args.templateId;
            this.group = args.group;
            this.type = args.type;
            this.description = args.description;
            this.location = args.location;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.CreateCard, DV.Application';
        $commandName = 'Cases.Commands.CreateCard';
        caseId: string;
        newCardId: string;
        templateId: string;
        group: string;
        type: string;
        description: string;
        location: string;
    }
}

export namespace Cases.Commands {
    export class CreateCase implements Command {

        constructor(args: {caseId: string, caseType: string, name: string, indexYear: number, durationYears: number}) {
            this.caseId = args.caseId;
            this.caseType = args.caseType;
            this.name = args.name;
            this.indexYear = args.indexYear;
            this.durationYears = args.durationYears;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.CreateCase, DV.Application';
        $commandName = 'Cases.Commands.CreateCase';
        caseId: string;
        caseType: string;
        name: string;
        indexYear: number;
        durationYears: number;
    }
}

export namespace Cases.Commands {
    export class CreateTask implements Command {

        constructor(args: {caseId: string, cardId: string, templateId: string, newTaskId: string, type: Cases.TaskType, description: string}) {
            this.caseId = args.caseId;
            this.cardId = args.cardId;
            this.templateId = args.templateId;
            this.newTaskId = args.newTaskId;
            this.type = args.type;
            this.description = args.description;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.CreateTask, DV.Application';
        $commandName = 'Cases.Commands.CreateTask';
        caseId: string;
        cardId: string;
        templateId: string;
        newTaskId: string;
        type: Cases.TaskType;
        description: string;
    }
}

export namespace Cases.Commands {
    export class DeleteBudgetLine implements Command {

        constructor(args: {caseId: string, cardId: string, budgetLineId: string}) {
            this.caseId = args.caseId;
            this.cardId = args.cardId;
            this.budgetLineId = args.budgetLineId;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.DeleteBudgetLine, DV.Application';
        $commandName = 'Cases.Commands.DeleteBudgetLine';
        caseId: string;
        cardId: string;
        budgetLineId: string;
    }
}

export namespace Cases.Commands {
    export class DeleteBuilding implements Command {

        constructor(args: {caseId: string, buildingId: string}) {
            this.caseId = args.caseId;
            this.buildingId = args.buildingId;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.DeleteBuilding, DV.Application';
        $commandName = 'Cases.Commands.DeleteBuilding';
        caseId: string;
        buildingId: string;
    }
}

export namespace Cases.Commands {
    export class DeleteCard implements Command {

        constructor(args: {cardId: string}) {
            this.cardId = args.cardId;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.DeleteCard, DV.Application';
        $commandName = 'Cases.Commands.DeleteCard';
        cardId: string;
    }
}

export namespace Cases.Commands {
    export class DeleteCardImage implements Command {

        constructor(args: {cardId: string, imageId: string}) {
            this.cardId = args.cardId;
            this.imageId = args.imageId;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.DeleteCardImage, DV.Application';
        $commandName = 'Cases.Commands.DeleteCardImage';
        cardId: string;
        imageId: string;
    }
}

export namespace Cases.Commands {
    export class DeleteCase implements Command {

        constructor(args: {caseId: string}) {
            this.caseId = args.caseId;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.DeleteCase, DV.Application';
        $commandName = 'Cases.Commands.DeleteCase';
        caseId: string;
    }
}

export namespace Cases.Commands {
    export class DeleteCaseImage implements Command {

        constructor(args: {caseId: string, imageId: string}) {
            this.caseId = args.caseId;
            this.imageId = args.imageId;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.DeleteCaseImage, DV.Application';
        $commandName = 'Cases.Commands.DeleteCaseImage';
        caseId: string;
        imageId: string;
    }
}

export namespace Cases.Commands {
    export class DeleteTask implements Command {

        constructor(args: {caseId: string, cardId: string, taskId: string}) {
            this.caseId = args.caseId;
            this.cardId = args.cardId;
            this.taskId = args.taskId;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.DeleteTask, DV.Application';
        $commandName = 'Cases.Commands.DeleteTask';
        caseId: string;
        cardId: string;
        taskId: string;
    }
}

export namespace Cases.Commands {
    export class DuplicateBudgetLine implements Command {

        constructor(args: {caseId: string, cardId: string, taskId: string, originBudgetLineId: string, newBudgetLineId: string}) {
            this.caseId = args.caseId;
            this.cardId = args.cardId;
            this.taskId = args.taskId;
            this.originBudgetLineId = args.originBudgetLineId;
            this.newBudgetLineId = args.newBudgetLineId;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.DuplicateBudgetLine, DV.Application';
        $commandName = 'Cases.Commands.DuplicateBudgetLine';
        caseId: string;
        cardId: string;
        taskId: string;
        originBudgetLineId: string;
        newBudgetLineId: string;
    }
}

export namespace Cases.Commands {
    export class DuplicateCard implements Command {

        constructor(args: {oldCardId: string, newCardId: string}) {
            this.oldCardId = args.oldCardId;
            this.newCardId = args.newCardId;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.DuplicateCard, DV.Application';
        $commandName = 'Cases.Commands.DuplicateCard';
        oldCardId: string;
        newCardId: string;
    }
}

export namespace Cases.Commands {
    export class DuplicateCase implements Command {

        constructor(args: {oldCaseId: string, newCaseId: string, newName: string}) {
            this.oldCaseId = args.oldCaseId;
            this.newCaseId = args.newCaseId;
            this.newName = args.newName;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.DuplicateCase, DV.Application';
        $commandName = 'Cases.Commands.DuplicateCase';
        oldCaseId: string;
        newCaseId: string;
        newName: string;
    }
}

export namespace Cases.Commands {
    export class MarkCardImage implements Command {

        constructor(args: {cardId: string, imageId: string}) {
            this.cardId = args.cardId;
            this.imageId = args.imageId;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.MarkCardImage, DV.Application';
        $commandName = 'Cases.Commands.MarkCardImage';
        cardId: string;
        imageId: string;
    }
}

export namespace Cases.Commands {
    export class MarkCaseImage implements Command {

        constructor(args: {caseId: string, imageId: string}) {
            this.caseId = args.caseId;
            this.imageId = args.imageId;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.MarkCaseImage, DV.Application';
        $commandName = 'Cases.Commands.MarkCaseImage';
        caseId: string;
        imageId: string;
    }
}

export namespace Cases.Commands {
    export class OrderCardImages implements Command {

        constructor(args: {cardId: string, imageIds: readonly string[]}) {
            this.cardId = args.cardId;
            this.imageIds = args.imageIds;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.OrderCardImages, DV.Application';
        $commandName = 'Cases.Commands.OrderCardImages';
        cardId: string;
        imageIds: readonly string[];
    }
}

export namespace Cases.Commands {
    export class OrderCaseImages implements Command {

        constructor(args: {caseId: string, imageIds: readonly string[]}) {
            this.caseId = args.caseId;
            this.imageIds = args.imageIds;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.OrderCaseImages, DV.Application';
        $commandName = 'Cases.Commands.OrderCaseImages';
        caseId: string;
        imageIds: readonly string[];
    }
}

export namespace Cases.Commands {
    export class UnmarkCardImage implements Command {

        constructor(args: {cardId: string, imageId: string}) {
            this.cardId = args.cardId;
            this.imageId = args.imageId;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.UnmarkCardImage, DV.Application';
        $commandName = 'Cases.Commands.UnmarkCardImage';
        cardId: string;
        imageId: string;
    }
}

export namespace Cases.Commands {
    export class UpdateReport implements Command {

        constructor(args: {caseId: string, reportData: string, reportVersion: number}) {
            this.caseId = args.caseId;
            this.reportData = args.reportData;
            this.reportVersion = args.reportVersion;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.UpdateReport, DV.Application';
        $commandName = 'Cases.Commands.UpdateReport';
        caseId: string;
        reportData: string;
        reportVersion: number;
    }
}

export namespace Cases.Commands {
    export class UpdateSettingsOnCase implements Command {

        constructor(args: {caseId: string}) {
            this.caseId = args.caseId;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.UpdateSettingsOnCase, DV.Application';
        $commandName = 'Cases.Commands.UpdateSettingsOnCase';
        caseId: string;
    }
}

export namespace Cases.Commands {
    export class UploadCardImage implements Command {

        constructor(args: {cardId: string, imageId: string, data: string}) {
            this.cardId = args.cardId;
            this.imageId = args.imageId;
            this.data = args.data;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.UploadCardImage, DV.Application';
        $commandName = 'Cases.Commands.UploadCardImage';
        cardId: string;
        imageId: string;
        data: string;
    }
}

export namespace Cases.Commands {
    export class UploadCaseImage implements Command {

        constructor(args: {caseId: string, imageId: string, data: string}) {
            this.caseId = args.caseId;
            this.imageId = args.imageId;
            this.data = args.data;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.UploadCaseImage, DV.Application';
        $commandName = 'Cases.Commands.UploadCaseImage';
        caseId: string;
        imageId: string;
        data: string;
    }
}

export namespace Cases.Commands {
    export class WriteBudgetLineField implements Command {

        constructor(args: {caseId: string, cardId: string, budgetLineId: string, fieldName: string, value: any}) {
            this.caseId = args.caseId;
            this.cardId = args.cardId;
            this.budgetLineId = args.budgetLineId;
            this.fieldName = args.fieldName;
            this.value = args.value;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.WriteBudgetLineField, DV.Application';
        $commandName = 'Cases.Commands.WriteBudgetLineField';
        caseId: string;
        cardId: string;
        budgetLineId: string;
        fieldName: string;
        value: any;
    }
}

export namespace Cases.Commands {
    export class WriteBuildingField implements Command {

        constructor(args: {caseId: string, buildingId: string, fieldName: string, value: any}) {
            this.caseId = args.caseId;
            this.buildingId = args.buildingId;
            this.fieldName = args.fieldName;
            this.value = args.value;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.WriteBuildingField, DV.Application';
        $commandName = 'Cases.Commands.WriteBuildingField';
        caseId: string;
        buildingId: string;
        fieldName: string;
        value: any;
    }
}

export namespace Cases.Commands {
    export class WriteCardField implements Command {

        constructor(args: {cardId: string, fieldName: string, value: any}) {
            this.cardId = args.cardId;
            this.fieldName = args.fieldName;
            this.value = args.value;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.WriteCardField, DV.Application';
        $commandName = 'Cases.Commands.WriteCardField';
        cardId: string;
        fieldName: string;
        value: any;
    }
}

export namespace Cases.Commands {
    export class WriteCardImageField implements Command {

        constructor(args: {cardId: string, imageId: string, fieldName: string, value: any}) {
            this.cardId = args.cardId;
            this.imageId = args.imageId;
            this.fieldName = args.fieldName;
            this.value = args.value;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.WriteCardImageField, DV.Application';
        $commandName = 'Cases.Commands.WriteCardImageField';
        cardId: string;
        imageId: string;
        fieldName: string;
        value: any;
    }
}

export namespace Cases.Commands {
    export class WriteCaseField implements Command {

        constructor(args: {caseId: string, fieldName: string, value: any}) {
            this.caseId = args.caseId;
            this.fieldName = args.fieldName;
            this.value = args.value;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.WriteCaseField, DV.Application';
        $commandName = 'Cases.Commands.WriteCaseField';
        caseId: string;
        fieldName: string;
        value: any;
    }
}

export namespace Cases.Commands {
    export class WriteCaseImageField implements Command {

        constructor(args: {caseId: string, imageId: string, fieldName: string, value: any}) {
            this.caseId = args.caseId;
            this.imageId = args.imageId;
            this.fieldName = args.fieldName;
            this.value = args.value;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.WriteCaseImageField, DV.Application';
        $commandName = 'Cases.Commands.WriteCaseImageField';
        caseId: string;
        imageId: string;
        fieldName: string;
        value: any;
    }
}

export namespace Cases.Commands {
    export class WriteTaskField implements Command {

        constructor(args: {caseId: string, taskId: string, cardId: string, fieldName: string, value: any}) {
            this.caseId = args.caseId;
            this.taskId = args.taskId;
            this.cardId = args.cardId;
            this.fieldName = args.fieldName;
            this.value = args.value;
        }

        $commandType = 'DV.Application.Cases.Api.Commands.WriteTaskField, DV.Application';
        $commandName = 'Cases.Commands.WriteTaskField';
        caseId: string;
        taskId: string;
        cardId: string;
        fieldName: string;
        value: any;
    }
}


/* Domain queries */
export namespace Users.Queries {
    export class GetCompanies implements Query {

        constructor(args: {}) {
            
        }

        $queryType = 'DV.Application.Users.Api.Queries.GetCompanies, DV.Application';
        $queryName = 'Users.Queries.GetCompanies';
    }
}

export namespace Users.Queries {
    export class GetCompanyInfo implements Query {

        constructor(args: {}) {
            
        }

        $queryType = 'DV.Application.Users.Api.Queries.GetCompanyInfo, DV.Application';
        $queryName = 'Users.Queries.GetCompanyInfo';
    }
}

export namespace Users.Queries {
    export class GetGdprApproval implements Query {

        constructor(args: {}) {
            
        }

        $queryType = 'DV.Application.Users.Api.Queries.GetGdprApproval, DV.Application';
        $queryName = 'Users.Queries.GetGdprApproval';
    }
}

export namespace Users.Queries {
    export class GetImportableTypeMappings implements Query {

        constructor(args: {}) {
            
        }

        $queryType = 'DV.Application.Users.Api.Queries.GetImportableTypeMappings, DV.Application';
        $queryName = 'Users.Queries.GetImportableTypeMappings';
    }
}

export namespace Users.Queries {
    export class GetProfiles implements Query {

        constructor(args: {}) {
            
        }

        $queryType = 'DV.Application.Users.Api.Queries.GetProfiles, DV.Application';
        $queryName = 'Users.Queries.GetProfiles';
    }
}

export namespace Users.Queries {
    export class GetProfilesInCompany implements Query {

        constructor(args: {}) {
            
        }

        $queryType = 'DV.Application.Users.Api.Queries.GetProfilesInCompany, DV.Application';
        $queryName = 'Users.Queries.GetProfilesInCompany';
    }
}

export namespace Users.Queries {
    export class GetTypeMappings implements Query {

        constructor(args: {}) {
            
        }

        $queryType = 'DV.Application.Users.Api.Queries.GetTypeMappings, DV.Application';
        $queryName = 'Users.Queries.GetTypeMappings';
    }
}

export namespace Templates.Queries {
    export class ExportCompanyTemplates implements Query {

        constructor(args: {groupName: string}) {
            this.groupName = args.groupName;
        }

        $queryType = 'DV.Application.Templates.Api.Queries.ExportCompanyTemplates, DV.Application';
        $queryName = 'Templates.Queries.ExportCompanyTemplates';
        groupName: string;
    }
}

export namespace Templates.Queries {
    export class GetCompanyTemplates implements Query {

        constructor(args: {}) {
            
        }

        $queryType = 'DV.Application.Templates.Api.Queries.GetCompanyTemplates, DV.Application';
        $queryName = 'Templates.Queries.GetCompanyTemplates';
    }
}

export namespace Modules.Priority.Queries {
    export class GetPriorities implements Query {

        constructor(args: {caseId: string}) {
            this.caseId = args.caseId;
        }

        $queryType = 'DV.Application.Modules.Priority.Api.Queries.GetPriorities, DV.Application';
        $queryName = 'Modules.Priority.Queries.GetPriorities';
        caseId: string;
    }
}

export namespace Cases.Queries {
    export class ExportExcelBudget implements Query {

        constructor(args: {caseId: string}) {
            this.caseId = args.caseId;
        }

        $queryType = 'DV.Application.Cases.Api.Queries.ExportExcelBudget, DV.Application';
        $queryName = 'Cases.Queries.ExportExcelBudget';
        caseId: string;
    }
}

export namespace Cases.Queries {
    export class GetBudget implements Query {

        constructor(args: {caseId: string}) {
            this.caseId = args.caseId;
        }

        $queryType = 'DV.Application.Cases.Api.Queries.GetBudget, DV.Application';
        $queryName = 'Cases.Queries.GetBudget';
        caseId: string;
    }
}

export namespace Cases.Queries {
    export class GetCard implements Query {

        constructor(args: {cardId: string}) {
            this.cardId = args.cardId;
        }

        $queryType = 'DV.Application.Cases.Api.Queries.GetCard, DV.Application';
        $queryName = 'Cases.Queries.GetCard';
        cardId: string;
    }
}

export namespace Cases.Queries {
    export class GetCards implements Query {

        constructor(args: {caseId: string, showAllClassifications: boolean}) {
            this.caseId = args.caseId;
            this.showAllClassifications = args.showAllClassifications;
        }

        $queryType = 'DV.Application.Cases.Api.Queries.GetCards, DV.Application';
        $queryName = 'Cases.Queries.GetCards';
        caseId: string;
        showAllClassifications: boolean;
    }
}

export namespace Cases.Queries {
    export class GetCase implements Query {

        constructor(args: {caseId: string}) {
            this.caseId = args.caseId;
        }

        $queryType = 'DV.Application.Cases.Api.Queries.GetCase, DV.Application';
        $queryName = 'Cases.Queries.GetCase';
        caseId: string;
    }
}

export namespace Cases.Queries {
    export class GetCaseCompletion implements Query {

        constructor(args: {caseId: string}) {
            this.caseId = args.caseId;
        }

        $queryType = 'DV.Application.Cases.Api.Queries.GetCaseCompletion, DV.Application';
        $queryName = 'Cases.Queries.GetCaseCompletion';
        caseId: string;
    }
}

export namespace Cases.Queries {
    export class GetCaseLockedStatus implements Query {

        constructor(args: {caseId: string}) {
            this.caseId = args.caseId;
        }

        $queryType = 'DV.Application.Cases.Api.Queries.GetCaseLockedStatus, DV.Application';
        $queryName = 'Cases.Queries.GetCaseLockedStatus';
        caseId: string;
    }
}

export namespace Cases.Queries {
    export class GetCases implements Query {

        constructor(args: {progressFilter: Cases.Queries.GetCases.CaseProgress, caseNameFilter: string, nickNameFilter: string, addressFilter: string}) {
            this.progressFilter = args.progressFilter;
            this.caseNameFilter = args.caseNameFilter;
            this.nickNameFilter = args.nickNameFilter;
            this.addressFilter = args.addressFilter;
        }

        $queryType = 'DV.Application.Cases.Api.Queries.GetCases, DV.Application';
        $queryName = 'Cases.Queries.GetCases';
        progressFilter: Cases.Queries.GetCases.CaseProgress;
        caseNameFilter: string;
        nickNameFilter: string;
        addressFilter: string;
    }
}

export namespace Cases.Queries {
    export class GetCaseTask implements Query {

        constructor(args: {caseId: string, taskId: string}) {
            this.caseId = args.caseId;
            this.taskId = args.taskId;
        }

        $queryType = 'DV.Application.Cases.Api.Queries.GetCaseTask, DV.Application';
        $queryName = 'Cases.Queries.GetCaseTask';
        caseId: string;
        taskId: string;
    }
}

export namespace Cases.Queries {
    export class GetCaseType implements Query {

        constructor(args: {caseId: string}) {
            this.caseId = args.caseId;
        }

        $queryType = 'DV.Application.Cases.Api.Queries.GetCaseType, DV.Application';
        $queryName = 'Cases.Queries.GetCaseType';
        caseId: string;
    }
}

export namespace Cases.Queries {
    export class GetCaseTypes implements Query {

        constructor(args: {}) {
            
        }

        $queryType = 'DV.Application.Cases.Api.Queries.GetCaseTypes, DV.Application';
        $queryName = 'Cases.Queries.GetCaseTypes';
    }
}

export namespace Cases.Queries {
    export class GetCompanyTemplatesForCase implements Query {

        constructor(args: {caseId: string}) {
            this.caseId = args.caseId;
        }

        $queryType = 'DV.Application.Cases.Api.Queries.GetCompanyTemplatesForCase, DV.Application';
        $queryName = 'Cases.Queries.GetCompanyTemplatesForCase';
        caseId: string;
    }
}

export namespace Cases.Queries {
    export class GetConditionRatings implements Query {

        constructor(args: {caseId: string}) {
            this.caseId = args.caseId;
        }

        $queryType = 'DV.Application.Cases.Api.Queries.GetConditionRatings, DV.Application';
        $queryName = 'Cases.Queries.GetConditionRatings';
        caseId: string;
    }
}

export namespace Cases.Queries {
    export class GetReport implements Query {

        constructor(args: {caseId: string}) {
            this.caseId = args.caseId;
        }

        $queryType = 'DV.Application.Cases.Api.Queries.GetReport, DV.Application';
        $queryName = 'Cases.Queries.GetReport';
        caseId: string;
    }
}

export namespace Cases.Queries {
    export class GetReportData implements Query {

        constructor(args: {caseId: string}) {
            this.caseId = args.caseId;
        }

        $queryType = 'DV.Application.Cases.Api.Queries.GetReportData, DV.Application';
        $queryName = 'Cases.Queries.GetReportData';
        caseId: string;
    }
}

export namespace Cases.Queries {
    export class GetTask implements Query {

        constructor(args: {cardId: string, taskId: string}) {
            this.cardId = args.cardId;
            this.taskId = args.taskId;
        }

        $queryType = 'DV.Application.Cases.Api.Queries.GetTask, DV.Application';
        $queryName = 'Cases.Queries.GetTask';
        cardId: string;
        taskId: string;
    }
}


/* Domain primitives */
export namespace DV.Application.Users {
    export interface ProfileRights {
        companyAdministrator: boolean;
        allowAsConsultant: boolean;
        allowAsApprover: boolean;
        allowAsCompleter: boolean;
    }
}

export namespace Users.Commands.UpdateTypeMappings {
    export interface MappingDto {
        group: string;
        type: string;
        classification: string;
        classificationDescription: string;
        header: string;
    }
}

export namespace Modules.Obh.Queries {
    export interface BuildingDto {
        name: string;
        cadastralArea: string;
        buildingArea: string;
        buildingYear: string;
        energyLabelSerialIdentifier: string;
        energyLabelValidityPeriod: string;
    }
}

export namespace Modules.Obh.Queries {
    export interface CardDto {
        cardId: string;
        description: string;
        location: string;
        year: number;
        remainingLifetime: string;
    }
}

export namespace Modules.Obh.Queries {
    export interface ConditionDto {
        location: string;
        description: string;
    }
}

export namespace Modules.Obh.Queries {
    export interface GroupDto {
        classificationDescription: string;
        classification: string;
        conditionRating: string;
        cards: readonly Modules.Obh.Queries.CardDto[];
        conditions: readonly Modules.Obh.Queries.ConditionDto[];
        instructions: readonly Modules.Obh.Queries.InstructionsDto[];
        tasks: readonly Modules.Obh.Queries.TaskDto[];
        images: readonly Modules.Obh.Queries.ImageDto[];
        buildingLocation: string;
    }
}

export namespace Modules.Obh.Queries {
    export interface InstructionsDto {
        location: string;
        description: string;
    }
}

export namespace Modules.Obh.Queries {
    export interface TaskDto {
        taskId: string;
        description: string;
        location: string;
        priority: string;
        interval: string;
        totalPrice: number;
    }
}

export namespace Modules.Obh.Queries {
    export interface ImageDto {
        url: string;
        description: string;
    }
}

export namespace Modules.Obh.Queries {
    export interface GroupLine {
        groupTitle: string;
        lines: readonly Modules.Obh.Queries.TaskLine[];
        discriminator: string;
    }
}

export namespace Modules.Obh.Queries {
    export interface TaskLine {
        id: string;
        cardId: string;
        location: string;
        title: string;
        priority: string;
        years: readonly number[];
        subtotal: number;
        discriminator: string;
    }
}

export namespace Modules.Obh.Queries {
    export interface HeaderLine {
        cardHeader: string;
        taskHeader: string;
        priorityHeader: string;
        years: readonly string[];
        subtotalHeader: string;
        discriminator: string;
    }
}

export namespace Modules.Obh.Queries {
    export interface InspectionDto {
        priorities: readonly Modules.Obh.Queries.PriorityDto[];
    }
}

export namespace Modules.Obh.Queries {
    export interface PriorityDto {
        rating: number;
        label: string;
        helpText: string;
        description: string;
    }
}

export namespace Modules.Obh.Queries {
    export interface ObhReportDto {
        caseName: string;
        caseNickname: string;
        isCompleted: boolean;
        indexYear: string;
        durationYears: string;
        priceIndex: string;
        streetName: string;
        houseNumber: string;
        postalCode: string;
        postalCity: string;
        inspectionDate: string;
        dateOfReport: string;
        customer: string;
        contactPerson: string;
        approvedBy: string;
        description: string;
        consultant: string;
        phoneNumber: string;
        email: string;
        purpose: string;
        background: string;
        results: Modules.Obh.Queries.ResultsDto;
        inspection: Modules.Obh.Queries.InspectionDto;
        caseImage: Modules.Obh.Queries.ImageDto;
        buildings: readonly Modules.Obh.Queries.BuildingDto[];
        groups: readonly Modules.Obh.Queries.GroupDto[];
        tenYearBudgets: readonly Modules.Obh.Queries.TenYearBudget[];
    }
}

export namespace Modules.Obh.Queries {
    export interface ResultsDto {
        buildingConditionRating: number;
        cardGroups: readonly Modules.Obh.Queries.ResultsDto.CardGroupDto[];
        resultResume: string;
        taskPriorityHistogram: Dictionary<number>;
    }
}

export namespace Modules.Obh.Queries.ResultsDto {
    export interface CardGroupDto {
        name: string;
        conditionRating: number;
    }
}

export namespace Modules.Obh.Queries {
    export interface TenYearBudget {
        headerLine: Modules.Obh.Queries.HeaderLine;
        lines: readonly Modules.Obh.Queries.Line[];
        description: string;
    }
}

export namespace Modules.Obh.Queries {
    export interface Line {
        discriminator: string;
    }
}

export namespace Modules.Obh.Queries {
    export interface SumLine {
        title: string;
        years: readonly number[];
        subtotal: number;
        discriminator: string;
    }
}

export namespace Modules.Mmake.Queries {
    export interface MmakeGuidePageDto {
        customerCompany: string;
        inspectionDate: string;
    }
}

export namespace Modules.Ebas.Queries {
    export interface EbasBuildingDto {
        energyLabel: string;
    }
}

export namespace Modules.Ebas.Queries {
    export interface EbasCaseDto {
        streetName: string;
        houseNumber: string;
        postalCode: number;
        postalCity: string;
    }
}

export namespace Modules.Ebas.Queries {
    export interface EbasEstatePageDto {
        additionalEnergyComments: string;
        indoorEnvironmentComments: string;
        budgetTotal: number;
        durationYears: number;
        priceIndexInPercentage: string;
        includeVat: boolean;
    }
}

export namespace Modules.Deas.Queries {
    export interface DeasEstatePageDto {
        latestActivitiesComments: string;
        generalConditionComments: string;
        communalAreasComments: string;
    }
}

export namespace Modules.Deas.Queries {
    export interface DeasPurposePageDto {
        durationYears: number;
        backgroundMaterialComments: string;
    }
}

export namespace Modules.Deas.Queries {
    export interface DeasReportDto {
        deasPurposePage: Modules.Deas.Queries.DeasPurposePageDto;
    }
}

export namespace Cases.Queries.GetCases {
    export enum CaseProgress {
        Draft = 0,
        Completed = 1,
        Deleted = 2
    }
}

export namespace Cases.Queries.Reports.Es {
    export interface CardPageDto {
        header: Cases.Queries.Reports.Es.CardPageDto.HeaderDto;
        status: readonly Cases.Queries.Reports.Es.CardPageDto.StatusLocationDto[];
        oneTimeTasks: readonly Cases.Queries.Reports.Es.CardPageDto.TaskDto[];
        recurringTasks: readonly Cases.Queries.Reports.Es.CardPageDto.TaskDto[];
        images: readonly Cases.Queries.Reports.Es.CardPageDto.ImageDto[];
    }
}

export namespace Cases.Queries.Reports.Es.CardPageDto {
    export interface HeaderDto {
        number: string;
        classification: string;
        classificationDescription: string;
        conditionRating: number;
        maxConditionRating: number;
    }
}

export namespace Cases.Queries.Reports.Es.CardPageDto {
    export interface StatusLocationDto {
        location: string;
        cardGroups: readonly Cases.Queries.Reports.Es.CardPageDto.CardGroupDto[];
        problems: readonly Cases.Queries.Reports.Es.CardPageDto.ProblemLocationDto[];
    }
}

export namespace Cases.Queries.Reports.Es.CardPageDto {
    export interface CardGroupDto {
        header: string;
        cards: readonly Cases.Queries.Reports.Es.CardPageDto.CardDto[];
    }
}

export namespace Cases.Queries.Reports.Es.CardPageDto {
    export interface CardDto {
        cardId: string;
        description: string;
        expectedRemainingLifetime: string;
        problems: readonly Cases.Queries.Reports.Es.CardPageDto.ProblemDto[];
    }
}

export namespace Cases.Queries.Reports.Es.CardPageDto {
    export interface ProblemDto {
        taskId: string;
        number: string;
        description: string;
    }
}

export namespace Cases.Queries.Reports.Es.CardPageDto {
    export interface ProblemLocationDto {
        location: string;
        problems: readonly Cases.Queries.Reports.Es.CardPageDto.ProblemDto[];
    }
}

export namespace Cases.Queries.Reports.Es.CardPageDto {
    export interface TaskDto {
        taskId: string;
        number: string;
        description: string;
        solutionDescription: string;
        location: string;
        locationType: string;
        year: number;
        totalPrice: string;
    }
}

export namespace Cases.Queries.Reports.Es.CardPageDto {
    export interface ImageDto {
        url: string;
        description: string;
    }
}

export namespace Cases.Queries.Reports.Es {
    export interface EsReportDto {
        caseType: string;
        masterData: Cases.Queries.Reports.Es.MasterDataDto;
        guidePage: Cases.Queries.Reports.Es.GuidePageDto;
        estatePage: Cases.Queries.Reports.Es.EstatePageDto;
        cardPages: readonly Cases.Queries.Reports.Es.CardPageDto[];
        budgetNote: string;
        budgetPages: readonly Cases.Queries.Reports.Es.EsReportBudget[];
        extensions: Cases.Queries.Reports.Es.ReportExtensions;
    }
}

export namespace Cases.Queries.Reports.Es {
    export interface MasterDataDto {
        isCompleted: boolean;
        reportDate: string;
        case: Cases.Queries.Reports.Es.MasterDataDto.CaseDto;
        company: Cases.Queries.Reports.Es.MasterDataDto.CompanyDto;
    }
}

export namespace Cases.Queries.Reports.Es.MasterDataDto {
    export interface CaseDto {
        name: string;
        nickName: string;
        address: string;
        imageUrl: string;
        maxConditionRating: number;
        extensions: Cases.Queries.Reports.Es.MasterDataDto.CaseExtensions;
    }
}

export namespace Cases.Queries.Reports.Es.MasterDataDto {
    export interface CaseExtensions {
        ebas: Modules.Ebas.Queries.EbasCaseDto;
    }
}

export namespace Cases.Queries.Reports.Es.MasterDataDto {
    export interface CompanyDto {
        name: string;
        address: string;
        phoneNumber: string;
        email: string;
        logoUrl: string;
    }
}

export namespace Cases.Queries.Reports.Es {
    export interface GuidePageDto {
        durationYears: number;
        typeMappingsName: string;
        indexYear: number;
        priceIndexInPercentage: string;
        includeVat: boolean;
        conditionRatings: readonly Cases.Queries.Reports.Es.GuidePageDto.ConditionRatingDto[];
        extensions: Cases.Queries.Reports.Es.GuidePageDto.GuidePageExtensions;
    }
}

export namespace Cases.Queries.Reports.Es.GuidePageDto {
    export interface ConditionRatingDto {
        rating: number;
        maxRating: number;
        label: string;
    }
}

export namespace Cases.Queries.Reports.Es.GuidePageDto {
    export interface GuidePageExtensions {
        mmake: Modules.Mmake.Queries.MmakeGuidePageDto;
    }
}

export namespace Cases.Queries.Reports.Es {
    export interface EstatePageDto {
        caseData: Cases.Queries.Reports.Es.EstatePageDto.CaseDataDto;
        buildings: readonly Cases.Queries.Reports.Es.EstatePageDto.BuildingDto[];
        imageUrl: string;
        description: string;
        extensions: Cases.Queries.Reports.Es.EstatePageDto.EstatePageExtensions;
    }
}

export namespace Cases.Queries.Reports.Es.EstatePageDto {
    export interface CaseDataDto {
        name: string;
        nickname: string;
        address: string;
        customerCompany: string;
        customerContact: string;
        inspectionDate: string;
        dateOfReport: string;
        companyName: string;
        consultant: string;
        phoneNumber: string;
        email: string;
        approvedBy: string;
    }
}

export namespace Cases.Queries.Reports.Es.EstatePageDto {
    export interface BuildingDto {
        name: string;
        buildingArea: string;
        buildingYear: string;
        energyLabelValidityStart: string;
        energyLabelValidityEnd: string;
        extensions: Cases.Queries.Reports.Es.EstatePageDto.BuildingExtensions;
    }
}

export namespace Cases.Queries.Reports.Es.EstatePageDto {
    export interface BuildingExtensions {
        ebas: Modules.Ebas.Queries.EbasBuildingDto;
    }
}

export namespace Cases.Queries.Reports.Es.EstatePageDto {
    export interface EstatePageExtensions {
        ebas: Modules.Ebas.Queries.EbasEstatePageDto;
        deas: Modules.Deas.Queries.DeasEstatePageDto;
    }
}

export namespace Cases.Queries.Reports.Es {
    export interface EsReportBudget {
        years: readonly string[];
        groups: readonly Cases.Queries.Reports.Es.EsReportGroupLine[];
        totals: readonly Cases.Queries.Reports.Es.EsReportTotalLine[];
    }
}

export namespace Cases.Queries.Reports.Es {
    export interface EsReportGroupLine {
        title: string;
        conditionRating: number;
        maxConditionRating: number;
        lines: readonly Cases.Queries.Reports.Es.EsReportLine[];
        total: Cases.Queries.Reports.Es.EsReportTotalLine;
    }
}

export namespace Cases.Queries.Reports.Es {
    export interface EsReportTaskLine {
        tag: 'EsReportTaskLine';
        years: readonly number[];
        lineTotal: number;
        title: string;
    }
}

export namespace Cases.Queries.Reports.Es {
    export interface EsReportPercentageTaskLine {
        tag: 'EsReportPercentageTaskLine';
        percentage: number;
        years: readonly number[];
        lineTotal: number;
        title: string;
    }
}

export namespace Cases.Queries.Reports.Es {
    export interface EsReportTotalLine {
        tag: 'EsReportTotalLine';
        years: readonly number[];
        lineTotal: number;
        title: string;
    }
}

export namespace Cases.Queries.Reports.Es {
    export type EsReportLine = Cases.Queries.Reports.Es.EsReportTaskLine | Cases.Queries.Reports.Es.EsReportPercentageTaskLine | Cases.Queries.Reports.Es.EsReportTotalLine
}

export namespace Cases.Queries.Reports.Es {
    export interface ReportExtensions {
        deas: Modules.Deas.Queries.DeasReportDto;
    }
}

export namespace Cases {
    export enum TaskType {
        RecurringTask = 0,
        OneTimeTask = 1
    }
}

export namespace Users.Queries.GetCompanies {
    export interface CompanyDto {
        id: string;
        name: string;
        isEnabled: boolean;
    }
}

export namespace Users.Queries.GetCompanyInfo {
    export interface CompanyInfoDto {
        name: string;
        streetName: string;
        houseNumber: string;
        postalCode: number;
        postalCity: string;
        phoneNumber: string;
        email: string;
        cvr: string;
        accountId: string;
        invoiceAttention: string;
        invoiceEmail: string;
        gln: string;
        logo: Users.Queries.GetCompanyInfo.LogoDto;
    }
}

export namespace Users.Queries.GetCompanyInfo {
    export interface LogoDto {
        id: string;
        url: string;
    }
}

export namespace Users.Queries.GetGdprApproval {
    export interface GdprApprovalDto {
        approverUsername: string;
        approverFullName: string;
        timeOfApproval: string;
    }
}

export namespace Users.Queries.GetImportableTypeMappings {
    export interface ImportableTypeMappings {
        systemTypeMappings: readonly Users.Queries.GetImportableTypeMappings.TypeMappings[];
        companyTypeMappings: readonly Users.Queries.GetImportableTypeMappings.TypeMappings[];
    }
}

export namespace Users.Queries.GetImportableTypeMappings {
    export interface TypeMappings {
        id: string;
        name: string;
    }
}

export namespace Users.Queries.GetProfiles {
    export interface ProfileDto {
        id: string;
        username: string;
        fullName: string;
        initials: string;
        company: string;
    }
}

export namespace Users.Queries.GetProfilesInCompany {
    export interface ProfileDto {
        id: string;
        username: string;
        isCompanyAdministrator: boolean;
        fullName: string;
        initials: string;
        alternativeEmail: string;
        allowAsConsultant: boolean;
        allowAsApprover: boolean;
        allowAsCompleter: boolean;
    }
}

export namespace Users.Queries.GetTypeMappings {
    export interface TypeMappingsDto {
        id: string;
        name: string;
        mappings: readonly Users.Queries.GetTypeMappings.MappingDto[];
    }
}

export namespace Users.Queries.GetTypeMappings {
    export interface MappingDto {
        group: string;
        type: string;
        classification: string;
        classificationDescription: string;
        header: string;
    }
}

export namespace Templates.Queries.GetCompanyTemplates {
    export interface TemplateGroupDto {
        group: string;
        types: readonly string[];
    }
}

export namespace Modules.Priority.Queries.GetPriorities {
    export interface PriorityDto {
        value: number;
        translation: string;
    }
}

export namespace Cases.Queries.GetBudget {
    export interface Budget {
        description: string;
        years: readonly string[];
        groups: readonly Cases.Queries.GetBudget.GroupLine[];
        totals: readonly Cases.TotalLine[];
        extensions: readonly Cases.BudgetExtensions[];
    }
}

export namespace Cases.Queries.GetBudget {
    export interface GroupLine {
        classification: string;
        classificationDescription: string;
        taskLines: readonly Cases.Queries.GetBudget.Line[];
        total: Cases.TotalLine;
        extensions: readonly Cases.GroupLineExtensions[];
    }
}

export namespace Cases.Queries.GetBudget {
    export interface TaskLine {
        tag: 'TaskLine';
        id: string;
        cardId: string;
        location: string;
        title: string;
        years: readonly Cases.Price[];
        extensions: readonly Cases.TaskLineExtensions[];
    }
}

export namespace Cases {
    export interface Price {
        original: number;
        indexed: number;
        indexedRoundedToNearestHundred: number;
    }
}

export namespace Cases {
    export interface TaskPriority {
        tag: 'TaskPriority';
        label: string;
    }
}

export namespace Cases {
    export type TaskLineExtensions = Cases.TaskPriority
}

export namespace Cases.Queries.GetBudget {
    export interface PercentageTaskLine {
        tag: 'PercentageTaskLine';
        title: string;
        percentage: number;
        years: readonly Cases.Price[];
    }
}

export namespace Cases.Queries.GetBudget {
    export interface SubTotalTaskLine {
        tag: 'SubTotalTaskLine';
        title: string;
        years: readonly Cases.Price[];
    }
}

export namespace Cases.Queries.GetBudget {
    export type Line = Cases.Queries.GetBudget.TaskLine | Cases.Queries.GetBudget.PercentageTaskLine | Cases.Queries.GetBudget.SubTotalTaskLine
}

export namespace Cases {
    export interface TotalLine {
        tag: 'TotalLine';
        title: string;
        years: readonly Cases.Price[];
        lineTotal: Cases.Price;
        lineTotalAccumulated: Cases.Price;
        priceReadHint: Cases.PriceSelector;
    }
}

export namespace Cases {
    export enum PriceSelector {
        Original = 0,
        Indexed = 1,
        IndexedRoundedToNearestHundred = 2
    }
}

export namespace Cases {
    export interface GroupLineExtensions {
    }
}

export namespace Cases {
    export interface PriorityHeader {
        tag: 'PriorityHeader';
        header: string;
    }
}

export namespace Cases {
    export type BudgetExtensions = Cases.PriorityHeader
}

export namespace Cases.Queries.GetCard {
    export interface CardDto {
        id: string;
        description: string;
        conditionDescription: string;
        group: string;
        type: string;
        location: string;
        installationYear: number;
        rebuildYear: number;
        lifespan: number;
        remainingLifetime: number;
        conditionRating: number;
        images: readonly Cases.Queries.GetCard.ImageDto[];
        tags: Dictionary<string>;
    }
}

export namespace Cases.Queries.GetCard {
    export interface ImageDto {
        id: string;
        url: string;
        description: string;
        rotation: number;
        isMarked: boolean;
        filename: string;
    }
}

export namespace Cases.Queries.GetCards {
    export interface GroupDto {
        name: string;
        cards: readonly Cases.Queries.GetCards.CardDto[];
    }
}

export namespace Cases.Queries.GetCards {
    export interface CardDto {
        id: string;
        name: string;
        location: string;
        oneTimeTasks: readonly Cases.Queries.GetCards.TaskDto[];
        recurringTasks: readonly Cases.Queries.GetCards.TaskDto[];
    }
}

export namespace Cases.Queries.GetCards {
    export interface TaskDto {
        id: string;
        name: string;
        payment: string;
    }
}

export namespace Cases.Queries.GetCase {
    export interface Result {
        case: Cases.Queries.GetCase.CaseDto;
        buildings: readonly Cases.Queries.GetCase.BuildingDto[];
        images: readonly Cases.Queries.GetCase.ImageDto[];
        selectableConsultants: readonly Cases.Queries.GetCase.ProfileDto[];
        selectableApprovers: readonly Cases.Queries.GetCase.ProfileDto[];
        typeMappingsIdValues: readonly Cases.Queries.GetCase.TypeMappingsDto[];
    }
}

export namespace Cases.Queries.GetCase {
    export interface CaseDto {
        id: string;
        name: DV.Application.FieldInfo<string>;
        nickname: DV.Application.FieldInfo<string>;
        streetName: DV.Application.FieldInfo<string>;
        houseNumber: DV.Application.FieldInfo<string>;
        postalCode: DV.Application.FieldInfo<number>;
        postalCity: DV.Application.FieldInfo<string>;
        indexYear: DV.Application.FieldInfo<number>;
        priceIndex: DV.Application.FieldInfo<number>;
        durationYears: DV.Application.FieldInfo<number>;
        includeVat: DV.Application.FieldInfo<boolean>;
        inspectionDate: DV.Application.FieldInfo<Cases.LocalDate>;
        customer: DV.Application.FieldInfo<string>;
        contactPerson: DV.Application.FieldInfo<string>;
        approvedBy: DV.Application.FieldInfo<string>;
        description: DV.Application.FieldInfo<string>;
        consultantId: DV.Application.FieldInfo<string>;
        typeMappingsId: DV.Application.FieldInfo<Sideshow.Abstractions.Revision<string>>;
        caseCanBeUpdated: boolean;
        updateMessage: string;
        extensions: Cases.Queries.GetCase.CaseExtensions;
    }
}

export namespace DV.Application {
    export interface FieldInfo<T> {
        name: string;
        show: boolean;
        writable: boolean;
        value: T;
    }
}

export namespace Cases {
    export interface LocalDate {
        year: number;
        month: number;
        day: number;
    }
}

export namespace Sideshow.Abstractions {
    export interface Revision<T> {
        value: T;
        commitId: Guid;
    }
}

export namespace Cases.Queries.GetCase {
    export interface CaseExtensions {
        obh: Cases.Queries.GetCase.ObhCaseDto;
        deas: Cases.Queries.GetCase.DeasCaseDto;
        ebas: Cases.Queries.GetCase.EbasCaseDto;
        bjornsholm: Cases.Queries.GetCase.BjornsholmCaseDto;
    }
}

export namespace Cases.Queries.GetCase {
    export interface ObhCaseDto {
        reportBackground: DV.Application.FieldInfo<string>;
        reportResultResume: DV.Application.FieldInfo<string>;
    }
}

export namespace Cases.Queries.GetCase {
    export interface DeasCaseDto {
        latestActivitiesComments: DV.Application.FieldInfo<string>;
        generalConditionComments: DV.Application.FieldInfo<string>;
        communalAreasComments: DV.Application.FieldInfo<string>;
        backgroundMaterialComments: DV.Application.FieldInfo<string>;
    }
}

export namespace Cases.Queries.GetCase {
    export interface EbasCaseDto {
        additionalEnergyComments: DV.Application.FieldInfo<string>;
        indoorEnvironmentComments: DV.Application.FieldInfo<string>;
    }
}

export namespace Cases.Queries.GetCase {
    export interface BjornsholmCaseDto {
    }
}

export namespace Cases.Queries.GetCase {
    export interface BuildingDto {
        id: string;
        name: DV.Application.FieldInfo<string>;
        buildingArea: DV.Application.FieldInfo<number>;
        buildingYear: DV.Application.FieldInfo<number>;
        energyLabelSerialIdentifier: DV.Application.FieldInfo<number>;
        energyLabelReportDate: DV.Application.FieldInfo<Cases.LocalDate>;
        extensions: Cases.Queries.GetCase.BuildingExtensions;
    }
}

export namespace Cases.Queries.GetCase {
    export interface BuildingExtensions {
        ebas: Cases.Queries.GetCase.EbasBuildingDto;
        obh: Cases.Queries.GetCase.ObhBuildingDto;
    }
}

export namespace Cases.Queries.GetCase {
    export interface EbasBuildingDto {
        energyLabel: DV.Application.FieldInfo<string>;
    }
}

export namespace Cases.Queries.GetCase {
    export interface ObhBuildingDto {
        cadastralArea: DV.Application.FieldInfo<number>;
    }
}

export namespace Cases.Queries.GetCase {
    export interface ImageDto {
        id: string;
        url: string;
        description: string;
        rotation: number;
        isMarked: boolean;
        filename: string;
    }
}

export namespace Cases.Queries.GetCase {
    export interface ProfileDto {
        id: string;
        fullName: string;
    }
}

export namespace Cases.Queries.GetCase {
    export interface TypeMappingsDto {
        id: string;
        commitId: Guid;
        name: string;
    }
}

export namespace Cases.Queries.GetCaseCompletion {
    export interface CaseCompletionDto {
        isCompleted: boolean;
        completedBy: string;
        completedAt: string;
    }
}

export namespace Cases.Queries.GetCases {
    export interface CaseDto {
        id: string;
        type: string;
        name: string;
        nickname: string;
        address: string;
        customer: string;
        inspectionDate: string;
        approvedBy: string;
        isCompleted: boolean;
        completedAt: string;
        isDeleted: boolean;
        isLocked: boolean;
    }
}

export namespace Cases.Queries.GetCaseTask {
    export interface Result {
        taskType: Cases.TaskType;
        task: Cases.Queries.GetCaseTask.TaskDto;
    }
}

export namespace Cases.Queries.GetCaseTask {
    export interface TaskDto {
        id: string;
        description: DV.Application.FieldInfo<string>;
        budgetLines: readonly Cases.Queries.GetCaseTask.BudgetLineDto[];
        extensions: Cases.Queries.GetCaseTask.Extensions;
    }
}

export namespace Cases.Queries.GetCaseTask {
    export interface BudgetLineDto {
        id: string;
        description: string;
        amount: number;
        unit: string;
        pricePerUnit: number;
        fixedPrice: number;
        subtotal: number;
    }
}

export namespace Cases.Queries.GetCaseTask {
    export interface Extensions {
        priority: Cases.Queries.GetCaseTask.PriorityExtensionDto;
    }
}

export namespace Cases.Queries.GetCaseTask {
    export interface PriorityExtensionDto {
        priority: DV.Application.FieldInfo<number>;
    }
}

export namespace Cases.Queries.GetCaseTask {
    export interface RecurringTaskDto {
        interval: DV.Application.FieldInfo<number>;
        id: string;
        description: DV.Application.FieldInfo<string>;
        budgetLines: readonly Cases.Queries.GetCaseTask.BudgetLineDto[];
        extensions: Cases.Queries.GetCaseTask.Extensions;
    }
}

export namespace Cases.Queries.GetCaseTask {
    export interface OneTimeTaskDto {
        id: string;
        description: DV.Application.FieldInfo<string>;
        budgetLines: readonly Cases.Queries.GetCaseTask.BudgetLineDto[];
        extensions: Cases.Queries.GetCaseTask.Extensions;
    }
}

export namespace Cases.Queries.GetCaseType {
    export interface ResultDto {
        caseType: string;
    }
}

export namespace Cases.Queries.GetCaseTypes {
    export interface CaseTypeDto {
        name: string;
        displayName: string;
    }
}

export namespace Cases.Queries.GetCompanyTemplatesForCase {
    export interface TemplateGroupDto {
        group: string;
        types: readonly string[];
        cardTemplates: Dictionary<readonly Cases.Queries.GetCompanyTemplatesForCase.CardTemplateDto[]>;
        recurringTaskTemplates: readonly Cases.Queries.GetCompanyTemplatesForCase.TaskTemplateDto[];
        oneTimeTaskTemplates: readonly Cases.Queries.GetCompanyTemplatesForCase.TaskTemplateDto[];
    }
}

export namespace Cases.Queries.GetCompanyTemplatesForCase {
    export interface CardTemplateDto {
        id: string;
        type: string;
        searchTitle: string;
        description: string;
        tags: Dictionary<string>;
    }
}

export namespace Cases.Queries.GetCompanyTemplatesForCase {
    export interface TaskTemplateDto {
        id: string;
        searchTitle: string;
        description: string;
        rules: readonly Cases.Queries.GetCompanyTemplatesForCase.RuleDto[];
    }
}

export namespace Cases.Queries.GetCompanyTemplatesForCase {
    export interface RuleDto {
        operator: string;
        identifier: string;
        value: string;
    }
}

export namespace Cases.Queries.GetConditionRatings {
    export interface ConditionRatingDto {
        rating: number;
        translation: string;
    }
}

export namespace Cases.Queries.GetReport {
    export interface ReportResult {
        caseIsCompleted: boolean;
        reportVersion: number;
        reportExists: boolean;
        filename: string;
        reportData: string;
        caseType: string;
    }
}

export namespace Cases.Queries.GetReportData {
    export interface ReportDto {
        caseType: string;
        data: any;
        filename: string;
        companyDetailsAreIncomplete: boolean;
    }
}

export namespace Cases.Queries.GetTask {
    export interface Result {
        taskType: Cases.TaskType;
        card: Cases.Queries.GetTask.CardDto;
        task: Cases.Queries.GetTask.TaskDto;
    }
}

export namespace Cases.Queries.GetTask {
    export interface CardDto {
        id: string;
        group: string;
        type: string;
        description: string;
        location: string;
    }
}

export namespace Cases.Queries.GetTask {
    export interface TaskDto {
        description: DV.Application.FieldInfo<string>;
        location: DV.Application.FieldInfo<string>;
        solutionDescription: DV.Application.FieldInfo<string>;
        budgetLines: readonly Cases.Queries.GetTask.BudgetLineDto[];
        extensions: Cases.Queries.GetTask.Extensions;
    }
}

export namespace Cases.Queries.GetTask {
    export interface BudgetLineDto {
        id: string;
        description: string;
        amount: number;
        unit: string;
        pricePerUnit: number;
        fixedPrice: number;
        subtotal: number;
    }
}

export namespace Cases.Queries.GetTask {
    export interface Extensions {
        priority: Cases.Queries.GetTask.PriorityExtensionDto;
    }
}

export namespace Cases.Queries.GetTask {
    export interface PriorityExtensionDto {
        priority: DV.Application.FieldInfo<number>;
    }
}

export namespace Cases.Queries.GetTask {
    export interface RecurringTaskDto {
        interval: DV.Application.FieldInfo<number>;
        description: DV.Application.FieldInfo<string>;
        location: DV.Application.FieldInfo<string>;
        solutionDescription: DV.Application.FieldInfo<string>;
        budgetLines: readonly Cases.Queries.GetTask.BudgetLineDto[];
        extensions: Cases.Queries.GetTask.Extensions;
    }
}

export namespace Cases.Queries.GetTask {
    export interface OneTimeTaskDto {
        problemDescription: DV.Application.FieldInfo<string>;
        description: DV.Application.FieldInfo<string>;
        location: DV.Application.FieldInfo<string>;
        solutionDescription: DV.Application.FieldInfo<string>;
        budgetLines: readonly Cases.Queries.GetTask.BudgetLineDto[];
        extensions: Cases.Queries.GetTask.Extensions;
    }
}

export namespace Utils.LoginController {
    export interface UserInfo {
        companyId: string;
        profileId: string;
        isSystemAdmin: boolean;
        isCompanyAdmin: boolean;
        isImpersonating: boolean;
        isGdprApproved: boolean;
    }
}


/* Built-in primitives */
export interface Command {}

export interface Query {}

export interface Guid {}


}
export default Api;

