
export const emptyImmutableArray = Object.freeze([]);

const freeze = <T>(array : readonly T[]) : readonly T[] => {
    if(array === null || Object.isFrozen(array)) return array;
    if(array.length === 0) return emptyImmutableArray;
    return Object.freeze(array);
}

export function toImmutable<T>(array: readonly T[] | null) : readonly T[] | null {
    if(array === null || Object.isFrozen(array)) return array;
    if(array.length === 0) return emptyImmutableArray;
    return Object.freeze([...array]);
}

export function toMutable<T>(array: readonly T[] | null) : T[] | null {
    if(array === null) return null;
    return [...array];
}

export const add = <T>(item:  T) => (array: readonly T[]) => freeze([...(array || []), item])
export const addAll = <T>(items:  T[]) => (array: readonly T[]) => freeze([...(array || []), ...items])
export const remove = <T>(predicate: ((item: T) => boolean)) => (array: readonly T[]) => freeze(array.filter(x => !predicate(x)))
export const removeById = <T extends { id: string }>(id: string) => remove<T>(item => item.id === id);
export const replace = <T>(predicate: ((item: T) => boolean), replacement: T) => 
    (array: readonly T[]) => freeze(array.map(x => predicate(x) ? replacement : x))
export const replaceFunc = <T>(predicate: ((item: T) => boolean), replacementFunc: (old: T)=> T) => 
    (array: readonly T[]) => freeze(array.map(x => predicate(x) ? replacementFunc(x) : x)) 
export const replaceById = <T extends { id: string }>(newItem: T)  =>  
    replace(item => item.id === newItem.id, newItem)
export const replaceByRef = <T>(oldItem: T, newItem: T) => replace(item => item === oldItem, newItem)

/**
 * Takes many array transform functions and returns a single array transform function that applies them all in order.
 * @param setters The arrary tranform functions
 */
export const pipe = <T>(...setters: ((array: readonly T[]) => readonly T[])[]): (source: readonly T[]) => readonly T[] => (source: readonly T[]) => {
    let result = source;

    for (const setter of setters) {
        result = setter(result);
    }
    return result;
}