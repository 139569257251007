import React from "react";
import { TableRow, TableCell, makeStyles } from "@material-ui/core";
import Api from "server/api";
import styles from './styles'
import { TaskYearCell } from './task-year-cell';
import { get } from "utils";

type Props = {
    line: Api.Cases.Queries.GetBudget.SubTotalTaskLine;
    budget: Api.Cases.Queries.GetBudget.Budget;
    className?: string;
}

const useStyles = makeStyles(styles);

export const SubTotalRow = (props: Props) => {
    
    const styles = useStyles(props);
    
    return <TableRow className={styles.taskRow}>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell colSpan={get(props.budget.extensions, 'PriorityHeader') ? 2 : 1}>{props.line.title}</TableCell>
        {props.line.years.map((value, index) => <TaskYearCell key={index} price={value} />)}
    </TableRow>
}