import * as React from "react";
import { GridListTile } from "@material-ui/core";
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveAlt from '@material-ui/icons/SaveAlt';
import * as _ from 'lodash';
import { Logo } from "./logo-container";
import { FileUpload} from "controls/file-upload";
import { ImageToolButton } from 'controls/images/image-tool-button';
import {ImageToolbar} from "controls/images/image-toolbar";

type Props = {
    logo: Logo
    downloadTitle: string
    confirmDeleteLogo: () => void
    onFileSelected: (files: File[]) => Promise<void>
    style?: any // style property is set automatically by parent GridList. see https://material-ui.com/guides/composition/
}

export const LogoTile = (props: Props) => {

    const image = props.logo;

    return (
        <GridListTile key={image.id} style={props.style}>
            <ImageToolbar>
                <ImageToolButton tooltip='Hent logo' href={'/api/' + image.url} download={props.downloadTitle} >
                    <SaveAlt />
                </ImageToolButton>
                <ImageToolButton tooltip='Erstat logo' >
                    <FileUpload label='Upload billeder' accept='.png, .jpg, .jpeg' multiple={true} onUpload={files => props.onFileSelected(files)}>
                        <SwapHorizIcon />
                    </FileUpload>
                </ImageToolButton>
                <ImageToolButton tooltip='Slet logo' onClick={() => props.confirmDeleteLogo()}>
                    <DeleteIcon />
                </ImageToolButton>
            </ImageToolbar>
            <div style={{ position: 'absolute' }} />
            <img src={'/api/' + image.url + '&width=316&height=219'} />
        </GridListTile>)
}