import React from "react";

type MarkdownRenderer = (node: React.ReactNode) => React.ReactNode;

type MarkdownProps = {
    renderText: MarkdownRenderer;
    renderBoldText: MarkdownRenderer;
    renderBulletPoint: MarkdownRenderer;
}

export const CreateMarkdown = (settings: MarkdownProps) => (props: {children: string}) => 
    props.children
        ? <>{
            props.children.split('\n').map(text =>
                renderMarkdown(settings)(text)
            )
        }</>
        : null;

const renderMarkdown = (settings: MarkdownProps) => (text: string): React.ReactNode =>
    {
        return text
            ? text.startsWith("- ")
                ? settings.renderBulletPoint(renderMarkdown(settings)(text.slice(2)))
                // Wrap mixed bold / non-bold text in text element to keep them on same line
                : settings.renderText(text.split('**').map((subStr, i, arr) => i % 2 && i < arr.length - 1
                    ? settings.renderBoldText(subStr)
                    : settings.renderText(subStr)))
            : settings.renderText(" ")
    }

