import React, { useState } from "react";
import { makeStyles} from "@material-ui/core";
import { Button } from "controls/button";
import { hasValue } from "utils";
import { passwordError } from "../validate-password";
import { AnonymousField } from 'controls/field/anonymous-field';
import {  DvError } from "custom-hooks";
import { ErrorListDisplay } from "controls/error-list-display";

const useStyles = makeStyles({
    registerButton: {
        width: '100%', 
        height: 50, 
        fontSize: 14
    }
});

type Props = {
    errors: readonly DvError[],
    loading: boolean,
    register: (username: string, password: string, fullName: string, companyName: string, phoneNumber: string) => void,
}

export const NewCompanyInformation = (props: Props) =>  {
    const styles = useStyles();

    const [companyName, setCompanyName] = useState<string>(null);
    const [username, setUsername] = useState<string>(null);
    const [fullName, setFullName] = useState<string>(null);
    const [companyPhoneNumber, setCompanyPhoneNumber] = useState<string>(null);
    const [password, setPassword] = useState<string>(null);
    const [passwordRepeated, setPasswordRepeated] = useState<string>(null);

    const passwordErrors = hasValue(passwordRepeated, false)
        ? passwordError(password, passwordRepeated)
        : [];

    const canExecute = 
        hasValue(companyName) && 
        hasValue(username) && 
        hasValue(fullName) && 
        hasValue(password) && 
        hasValue(passwordRepeated) && 
        hasValue(companyName) && 
        hasValue(companyPhoneNumber) && 
        hasValue(password) && 
        passwordErrors.length === 0

    const register = () => props.register(username, password, fullName, companyName, companyPhoneNumber)

    const onKeyDown = (event: React.KeyboardEvent<any>) => {
        if (event.key === 'Enter') {
            register();
        }
    }             

    return ( 
        <div style={{flex: 1, flexDirection:'column'}}>
            <div style={{display:'flex', flexDirection:'column', alignItems: 'center'}}>
                <div style={{fontSize: 30}}>Prøv D&V Plan gratis</div>
                <div style={{fontSize: 14}}>Har du allerede en konto?<a style={{color: '#56DFA6', textDecoration: 'None'}} href='/'> Log ind</a></div>
                <div style={{fontSize: 14, marginTop: 5, textAlign:'center'}}>
                    Anvender din virksomhed allerede D&V Plan, bør du kontakte os eller din virksomheds-administrator for at blive oprettet som bruger i din virksomheds konto.
                </div>
            </div>

            <div style={{display:'flex', flexDirection:'row', alignItems: 'stretch', fontSize: 14, marginTop:30}}>
                <div style={{flex: 1, paddingTop: 3}}><hr/></div>
                <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>Opret en konto</div>
                <div style={{flex: 1, paddingTop: 3}}><hr/></div>
            </div>

            <div style={{display:'flex', flexDirection:'column'}}>
                <AnonymousField     
                    type='text'
                    label='Brugernavn (e-mail)'
                    value={username}
                    onValidChange={setUsername}/>

                <AnonymousField     
                    type='password'
                    label='Adgangskode'
                    value={password}
                    showError={passwordErrors.length > 0}
                    onValidChange={setPassword}/>

                <AnonymousField     
                    type='password'
                    label='Gentag Adgangskode'
                    value={passwordRepeated}
                    errors={passwordErrors}
                    onValidChange={setPasswordRepeated}/>

                <AnonymousField     
                    type='text'
                    label='Navn'
                    value={fullName}
                    onValidChange={setFullName}/>

                <AnonymousField     
                    type='text'
                    label='Firmanavn'
                    value={companyName}
                    onValidChange={setCompanyName}/>

                <AnonymousField     
                    type='text'
                    label='Telefonnummer'
                    value={companyPhoneNumber}
                    onValidChange={setCompanyPhoneNumber}
                    onKeyDown={onKeyDown}
                />
            </div>

            <div style={{marginTop:20}}>
                <Button loading={props.loading} disabled={!canExecute} className={styles.registerButton} onClick={register} label='Opret konto' />
            </div>

            <ErrorListDisplay errors={ props.errors ? props.errors :[]}/>
        </div>
    ) 
}