import React from "react";
import { withStyles, WithStyles, Grid, Typography } from "@material-ui/core";
import Api from "server/api";
import Component from "component";
import { Paper } from 'controls/paper'
import styles from './styles'
import Building from './building';
import _ from 'lodash';
import BuildingDto = Api.Cases.Queries.GetCase.BuildingDto

type Props = {
    buildings: readonly BuildingDto[];
    updateBuilding: (building: BuildingDto) => void;
    deleteBuilding: (id: string) => Promise<void | unknown>;
} & WithStyles<typeof styles>

type State = {
    openBuildingId: string;
    addedBuildingIds: { [id: string]: string };
    prevProps: {
        buildings: readonly BuildingDto[]
    };
}

export default withStyles(styles)(class Buildings extends Component<Props, State> {
    state : State = {
        openBuildingId: null,
        addedBuildingIds: {},
        prevProps: {
            buildings: null
        }
    };

    componentDidMount = () => {
        if (this.props.buildings.length > 0) {
            this.setState({ openBuildingId: this.props.buildings.find(() => true).id });
        }
    }

    static getDerivedStateFromProps = (nextProps: Props, state: State): Partial<State> => {
        if (nextProps.buildings == state.prevProps.buildings) return null;

        const diff = !!state.prevProps.buildings
            ? _(nextProps.buildings).differenceBy(state.prevProps.buildings, 'id').map(x => x.id).keyBy().value()
            : {};

        return {
            addedBuildingIds: diff,
            prevProps: {
                buildings: nextProps.buildings
            }
        };
    }

    render() {
        return (
            <div className={this.props.classes.buildings}>
                {
                    this.props.buildings.filter(x => !this.isOpen(x.id)).length
                        ? <Paper className={this.props.classes.buildingHeader}>
                            <Grid container>
                                <Grid item xs={6}><Typography variant='h6'>BYGNINGER</Typography></Grid>
                                <Grid item xs={2}><Typography variant='body1'>Bygningsareal</Typography></Grid>
                                <Grid item xs={2}><Typography variant='body1'>Opførelsesår</Typography></Grid>
                            </Grid>
                        </Paper>
                        : null
                }
                {
                    this.props.buildings.map(building => (
                        <Building
                            key={building.id}
                            building={building}
                            isOpen={this.isOpen(building.id)}
                            isNewest={!!this.state.addedBuildingIds[building.id]}
                            canClose={this.canClose}
                            updateBuilding={this.props.updateBuilding}
                            deleteBuilding={this.props.deleteBuilding}
                            setOpenBuildingId={this.setOpenBuildingId}
                        />
                    ))
                }
            </div>
        )
    }

    isOpen = (buildingId: string) => !!this.state && buildingId === this.state.openBuildingId;

    canClose = () => this.props.buildings.length > 1;

    setOpenBuildingId = (openBuildingId: string) => {
        this.setState({ openBuildingId });
    }
})

