import React, { useContext } from 'react'
import ReactPDF, { Image, View } from '@react-pdf/renderer';
import { Text, Header1, Header2, Header3, SmallBoldText, BoldText } from './text'
import { Divider } from './controls'
import { Table, Row } from 'pages/report/controls/table'
import { flatten, chunk, flattenDeep } from 'lodash';
import { isNullOrWhitespace, isParsableNumber } from 'utils';
import { ReportThemeContext } from './context';
import { Circle, Line, Square} from './shapes';

import Api from 'server/api';
import Es = Api.Cases.Queries.Reports.Es;
import CardPageDto = Es.CardPageDto;
import _ from 'lodash';

const ImageTile = (props: { image: CardPageDto.ImageDto, height: number, width: number }) =>
    <View style={{ maxHeight: props.height, maxWidth: props.width }} >
        {/* Note that adding a query param like below is not very "safe" as I rely on there already being a ? in the URL,
            But I do it for now until the URL javascript API support relative URLs - or we have time to find an appropriate lib to do it.
            
            Get image three times larger for better image quality for print*/}
        <Image style={{ objectFit: 'scale-down', objectPosition: '50% 100%' }} source={'/api/' + props.image.url + `&width=${props.width * 3}`} />
    </View>;

const ImageText = (props: { text: string }) =>
    <Text style={{ padding: 6, paddingBottom: 12, alignSelf: 'center' }}>{props.text}</Text>

const IconCell = (props: React.PropsWithChildren<ReactPDF.ViewProps>) =>
    <View style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row'
    }}>{props.children}</View>

const RightCell = (props: Merge<ReactPDF.ViewProps, { children: React.ReactText | React.ReactText[] }>) => {
    const colorTheme = useContext(ReportThemeContext).color;

    return <View style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center' as any, // 'center' not part of the typings yet
        borderLeft: 1,
        borderLeftColor: colorTheme.secondaryColor,
        color: '#9B9C91',
        paddingLeft: 4,
        marginVertical: 1,
        ...props.style
    }}>
        <Text style={{
            fontSize: 6,
            lineHeight: 7 / 6 // else it would not center vertically
        }}>{props.children}</Text>
    </View>
}

const LocationCell = (props: Merge<ReactPDF.ViewProps, { children: React.ReactText }>) =>
    <View style={{ marginLeft: 5, marginVertical: 1 }}>
        <SmallBoldText style={{ marginTop: 1 }}>{props.children}</SmallBoldText>
    </View>

const DescriptionCell = (props: Merge<ReactPDF.ViewProps, { children: React.ReactText }>) =>
    <View style={{
        marginLeft: 10,
        marginVertical: 1,
        marginRight: 26
    }}>
        <Text>{props.children}</Text>
    </View>

const HeaderCell = (props: Merge<ReactPDF.ViewProps, { children: React.ReactText }>) =>
    <View style={{
        marginLeft: 10,
        marginVertical: 1,
        marginRight: 26
    }}>
        <BoldText style={{ marginTop: 1 }}>{props.children}</BoldText>
    </View>

const CardProblemRow = ({ problem, hasMore }: { problem: CardPageDto.ProblemDto, hasMore: boolean }) =>
    <Row key={problem.taskId} wrap>
        <IconCell>{hasMore && <Line />}</IconCell>
        <View />
        <DescriptionCell>{problem.description}</DescriptionCell>
        <RightCell>{problem.number}</RightCell>
    </Row>

const HeadProblemRow = ({ location, problem, hasMore }: { location: string, problem: CardPageDto.ProblemDto, hasMore: boolean }) =>
    <Row key={location} wrap>
        <IconCell><Circle hasMore={hasMore} /></IconCell>
        <LocationCell>{location}</LocationCell>
        <DescriptionCell>{problem.description}</DescriptionCell>
        <RightCell>{problem.number}</RightCell>
    </Row>

const TailProblemRow = ({ problem, hasMore }: { problem: CardPageDto.ProblemDto, hasMore: boolean }) =>
    <Row key={problem.taskId} wrap>
        <IconCell>{hasMore && <Line />}</IconCell>
        <View />
        <DescriptionCell>{problem.description}</DescriptionCell>
        <RightCell>{problem.number}</RightCell>
    </Row>

const HeadHeaderRow = ({ location, header, hasMore }: { location: string, header: string, hasMore: boolean }) =>
    <Row key={header} wrap>
        <IconCell><Square hasMore={hasMore} /></IconCell>
        <LocationCell>{location}</LocationCell>
        <HeaderCell>{header}</HeaderCell>
        <View />
    </Row>

const TailHeaderRow = ({ header, hasMore }: { header: string, hasMore: boolean }) =>
    <Row key={header} wrap>
        <IconCell>{hasMore && <Line />}</IconCell>
        <View />
        <HeaderCell>{header}</HeaderCell>
        <View />
    </Row>

const EmptyRow = ({ hasMore }: { hasMore: boolean }) =>
    <Row style={{ height: 10 }}>
        <IconCell>{hasMore && <Line />}</IconCell>
        <View />
        <View />
        <View />
    </Row>

// only applies if there is no card group
const HeadCardRow = ({ location, card, hasMore }: { location: string, card: CardPageDto.CardDto, hasMore: boolean }) =>
    <Row key={card.cardId} wrap>
        <IconCell><Square hasMore={hasMore} /></IconCell>
        <LocationCell>{location}</LocationCell>
        <DescriptionCell>{card.description}</DescriptionCell>
        {!isNullOrWhitespace(card.expectedRemainingLifetime) && 
            <RightCell style={{ color: '#000' }}>Forventet levetid: {card.expectedRemainingLifetime}</RightCell>}
    </Row>

const TailCardRow = ({ card, hasMore, omitHeader }: { card: CardPageDto.CardDto; hasMore: boolean; omitHeader?: boolean; }) =>
    <Row key={card.cardId} wrap>
        <IconCell>{hasMore && <Line />}</IconCell>
        <View />
        <DescriptionCell>{!omitHeader ? card.description : ''}</DescriptionCell>
        {!isNullOrWhitespace(card.expectedRemainingLifetime) && 
            <RightCell style={{ color: '#000' }}>Forventet levetid: {card.expectedRemainingLifetime}</RightCell>}
    </Row>

const NoCardGroups = (cards: readonly CardPageDto.CardDto[], location: string, hasProblemLocations: boolean) =>
    cards.map((card, i) => [
        i === 0
            ? <HeadCardRow location={location} card={card} hasMore={hasProblemLocations} />
            : <>
                <EmptyRow hasMore={hasProblemLocations} />
                <EmptyRow hasMore={hasProblemLocations} />
                <TailCardRow card={card} hasMore={hasProblemLocations} />
            </>,
        card.problems.map((problem, j) => <>
            <EmptyRow hasMore={hasProblemLocations} />
            <CardProblemRow key={problem.taskId} problem={problem} hasMore={hasProblemLocations} />
        </>)
    ]);

const CardGroups = (cardGroups: readonly CardPageDto.CardGroupDto[], location: string, hasProblemLocations: boolean) =>
    cardGroups.map((cardGroup, i) => [
        i === 0
            ? <HeadHeaderRow location={location} header={cardGroup.header} hasMore={hasProblemLocations} />
            : <>
                <EmptyRow hasMore={hasProblemLocations} />
                {_.isEmpty(cardGroup.header) || <TailHeaderRow header={cardGroup.header} hasMore={hasProblemLocations} />}
            </>,
        cardGroup.cards.map((card, j) => [
            j !== 0 && <>
                <EmptyRow hasMore={hasProblemLocations} />,
                <EmptyRow hasMore={hasProblemLocations} />
            </>,
            <TailCardRow card={card} hasMore={hasProblemLocations} />,
            card.problems.map((problem, k) => <>
                <EmptyRow hasMore={hasProblemLocations} />
                <CardProblemRow key={problem.taskId} problem={problem} hasMore={hasProblemLocations} />
            </>)
        ])
    ]);

const StatusLocationRows = (statusLocation: CardPageDto.StatusLocationDto, first: boolean) => {

    // Build new statusLocation where all descendent problems without description are filtered out
    // This is to avoid having empty problem-rows, leaving number-reference hanging to the right
    statusLocation = {
        ...statusLocation,
        cardGroups: statusLocation.cardGroups.map(x => ({
            ...x,
            cards: x.cards.map(c => ({
                ...c,
                problems: c.problems.filter(p => p.description)
            }))
        })),
        problems: statusLocation.problems.map(x => ({
            ...x,
            problems: x.problems.filter(p => p.description)
        })).filter(x => x.problems.length > 0)
    }

    const hasProblemLocations = statusLocation.problems.length > 0;
    const location = statusLocation.location;
    const cardGroups = statusLocation.cardGroups;

    return [
        !first ? <Divider size="thin" margin="small" /> : null,
        cardGroups.length === 1 && _.isEmpty(cardGroups[0].header)
            ? NoCardGroups(cardGroups[0].cards, location, hasProblemLocations)
            : CardGroups(cardGroups, location, hasProblemLocations),
            
        statusLocation.problems.map((problemLocation, i) => {
            const [headProblem, ...problems] = problemLocation.problems;
            const hasMoreProblemLocations = i < statusLocation.problems.length - 1;

            return [
                <EmptyRow hasMore={true} />,
                <HeadProblemRow key={headProblem.taskId} location={problemLocation.location} problem={headProblem} hasMore={hasMoreProblemLocations} />,
                problems.map((p, j) => <>
                    <EmptyRow hasMore={true} />
                    <TailProblemRow key={p.taskId} problem={p} hasMore={hasMoreProblemLocations} />
                </>)
            ];
        })
    ]
}

const TaskTableIconCell = ({ task }: { task: CardPageDto.TaskDto }) =>
    task.location != ''
        ? <View style={{
            minHeight: 15,
            flexDirection: 'row',
            marginRight: 3
        }}>{task.locationType == 'top'
            ? <Square hasMore={false} />
            : <Circle hasMore={false} />}
        </View>
        : null

const TaskHeaderRow = (props: { header: string, task: CardPageDto.TaskDto }) =>
    <Row minPresenceAhead={20} style={{
        paddingRight: 8
    }}>
        <Header3>{props.task.number}. {props.task.description}</Header3>
        <View/>
        <PriceCell header={props.header} value={props.task.totalPrice} />
    </Row>

const TaskTextRow = (props: { task: CardPageDto.TaskDto }) =>
    <Row wrap style={{
        paddingRight: 8
    }}>        
        <View style={{ flexDirection: 'row' }}>
            <TaskTableIconCell task={props.task} />
            <Text style={{
                flex: 1,
                marginTop: 2,
                marginRight: 5
            }}>
                {props.task.location != '' ? <SmallBoldText style={{ letterSpacing: -0.3 }}>{props.task.location}  </SmallBoldText> : null}
                {props.task.solutionDescription}
            </Text>
        </View>
        <View/>
        <View/>
    </Row>

const PriceCell = (props: { header: string, value: string }) => {
    const colorTheme = useContext(ReportThemeContext).color;

    return <View
        style={{
            alignSelf: 'flex-start',
            borderLeft: 1,
            borderLeftColor: colorTheme.secondaryColor,
            paddingLeft: 6,
            marginVertical: 1,
        }}>
        <SmallBoldText>{props.header}:</SmallBoldText>
        <Text>{props.value}</Text>
    </View>
}

const OneTimeTaskTable = (props: CardPageDto) =>
    <Table colWidths={[0, 34, 85]}>
        {flatten(props.oneTimeTasks.map((task, i) => [
            <TaskHeaderRow header={`Omkostning år ${task.year}`} task={task}/>,
            <TaskTextRow task={task}/>,
            i < props.oneTimeTasks.length - 1 ? <Row key={'divider' + i} colWidths={[0]}><Divider size="thin" margin="large" /></Row> : null
        ]
        ))}
    </Table>

const cardHeader = (header: Api.Cases.Queries.Reports.Es.CardPageDto.HeaderDto) => header.classification == null || isParsableNumber(header.classification)
    ? `${header.classificationDescription}`
    : `${header.classificationDescription} (${header.classification})`;

export const ImageSection = (props: { images: readonly CardPageDto.ImageDto[] }) => {
    const { images } = props;

    if (images.length === 0) return null;

    const imageWidth = 230;
    const imageHeight = imageWidth * 4 / 3 - 5;

    return <>
        <Divider size="thick" margin="large" />
        {
            chunk(images, 2)
                .map((group, i) =>
                    <Table colWidths={[0]} wrap={false} key={i}>
                        <Row colWidths={[imageWidth, 0, imageWidth]} cellStyle={{ justifyContent: "flex-end" }} wrap>
                            <ImageTile image={group[0]} height={imageHeight} width={imageWidth} />
                            <View />
                            {group.length === 2
                                ? <ImageTile image={group[1]} height={imageHeight} width={imageWidth} />
                                : <View />}
                        </Row>
                        <Row key={i + "description"} colWidths={[imageWidth, 0, imageWidth]} wrap>
                            <ImageText text={group[0].description} />
                            <View />
                            {group.length === 2
                                ? <ImageText text={group[1].description} />
                                : <View />}
                        </Row>
                    </Table>)
        }
    </>;
}

export const StatusTable = (props: CardPageDto) =>
    <Table colWidths={[14, 56, 0, 119]}>
        {flattenDeep(props.status.map((statusLocation, i) => StatusLocationRows(statusLocation, i === 0)))}
    </Table>

export const RecurringTaskTable = (props: CardPageDto) =>
    <Table colWidths={[0, 34, 85]}>
        {flatten(props.recurringTasks.map((task, i) => [
            <TaskHeaderRow header={`Omkostning fra ${task.year}`} task={task}/>,
            <TaskTextRow task={task}/>,
            i < props.recurringTasks.length - 1 ? <Row key={'divider' + i} colWidths={[0]}><Divider size="thin" margin="large" /></Row> : null
        ]
        ))}
    </Table>

export const OneTimeTasks = (props: CardPageDto) =>
    props.oneTimeTasks.length > 0
        ? <>
            <Divider size="thick" margin="large" />
            <Header2 style={{ marginTop: 10 }}>{props.header.number}.1 Genopretninger</Header2>
            <OneTimeTaskTable {...props} />
        </>
        : null;

export const CardHeader = (props: CardPageDto) =>
    <Header1>{props.header.number}. {cardHeader(props.header)}</Header1>