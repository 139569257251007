import React, { useContext } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Api from "server/api";
import styles from './styles'
import { FormField } from 'controls/field/form-field';
import { SelectField } from 'controls/field/select-field';
import { Paper } from 'controls/paper'
import { BudgetLines } from 'pages/budget-lines';
import Breadcrumbs from "controls/breadcrumbs";
import { createUpdater2, via, UpdateAction, createUpdater0 } from "utils/updaters";
import { MainContext } from "context";
import RecurringTaskDto  = Api.Cases.Queries.GetTask.RecurringTaskDto;
import { Maybe } from "utils/maybe";

type Props = {
    taskId: string
    priorities: Api.Modules.Priority.Queries.GetPriorities.PriorityDto[]
    task: Api.Cases.Queries.GetTask.RecurringTaskDto
    card: Api.Cases.Queries.GetTask.CardDto
    updateTask: (task: RecurringTaskDto) => void,
}

const useStyles = makeStyles(styles);

export const RecurringTask = (props: Props) => {
    const context = useContext(MainContext);

    const styles = useStyles(props);

    const priorities = props.priorities.map(x => ({ label: x.translation, value: x.value }));

    const writeTask: UpdateAction = async (update) =>
        await context.server.command(new Api.Cases.Commands.WriteTaskField({
            caseId: null,
            cardId: props.card.id,
            taskId: props.taskId,
            fieldName: update.fieldName,
            value: update.value
        }));

    const update = createUpdater0(props.task, via(props.updateTask), writeTask);
    const updatePriority = createUpdater2(props.task, via(props.updateTask), ['extensions', 'priority'], writeTask);

    return <>
        <Breadcrumbs crumbs={[props.card.group, props.card.type, props.card.description || 'Unavngivet kort', 'Løbende opgave']} />
        <Paper className={styles.container} header='Placering'>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <FormField
                        type='text'
                        label='Præcisér placering'
                        field={props.task.location}
                        onValidBlur={update("location")}
                        optional
                        placeholder={props.card.location}
                        inputLabelProps={{
                            shrink: !!props.card.location || undefined
                        }}
                    />
                </Grid>
            </Grid>
        </Paper>
        <Paper className={styles.container} header='Opgave'>
            <Grid container spacing={1}>
                <Grid item xs={props.task.extensions.priority ? 9 : 12}>
                    <FormField
                        type='text'
                        label="Opgavebeskrivelse"
                        field={props.task.description}
                        onValidBlur={update("description")}
                    />
                </Grid>
                {props.task.extensions.priority && <Grid item xs={3}>
                    <SelectField
                        optional
                        label="Prioritet"
                        field={props.task.extensions.priority.priority}
                        onChange={updatePriority("priority")}
                        items={priorities}
                    />
                </Grid>}
                <Grid item xs={12}>
                    <FormField
                        type='text'
                        label='Vedligeholdelsesinstruks'
                        multiline
                        field={props.task.solutionDescription}
                        onValidBlur={update("solutionDescription")}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormField
                        label='Interval'
                        tooltip='Antal år mellem gentagelse af opgaven'
                        field={props.task.interval}
                        type='number'
                        onValidBlur={update("interval")}
                    />
                </Grid>
            </Grid>
        </Paper>
        <BudgetLines
            cardId={props.card && props.card.id}
            taskId={props.taskId}
            budgetLines={props.task.budgetLines}
        />
        <div className={styles.spacer}></div>
    </>
}