import React, { useState } from "react";
import { withStyles, WithStyles, Typography, Toolbar, Grid, TableHead, TableCell, TableBody, TableRow, Table, Button as MuiButton, makeStyles } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import Api from "server/api";
import styles from './styles'
import { Button } from "controls/button";
import { WarningPopup } from "controls/warning-popup";
import { NewConsultantResult, NewConsultantPopup } from "./new-consultant-popup";
import { sguid } from "utils";
import { replaceById } from "utils/immutable-array";
import { EditUserPopup } from "./edit-user-popup";
import { user } from "utils";
import { useServer } from "custom-hooks/use-server";
import { useImmutableArrayState, useCommandSubscription } from "custom-hooks";
import { Authentication } from "root";
import { Tooltip } from "controls/tooltip";
import { IconButton } from "controls/button/icon-button";
import { Checkbox } from "controls/checkbox";

import ProfileDto = Api.Users.Queries.GetProfilesInCompany.ProfileDto;
import { ChangePasswordPopup } from "./change-password-popup";

type Props = {
    authentication: Authentication
}

const useStyles = makeStyles(styles);

export const Users = (props: Props) => {
    const styles = useStyles();

    const server = useServer();

    const [profiles, setProfiles] = useImmutableArrayState<ProfileDto>();
    const [showCreateProfilePopup, setShowCreateProfilePopup] = useState<boolean>(false);
    const [confirm, setConfirm] = useState<string|false>(false);
    const [editUser, setEditUser] = useState<ProfileDto|false>(false);
    const [changePasswordPopup, setChangePasswordPopup] = useState<string|false>(false);

    useCommandSubscription(() => {
        server.query<readonly ProfileDto[]>(
            new Api.Users.Queries.GetProfilesInCompany({})
        ).subscribe(setProfiles)
    }, []);

    const newConsultant = async (result: NewConsultantResult) => {
        setShowCreateProfilePopup(false);

        await server.command(new Api.Users.Commands.NewConsultant_ByCompanyAdmin({ 
            newProfileId: `profile/${sguid()}`, 
            ...result
        }));
    }
    
    const disable = async (profileId: string) => {
        await server.command(new Api.Users.Commands.DisableProfile({ profileId }));
    }

    const updateProfile = async (profile: ProfileDto) => {
        setProfiles(replaceById(profile));

        await server.command(new Api.Users.Commands.UpdateProfile({
            profileId: profile.id,
            ...profile,
            rights: {
                companyAdministrator: profile.isCompanyAdministrator,
                allowAsCompleter: profile.allowAsCompleter,
                allowAsApprover: profile.allowAsApprover,
                allowAsConsultant: profile.allowAsConsultant,
            }
        }));
    }    

    const changePassword = async (profileId: string, newPassword: string) => {
        await server.command(new Api.Users.Commands.ChangePassword_BySysAdmin({
            profileId: profileId,
            newPassword: newPassword
        }));
    }   

    return (
        <div style={{ width: '100%' }}>
            <Toolbar>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container justify='flex-end'>
                            <Button label={'Opret bruger'} onClick={() => setShowCreateProfilePopup(true)}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell variant='head'>Navn</TableCell>
                        <TableCell variant='head'>Brugernavn</TableCell>
                        <TableCell variant='head'>Admin</TableCell>
                        <TableCell variant='head'>Initialer</TableCell>
                        <TableCell variant='head'>Alternativ email</TableCell>
                        <TableCell variant='head'></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{profiles.map(row => 
                    (<TableRow key={row.username} hover>
                        <TableCell component="th" scope="row">{row.fullName}</TableCell>
                        <TableCell>{row.username}</TableCell>
                        <TableCell>
                            <Checkbox 
                                checked={row.isCompanyAdministrator} 
                                disabled
                                />
                        </TableCell>
                        <TableCell>{row.initials}</TableCell>
                        <TableCell>{row.alternativeEmail}</TableCell>
                        <TableCell>
                            <Toolbar variant='dense' disableGutters className={styles.toolbar}>
                                {
                                    props.authentication.isSystemAdmin && 
                                    <Tooltip title='Skift adgangskode'>
                                        <IconButton onClick={() => setChangePasswordPopup(row.id)}>
                                            <LockIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                <Tooltip title='Rediger bruger'>
                                    <IconButton onClick={() => setEditUser(row)}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title='Deaktivér bruger'>
                                    <IconButton disabled={user.profileId === row.id} onClick={() => setConfirm(row.id)}>
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Toolbar>
                        </TableCell>
                    </TableRow>)
                )}
                </TableBody>
            </Table>
            <NewConsultantPopup
                open={showCreateProfilePopup}
                onClose={() => setShowCreateProfilePopup(false)}                    
                onOk={newConsultant}
            />

            <ChangePasswordPopup
                open={!!changePasswordPopup}
                onOk={async (profileId, newPassword) => { 
                    await changePassword(profileId, newPassword); 
                    setChangePasswordPopup(false);
                }}
                onClose={() => setChangePasswordPopup(false)}
                profileId={changePasswordPopup && changePasswordPopup}
            />

            <EditUserPopup
                open={!!editUser}
                onOk={async newProfile => { 
                    await updateProfile(newProfile); 
                    setEditUser(false);
                }}
                onClose={() => setEditUser(false)}
                profile={editUser && editUser}
            />

            <WarningPopup
                onOk={async () => {  
                    if (typeof confirm !== 'string') return;
                    // we change state before the command call, as the command might fail and we have no
                    // proper handling of server side errors yet. When we have that we can think about 
                    // keeping the dialog open and showing the error instead. Or something in that vein.
                    setConfirm(false);
                    await disable(confirm);
                }}
                onClose={() => setConfirm(false)}
                isOpen={!!confirm} 
                title='Deaktivér bruger?'
                message='Du er ved at deaktivere en bruger. Er du sikker?'
                okText='DEAKTIVÈR'
                cancelText='FORTRYD'
            />

        </div>            
    )
}
