import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { FormField } from "controls/field/form-field";
import { upperFirst } from "lodash";
import { LogoContainer } from "./logo/logo-container";
import { sguid, readSingleFile } from "utils";
import { EditCompanyNamePopup } from "./edit-company-name-popup";
import { useState} from "react";
import { useServer, useCommandSubscription, useCommand } from "custom-hooks";
import { Button } from "controls/button";
import { Authentication } from "root";
import { v4 as uuid } from 'uuid';

import Api from "server/api";
import CompanyInfoDto = Api.Users.Queries.GetCompanyInfo.CompanyInfoDto;
import Commands = Api.Users.Commands;

export const CompanyInfo = (props : { authentication: Authentication }) => {

    const [company, setCompanyInfo] = useState(null as CompanyInfoDto | null);
    const [openChangeCompanyNamePopup, setOpenChangeCompanyNamePopup] = useState(false);
    const server = useServer();

    useCommandSubscription(() => server.query<Api.Users.Queries.GetCompanyInfo.CompanyInfoDto>(
        new Api.Users.Queries.GetCompanyInfo({})
    ).subscribe(setCompanyInfo), [server]);

    const writeCompany = useCommand((field: { fieldName: string, value: any }) => 
        new Api.Users.Commands.WriteCompanyField(field));

    const updateCompany = <TKey extends keyof CompanyInfoDto>(name: TKey) => 
        (value: CompanyInfoDto[TKey]) => 
            {
                writeCompany({fieldName: upperFirst(name), value: value});
                setCompanyInfo(s => ({...s, [name]: value}))
            };

    const createAccount = useCommand(() => 
        new Commands.CreateAccount({
            accountId: uuid()
        }));

    const onFileSelected = async (files: File[]) => {
        const onFileLoaded = async (_: File, content: any) => {
            await uploadLogo(content);
        };

        files.map(file => {
            readSingleFile(file, onFileLoaded);
        })
    };

    const uploadLogo = useCommand((data: string) => 
        new Commands.UploadCompanyLogo({
            logoId: `image/${sguid()}`,
            data: data
        }));

    const deleteLogo = useCommand(() => 
        new Commands.DeleteCompanyLogo({}));

    if (company === null) return null;

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <FormField
                        type='text'
                        label='Firmanavn'
                        value={company.name}
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <div style={{ height: '100%', display: 'flex', alignItems: 'center', paddingTop: 7 }}>
                        <Button label={'Skift firmanavn'} onClick={() => setOpenChangeCompanyNamePopup(true)} />
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <FormField
                        type='text'
                        label='Vejnavn'
                        value={company.streetName}
                        onValidBlur={updateCompany("streetName")}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormField
                        type='text'
                        label='Husnr.'
                        value={company.houseNumber}
                        onValidBlur={updateCompany("houseNumber")}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormField
                        type='number'
                        label='Postnr.'
                        value={company.postalCode}
                        onValidBlur={updateCompany("postalCode")}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormField
                        disabled={true}
                        optional={true}
                        type='text'
                        label='By'
                        value={company.postalCity}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormField
                        type='text'
                        label='Tlf.'
                        value={company.phoneNumber}
                        onValidBlur={updateCompany("phoneNumber")}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormField
                        type='text'
                        label='Email'
                        value={company.email}
                        onValidBlur={updateCompany("email")}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormField
                        type='text'
                        label='CVR-nummer'
                        value={company.cvr}
                        onValidBlur={updateCompany("cvr")}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormField
                        type='text'
                        label='EAN/GLN-nummer'
                        value={company.gln}
                        onValidBlur={updateCompany("gln")}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormField
                        type='text'
                        label='Faktura att.'
                        value={company.invoiceAttention}
                        onValidBlur={updateCompany("invoiceAttention")}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormField
                        type='text'
                        label='Faktura e-mail'
                        value={company.invoiceEmail}
                        onValidBlur={updateCompany("invoiceEmail")}
                    />
                </Grid>
                {props.authentication.isSystemAdmin && <>
                    <Grid item xs={6}>
                        <FormField
                            type='text'
                            label='ESA id'
                            value={company.accountId}
                            disabled />
                    </Grid>
                    <Grid item xs={6}>
                        {!company.accountId &&
                            <div style={{ height: '100%', display: 'flex', alignItems: 'center', paddingTop: 7 }}>
                                <Button label={'Opret ny konto'} onClick={async () => createAccount()} />
                            </div>
                        }
                    </Grid>
                </>}

                <Grid item xs={12}>
                    <Typography variant='h6'>Firmalogo</Typography>
                </Grid>
                <Grid item>
                    <LogoContainer
                        onFileSelected={onFileSelected}
                        logo={company.logo}
                        deleteLogo={async () => {
                            await deleteLogo();
                        }}
                    />
                </Grid>
            </Grid>
            <EditCompanyNamePopup
                open={!!openChangeCompanyNamePopup}
                onOk={async (companyName: string) => {
                    updateCompany("name")(companyName);
                    setOpenChangeCompanyNamePopup(false)
                }}
                onClose={() => setOpenChangeCompanyNamePopup(false)}
                companyName={company.name}
            />
        </>
    )
}