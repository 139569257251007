import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Api from "server/api";
import { FormField } from "controls/field/form-field";
import { SelectField } from "controls/field/select-field";
import { Button } from "controls/button";
import { WarningPopup } from "controls/warning-popup";
import { createUpdater0, via, createUpdater2, UpdateAction } from "utils/updaters";
import { useCommand, useOpenIds } from "custom-hooks";

import styles from './styles';

import BuildingDto = Api.Cases.Queries.GetCase.BuildingDto

type Props = {
    building: BuildingDto;
    updateBuilding: (building: BuildingDto) => void;
    deleteBuilding: (id: string) => Promise<void | unknown>;
}

const useStyles = makeStyles(styles);

export const BuildingDetails = (props: Props) => {

    const { caseId } = useOpenIds();

    const styles = useStyles();

    const [deletePopupOpen, setDeletePopupOpen] = useState(false);

    const writeBuilding : UpdateAction = useCommand((update: { fieldName: string, value: any }) =>
        new Api.Cases.Commands.WriteBuildingField({
            caseId: caseId,
            buildingId: props.building.id,
            fieldName: update.fieldName,
            value: update.value
        })
    )

    const deleteBuilding = () => {
        setDeletePopupOpen(false);
        return props.deleteBuilding(props.building.id);
    };

    const update = createUpdater0(props.building, via(props.updateBuilding), writeBuilding);
    const updateObh = createUpdater2(props.building, via(props.updateBuilding), ['extensions', 'obh'], writeBuilding);
    const updateEbas = createUpdater2(props.building, via(props.updateBuilding), ['extensions', 'ebas'], writeBuilding);

    const energyLabels = [
        {label: "A2020", value: "A2020"},
        {label: "A2015", value: "A2015"},
        {label: "A2010", value: "A2010"},
        {label: "B", value: "B"},
        {label: "C", value: "C"},
        {label: "D", value: "D"},
        {label: "E", value: "E"},
        {label: "F", value: "F"},
        {label: "G", value: "G"}
    ];

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FormField
                        type='text'
                        label="Bygningsnavn"
                        field={props.building.name}
                        onValidBlur={update("name")}
                    />
                </Grid>
                <Grid container spacing={1}>
                    {props.building.extensions.obh && <Grid item xs>
                        <FormField
                            type='number'
                            label="Matrikulært areal"
                            field={props.building.extensions.obh.cadastralArea}
                            onValidBlur={updateObh("cadastralArea")}
                        />
                    </Grid>}
                    <Grid item xs>
                        <FormField
                            type='number'
                            label="Bygningsareal"
                            field={props.building.buildingArea}
                            onValidBlur={update("buildingArea")}
                        />
                    </Grid>
                    <Grid item xs>
                        <FormField
                            type='number'
                            label="Opførelsesår"
                            field={props.building.buildingYear}
                            onValidBlur={update("buildingYear")}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    {props.building.extensions.ebas && <Grid item xs>
                        <SelectField
                            optional
                            label='Energimærke'
                            items={energyLabels}
                            field={props.building.extensions.ebas.energyLabel}
                            onChange={updateEbas('energyLabel')}
                        />
                    </Grid>}
                    <Grid item xs>
                        <FormField
                            type='number'
                            label="Energimærke-nr."
                            field={props.building.energyLabelSerialIdentifier}
                            onValidBlur={update("energyLabelSerialIdentifier")}
                        />
                    </Grid>
                    <Grid item xs>
                        <FormField
                            type='date'
                                label="Energimærke gyldigt fra"
                            field={props.building.energyLabelReportDate}
                            onValidBlur={update("energyLabelReportDate")}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Button label="Slet bygning" onClick={() => setDeletePopupOpen(true)} />
                    <WarningPopup
                        onOk={deleteBuilding}
                        onClose={() => setDeletePopupOpen(false)}
                        isOpen={deletePopupOpen}
                        title='Slet bygningen?'
                        message='Du er ved at slette bygningen. Er du sikker?'
                        okText='SLET'
                        cancelText='FORTRYD'
                    />
                </Grid>
            </Grid>
        </>
    )
}
