import React, { useContext } from 'react'
import { View } from '@react-pdf/renderer';
import Api from 'server/api'
import { VerticalSpacer } from '../controls'
import { PageContext } from '../context'
import { BuildingInfo, CaseData, EstateDescription, Paragraph } from '../estate-page'

import Es = Api.Cases.Queries.Reports.Es;
import EstatePageDto = Es.EstatePageDto;

const EstateDescriptionEs = (props: EstatePageDto) =>
    <EstateDescription imageUrl={props.imageUrl}>
          <Paragraph body={props.description}/>
    </EstateDescription >

export const EsEstatePage = (props: EstatePageDto) => {
    const context = useContext(PageContext);
    const colWidths = [80, 170, 80, 0];
    
    return <View>
        <CaseData {...props} header={`Data for ${context.reportTitle.toLowerCase()}`} colWidths={colWidths} />
        <VerticalSpacer height="xlarge" />
        <BuildingInfo {...props} colWidths={colWidths} />
        <VerticalSpacer height="xlarge" />
        <EstateDescriptionEs {...props} />
    </View>
}