import { PropsWithChildren } from "react"
import React from "react"
import MuiPickersUtilsProvider from "@material-ui/pickers/MuiPickersUtilsProvider"
import DateFnsUtils from "@date-io/date-fns"
import da from "date-fns/locale/da"

export { DateUtilsProvider };
const DateUtilsProvider = (props: PropsWithChildren<{}>) => {

    // The @material-ui/pickers package use @date-io as an adapter, in order to support multiple date-libraries
    // We are currently using date-fns, as per the @date-io/date-fns adapter we use here.
    // Each KeyboardDatePicker consumes these values.

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={da}>
            {props.children}
        </MuiPickersUtilsProvider>
    )
}