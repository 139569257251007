import React, { useState } from "react";
import AnonymousPage from "controls/anonymous-page";
import { sguid } from 'utils';
import { useCommand, pipe, after, useLoadingState} from "custom-hooks";
import { NewCompanyInformation } from "./new-company-information";
import { includeAllErrors, DvError, useErrorDisplay } from "custom-hooks";
import Api from "server/api";
import { v4 as uuid } from 'uuid';

const CompletedRegistrationInformation = () =>
    <div style={{flex: 1, flexDirection:'column'}}>
        <div style={{display:'flex', flexDirection:'column', alignItems: 'center'}}>
            <div style={{fontSize: 30}}>Tak for din registrering!</div>
            <p style={{fontSize: 14}}>
                Du vil inden for få minutter modtage en bekræftelses-e-mail i din indbakke.
            </p>

            <p style={{fontSize: 14}}>
                For at aktivere din konto skal du klikke på linket i e-mailen, hvorefter du kan logge ind med dit valgte brugernavn og din ønskede adgangskode.
                Hvis du ikke modtager denne e-mail, kan det ske, at den findes i den uønsket post.
            </p>

            <p style={{fontSize: 14}}>Du kan komme til login-siden <a style={{color: '#56DFA6', textDecoration: 'None'}} href='/'>her</a>.</p>
        </div>  
    </div> 

export const NewCompanyBySelfRegistration = () =>  {
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const errorDisplay = useErrorDisplay<DvError>(includeAllErrors);
    const loadingState = useLoadingState();
    
    const registerCompany = useCommand((username: string, password: string, fullName: string, companyName: string, phoneNumber: string) => 
        new Api.Users.Commands.NewConsultant_BySelfRegistration({
            newUserProcessId: `new-user-process/${sguid()}`,
            username: username,
            fullName: fullName,
            password: password,
            companyName: companyName,
            companyPhoneNumber: phoneNumber,
            accountId: uuid()
        }),
        pipe(
            errorDisplay,
            after(async x => setIsCompleted(true)),
            loadingState
        ),
        '/api/user/'
    );

    return ( 
        <AnonymousPage>
            {
                !isCompleted 
                    ? <NewCompanyInformation loading={loadingState.loading} register={registerCompany} errors={errorDisplay.errors}/>
                    : <CompletedRegistrationInformation/>
            }
        </AnonymousPage>
       ) 
}