import React, { useContext } from 'react'
import ReactPDF, { View, Canvas } from '@react-pdf/renderer';
import _ from 'lodash'
import { PageContext } from './context';

type ConditionRatingProps = { rating: number, maxRating: number, width: number, spacing: number, style?: ReactPDF.Style }

export const ConditionRating = (props: ConditionRatingProps) => {
    
    const context = useContext(PageContext);
    var starColor = context.conditionRatingColors.find(x => x.rating === props.rating)?.color ?? "#EDEDEA";

    return (
        <View style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: 2 * props.spacing + props.maxRating * props.width,
            ...props.style
        }}>
            {_.range(1, props.maxRating + 1).map(i =>
                <Star key={i} width={props.width} color={i <= props.rating ? starColor : "#EDEDEA"} />
            )}
        </View>
    )
}

const Star = ({ color, width }: { color: string, width: number }) =>
    <Canvas
        style={{ width: width, height: width }}
        paint={(painter, width, height) => painter
            .scale(width / 16)
            .translate(-535, -92)
            .polygon([543, 92], [540.752, 97.241838], [535, 97.7299827], [539.3624, 101.456916], [538.056, 107], [543, 104.062457], [547.9448, 107], [546.6376, 101.456916], [551, 97.7299827], [545.2488, 97.241838])
            .fill(color)
        } />