import React from 'react'
import { StyleSheet } from '@react-pdf/renderer';
import { List, ListElement, Text, Page, Header2, TOCHeader, Header1 } from './controls'
import Api from 'server/api';

const styles = StyleSheet.create({
    title: {
        fontSize: 12,
        textAlign: 'left',
        fontFamily: 'arial',
        marginBottom: 7
    },
    section: {
        marginTop: 8,
        marginBottom: 2,
        fontSize: 10,
        textAlign: 'left',
        fontFamily: 'arial-bold'
    },
});  

const SectionHeader = (props: React.PropsWithChildren<{}>) => {
    return <Text style={styles.section}>{props.children}</Text>;
}

type Props = {
    caseName: string,
    headers: TOCHeader[],
    priorities: readonly Api.Modules.Obh.Queries.PriorityDto[],
    isCompleted: boolean
};

export const Inspection = (props: Props) =>
    <Page style={{ textAlign: 'justify' as any }} size="A4" caseName={props.caseName} showWatermark={!props.isCompleted}>
        <Header1 headers={props.headers}>Bygningsgennemgang</Header1>
        <Header2 headers={props.headers}>Beskrivelse</Header2>
        <Text>Rapporten er opbygget i bygningsdelskort, som indeholder en skematisk gennemgang af bygningsdele. Hvert bygningsdelskort indeholder følgende informationer:</Text>
        <SectionHeader>Bygningsdel</SectionHeader>
        <Text>Navngivning og klassifikation af bygningsdel.</Text>
        <SectionHeader>Placering</SectionHeader>
        <Text>Angivelse af bygningens nummer eller navn, samt bygningsdelens placering i bygningen.</Text>
        <SectionHeader>Bygningsdelsbeskrivelse</SectionHeader>
        <Text>Beskrivelse af den enkelte bygningsdel og materialeopbygning.</Text>
        <SectionHeader>Indbygningsår</SectionHeader>
        <Text>Angivelse af bygningsdelens alder.</Text>
        <SectionHeader>Forventet levetid for eksisterende bygningsdel</SectionHeader>
        <Text>Angivelse af den forventede levetid for bygningsdelen.</Text>
        <Text>Hvis der i skemaet er anbefalet en evt. genopretning, er levetiden angivet som restlevetiden for bygningsdelen, såfremt genopretning undlades.</Text>
        <SectionHeader>Tilstandsvurdering</SectionHeader>
        <Text>Beskrivelse af evt. konstaterede skader med en samlet vurdering af bygningsdelen.</Text>
        <SectionHeader>Tilstandskategori</SectionHeader>
        <Text>Kategorisering af tilstand på bygningsdelsniveau, der efterfølgende opsummeres på ejendomsniveau (under middel, middel, over middel).</Text>
        <List bullet='&bull;'>
            <ListElement label='Over middel: ' value='Kræver fortsat kun god, normal vedligeholdelse.'/>
            <ListElement label='Middel: ' value='Der må forventes nogle reparationer og en del vedligeholdelse.'/>
            <ListElement label='Under middel: ' value='Større reparations- og vedligeholdelsesarbejder må påregnes.'/>
        </List>
        <SectionHeader>Vedligeholdelses- og reparationsinstruks</SectionHeader>
        <Text>Beskrivelse af evt. nødvendige tiltag for genopretning af bygningsdelen, samt nødvendig vedligeholdelse for at bevare bygningsdelen intakt.</Text>
        <SectionHeader>Opgaver</SectionHeader>
        <Text>Oplistning af vedligeholdelsesopgaver, eftersyn og evt. skader, som skal udbedres.</Text>
        <SectionHeader>Prioritet</SectionHeader>
        <Text>Genopretnings- og vedligeholdelsesarbejderne er opdelt i 4 kategorier, som anvendes for prioritering af udførelsestidspunkt.</Text>
        <List bullet='&bull;'>
            {props.priorities.map(x =>
                <ListElement key={x.rating} label={`${x.label} (${x.helpText}): `} value={x.description} />
            )}
        </List>
        <SectionHeader>Interval</SectionHeader>
        <Text>Angivelse af anbefalet tidsinterval for udførelse af vedligeholdelse, eftersyn og evt. udbedring af skader.</Text>
        <SectionHeader>Prisoverslag</SectionHeader>
        <Text>Økonomisk overslag vedr. evt. nødvendig vedligehold/genopretning for den enkelte bygningsdel.</Text>
    </Page> 
