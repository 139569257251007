import { createStyles, Theme } from "@material-ui/core";

export default (theme: Theme) => createStyles({
    caseRow:{
        height: 55,
    },
    clickable: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    radioButtons: {
        margin: theme.spacing(2),
        border: '1px solid lightgray',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '8px',
        marginBottom: '19px',
        padding: '4px 16px 0px 16px'
    },
    searchField: {
        marginLeft: '16px',
        width: '20em'
    },
    spacer: {
        minHeight: theme.spacing(21)
    },
    title: {
        textTransform: 'uppercase'
    },
    caseType: {
        '&:hover': {
            color: '#56DFA6'
        }
    }
});
