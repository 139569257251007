import React, { useContext } from 'react'
import ReactPDF, { Image as PdfImage, View, Text as PdfText } from '@react-pdf/renderer';
import { BoldText } from '../text'
import Api from 'server/api'
import { PageContext, ReportThemeContext } from '../context'
import { EbasHouseGraphic, NrgiLogo } from './ebas-graphics'

import Es = Api.Cases.Queries.Reports.Es;
import MasterDataDto = Es.MasterDataDto;

const Title = (props: ReactPDF.TextProps) => {
    const colorTheme = useContext(ReportThemeContext).color;

    return <PdfText {...props} style={{
        color: colorTheme.primaryColor,
        fontSize: 51.4,
        fontFamily: 'DINOT Black',
        ...props.style
    }}/>
}
const SubTitle = (props: ReactPDF.TextProps) => 
    <BoldText {...props} style={{
        backgroundColor: '#FFFFFF',
        marginBottom: 2.5,
        fontSize: 14,
        padding: '3px 8px 3px 8px',
        lineHeight: 1.28,
        minHeight: 25
    }}/>

export const EbasFrontPage = (props: {masterData: MasterDataDto}) => {
    
    const context = useContext(PageContext);
    
    return <View style={{ height: '100%', marginLeft: -5 }}>
        <View style={{ right: -2, top: -12, alignSelf: 'flex-end' }}>
            <NrgiLogo height={43} />
        </View>
        <View style={{ position: 'absolute', top: 86, height: 645, justifyContent: 'space-between' }}>
            {/* z-index is not supported by React-pdf, so this needs to be the first element in order to remain in the background */}
            <View style={{ position: 'absolute' }}>
                <EbasHouseGraphic height={500} />
            </View>
            <View>
                <Title>{context.reportTitle}</Title>
                <View style={{ width: 335, marginTop: 20 }}>
                    <SubTitle>{props.masterData.case.nickName}</SubTitle>
                    <SubTitle>{[props.masterData.case.extensions.ebas.streetName, props.masterData.case.extensions.ebas.houseNumber].filter(x => x).join(' ')}</SubTitle>
                    <SubTitle>{[props.masterData.case.extensions.ebas.postalCode, props.masterData.case.extensions.ebas.postalCity].filter(x => x).join(' ')}</SubTitle>
                </View>
            </View>
            {props.masterData.case.imageUrl && <PdfImage style={{
                    height: 186,
                    objectFit: 'contain',
                    objectPosition: 'right bottom',
                    alignSelf: 'flex-end',
                    right: 2
                } as any} source={'/api/' + props.masterData.case.imageUrl + '&width=1146'} />}
        </View>
    </View>
}