import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography, withStyles, WithStyles } from "@material-ui/core";
import styles from './styles'
import AdminSettings from "./admin-settings";
import { Users } from "./users";
import GdprApproval from "./gdpr-approval";
import Page from 'controls/page'
import { Authentication } from "root";
import { TemplatesAndTypeMappings } from "./templates-and-type-mappings";
import { CompanyInfo } from "./company-info";
import { Paper } from "controls/paper";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Api from "server/api";
import TypeMappingsDto = Api.Users.Queries.GetTypeMappings.TypeMappingsDto;
import TemplateGroupDto = Api.Templates.Queries.GetCompanyTemplates.TemplateGroupDto;
import { useCommandSubscription, useImmutableArrayState, useServer } from "custom-hooks";

type Props = {
    authentication: Authentication
} & WithStyles<typeof styles>

export const Settings = withStyles(styles)((props: Props) => {

    const [templates, setTemplates] = useImmutableArrayState<TemplateGroupDto>();
    const [typeMappings, setTypeMappings] = useImmutableArrayState<TypeMappingsDto>();

    const server = useServer();

    useCommandSubscription(() =>
        server.query<TemplateGroupDto[]>(
            new Api.Templates.Queries.GetCompanyTemplates({})
        ).subscribe(setTemplates), []);

    useCommandSubscription(() =>
        server.query<TypeMappingsDto[]>(
            new Api.Users.Queries.GetTypeMappings({})
        ).subscribe(setTypeMappings), []);

    return (
        <Page>

            <Paper>
                {props.authentication.isSystemAdmin ? <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant='h6'>Systemadministration</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AdminSettings isImpersonating={props.authentication.isImpersonating} />
                    </AccordionDetails>
                </Accordion> : null}

                {props.authentication.isSystemAdmin || props.authentication.isCompanyAdmin ? <>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant='h6'>Firmaoplysninger</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <CompanyInfo authentication={props.authentication} key='company' />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant='h6'>Opret og rediger brugere i firmaet</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Users key='users' authentication={props.authentication} />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant='h6'>Databehandleraftale</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <GdprApproval key='gdprapproval' />
                        </AccordionDetails>
                    </Accordion>
                </> : null}

                {props.authentication.isSystemAdmin ||
                    (props.authentication.isCompanyAdmin && (templates.length > 0 || typeMappings.length > 0)) ? <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant='h6'>Skabeloner og Klassifikationer</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TemplatesAndTypeMappings key='templatesandtypemappings' authentication={props.authentication} templates={templates} />
                        </AccordionDetails>
                    </Accordion>
                    : null}
            </Paper>

        </Page>);
});