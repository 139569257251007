import React, { useState } from 'react';
import Select from 'react-select';
import { ValueType, ActionMeta } from 'react-select/lib/types';
import { Option } from 'react-select/lib/filters';
import { useWriteLock } from 'custom-hooks';
import { Lockable } from 'utils/lockable';

type OptionType = {label: string, value: string} | null;
type GroupedOptionType<T extends ValueType<OptionType>> = { label: string; options: ReadonlyArray<T>; };

type Props<T extends OptionType> = {
    value: T;
    onChange: (value: T) => void;
    options: GroupedOptionType<T>[] | T[];
    label?: string;
    placeholder?: string;
    autoFocus?: boolean;
} & Lockable;

export const customSearch = (option: Option, searchText: string) => {        
    if(!searchText || searchText.length === 0) return true;

    const needles = searchText.toLowerCase().split(' ');
    const label = option.label.toLowerCase();

    return needles.every(needle => label.includes(needle));
}

export const SearchableTextField = <T extends OptionType>(props: Props<T>) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const writeLock = useWriteLock(props);

    const onChange = (value: T, action: ActionMeta) => {
        props.onChange(value);
    }

    return (
        // Warning! When editing this select, there is no typechecking of props.
        // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/34959
        <Select
            autoFocus={props.autoFocus}
            styles={{
                container: (base, state) => ({
                    ...base,
                    marginTop: 16,
                    marginBottom: 8,
                    fontFamily: 'Open Sans',
                    lineHeight: 1.43 // override Paper.Root line-height=1, otherwise char's below baseline, gets clipped (e.g. 'g')
                }),
                menuPortal: (base) => ({
                    ...base,
                    zIndex: 2
                })
            }}
            isClearable
            placeholder={props.placeholder || 'Vælg...'}
            options={props.options}
            value={props.value}
            onChange={onChange}
            isDisabled={writeLock}
            filterOption={customSearch}
            isSearchable
            noOptionsMessage={() => "Ingen valgmuligheder"}
            menuPosition='fixed'       
            onMenuClose={() => setMenuOpen(false)}
            onMenuOpen={() => setMenuOpen(true)}
            onKeyDown={e => menuOpen && e.stopPropagation()}
        />
    )
}