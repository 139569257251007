import React, { useContext } from 'react'
import { View } from '@react-pdf/renderer';
import { Text, BoldText, ItalicText, SmallText, SmallBoldText, SmallItalicText, SmallBoldItalicText, InvisibleHeader1 } from './text'
import { Table, Row } from 'pages/report/controls/table'
import { ConditionRating } from './condition-rating';
import Api from 'server/api'
import { numeral } from 'utils'
import _ from 'lodash'
import { ReportThemeContext } from './context'

import EsReportBudget = Api.Cases.Queries.Reports.Es.EsReportBudget
import EsReportGroupLine = Api.Cases.Queries.Reports.Es.EsReportGroupLine
import EsReportLine = Api.Cases.Queries.Reports.Es.EsReportLine
import EsReportTotalLine = Api.Cases.Queries.Reports.Es.EsReportTotalLine

type RowProps = {
    /**
     * Property passed to react-pdf to tell it that no page wrapping should occur
     * between all sibling elements following this row within n points
     * @see https://react-pdf.org/advanced#orphan-&-widow-protection
     */
    minPresenceAhead?: 30 | 60 | 90
}

const FixedYearHeader = (props: RowProps & { years: readonly string[] }) => {
    const colorTheme = useContext(ReportThemeContext).color;

    return <Row fixed
        style={{
            backgroundColor: colorTheme.quaternaryColor, 
            height: 18
        }}
        minPresenceAhead={props.minPresenceAhead}>
        <View style={{ flexDirection: 'row' }}>
            <SmallBoldText style={{ 
                paddingRight: 4,
                marginRight: 6, 
                borderRight: 0, 
                borderRightColor: '#999' }}
            ></SmallBoldText>
        </View>
        {props.years.map(year => <SmallBoldText key={year} style={{ textAlign: 'right' }}>{year}</SmallBoldText>)}
        <View />        
        <SmallBoldText style={{ textAlign: 'right' }}>Samlet</SmallBoldText>
    </Row>
}

const GroupRow = (props: RowProps & { budget: EsReportBudget, group: EsReportGroupLine, first: boolean }) => {
    const colorTheme = useContext(ReportThemeContext).color;

    return <Row 
        style={{
            backgroundColor: colorTheme.quaternaryColor, 
            borderTop: 0.5,
            height: 18,
            borderTopColor: '#999A94'
        }}
        minPresenceAhead={props.minPresenceAhead} >
        <View style={{ flexDirection: 'row' }}>
            <SmallBoldText style={{ 
                paddingRight: 4,
                marginRight: 6, 
                borderRight: props.group.maxConditionRating ? 1 : 0, 
                borderRightColor: '#999' }}
            >{props.group.title}</SmallBoldText>
            {props.group.maxConditionRating && <ConditionRating
                rating={props.group.conditionRating}
                maxRating={props.group.maxConditionRating}
                width={7}
                spacing={1} />}
        </View>
        {props.budget.years.map(year => <View key={year}></View>)}
        <View />
        <View />
    </Row>
}

const TaskRow = (props: RowProps & { line: EsReportLine, first: boolean }) => {
    const colorTheme = useContext(ReportThemeContext).color;

    return <Row style={{ 
        backgroundColor: '#FFF', 
        height: 24,
        ...!props.first && {
            borderTop: 1,
            borderTopColor: '#EDEDEA',
            height: 25
        }
    }}
    minPresenceAhead={props.minPresenceAhead} >
        { /* This is not a proper way to address variations. Instead please move the composition further out, and make 
             independent units. That might give some code duplication, but that is preferable to have a deep hierarchy 
             of components that have all sorts of built in variation based on "hidden" information e.g. the case type or something alike. 
             In other words: Switch variations as close to the root as possible. */ }
        {props.line.tag == 'EsReportPercentageTaskLine' 
            ? <View style={{ flexDirection: 'row' }}>
                <Text style={{ flex: 1 }}>{props.line.title}</Text>
                <Text style={{ width: 20 }}>{props.line.percentage} %</Text>
            </View>
            : <Text>{props.line.title}</Text>}
        {props.line.years.map((x, i) => 
            <Text key={i} style={{ textAlign: 'right', opacity: x == 0 ? 0.2 : 1 }}>
                {numeral(x).format('0,0')}
            </Text>)}
        <View/>
        <BoldText style={{
            alignSelf: "flex-end",
            height: 25,
            marginLeft: 9,
            marginRight: -9,
            paddingRight: 9,
            paddingVertical: 7,
            textAlign: 'right',
            width: 43,
            ...props.line.lineTotal !== null && { backgroundColor: colorTheme.quaternaryColor }
        }}>
        {props.line.lineTotal !== null
            ? numeral(props.line.lineTotal).format('0,0')
            : "..."}
        </BoldText>
    </Row>
}
const GroupSumRow = (props: RowProps & { groupSumLine: EsReportTotalLine }) => {
    const colorTheme = useContext(ReportThemeContext).color;

    return <Row 
        style={{ 
            backgroundColor: '#FFF', 
            height: 17,
            borderTop: 0.5,
            borderTopColor: '#999A94'
        }}
        minPresenceAhead={props.minPresenceAhead} > 
        <SmallText>{props.groupSumLine.title}</SmallText>
        {props.groupSumLine.years.map((x, i) =>
            <SmallItalicText key={i} style={{ textAlign: 'right', opacity: x == 0 ? 0.2 : 1 }}>
                {numeral(x).format('0,0')}
            </SmallItalicText>
        )}
        <View/>
        <SmallBoldItalicText style={{
            alignSelf: "flex-end",
            height: 17, 
            marginLeft: 9, 
            marginRight: -9, 
            paddingRight: 9, 
            paddingVertical: 4.2, 
            textAlign: 'right',
            width: 43,
            ...props.groupSumLine.lineTotal !== null && { backgroundColor: colorTheme.quaternaryColor} 
            }}>
            {props.groupSumLine.lineTotal !== null
                ? numeral(props.groupSumLine.lineTotal).format('0,0')
                : "..."}
        </SmallBoldItalicText>
    </Row>
}
const TotalRow = (props: RowProps & { sumLine: EsReportTotalLine, first: boolean }) => {
    const colorTheme = useContext(ReportThemeContext).color;

    return <Row 
        style={{ 
            backgroundColor: colorTheme.quaternaryColor, 
            borderTop: props.first ? 0.5 : 0, 
            borderTopColor: '#999A94',
            height: 17
        }}
        minPresenceAhead={props.minPresenceAhead} >
        <BoldText>{props.sumLine.title}</BoldText>
        {props.sumLine.years.map((x, i) =>
            <BoldText key={i} style={{ textAlign: 'right', opacity: x == 0 ? 0.2 : 1 }}>
                {numeral(x).format('0,0')}
            </BoldText>
        )}
        <View/>
        {props.sumLine.lineTotal !== null && <BoldText style={{
            alignSelf: "flex-end",
            height: 21,
            marginLeft: 9,
            paddingVertical: 5.5,
            textAlign: 'right',
            width: 43 }}>
            {numeral(props.sumLine.lineTotal).format('0,0')}
        </BoldText>}
    </Row>
}
const Description = (props: { text: string }) =>
    <ItalicText style={{ 
        backgroundColor: '#FFF',
        margin: '10 10 0 10'
    }}>
        {props.text}
    </ItalicText>

const isLast = (array: readonly any[], index: number) => index == array.length - 1

export default (props: EsReportBudget & { index: number, footNote: string }) =>
    <View>
        {props.index === 0 &&
            <InvisibleHeader1>Budget</InvisibleHeader1>}
        <Table
            colWidths={[266, ...props.years.map(_ => 43), 0, 43]}
            rowStyle={{
                alignItems: 'center',
                paddingHorizontal: 9,
            }}>
            <FixedYearHeader years={props.years} minPresenceAhead={30}></FixedYearHeader>
            {_.flattenDeep(props.groups.map((group: EsReportGroupLine, i) => [
                <GroupRow budget={props} group={group} key={group.title + i} first={i == 0} minPresenceAhead={90} />,
                group.lines.map((line: EsReportLine, taskIndex) =>
                    <TaskRow 
                        key={line.title + taskIndex} 
                        line={line} 
                        first={taskIndex == 0}
                        minPresenceAhead={isLast(group.lines, taskIndex) ? 30 : null} />
                ),
                <GroupSumRow groupSumLine={group.total} minPresenceAhead={isLast(props.groups, i) ? 60 : null} />
            ]))}
            {props.totals.map((line, totalsIndex) => 
                <TotalRow 
                    sumLine={line} 
                    first={totalsIndex==0} 
                    minPresenceAhead={isLast(props.totals, totalsIndex) ? 60 : null} />)}
            <View fixed style={{ height: 0, borderTop: 0.5, borderTopColor: '#D6D7D0' }}></View>
            <Description text={props.footNote} />
        </Table>
    </View>