
import Api from "./server/api";
import _ = require("lodash");

export type Templates = Dictionary<Api.Cases.Queries.GetCompanyTemplatesForCase.TemplateGroupDto>;

export const getGroupedOptions = (templates: Templates) =>
    _.map(templates, x => ({ 
        label: x.group, 
        options: x.types.map(t => ({ label: t, value: t, group: x.group })) 
    }));