import React, { forwardRef } from "react";
import MaterialUiIconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import { useWriteLock } from "custom-hooks";
import { Lockable } from "utils/lockable";

type Props = IconButtonProps & { href?: string } & Lockable;

export const IconButton = forwardRef((props: Props, ref) => {

    const writeLock = useWriteLock(props)

    return (
        <MaterialUiIconButton {...props} 
            buttonRef={ref} 
            disabled={writeLock} />
    )
})