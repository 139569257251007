import React from 'react';
import { withStyles, WithStyles, TableRow, TableCell } from "@material-ui/core";
import { IconButton } from 'controls/button/icon-button';
import DeleteIcon from '@material-ui/icons/Delete'
import Component from "component";
import styles from './styles';
import { WarningPopup } from 'controls/warning-popup';
import Api from "server/api";
import { TableField } from 'controls/field/table-field';
import { upperFirst } from 'lodash';
import { numeral, sguid } from 'utils';
import { DuplicateIcon } from 'controls/images/custom-icons';
import { Tooltip } from 'controls/tooltip';

type Props = {
    caseId?: string,
    cardId?: string,
    taskId: string,
    budgetLine: Api.Cases.Queries.GetTask.BudgetLineDto,
} & WithStyles<typeof styles>

type State = {
    isWarningPopupOpen: boolean
}

export default withStyles(styles)(class BudgetLine extends Component<Props, State> {
    state = { isWarningPopupOpen: false, budgetLine: this.props.budgetLine }

    render() {
        const budgetLine  = this.props.budgetLine;

        return (<TableRow hover>
            <TableCell className={this.props.classes.descriptionCell}>
                <TableField
                    type='text'
                    value={budgetLine.description}
                    onValidBlur={this.update('description')} />   
            </TableCell>
            <TableCell className={this.props.classes.cell}  >
                <TableField
                    value={budgetLine.amount}
                    type='number'
                    onValidBlur={this.update('amount')} />
            </TableCell>
            <TableCell className={this.props.classes.cell}>
                <TableField
                    type='text'
                    value={budgetLine.unit}
                    onValidBlur={this.update('unit')} />
            </TableCell>
            <TableCell className={this.props.classes.cell}>
                <TableField
                    value={budgetLine.pricePerUnit}
                    type='number'
                    onValidBlur={this.update('pricePerUnit')} />
            </TableCell>
            <TableCell className={this.props.classes.cell}>
                <TableField
                    value={budgetLine.fixedPrice}
                    type='number'
                    onValidBlur={this.update('fixedPrice')} />
            </TableCell>
            <TableCell className={this.props.classes.sumCell}>
                {numeral(this.props.budgetLine.subtotal).format('0,0')}
            </TableCell>
            <TableCell>
                <div className={this.props.classes.iconColumn}>
                    <Tooltip title='Kopier budgetlinjen'>
                        <IconButton
                            onClick={() => this.duplicate(budgetLine.id)}>
                            <DuplicateIcon size={20} classSelector={c => c.contentColors} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Slet budgetlinjen'>
                        <IconButton
                            onClick={() => this.setState({ isWarningPopupOpen: true })}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </div>
                <WarningPopup
                    onOk={() => {
                        this.setState({ isWarningPopupOpen: false });
                        return this.delete(budgetLine)
                    }}
                    onClose={() => this.setState({ isWarningPopupOpen: false })}
                    isOpen={this.state.isWarningPopupOpen}
                    title='Slet budgetlinjen?'
                    message='Du er ved at slette budgetlinjen. Er du sikker?'
                    okText='SLET'
                    cancelText='FORTRYD' />
            </TableCell>
        </TableRow>);
    }   

    delete = async (data: Api.Cases.Queries.GetTask.BudgetLineDto) => {
        await this.server.command(new Api.Cases.Commands.DeleteBudgetLine({ 
            caseId: this.props.caseId, 
            cardId: this.props.cardId, 
            budgetLineId: data.id 
        }));
    }

    duplicate = async (originBudgetLineId: string) => {
        await this.server.command(new Api.Cases.Commands.DuplicateBudgetLine({
            caseId: this.props.caseId, 
            cardId: this.props.cardId,
            taskId: this.props.taskId,
            originBudgetLineId: originBudgetLineId,
            newBudgetLineId: `budget/${sguid()}`
        }));
    }

    update = <TKey extends keyof Api.Cases.Queries.GetTask.BudgetLineDto>(name: TKey) => async (value: Api.Cases.Queries.GetTask.BudgetLineDto[TKey]) => {
        await this.server.command(new Api.Cases.Commands.WriteBudgetLineField({
            caseId: this.props.caseId, 
            cardId: this.props.cardId,
            budgetLineId: this.props.budgetLine.id,
            fieldName: upperFirst(name),
            value: value
        }));
    };
})