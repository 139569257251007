import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import Api from "server/api";
import { TaskYearCell } from './task-year-cell';
import { get } from "utils";

type Props = {
    budget: Api.Cases.Queries.GetBudget.Budget;
    sum: Api.Cases.TotalLine;
    className?: string;
}

export const SumRow = (props: Props) => (
    <TableRow className={props.className}>
        <TableCell colSpan={get(props.budget.extensions, 'PriorityHeader') ? 4 : 3}>{props.sum.title}</TableCell>
        {props.sum.years.map((value, index) => <TaskYearCell key={index} price={value} />)}
    </TableRow>
)