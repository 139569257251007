import React, { useState, useCallback, useEffect } from "react";
import { useServer, useImmutableArrayState, useCommand, after } from "custom-hooks";
import { Templates } from "templates";

import Api from "server/api";

import ConditionRatingDto = Api.Cases.Queries.GetConditionRatings.ConditionRatingDto;
import PriorityDto = Api.Modules.Priority.Queries.GetPriorities.PriorityDto;
import TypeMappingsDto = Api.Users.Queries.GetTypeMappings.TypeMappingsDto;
import CaseTypeDto = Api.Cases.Queries.GetCaseTypes.CaseTypeDto;

type Props = { 
    caseId: string, 
    children: (props?: object) => React.ReactElement 
}

export const LoadCaseData = (props: Props) => {
        
    const server = useServer();

    const [templates, setTemplates] = useState<Templates>({});
    const [conditionRatings, setConditionRatings] = useImmutableArrayState<ConditionRatingDto>();
    const [priorities, setPriorities] = useImmutableArrayState<PriorityDto>();
    const [typeMappings, setTypeMappings] = useImmutableArrayState<TypeMappingsDto>();
    const [caseTypes, setCaseTypes] = useImmutableArrayState<CaseTypeDto>();

    const loadCaseData = useCallback(async () => {
        if(props.caseId === undefined) return;

        const templatesPromise = server.query<Templates>(
            new Api.Cases.Queries.GetCompanyTemplatesForCase({ caseId: props.caseId })
        ).toPromise();

        const conditionRatingsPromise = server.query<ConditionRatingDto[]>(
            new Api.Cases.Queries.GetConditionRatings({ caseId: props.caseId })
        ).toPromise();

        const prioritiesPromise = server.query<PriorityDto[]>(
            new Api.Modules.Priority.Queries.GetPriorities({ caseId: props.caseId })
        ).toPromise();

        const typeMappingsPromise = server.query<TypeMappingsDto[]>(
            new Api.Users.Queries.GetTypeMappings({})
        ).toPromise();

        const [templates, conditionRatings, priorities, typeMappings] =
            await Promise.all([templatesPromise, conditionRatingsPromise, prioritiesPromise, typeMappingsPromise]);

        setTemplates(templates);
        setConditionRatings(conditionRatings);
        setPriorities(priorities);
        setTypeMappings(typeMappings);
        
        return () => {
            setTemplates({});
            setConditionRatings([]);
            setPriorities([]);
            setTypeMappings([]);
        };
    }, [server, props.caseId])
    
    const updateSettings = useCommand((caseId: string) => 
        new Api.Cases.Commands.UpdateSettingsOnCase({ caseId: caseId }), 
        after(async () => await loadCaseData()));

    useEffect(() => {
        server.query<CaseTypeDto[]>(new Api.Cases.Queries.GetCaseTypes({}))
            .subscribe(setCaseTypes);
    }, []);

    useEffect(() => { loadCaseData() }, [loadCaseData]);

    return <>
        {props.children({templates, conditionRatings, priorities, typeMappings, caseTypes, updateSettings })}
    </>
}