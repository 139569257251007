import React from 'react';
import { Button, makeStyles } from "@material-ui/core";
import styles from './styles'
import cx from 'classnames';
import { useWriteLock } from 'custom-hooks';
import { Lockable } from 'utils/lockable';

type Props = React.PropsWithChildren<{
    onClick?: () => void;
    className?: string;
    download?: string;
    href?: string;
}> & Lockable;

const useStyles = makeStyles(styles);

export const ActionButton = React.forwardRef<any, Props>((props, ref) => {

    const styles = useStyles(props);
    const writeLock = useWriteLock(props);

    return (
        <Button 
            {...props}
            ref={ref}
            color="primary" 
            href={props.href} 
            download={props.download}
            className={cx(
                styles.button, 
                styles.actionButton, 
                writeLock ? styles.disabled : '', 
                props.className)}
            onClick={e => {
                e.stopPropagation();
                return props.onClick && props.onClick();
            }}
            disabled={writeLock}>
                {props.children}
        </Button>
    );
})