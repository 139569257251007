import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Button } from "controls/button";
import { hasValue } from "utils";
import { DvError } from "custom-hooks";
import { ErrorListDisplay } from "controls/error-list-display";
import { AnonymousField } from 'controls/field/anonymous-field';

const useStyles = makeStyles({
    registerButton: {
        width: '100%', 
        height: 50, 
        fontSize: 14
    }
});

type Props = {
    errors: readonly DvError[],
    loading: boolean,
    requestResetPassword: (username: string) => void,
}

export const RequestByUsername = (props: Props) =>  {
    const styles = useStyles();

    const [username, setUsername] = useState('');

    const canExecute = () => hasValue(username);
    const requestResetPassword = () => props.requestResetPassword(username)

    const onKeyDown = async (event: React.KeyboardEvent<any>) => {
        if (event.key === 'Enter') {
            requestResetPassword();
        }
    }
   
    return ( 
        <div style={{flex: 1, flexDirection:'column'}}>
            <div style={{display:'flex', flexDirection:'column', alignItems: 'center'}}>
                <div style={{fontSize: 30}}>Nulstil og opret ny adgangskode i D&V Plan</div>
                <div style={{fontSize: 14, margin:30}}>Når du har trykket nulstil, vil du modtage en mail, der hjælper dig til at oprette en ny adgangskode.</div>
            </div>

            <div style={{display:'flex', flexDirection:'row', alignItems: 'stretch', fontSize: 14, marginTop:30}}>
                <div style={{flex: 1, paddingTop: 3}}><hr/></div>
                <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>Indtast brugernavn</div>
                <div style={{flex: 1, paddingTop: 3}}><hr/></div>
            </div>

            <div style={{display:'flex', flexDirection:'column'}}>
                <AnonymousField     
                    type='text'
                    label='Brugernavn'
                    value={username}
                    onValidChange={setUsername}
                    onKeyDown={onKeyDown}
                />
            </div> 

            <div style={{marginTop:20}}>
                <Button loading={props.loading} disabled={!canExecute() } className={styles.registerButton} onClick={requestResetPassword} label='Nulstil' />
            </div>

            <ErrorListDisplay errors={ props.errors ? props.errors :[]}/>
        </div>
    )       
}