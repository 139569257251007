import React, { useState, useEffect, useMemo } from "react";
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Grid, Toolbar } from "@material-ui/core";
import { Popup } from 'controls/popup';
import { FormField } from "controls/field/form-field";
import { Button } from "controls/button";
import _ from "lodash";
import { SearchableTextField } from "controls/field/searchable-text-field";
import { Templates, getGroupedOptions } from "templates";

export type CardData = {
    readonly description: string;
    readonly location: string;
}

type Props = {
    onOk: (group: string, type: string, data: CardData, templateId: string) => Promise<unknown>;
    onClose: () => void;
    open: boolean;
    templates: Templates;
}

type SelectedType = { label: string, value: string, group: string } | null
type SelectedTemplate = { label: string, value: string, description: string } | null

export const CreateCardPopup = (props: Props) => {
    const [description, setDescription] = useState('');
    const [location, setLocation] = useState('');
    const [selectedType, setSelectedType] = useState<SelectedType>(null);
    const [selectedTemplate, setSelectedTemplate] = useState<SelectedTemplate>(null);

    useEffect(() => {
        setDescription('');
        setLocation('');
        setSelectedType(null);
        setSelectedTemplate(null);
    }, [props.open]);

    const canExecute = selectedType;

    const create = () => {
        if (!canExecute) return;

        return props.onOk(
            selectedType.group,
            selectedType.value,
            {
                description: description,
                location: location,
            },
            selectedTemplate && selectedTemplate.value);
    }

    const getSelectedTemplates = () => {
        if (!selectedType) return [];

        const group = props.templates[selectedType.group];
        if (!group) return [];

        const cardTemplates = group.cardTemplates[selectedType.value]
        return cardTemplates ? cardTemplates : [];
    };
    
    const groupedOptions = useMemo(() => getGroupedOptions(props.templates), [props.templates]);
    const templates = useMemo(() => getSelectedTemplates().map(x => ({label: x.searchTitle, value: x.id, description: x.description})), [selectedType]);

    useEffect(() => {
        if (templates.length == 1) setSelectedTemplate(templates[0]);
    }, [selectedType]);

    return (
        <Popup
            maxWidth='lg'
            open={props.open}
            onClose={props.onClose}
            onOk={create}
            onCancel={props.onClose}>

            <DialogTitle>Opret kort</DialogTitle>

            <DialogContent>
                <DialogContentText>
                    Opret via skabelonerne
                </DialogContentText>
                <SearchableTextField
                    label="Type"
                    value={selectedType}
                    options={groupedOptions}
                    placeholder="Vælg type..."
                    autoFocus
                    onChange={(value: SelectedType) => {
                        setSelectedType(value);
                        setSelectedTemplate(null)
                    }}
                />

                <SearchableTextField
                    label="Skabelon"
                    value={selectedTemplate}
                    placeholder="Vælg skabelon..."
                    options={templates}
                    onChange={(value: SelectedTemplate) => {
                        setSelectedTemplate(value);
                        setDescription(value && value.description);
                    }}
                    disabled={!selectedType}
                />

                <FormField
                    type='text'
                    label='Placering'
                    value={location}
                    optional
                    onValidChange={setLocation} />
                <FormField
                    type='text'
                    label='Beskrivelse'
                    value={description}
                    optional
                    onValidChange={setDescription} />

            </DialogContent>

            <DialogActions>
                <Toolbar>
                    <Button label='Fortryd' onClick={props.onClose} />
                    <Button label='Opret'
                        disabled={!canExecute}
                        onClick={create} />
                </Toolbar>
            </DialogActions>
        </Popup>);
};