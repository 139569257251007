import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, TableFooter, makeStyles } from "@material-ui/core";
import styles from './styles';
import Api from "server/api";
import { Button } from 'controls/button';
import BudgetLine from './budget-line';
import { Paper } from 'controls/paper'
import { sguid } from 'utils';
import { useCommand } from 'custom-hooks/use-command';
import { SumLine } from './sum-line';

type Props = {
    caseId?: string,
    cardId?: string,
    taskId: string,
    budgetLines: readonly Api.Cases.Queries.GetTask.BudgetLineDto[]
}

const useStyles = makeStyles(styles);

export const BudgetLines = (props: Props) => {

    const styles = useStyles();

    const createBudgetLine = useCommand(() => 
        new Api.Cases.Commands.CreateBudgetLine({
            caseId: props.caseId,
            cardId: props.cardId, 
            taskId: props.taskId, 
            newBudgetLineId: `budget/${sguid()}`
        }));

    if (props.budgetLines.length === 0) {
        return (
            <Paper className={styles.container}>
                <Button label='Tilføj Budgetlinje' onClick={createBudgetLine} />
            </Paper>);
    }
        
    const budgetSum = props.budgetLines.map(x => x.subtotal).reduce((x, y) => x + y);

    return (
        <Paper className={styles.container} header='Budget'>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={styles.descriptionCell}>Beskrivelse</TableCell>
                        <TableCell className={styles.cell}>Antal</TableCell>
                        <TableCell className={styles.cell}>Enhed</TableCell>
                        <TableCell className={styles.cell}>Enhedspris</TableCell>
                        <TableCell className={styles.cell}>Fastpris</TableCell>
                        <TableCell className={styles.sumCell}>Pris i alt</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.budgetLines.map(row => (
                        <BudgetLine
                            key={row.id}
                            budgetLine={row}
                            caseId={props.caseId}
                            cardId={props.cardId}
                            taskId={props.taskId}
                        />
                    ))}
                </TableBody>
                <TableFooter className={styles.footer}>
                    <SumLine sum={budgetSum} text="I alt ekskl. moms"/>
                    <SumLine sum={budgetSum * 0.25} text="Moms (25%)" underline="solid" italic={true}/>
                    <SumLine sum={budgetSum * 1.25} text="I alt inkl. moms" underline="double"/>
                    <TableRow className={styles.rowSpacer}/>
                </TableFooter>
            </Table>
            <Button label='Tilføj Budgetlinje' onClick={createBudgetLine} />
        </Paper>
    )
}