import React, { useCallback } from 'react';
import { FormControl, InputLabel, Select, FilledInput, MenuItem, makeStyles } from '@material-ui/core';
import styles from './styles';
import { AutoProps, isAuto } from '../.';
import { useWriteLock } from 'custom-hooks';
import { Lockable } from 'utils/lockable';

type Props<T> = {
    label: string;
    value: T;
    items: Item<T>[];
    optional?: boolean;
    onChange: (value: T, event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
} & Lockable;

type Item<T> = {
    label: string;
    value: T;
}

const useStyles = makeStyles(styles);

export const SelectField = <T extends string | {}>(props: Props<T> | AutoProps<Props<T>>) => {
    
    if (isAuto(props)) {
        if (props.field == null) return null;

        return <Field
            {...props}
            value={props.field.value}
            disabled={props.disabled || !props.field.writable}
        />
    }

    return (
        <Field
            {...props}
        />
    );
};

const Field = <T extends string | {}>(props: Props<T>) => {

    const writeLock = useWriteLock(props);

    const onChange = useCallback((event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const value = event.target.value !== "NOVALUE" ? event.target.value : null;
        props.onChange(value as T, event);
    }, [props.onChange])

    const styles = useStyles();
    
    return (
        <FormControl disabled={writeLock} variant="filled" className={styles.selector}>
            <InputLabel shrink>{props.label}</InputLabel>
            <Select
                value={props.value ?? "NOVALUE"}
                onChange={onChange}
                input={<FilledInput style={{
                    // Default fontSize is 16px, but due to a bug with using Open Sans font, it gives a 1px scroll-bug in text fields
                    // (see src\app\controls\field\index.tsx). This is resized to align with those.
                    fontSize: 15
                }} />}
                error={props.value === null}
            >
                {props.optional && <MenuItem value={"NOVALUE"}><em>Vælg...</em></MenuItem>}
                {props.items.map(x => <MenuItem key={x.value as any} value={x.value as any}>{x.label}</MenuItem>)}
            </Select>
        </FormControl>
    );
}