import { createStyles, Theme } from "@material-ui/core";
import { buttonGradient, buttonColors } from "theme";

export default (theme: Theme) => createStyles({
    fab: {
        margin: 10,
        textAlign: 'center'
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        margin: 30,
        bottom: 0,
        right: 0
    },
    toolbarHeader: {
        position: 'absolute',
        bottom: 105,
        left: 10,
        fontSize: 'smaller'
    },
    disabled: {
        background: buttonColors.disabled.background1,
        boxShadow: 'none',
        color: buttonColors.disabled.text
    },
});