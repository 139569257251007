import React from 'react'
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core";
import cx from 'classnames';

const styles = (theme: Theme) => createStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'auto'
    }
});

type Props = {
    style?: React.CSSProperties
    children?: React.ReactNode
    className?: string
} & WithStyles<typeof styles>

export default withStyles(styles)((props: Props) => (
    <div className={cx(props.classes.content, props.className)} style={props.style}>
        {props.children}
    </div>
));
